<template>
  <div style="max-width: 290px">
    <form v-on:submit.prevent="$v.$invalid ? null : verify()">
      <div class="forgot-password-title q-mb-xs app-text-normal">
        Forgot password
      </div>
      <div class="check-email-instruction app-text-normal">
        We sent an e-mail with your code to
        <span class="email">{{ email }}</span>
      </div>
      <div class="input-label app-text-normal">
        Input your 5 digits Verification code
      </div>
      <masked-input
        placeholder="000-00"
        mask="111-11"
        @input="code = arguments[1]"
        class="masked-box"
        :error-messages="fieldErrors('code')"
        @blur="$v.code.$touch()"
        type="tel"
      />
      <timmer
        :seconds="60"
        :onlyseconds="true"
        @timeover="startTimer = false"
        v-show="startTimer"
      >
        <span class="q-mr-xs app-text-normal" style="font-weight: 600"
          >Resend Verification Code in</span
        >
        <span slot="footer">seconds.</span>
      </timmer>
      <q-btn
        flat
        dense
        color="indigo-5"
        size="md"
        label="Resend Verification Code"
        no-caps
        class="q-mt-sm block app-text-normal"
        @click.native.stop="sendPasswordResetCode"
        v-if="!startTimer"
      />
      <p v-show="invalidCodeMessage" class="q-mt-sm text-negative">
        {{ invalidCodeMessage }}
      </p>
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        padding="0"
        label="Reset password"
        class="signin-btn-class app-text-normal"
        @click="verify()"
        :loading="loader"
        :disabled="$v.$invalid || loader"
      />
    </form>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import VerificationCodeMixin from "@/mixins/VerificationCodeMixin";
import errorHandler from "@/utils/ErrorHandler";
import { CheckPasswordResetCodeMutation } from "@/gql";

export default {
  name: "Step3",
  props: ["email", "companyUri"],
  mixins: [validationMixin, VerificationCodeMixin],
  validations: {
    code: { required },
  },
  validationMessages: {
    code: { required: "message.validation.verificationCode.required" },
  },
  mounted() {
    this.$track.page(`virtual/forgotpassword/3-verify-code`);
  },
  data() {
    return {
      loader: false,
      invalidCodeMessage: null,
      code: null,
      startTimer: true,
    };
  },
  methods: {
    async verify() {
      this.loader = true;
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
        company_uri: this.companyUri,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckPasswordResetCodeMutation,
          variables,
        });

        const isValidRestPasswordCode = response.data.checkPasswordResetCode;

        if (isValidRestPasswordCode) {
          this.$emit("next", {
            next: true,
            code: this.code,
          });
        } else {
          this.invalidCodeMessage = "Please enter valid code.";
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.InvalidVerificationCodeError"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.masked-box {
  height: 60px;
  font-size: 3.5em;
  max-width: 290px;
  border: 1.5px solid #c1dcf3;
  border-radius: 6px;
  padding: 0;
  letter-spacing: 26px;
  background: #fff;
}
.check-email-instruction {
  color: #59656e;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 335px;
  .email {
    font-weight: 500;
  }
}
</style>
