<template>
  <q-card
    style="height: 100%; max-height: 800px; padding: 30px 50px 10px 20px"
    class="bg-white"
  >
    <div class="footer-menu-dialog-title">
      <p>Change Password</p>
    </div>
    <div class="row col-grow justify-between change-password">
      <div class="column col-6" style="max-height: 580px">
        <p class="text-pink" v-if="error">
          You have entered incorrect old password
        </p>
        <p class="pink--text" v-else-if="invalidPassword">
          Password security is very low
        </p>
        <!-- Scrollable Section for tab components -->
        <div class="col-grow">
          <form v-on:submit.prevent="$v.$invalid ? null : changePassword()">
            <div class="mt-4 change-password-form">
              <p style="margin-bottom: 10px">Old Password</p>
              <q-input
                v-model="oldPassword"
                @blur="$v.oldPassword.$touch()"
                type="password"
                outlined
                bottom-slots
                :error="fieldErrors('oldPassword').length > 0"
                no-error-icon
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("oldPassword").length > 0
                        ? fieldErrors("oldPassword")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
              <p style="margin-bottom: 10px">New Password</p>
              <password
                v-model="password"
                :badge="false"
                hint
                @next="handlePasswordScoreEvent"
                required
              ></password>
              <p style="margin-bottom: 10px">Confirm Password</p>
              <q-input
                v-model="repeatPassword"
                @input="$v.repeatPassword.$touch()"
                @blur="$v.repeatPassword.$touch()"
                type="password"
                outlined
                bottom-slots
                :error="fieldErrors('repeatPassword').length > 0"
                no-error-icon
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("repeatPassword").length > 0
                        ? fieldErrors("repeatPassword")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
            <div class="text-xs-left">
              <q-btn
                type="submit"
                :loading="loader"
                :disabled="isDisabled"
                label="Update Password"
                class="footer-dialog-ok-btn"
                no-caps
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </q-card>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import Password from "@/components/PasswordStrength";
import { validPassword } from "@/utils/validators";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import { ChangePasswordMutation } from "@/gql";

export default {
  name: "AccountSettingDialog",
  mixins: [validationMixin, ProxyModelMixin],
  validations: {
    oldPassword: { required },
    password: {
      required,
      validPassword,
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  validationMessages: {
    oldPassword: {
      required: "message.validation.password.required",
    },
    password: {
      required: "message.validation.password.required",
      validPassword: "message.validation.password.validPassword",
    },
    repeatPassword: {
      sameAsPassword: "message.validation.password.confirm",
    },
  },
  components: {
    Password,
  },
  data() {
    return {
      oldPassword: null,
      password: null,
      repeatPassword: null,
      passwordScore: 0,
      error: null,
      invalidPassword: null,
      loader: false,
    };
  },
  methods: {
    handlePasswordScoreEvent(data) {
      this.passwordScore = data.score;
    },
    async changePassword() {
      this.loader = true;
      this.error = false;
      const variables = {
        old_password: this.oldPassword,
        password: this.password,
      };

      try {
        const response = await this.$api.mutate({
          mutation: ChangePasswordMutation,
          variables,
        });
        const result = response.data.changePassword;
        if (result) {
          this.$emit("success", {
            message: this.$t("message.accountSettingUpdate"),
            flag: "modalDialog.accountSetting",
          });
          this.error = false;
        } else {
          this.error = true;
          this.loader = false;
        }
        this.clearForm();
      } catch (error) {
        this.loader = false;
        if (error.graphQLErrors[0].message === "PasswordInvalid") {
          this.invalidPassword = true;
        } else {
          this.error = true;
        }
      }
    },
    clearForm() {
      this.$v.$reset();
      this.oldPassword = null;
      this.passwordScore = 0;
      this.password = null;
      this.repeatPassword = null;
      this.loader = false;
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid || this.password.length < 8) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-password p {
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
}
</style>
