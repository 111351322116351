<template>
  <div style="margin-top:23px">
    <div class="q-mb-sm row justify-between items-center q-px-md">
      <div class="row flex-no-wrap full-width justify-between">
        <div class="row  flex-no-wrap">
          <div @click="changeExpansionState" class=" cursor-pointer">
            <q-icon
              :name="$icons.matKeyboardArrowDown"
              size="17px"
              :class="[isCardExpanded && 'right q-mt-xs', 'rotate ']"
            />
          </div>
          <div>
            <q-icon class="q-mr-sm" :name="$icons.matPerson" size="sm" />
          </div>
          <div
            @click="redirectToBoard"
            class="text-bold cursor-pointer"
            style="font-size:17px"
          >
            My private tasks
            <q-tooltip>
              Private tasks
            </q-tooltip>
          </div>
          <div style="margin-left: 3px">
            <q-btn
              dense
              no-caps
              round
              flat
              size="sm"
              @click="openCreatePrivateTask"
              v-if="canPostMessageFromWorkspace"
            >
              <q-icon size="16px" color="black" :name="$icons.matAdd" />
              <q-tooltip>
                New Task
              </q-tooltip>
            </q-btn>
          </div>
        </div>
        <div>
          <q-btn
            size="md"
            :icon="$icons.matMoreHoriz"
            flat
            round
            padding="1px"
            color="black"
            v-if="canPostMessageFromWorkspace"
          >
            <q-menu auto-close>
              <q-list>
                <q-item
                  v-if="this.cardTasks.length"
                  @click="moveListToBoardConfirmBoxHandler(null)"
                  clickable
                >
                  <q-item-section>
                    Move to board
                  </q-item-section>
                </q-item>
                <q-item
                  class="text-red"
                  @click="clearCompletedTaskConfirmBoxHandler"
                  clickable
                >
                  <q-item-section>
                    Clear complete tasks
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>
    <template v-if="filteredTodos">
      <div style="transition: height ease 5s" v-if="!isCardExpanded">
        <div
          v-sortable="{
            group: {
              name: 'myTask',
            },
            onAdd: onDragAddOrUpdate,
            onUpdate: onDragAddOrUpdate,
            onRemove: onDragRemove,
            emptyInsertThreshold: 100,
          }"
        >
          <q-input
            v-if="isVisible2"
            autofocus
            rounded
            input-class="new-task-input"
            input-style="width: 85%"
            v-model="newTask.title"
            standout="bg-blue-grey-1 elevation-0 "
            class="q-px-md q-mb-lg relative-position"
            placeholder="Type task name"
            @blur="handleBlur(newTask.title, 'top')"
            @keydown.enter.exact="
              createTask(newTask, 'top');
              $event.preventDefault();
            "
            dense
          >
            <div
              class="create-task-btn absolute"
              :class="[
                newTask.title && newTask.title.length > 0
                  ? 'active-bg'
                  : 'disable-bg',
              ]"
              @click="createTask(newTask, 'top')"
            >
              <q-icon
                size="sm"
                class="text-white"
                style="margin-top:3px"
                :name="$icons.mdiCheckBold"
              />
            </div>
          </q-input>
          <div
            class="group-background-hover"
            style="transition: all ease 0.5s"
            v-for="task in filteredTodos"
            :key="task.id"
            :data-id="task.id"
          >
            <private-task
              v-if="task.title != ''"
              :task="task"
              :board="board"
              :user="user"
              :workspace="currentWorkspace"
              :changeCompleteStatus="changeCompleteStatus"
              :edit="edit"
              :duplicateTask="duplicateTask"
              :moveListToBoardConfirmBoxHandler="moveListToBoardConfirmBoxHandler"
              :deleteTaskConfirmBoxHandler="deleteTaskConfirmBoxHandler"
              :addTaskBookmark="addTaskBookmark"
              :removeTaskBookmark="removeTaskBookmark"
            />
          </div>
          <!-- <div class="relative-position">
          <q-icon size="20px" name="add" class="input-prepend-icon" />
          <input class="new-task-input" placeholder="New Task" autofocus />
        </div> -->
        </div>
      </div>
    </template>
    <div
      v-if="!isAddTaskVisible && !isCardExpanded && canPostMessageFromWorkspace"
      class="text-center q-mb-lg"
    >
      <q-btn dense no-caps @click="checkPrivateTaskAccess">
        <q-icon
          class="q-mr-sm"
          size="16px"
          color="black"
          :name="$icons.matAdd"
        />
        <span class="text-black" style="font-weight:500;font-size:15px">
          Add Task
        </span>
      </q-btn>
    </div>
    <q-input
      v-if="isAddTaskVisible"
      autofocus
      rounded
      input-class="new-task-input"
      input-style="width: 89%"
      v-model="newTask.title"
      standout="bg-blue-grey-1 elevation-0 "
      class="q-px-md q-mb-lg relative-position"
      placeholder="Type task name"
      @blur="handleBlur(newTask.title, 'bottom')"
      @keydown.enter.exact="
        createTask(newTask, 'bottom');
        $event.preventDefault();
      "
      dense
    >
      <div
        class="create-task-btn absolute"
        :class="[
          newTask.title && newTask.title.length > 0
            ? 'active-bg'
            : 'disable-bg',
        ]"
        @click="createTask(newTask, 'bottom')"
      >
        <q-icon
          size="sm"
          class="text-white"
          style="margin-top:3px"
          :name="$icons.mdiCheckBold"
        />
      </div>
    </q-input>
    <q-separator style="background:#e3e8ed;" />
    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="subTaskDialog"
      @before-hide="closeSubTaskDialog"
    >
      <sub-task-dialog
        v-if="currentSubTask"
        :tasks="currentTask"
        :subTask="currentSubTask"
        :taskLabels="taskLabels"
        :workspaceid="currentWorkspaceId"
        :workspace="currentWorkspace"
        :workspaceMembers="workspaceMembers"
        :currentCompany="currentCompany"
        :activeCompany="activeCompany"
        :attachTaskMediaHandler="attachTaskMedia"
        :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
        :openSubtask="openSubtask"
        :authToken="authToken"
        :reactions="reactions"
        @createComment="createComment"
        :openTaskComment="openTaskComment"
        :deleteTaskCommentHandler="deleteTaskComment"
        @deleteCommentPoint="deleteCommentPoint"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        @clearSubtasks="clearChildSubtasks"
        :backToTask="backToTask"
        @createSubTask="createSubTask"
        :currentUser="user"
        @reactOnPost="reactOnPost"
        @updateMediaCache="updateMediaCache"
        @clickonmoreoption="clickonmoreoption"
        @updatepositions="updatepositions"
        @closeMediaModel="closeMediaModel"
        @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
        @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"  
        ></sub-task-dialog>
    </q-dialog>
    <change-list-confirm-dialog
      v-model="moveToPrivateListConfirm.flag"
      v-if="moveToPrivateListConfirm.flag"
      title="Move to Personal list?"
      message="Members assigned to this task will be removed. Task will no longer show in boards"
      :cancleMethod="cancelPrivateListHandler"
      :successMethod="moveToPrivateList"
      :loading="moveToPrivateListConfirm.loader"
    />
    <confirm-dialog
      v-model="clearCompletedTaskConfirm.flag"
      v-if="clearCompletedTaskConfirm.flag"
      title="Delete Completed Tasks?"
      question="Are you sure you want to delete all completed tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearCompletedTasks"
      :successMethod="clearCompletedTasks"
      :loading="clearCompletedTaskConfirm.loader"
    />
    <confirm-dialog
      v-model="moveToBoardSectionConfirm.flag"
      v-if="moveToBoardSectionConfirm.flag"
      title="Move to Board section?"
      question="All the tasks will be visible in board section"
      cancleText="Cancel"
      successText="OK"
      :cancleMethod="cancelHandler"
      :successMethod="moveToBoardSection"
      :loading="moveToBoardSectionConfirm.loader"
    />
    <div v-if="confirmBox.taskRemove.id && confirmBox.taskRemove.flag">
      <confirm-dialog
        v-model="confirmBox.taskRemove.flag"
        title="Delete task?"
        question="Deleting this task will also delete all the sub-tasks and comments in it."
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="resetTaskRemoveConfirmbox"
        :successMethod="deleteTask"
        :loading="confirmBox.taskRemove.loader"
      />
    </div>
    <duplicate-task-dialog
      v-if="cloneTask.flag"
      v-model="cloneTask.flag"
      :task="taskdata"
      :closeDialog="closeCloneTaskDialog"
    />
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

const soundFile = require("@/assets/completeTaskSound.wav");

import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";
import last from "lodash/last";
import head from "lodash/head";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";
import ChangeListConfirmDialog from "@/views/WorkspaceView/InfoSidebar/MyTasks/ChangeListConfirmDialog";
import PrivateTask from "@/views/WorkspaceView/InfoSidebar/MyTasks/PrivateTask.vue";

import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import UserScope from "@/mixins/UserScope";

import DuplicateTaskDialog from "@/components/Dialogs/DuplicateTaskDialog.vue";

import {
  CreateCardMutation,
  CreateTaskMutation,
  AddMemberToTaskMutation,
  UpdateTaskMutation,
  DeleteTaskMemberMutation,
  //CreateDuplicateTaskMutation,
  AddTaskBookmarkMutation,
  RemoveTaskBookmarkMutation,
  TaskQuery,
  SubtaskQuery,
} from "@/gql";

import mixpanel from "@/mixpanel";

export default {
  name: "PrivateList",
  mixins: [BoardsMethodMixin, MediaMethodMixin, UserScope],
  props: [
    "currentWorkspaceId",
    "currentWorkspace",
    "board",
    "cardTasks",
    "isWorkspaceOwner",
    "imageList",
    "workspaceMembers",
    "reactions",
    "taskLabels",
    "boardCards",
    "card",
  ],
  components: {
    ConfirmDialog,
    ChangeListConfirmDialog,
    PrivateTask,
    DuplicateTaskDialog,
    SubTaskDialog,
  },
  data() {
    return {
      subTaskDialog: false,
      currentTask: null,
      moveCardTask: null,
      currentSubTask: null,
      sound: null,
      isAddTaskVisible: false,
      isVisible2: false,
      isPrivateTask: true,
      newTask: {
        title: null,
        html: null,
        details: null,
        labels: [],
        card_id: null,
      },
      clearCompletedTaskConfirm: {
        flag: false,
        loader: false,
      },
      moveToPrivateListConfirm: {
        flag: false,
        loader: false,
      },
      moveToBoardSectionConfirm: {
        flag: false,
        loader: false,
      },
      confirmBox: {
        taskRemove: {
          flag: false,
          loader: false,
          id: null,
        },
      },
      movedTask: null,
      convertedTask: null,
      cloneTask: {
        flag: false,
      },
      taskdata: null,
      showPrivateTask: false,
      planeDescription: null,
    };
  },
  mounted() {
    this.sound = new Audio(soundFile);
    const cardExpansionState = this.$store.getters.isMyTaskCardExpanded(
      this.currentWorkspaceId,
      "private"
    );
    if (!cardExpansionState) {
      this.$store.dispatch("setMyTaskTabCardExpansionState", {
        workspaceId: this.currentWorkspaceId,
        cardId: "private",
        flag: false,
      });
    }
    const taskWithActiveTimer = this.cardTasks.filter(
      (task) => task.activetimelog
    );
    if (taskWithActiveTimer.length) {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = taskWithActiveTimer[0].id;
      companyEntity.start_time_log_id = taskWithActiveTimer[0].activetimelog.id;
    }
  },
  methods: {
    async openTask(taskId) {
      const task = this.$api.getEntity("task", taskId);
      let taskData = null;
      if(task) {
        taskData = task;
      } else{
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });
        taskData = taskQueryData.data.task;
      }
      
      if (taskData) {
        let isOpen = null;
        let task = taskData;
        let taskDetails = {
          task,
          isOpen,
        };
        this.editTask(taskDetails);
        this.taskDialog = true;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async backToTask(taskId){
      await this.openTask(taskId);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog(){
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    async openSubtaskDialog(taskId, subtaskId, taskData = null, subtask = null) {
      if(taskData){
        this.currentTask = taskData;  
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if(subtask) {
        subtaskEntity = subtask;
      }else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });

        subtaskEntity = subtaskQueryData.data.subtask;
      }
      
      if (subtaskEntity) {
        this.closeDialog();
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    checkPrivateTaskAccess() {
      let eligible = this.$checkEligiblity("private_tasks");
      if (eligible) {
        this.isAddTaskVisible = !this.isAddTaskVisible;
      }
    },
    closePlanExpiredDialog() {
      this.showPrivateTask = false;
    },
    openCreatePrivateTask() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "With each workspace you will be able to create your own private tasks. Visible to you only.";
        this.showPrivateTask = true;
      } else {
        let eligible = this.$checkEligiblity("private_tasks");
        if (eligible) {
          this.isVisible2 = !this.isVisible2;
        }
      }
    },
    onDragRemove(event) {
      event.item.remove();
    },
    onDragAddOrUpdate(event) {
      if (event.from === event.to) {
        this.updateTask(event);
      }
      if (event.from !== event.to) {
        this.moveToPrivateListConfirm.flag = true;
        this.movedTask = event;
      }
    },
    async updateTask(event) {
      const { item, oldIndex, newIndex } = event;
      const task = this.$api.getEntity("task", item.dataset.id);
      const tmp = this.filteredTodos ? [...this.filteredTodos] : [];
      if (event.from === event.to) {
        tmp.splice(oldIndex, 1);
      }
      tmp.splice(newIndex, 0, task);

      task.card_id = null;
      task.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      task.completed = false;

      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables: {
          id: task.id,
          board_id: this.board.id,
          sequence: task.sequence,
          card_id: null,
        },
      });

      this.cancelPrivateListHandler();
      this.movedTask = null;
    },
    removeTaskMembers(task) {
      const filteredTaskMembers = task.members.filter(
        (m) => m.id !== task.owner.id
      );
      Promise.all(
        filteredTaskMembers.map(async (m) => {
          const variables = {
            task_id: task.id,
            user_id: m.id,
          };
          await this.$api.mutate({
            mutation: DeleteTaskMemberMutation,
            variables,
          });
        })
      ).then(() => {
        //remove task from BoardQuery and add it to PrivateTasksQuery
        const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        boardQuery.data.board.tasks = boardQuery.data.board.tasks.filter(
          (t) => t.id !== task.id
        );
        task.members = [this.user];
        const updatedTask = {
          card_id: null,
          completed: false,
          ...task,
        };
        privateTasksQuery.data.privateTasks.push(updatedTask);
      });
    },
    moveToPrivateList() {
      const { item } = this.movedTask;
      this.moveToPrivateListConfirm.loader = true;
      const task = this.$api.getEntity("task", item.dataset.id);
      this.removeTaskMembers(task);
      this.updateTask(this.movedTask);
    },
    cancelPrivateListHandler() {
      this.$emit("reRender");
      this.moveToPrivateListConfirm = {
        flag: false,
        loader: false,
      };
    },
    redirectToBoard() {
      if (this.$route.name !== "BoardView") {
        this.$router.push({
          name: "BoardView",
          params: {
            company: this.$route.params.company,
            workspace: this.currentWorkspaceId,
          },
        });
      }
    },
    changeExpansionState() {
      this.$store.dispatch("setMyTaskTabCardExpansionState", {
        workspaceId: this.currentWorkspaceId,
        cardId: "private",
        flag: !this.isCardExpanded,
      });
    },
    async changeCompleteStatus(task) {
      const variables = {
        id: task.id,
        completed: !task.completed,
      };
      task.completed = !task.completed;
      this.sound.currentTime = 0;
      this.sound.play();
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
          skipUpdates: true,
        });
        if (task.completed) {
          this.$mixpanelEvent("private-task-complete", {});
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    setConvertedTask(task) {
      this.convertedTask = task;
    },
    closeDialog() {
      if (this.convertedTask) {
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
          (t) => t.id !== this.convertedTask.id
        );
        this.convertedTask = null;
      }
      this.$eventBus.$emit("closeTaskDialog");
    },
    clearCompletedTaskConfirmBoxHandler() {
      this.clearCompletedTaskConfirm = {
        flag: true,
        loader: false,
      };
    },
    clearCompletedTasks() {
      this.clearCompletedTaskConfirm.loader = true;
      const ids = this.cardTasks.filter((t) => t.completed).map((o) => o.id);
      if (ids && ids.length) {
        this.deleteTaskByIdHandler(ids);
      }
      this.resetClearCompletedTasks();
    },
    resetClearCompletedTasks() {
      this.clearCompletedTaskConfirm = {
        flag: false,
        loader: false,
      };
    },
    moveListToBoardConfirmBoxHandler(task) {
      this.moveCardTask = task;
      this.moveToBoardSectionConfirm = {
        flag: true,
        loader: false,
      };
    },
    moveToBoardSection() {
      this.moveToBoardSectionConfirm.loader = true;
      // 1. get query
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      let cardData = boardQuery.data.board.cards.find((w) => w.title === `${this.user.fullname} To Do`);
      
      if(cardData && !this.moveCardTask) {
        this.attachTaskToCard(cardData);
      } else if(cardData && this.moveCardTask){
        this.attachSingleTaskToCard(cardData);
      } else if(!cardData) {
        this.addCard();
      }
    },
    addCard() {
      const sequence =
        this.boardCards && this.boardCards.length > 0
          ? RandomPosition.between(
              RandomPosition.first(),
              head(this.boardCards).sequence
            )
          : RandomPosition.between(
              RandomPosition.first(),
              RandomPosition.last()
            );
      const card = {
        title: `${this.user.fullname} To Do`,
        board_id: this.board.id,
        workspace_id: this.currentWorkspaceId,
        sequence,
      };
      if(this.moveCardTask) {
        this.createCardForTask(card);
      } else {
        this.createCard(card);
      }
    },
    async createCard(card) {
      const fakeCard = {
        __typename: "card",
        id: Math.round(Math.random() * -1000000),
        title: card.title,
        board_id: card.board_id,
        user_id: this.user.id,
        sequence: card.sequence,
      };

      // 1. get query
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

      // 2. add fake entity
      boardQuery.data.board.cards.unshift(fakeCard);

      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateCardMutation,
        variables: card,
      });
      // 4. replace fake entity
      boardQuery.data.board.cards = boardQuery.data.board.cards.map((card) =>
        card === fakeCard ? response.data.createCard : card
      );
      this.attachTaskToCard(response.data.createCard);
    },
    async createCardForTask(card) {
      const fakeCard = {
        __typename: "card",
        id: Math.round(Math.random() * -1000000),
        title: card.title,
        board_id: card.board_id,
        user_id: this.user.id,
        sequence: card.sequence,
      };

      // 1. get query
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

      // 2. add fake entity
      boardQuery.data.board.cards.unshift(fakeCard);

      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateCardMutation,
        variables: card,
      });
      // 4. replace fake entity
      boardQuery.data.board.cards = boardQuery.data.board.cards.map((card) =>
        card === fakeCard ? response.data.createCard : card
      );
      this.attachSingleTaskToCard(response.data.createCard);
    },
    async attachSingleTaskToCard(card) {
        const variables = {
          id: this.moveCardTask.id,
          card_id: card.id,
        };
        const boardQuey = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        const taskData = {
          ...this.moveCardTask,
          card_id: card.id,
        };
        boardQuey.data.board.tasks.push(taskData);
        
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });

        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        
        privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
          (task) => task.id !== this.moveCardTask.id
        );
        this.moveCardTask = null;
        this.cancelHandler();
    },
    attachTaskToCard(card) {
      Promise.all(
        this.cardTasks.map(async (t) => {
          const variables = {
            id: t.id,
            card_id: card.id,
          };
          const boardQuey = this.$api.getQuery(`BoardQuery:${this.board.id}`);
          const task = {
            ...t,
            card_id: card.id,
          };
          boardQuey.data.board.tasks.push(task);
          await this.$api.mutate({
            mutation: UpdateTaskMutation,
            variables,
          });
        })
      ).then(() => {
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        privateTasksQuery.data.privateTasks = [];
        this.cancelHandler();
      });
    },
    cancelHandler() {
      this.moveCardTask = null;
      this.moveToBoardSectionConfirm = {
        flag: false,
        loader: false,
      };
    },
    async createTask(task, position) {
      if (!task.title || task.title.trim() === "") {
        return;
      }
      const cardTasks = this.cardTasks;
      // const activeTasks = cardTasks
      //   ? cardTasks.filter((task) => !task.completed)
      //   : cardTasks;
      const activeTasksOrdered = cardTasks
        ? orderBy(cardTasks, ["sequence"], ["asc"])
        : cardTasks;
      let sequence;
      if (activeTasksOrdered === undefined) {
        if (position === "top") {
          sequence = RandomPosition.between(
            RandomPosition.first(),
            head(cardTasks).sequence
          );
        } else {
          sequence = activeTasksOrdered
            ? RandomPosition.between(
                last(activeTasksOrdered).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        }
      } else {
        if (position === "top") {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  RandomPosition.first(),
                  head(cardTasks).sequence
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        } else {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  last(activeTasksOrdered).sequence,
                  RandomPosition.last()
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        }
      }
      const variables = {
        ...task,
        sequence,
        card_id: null,
        board_id: this.board.id,
      };

      const privateTasksQuery = this.$api.getQuery(
        `PrivateTasksQuery:${this.board.id}`
      );

      this.newTask.title = null;
      const response = await this.$api.mutate({
        mutation: CreateTaskMutation,
        variables,
      });

      if (response) {
        privateTasksQuery.data.privateTasks.push(response.data.createTask);
        this.$mixpanelEvent("create-private-task", {});
        const variables = {
          workspace_id: this.currentWorkspaceId,
          task_id: response.data.createTask.id,
          user_id: this.user.id,
        };
        const taskEntity = this.$api.getEntity(
          "task",
          response.data.createTask.id
        );
        const memberEntity = this.$api.getEntity("user", this.user.id);
        taskEntity.members.push(memberEntity);
        await this.$api.mutate({
          mutation: AddMemberToTaskMutation,
          variables,
        });
      }
    },

    /*async duplicateTask(task) {
      const cardTasks = this.cardTasks;
      const activeTasks = cardTasks
        ? cardTasks.filter((task) => !task.completed)
        : cardTasks;
      const activeTasksOrdered = activeTasks
        ? orderBy(activeTasks, ["sequence"], ["asc"])
        : activeTasks;
      let sequence;
      if (activeTasksOrdered === undefined) {
        sequence = activeTasksOrdered
          ? RandomPosition.between(
              last(activeTasksOrdered).sequence,
              RandomPosition.last()
            )
          : RandomPosition.between(
              RandomPosition.first(),
              RandomPosition.last()
            );
      } else {
        sequence =
          activeTasksOrdered.length > 0
            ? RandomPosition.between(
                last(activeTasksOrdered).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
      }

      if (task.subtasks && task.subtasks.length) {
        let subTasks = [];
        task.subtasks.map((subTask) => {
          let task = {
            title: subTask.title,
            user_id: this.user.id,
            sequence: subTask.sequence,
          };
          subTasks.push(task);
        });
        task["subtask"] = subTasks;
      }
      delete task.subtasks;

      if (task.media && task.media.length) {
        let media_ids = [];
        task.media.map((media) => {
          media_ids.push(media.id);
        });
        task.media = media_ids;
      }

      const variables = {
        ...task,
        sequence,
        card_id: null,
        board_id: this.board.id,
      };

      const privateTasksQuery = this.$api.getQuery(
        `PrivateTasksQuery:${this.board.id}`
      );

      const response = await this.$api.mutate({
        mutation: CreateDuplicateTaskMutation,
        variables,
      });

      if (response) {
        privateTasksQuery.data.privateTasks.push(
          response.data.createDuplicateTask
        );

        const variables = {
          workspace_id: this.currentWorkspaceId,
          task_id: response.data.createDuplicateTask.id,
          user_id: this.user.id,
        };
        const taskEntity = this.$api.getEntity(
          "task",
          response.data.createDuplicateTask.id
        );
        const memberEntity = this.$api.getEntity("user", this.user.id);
        taskEntity.members.push(memberEntity);
        await this.$api.mutate({
          mutation: AddMemberToTaskMutation,
          variables,
        });
      }
    },*/
    duplicateTask(task) {
      if (this.currentCompany.status.subscriptionOver) {
        this.planeDescription =
          "Duplicate tasks across workspaces including task-details and sub-tasks.";
        this.showPrivateTask = true;
      } else {
        this.taskdata = task;
        this.cloneTask.flag = true;
      }
    },
    closeCloneTaskDialog() {
      this.cloneTask.flag = false;
    },
    handleBlur(newTaskTitle, position) {
      if (!newTaskTitle || !newTaskTitle.length) {
        switch (position) {
          case "top":
            this.isVisible2 = false;
            break;
          case "bottom":
            this.isAddTaskVisible = false;
            break;
        }
      }
    },

    async addTaskBookmark(taskId) {
      try {
        const variables = {
          task_id: taskId,
        };
        const task = this.$api.getEntity("task", taskId);
        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        // mixpanel.track("Task Bookmark Add");
        this.$mixpanelEvent("save-task", {});
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },

    async removeTaskBookmark(taskId) {
      try {
        const variables = {
          task_id: taskId,
        };
        const task = this.$api.getEntity("task", taskId);
        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspaceId
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspaceId
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
  },
  computed: {
    filteredTodos() {
      return orderBy(this.cardTasks, ["completed"]);
    },
    isCardExpanded() {
      if (
        this.$store.getters.isMyTaskCardExpanded(
          this.currentWorkspaceId,
          "private"
        )
      ) {
        return this.$store.getters.isMyTaskCardExpanded(
          this.currentWorkspaceId,
          "private"
        ).isExpanded;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.create-task-btn {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  right: -8px;
  top: 4px;
  bottom: 0;
  justify-content: center;
  display: flex;
}
</style>
