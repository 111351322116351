<template>
  <div class="about-section">
    <div
      class="overflow-auto"
      v-if="!isPeople"
      v-infinite-scroll="fetchScrollHandler"
      style="max-height: calc(100vh - 150px);"
    >
      <div
        class="walkthrough walkthrough-small"
        v-if="user.onboarding.is_about"
      >
        <div class="icon">
          <q-icon
            :name="$icons.matBusinessCenter"
            size="24px"
            style="color: #007aff"
          />
        </div>
        <div class="content">
          <p class="bold">Workspace details</p>
          <p>
            Add members to this workspace, view and edit details, change
            notifications and see the activity log.
          </p>
          <div class="video" @click="videoPlayer('569660633')">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="55px"
            />
          </div>
        </div>
        <div class="close" @click="closeAboutWalkthrough">
          <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
        </div>
      </div>
      <div style="height: calc(100vh - 120px);">
        <q-expansion-item
          header-class="justify-between"
          key="members"
          class="q-mt-md"
          v-if="currentWorkspaceId !== 1 || user.roles[0].id === 1"
          :value="isMemberExpanded"
          @input="changeMemberState"
        >
          <template v-slot:header>
            <div class="row items-center" style="width: 100%">
              <q-icon
                :name="$icons.matKeyboardArrowDown"
                size="17px"
                :class="[!isMemberExpanded && 'right', 'rotate']"
              />
              <q-icon size="sm" :name="$icons.matPeople" />
              <div class="text-bold text-h6" style="margin-left:12px">
                {{
                  workspaceMembers && workspaceMembers.length > 0
                    ? `${workspaceMembers.length + 1} Members`
                    : "1 Member"
                }}
              </div>
              <q-btn
                v-if="isBannerVisible()"
                :icon="$icons.mdiHelp"
                round
                size="sm"
                class="help-button-small"
                @click.stop="toggleBanner"
                style="margin-left: auto"
              >
              </q-btn>
            </div>
          </template>
          <q-card>
            <q-card-section class="q-pa-none" style="margin-top:-5px">
              <workspace-members
                :user="user"
                :isWorkspaceOwner="isWorkspaceOwner"
                :workspaceMembers="workspaceMembers"
                :showUserInfo="showUserInfo"
                :confirmBoxHandler="confirmBoxHandler"
                :canRemoveUserFromWorkspace="canRemoveUserFromWorkspace"
                :currentWorkspaceId="currentWorkspaceId"
              />
              <div
                v-if="isBannerVisible() && isAddMemberBannerVisible"
                class="no-members-section"
              >
                <div class="no-members-box">
                  <div style="display: flex">
                    <div>
                      <q-icon :name="$icons.matHelp" class="help" />
                    </div>
                    <div style="margin-left: 10px">
                      <span class="text-bold color-black"
                        >There are no additional members in your team</span
                      >
                      <p>
                        First invite people to your team, then you may add them
                        as a member.
                      </p>
                      <div class="learn-more">
                        <span @click="videoPlayer('578344075')"
                          >Learn more</span
                        >
                      </div>
                    </div>
                    <div style="width: 40px">
                      <q-btn
                        :icon="$icons.matClose"
                        round
                        flat
                        padding="0"
                        dense
                        class="close"
                        @click="closeBanner"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row flex-no-wrap items-center justify-evenly q-pl-md q-mr-sm q-mt-sm"
                v-if="
                  isWorkspaceOwner ||
                    (currentWorkspace.type === 'public' &&
                      canAddMemberToPublicWorkspace) ||
                    (currentWorkspace.type === 'private' &&
                      canAddMemberToPrivateWorkspace)
                "
              >
                <add-member-to-workspace
                  :currentWorkspaceId="currentWorkspaceId"
                  :isFromInfoSidebar="true"
                  ref="addMemberView"
                />
                <q-btn
                  padding="0"
                  dense
                  class="member-section-btn q-ml-sm"
                  :icon="$icons.mdiEmailOutline"
                  label="Invite new people"
                  no-caps
                  style="width:148px"
                  @click="toggleInivitePeopleDialog"
                />
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-separator
          v-if="currentWorkspaceId !== 1 || user.roles[0].id === 1"
          class="q-mt-sm"
          style="margin-top: 20px"
        />
        <q-expansion-item
          v-if="!isPeople"
          header-class="justify-between"
          key="descriptions"
          header-style="padding-top:15px"
          :value="isDescriptionExpanded"
          @input="changeDescriptionState"
        >
          <template v-slot:header>
            <div class="row items-center">
              <q-icon
                :name="$icons.matKeyboardArrowDown"
                size="17px"
                :class="[!isDescriptionExpanded && 'right', 'rotate']"
              />
              <q-icon size="sm" :name="$icons.matInfo" />
              <div class="text-bold text-h6" style="margin-left:12px">
                Description
              </div>
              <div
                class="absolute"
                style="right: 25px;z-index:10"
                v-if="isDescriptionExpanded"
              ></div>
            </div>
          </template>
          <q-card>
            <q-card-section
              class="q-py-none"
              :style="
                editorFocused
                  ? { 'margin-bottom': '10px' }
                  : { 'margin-bottom': '10px' }
              "
            >
              <div class="card__text no-mrpd" v-if="currentWorkspace !== ''">
                <div class="q-py-none" v-if="!isPeople">
                  <div class="row" v-if="currentWorkspace">
                    <!-- <div
                      class="editor-prototype about-section-editor"
                      v-if="editorFocused || !currentWorkspace.description"
                    >
                      <editor
                        v-model="currentWorkspace.description"
                        :editor-style="{
                          'min-height': '300px',
                          background: '#f4f5f8',
                        }"
                        @submit="updateWorkspaceDescription"
                        @blur="updateWorkspaceDescription"
                        @focus="handleEditorFocus"
                        :maxHeight="502"
                        :autofocus="true"
                        placeholder="Type your description here"
                        :hideEditorMenu="true"
                        :updateScroll="false"
                        overflow="auto"
                      />
                    </div>
                    <div
                      v-html="compiledWorkspaceDescription"
                      class="description break-word editor-prototype"
                      style="margin-top:-10px"
                      @click="handleEditorFocus"
                      v-else
                    ></div> -->
                    <about-document-editor-dialog
                        :closeDocEditor="closeDocEditor"
                        :documentProp="currentWorkspace"
                        :workspace="currentWorkspace"
                        @updateWorkspaceDescription="updateWorkspaceDescription"
                    />
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-separator
          :class="[editorFocused ? 'q-mt-sm' : 'q-mt-sm']"
          v-if="!isPeople"
        />
        <q-expansion-item
          :value="isNotificationSettingExpanded"
          @input="changeNotificationSettingState"
          header-class="justify-between"
          header-style="padding-top:26px"
          key="notifications"
        >
          <template v-slot:header>
            <div class="row items-center">
              <q-icon
                :name="$icons.matKeyboardArrowDown"
                size="17px"
                :class="[!isNotificationSettingExpanded && 'right', 'rotate']"
              />
              <q-icon size="25px" :name="$icons.matNotifications" />
              <div class="text-bold text-h6" style="margin-left:10px">
                Notifications
              </div>
            </div>
          </template>
          <div class="column q-px-md">
            <q-option-group
              :value="[notificationSetting]"
              :options="options"
              @input="changeNotificationSetting"
              type="checkbox"
              size="xs"
            />
          </div>
        </q-expansion-item>
        <q-separator
          :class="[editorFocused ? 'q-mt-sm' : 'q-mt-md']"
          v-if="!isPeople"
        />
        <q-expansion-item
          :value="isActivityLogExpanded"
          @input="changeActivityLogState"
          header-class="justify-between q-pb-none"
          header-style="padding-top:26px"
          key="activity-log"
          @click="checkActivityAccess"
        >
          <template v-slot:header>
            <div class="row items-center ">
              <q-icon
                :name="$icons.matKeyboardArrowDown"
                size="17px"
                :class="[!isActivityLogExpanded && 'right', 'rotate']"
              />
              <q-icon size="25px" :name="$icons.mdiClockFast" />
              <div class="text-bold text-h6" style="margin-left:10px">
                Activity Log
              </div>
            </div>
          </template>
          <div v-if="activityLogs">
            <activity-logs
              :isWorkspaceOwner="isWorkspaceOwner"
              :currentWorkspace="currentWorkspace"
              :currentWorkspaceId="currentWorkspaceId"
              :activityLog="activityLog"
            />
          </div>
        </q-expansion-item>
        <div
          class="full-width q-pr-sm text-center"
          v-if="activityLogs && isLoading"
        >
          <q-circular-progress
            indeterminate
            size="30px"
            :thickness="0.2"
            color="primary"
          />
        </div>
      </div>
    </div>
    <div
      class="people-info overflow-auto"
      style="max-height: calc(100vh - 110px);"
      v-else
    >
      <div class="q-pt-sm q-px-md">
        <div class="row">
          <q-icon size="sm" :name="$icons.matInfo" />
          <div class="text-bold text-h6 q-ml-md">
            Profile
          </div>
        </div>
        <div v-if="peopleMemberInfo">
          <div class="text-center">
            <q-avatar size="200px" color="grey-3" text-color="grey-10">
              <img
                :src="peopleMemberInfo.pic"
                :alt="peopleMemberInfo.first"
                v-if="user && peopleMemberInfo.pic"
              />
              <!-- <span class="text-bold" style="font-size:100px" v-else>
                {{ peopleMemberInfo.first | firstChar
                }}{{ peopleMemberInfo.last | firstChar }}
              </span> -->
              <avatar v-else :size="200" :customStyle="{'font-size':'80px', 'font-weight':'600'}" :username="peopleMemberInfo.first+' '+peopleMemberInfo.last"></avatar>
            </q-avatar>
          </div>
          <div class="name q-mt-lg">
            {{ peopleMemberInfo.first | capitalize }}
            {{ peopleMemberInfo.last | capitalize }}
          </div>
          <div v-if="peopleMemberInfo.designation" class="designation q-mt-sm">
            {{ peopleMemberInfo.designation }}
          </div>
          <div :class="['user-name']">@{{ peopleMemberInfo.username }}</div>
          <q-separator class="q-my-md" style="width:200px" />
          <div class="user-detail">
            <div
              v-if="
                peopleMemberInfo &&
                  (peopleMemberInfo.city || peopleMemberInfo.country)
              "
            >
              <div class="title">Location</div>
              <div class="content">
                {{ peopleMemberInfo.city }}, {{ peopleMemberInfo.country }}
              </div>
            </div>
            <div class="q-mt-md">
              <div class="title">Local Time</div>
              <div class="content">
                {{ localTime }}
              </div>
            </div>
            <div class="q-mt-md">
              <div class="title">Display Name</div>
              <div>{{ peopleMemberInfo.username }}</div>
            </div>
            <div
              class="q-mt-md"
              v-if="peopleMemberInfo && peopleMemberInfo.phone"
            >
              <div class="title">Phone Number</div>
              <div class="content">+{{ peopleMemberInfo.phone }}</div>
            </div>
            <div class="q-mt-md">
              <div class="title">Email</div>
              <div class="content">
                <a
                  class="break-word"
                  :href="`mailto:${peopleMemberInfo.email}`"
                  >{{ peopleMemberInfo.email }}</a
                >
              </div>
            </div>
            <div
              class="q-mt-md"
              v-if="peopleMemberInfo && peopleMemberInfo.skype"
            >
              <div class="title">Skype Username</div>
              <div class="content">
                {{ peopleMemberInfo.skype }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <q-separator class="q-mt-lg" />
      <div class="q-pt-md q-px-md">
        <div class="row items-center q-mb-sm">
          <q-icon size="sm" :name="$icons.matNotifications" />
          <div class="text-bold text-h6 q-ml-md">
            Notifcations
          </div>
        </div>
        <div class="column ">
          <q-option-group
            :value="[notificationSetting]"
            :options="options"
            @input="changeNotificationSetting"
            type="checkbox"
            size="xs"
          />
        </div>
      </div>
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :currentWorkspaceId="currentWorkspaceId"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <div v-if="confirmRemoveMember.flag">
      <confirm-dialog
        v-model="confirmRemoveMember.flag"
        title="Remove Member?"
        question="Are you sure you want to remove member from this workspace?"
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => confirmBoxHandler(false)"
        :successMethod="removeMember"
        :loading="confirmRemoveMember.loading"
      />
    </div>
    <invite-people-dialog
      v-if="invitepeopleDialog"
      v-model="invitepeopleDialog"
      @close="toggleInivitePeopleDialog"
      @success="successHandler"
      :currentWorkspaceId="currentWorkspaceId"
    />
  </div>
</template>
<script>
import UserScope from "@/mixins/UserScope";
import AddWorkspaceMemberMixin from "@/mixins/AddWorkspaceMemberMixin";
import BotMessenger from "@/mixins/BotMessenger";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import get from "lodash/get";
import head from "lodash/head";
import last from "lodash/last";
import format from "date-fns/format";

import { compile } from "@/components/Editor/MarkupUtils";

// import Editor from "@/components/Editor/Editor";
import WorkspaceMembers from "@/views/WorkspaceView/InfoSidebar/About/WorkspaceMembers";
import ActivityLogs from "@/views/WorkspaceView/InfoSidebar/About/ActivityLogs";
import ProfileDialog from "@/components/ProfileDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import AddMemberToWorkspace from "@/components/AddMemberToWorkspace";
import InvitePeopleDialog from "@/components/Dialogs/InvitePeopleDialog";
import Avatar from 'vue-avatar';
import AboutDocumentEditorDialog from "@/components/Dialogs/AboutDocumentEditorDialog";

import {
  UpdateWorkspaceMutation,
  UpdateNotificationLevelMutation,
  ActivityLogsQuery,
  UpdateUserOnboardingMutation,
} from "@/gql";
import VideoDialog from "../../../components/VuePlayer/VideoDialog";
import { mapGetters } from "vuex";
export default {
  name: "About",
  mixins: [UserScope, AddWorkspaceMemberMixin, BotMessenger, DocumentEditorMixin],
  components: {
    ProfileDialog,
    ConfirmDialog,
    AddMemberToWorkspace,
    WorkspaceMembers,
    ActivityLogs,
    // Editor,
    VideoDialog,
    InvitePeopleDialog,
    Avatar,
    AboutDocumentEditorDialog,
  },
  api: {
    activityLog: {
      cacheKey() {
        return `ActivityLogQuery:${this.currentWorkspaceId}`;
      },
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaceAvailableUsers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceAvailableUsersQuery:${this.currentWorkspaceId}`;
      },
      variables() {
        return {
          offset: 0,
          workspace_id: this.currentWorkspaceId,
        };
      },
    },
  },
  data() {
    return {
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      activityLogs: false,
      profileDialog: {
        show: false,
        user: null,
      },
      confirmRemoveMember: {
        flag: false,
        id: null,
        loading: false,
      },
      options: [
        {
          label: "Every new message",
          value: "all",
        },
        {
          label: "Just @mentions",
          value: "mentions",
        },
        {
          label: "Nothing",
          value: "none",
        },
      ],
      editorFocused: false,
      //workspaceDesc: null,
      currentDate: null,
      isExpanded: false,
      isLoading: false,
      id: null,
      invitepeopleDialog: false,
    };
  },
  mounted() {
    this.currentDate = new Date();
    clearInterval(this.id);
    this.id = setInterval(this.updateDateTime, 60000);
    //this.workspaceDesc = this.currentWorkspace.description;
  },
  beforeDestroy() {
    clearInterval(this.id);
  },
  methods: {
    toggleInivitePeopleDialog() {
      this.invitepeopleDialog = !this.invitepeopleDialog;
    },
    successHandler(data) {
      this.toggleInivitePeopleDialog();
      const verbiage = data.emails.length > 1 ? "people" : "person";
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: `You have invited ${data.emails.length} ${verbiage} to ${this.activeCompany.companyName}`,
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    checkActivityAccess() {
      let eligible = this.$checkEligiblity("activity_log");
      if (eligible) {
        this.activityLogs = true;
        // this.toggleCommentMode(!this.commentMode)
      }
    },
    fetchScrollHandler() {
      if (this.isLoading === false) {
        this.fetchMoreActivity();
      }
    },
    async fetchMoreActivity() {
      this.isLoading = true;
      const lastActivity = last(this.activityLog);
      const variables = {
        scope_id: this.currentWorkspaceId,
        scope_type: "App\\Workspace",
        cursor: lastActivity.id,
        limit: 10,
      };

      const currentActivityLogs = this.$api.getQuery(
        `ActivityLogQuery:${this.currentWorkspaceId}`
      );
      const newActivityLogs = await this.$api.query({
        query: ActivityLogsQuery,
        variables,
      });

      if (newActivityLogs.data.activityLog.length !== 0) {
        currentActivityLogs.data.activityLog = [
          ...currentActivityLogs.data.activityLog,
          ...newActivityLogs.data.activityLog,
        ];
      }
      this.isLoading = false;
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    handleEditorFocus() {
      this.editorFocused = true;
    },
    handleEditorRemoveFocus() {
      this.editorFocused = false;
    },
    async updateWorkspaceDescription(data) {
      this.editorFocused = false;
      let variables = {
        id: this.currentWorkspaceId,
        description: data,
      };
      try {
        await this.$api.mutate({
          mutation: UpdateWorkspaceMutation,
          variables,
        });
        this.editorFocused = false;
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    updateDateTime() {
      this.currentDate = new Date();
    },
    changeMemberState(value) {
      this.$store.dispatch("setMemberExpansionState", {
        workspaceId: this.currentWorkspaceId,
        flag: value,
      });
    },
    changeDescriptionState(value) {
      this.$store.dispatch("setDescriptionExpansionState", {
        workspaceId: this.currentWorkspaceId,
        flag: value,
      });
    },
    changeNotificationSettingState(value) {
      this.$store.dispatch("setWorkspaceNotificationSettingExpansionState", {
        workspaceId: this.currentWorkspaceId,
        flag: value,
      });
    },
    changeActivityLogState(value) {
      this.$store.dispatch("setActivityLogExpansionState", {
        workspaceId: this.currentWorkspaceId,
        flag: value,
      });
    },
    async changeNotificationSetting(val) {
      const notificationLevel = val.filter(
        (v) => v !== this.notificationSetting
      );
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2.map((w) => {
          if (w.id === this.currentWorkspaceId) {
            w.notification_level = notificationLevel[0];
          }
        });
      }
      try {
        await this.$api.mutate({
          mutation: UpdateNotificationLevelMutation,
          variables: {
            workspace_id: this.currentWorkspaceId,
            notification_level: notificationLevel[0],
          },
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    toggleBanner() {
      this.$store.dispatch(
        "toggleAddMemberBanner",
        !this.isAddMemberBannerVisible
      );
    },
    closeBanner() {
      this.$store.dispatch("toggleAddMemberBanner", false);
    },
    isBannerVisible() {
      let availableMembers = this.getAvailableMembers
        ? this.getAvailableMembers
        : [];
      let workspaceMembers = this.workspaceMembers ? this.workspaceMembers : [];
      let teamMembersCount = availableMembers.length + workspaceMembers.length;
      return !teamMembersCount;
    },
    closeWalkthroughBanner() {},
    videoPlayer(video_id) {
      let media = {
        isVimeo: true,
        file: `https://player.vimeo.com/video/${video_id}`,
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async closeAboutWalkthrough() {
      this.user.onboarding.is_about = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_about",
          flag_value: false,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      isAddMemberBannerVisible: "isAddMemberBannerVisible",
    }),
    ...mapGetters({
      companies: "auth",
      ownCompany: "activeCompany",
    }),
    activeCompany() {
      return get(this.companies, this.$route.params.company);
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    notificationSetting() {
      return this.currentWorkspace.notification_level;
    },
    compiledWorkspaceDescription() {
      //this.workspaceDesc = this.currentWorkspace.description;
      return compile(this.currentWorkspace.description);
    },
    isPeople() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "people";
      } else {
        return false;
      }
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    peopleMemberInfo() {
      if (this.isPeople && this.workspaceMembers) {
        return head(this.workspaceMembers);
      }
      return null;
    },
    localTime() {
      // TODO: if this.peopleTime: adjust for timezone
      // the hard part is to adjust for day light savings, etc
      return this.currentDate && format(this.currentDate, "p");
    },
    isMemberExpanded() {
      return this.$store.getters.isMemberExpanded(this.currentWorkspaceId);
    },
    isDescriptionExpanded() {
      return this.$store.getters.isDescriptionExpanded(this.currentWorkspaceId);
    },
    isNotificationSettingExpanded() {
      return this.$store.getters.isWorkspaceNotificationExpanded(
        this.currentWorkspaceId
      );
    },
    isActivityLogExpanded() {
      return this.$store.getters.isActivityLogExpanded(this.currentWorkspaceId);
    },
    getAvailableMembers() {
      return this.workspaceAvailableUsers;
    },
  },
};
</script>
<style scoped>
.about-section-editor {
  width: 300px;
  min-height: 167px;
  max-height: 528px;
  height: auto;
  background-color: #f4f5f8;
  padding: 3px 16px;
  border-radius: 8px;
  white-space: normal;
}
.description {
  padding: 16px 16px;
  border-radius: 8px;
  background-color: #f4f5f8;
  color: #000000 !important;
  min-height: 167px;
  max-height: 528px;
  height: auto;
  overflow: auto;
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3em;
  white-space: normal;
}
</style>
