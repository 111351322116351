<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <!-- SignIn -->
    <q-card style="width:380px">
      <div class="relative-position pa-4" v-if="currentComponent === 'signin'">
        <div
          class="cursor-pointer greybtn--text top-left pos-absolute ma-3"
          @click="stage = 1"
          v-if="stage > 1"
        >
          Back
        </div>
        <div class="px-3" v-if="stage <= 1">
          <div class="text-center">
            <img
              class="mt-3"
              src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab.svg"
              alt="Vabotu"
              height="145px"
            />
            <h2 class="headline-text">Sign in</h2>
          </div>
          <div class="mt-4">
            <form
              @submit.prevent="company.uri != '' ? checkCompanyUri() : null"
              id="signin-company"
            >
              <div class="relative-position">
                <q-input
                  ref="focus"
                  v-model="company.uri"
                  @input="
                    $v.company.uri.$touch();
                    errors.checkCompanyUri = null;
                  "
                  @blur="$v.company.uri.$touch()"
                  label="Team Name"
                  type="text"
                  bottom-slots
                  color="primary"
                  :error="fieldErrors('company.uri').length > 0"
                  no-error-icon
                >
                  <template v-slot:error>
                    <div class="text-pink">
                      {{
                        fieldErrors("company.uri").length > 0
                          ? fieldErrors("company.uri")[0]
                          : ""
                      }}
                    </div>
                  </template>
                </q-input>
                <q-btn
                  flat
                  :icon="$icons.matHelp"
                  size="md"
                  round
                  color="grey-5"
                  class="absolute"
                  @click.native.stop="currentComponent = 'forgotCompany'"
                  style="top:10px;right:0px"
                />
              </div>
              <p class="pink--text">{{ errors.checkCompanyUri }}</p>
              <q-btn
                type="submit"
                style="background-color: #007aff; color: #ffffff"
                class="full-width q-py-sm"
                label="Continue"
                :disabled="isDisabledStage1"
                :loading="loader.stage1"
              />
            </form>
          </div>
        </div>
        <div class="px-3" v-else>
          <div class="text-center">
            <img
              class="mb-3 mt-5"
              src="https://s3-us-west-1.amazonaws.com/vabotu/default/sign_in_lock_icon.svg"
              alt="Vabotu"
              height="145px"
            />
            <h2 class="headline-text app-text-normal">
              Sign in to {{ company.name }}
            </h2>
          </div>
          <div class="mt-4">
            <form
              v-on:submit.prevent="$v.$invalid ? null : singIn()"
              id="signin-credential"
            >
              <q-input
                autofocus
                ref="focus"
                v-model="email"
                @input="
                  $v.email.$touch();
                  errors.singIn = null;
                "
                @blur="$v.email.$touch()"
                label="Email"
                type="text"
                bottom-slots
                color="indigo-5"
                :error="fieldErrors('email').length > 0"
                no-error-icon
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("email").length > 0
                        ? fieldErrors("email")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
              <q-input
                ref="focus"
                v-model="password"
                @input="
                  $v.password.$touch();
                  errors.singIn = null;
                "
                @blur="$v.password.$touch()"
                label="Password"
                type="password"
                name="password"
                bottom-slots
                color="indigo-5"
                :error="fieldErrors('password').length > 0"
                no-error-icon
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("password").length > 0
                        ? fieldErrors("password")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
              <p class="pink--text">{{ errors.singIn }}</p>
              <q-btn
                type="submit"
                style="background-color: #007aff; color: #ffffff"
                class="full-width q-py-sm"
                label="Continue"
                :loading="loader.stage2"
                :disabled="isDisabledStage2"
              />
            </form>
          </div>
        </div>
      </div>
      <forgot-company
        v-else-if="currentComponent === 'forgotCompany'"
        @switchComponent="handleSwitchComponent"
        @success="forgetCompanyHandler"
      ></forgot-company>
      <!-- Forgot Password -->
      <forgot-password
        v-else-if="currentComponent === 'forgotPassword'"
        :companyDetail="company"
        :fromInsideCompany="true"
        @success="forgotPasswordSuccessHandler"
      ></forgot-password>
      <q-btn
        flat
        round
        :icon="$icons.matClose"
        color="grey-5"
        size="sm"
        class="absolute-top-right q-mt-xs q-mr-xs"
        @click="closeToRest"
      />
      <div
        v-if="stage === 2 && currentComponent === 'signin'"
        class="cursor-pointer demi-bold-text an-14 greybtn--text mb-3 text-center"
        @click="currentComponent = 'forgotPassword'"
      >
        Forgot Password?
      </div>
    </q-card>
    <!-- Forgot Company -->
  </q-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { login, getUser, getWorkspaces } from "@/services/auth";
import has from "lodash/has";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { validCompanyUri } from "@/utils/validators";
import validationMixin from "@/mixins/validationMixin";
import ForgotCompany from "@/components/ForgotCompany";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import ForgotPassword from "@/components/ForgotPassword";
import {
  CompanyQuery,
  UserActionLogsMutation,
  Workspaces2Query,
  CheckCompanyStatusMutation,
} from "@/gql";

const initialState = (currentComponent = "signin") => {
  return {
    stage: 0,
    errors: {
      checkCompanyUri: null,
      singIn: null,
    },
    company: {
      id: null,
      uri: null,
      name: null,
      public_link: null,
    },
    email: null,
    password: null,
    loader: {
      stage1: false,
      stage2: false,
    },
    currentComponent,
  };
};

export default {
  name: "SigninDialog",
  mixins: [validationMixin, ProxyModelMixin],
  validations: {
    email: { required, email },
    password: { required },
    company: {
      uri: {
        required,
        validCompanyUri,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
    },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    password: {
      required: "message.validation.password.required",
    },
    company: {
      uri: {
        required: "message.validation.company.uri.required",
        maxLength: "message.validation.company.uri.max",
        minLength: "message.validation.company.uri.min",
        validCompanyUri: "message.validation.company.uri.validurl",
      },
    },
  },
  components: {
    ForgotCompany,
    ForgotPassword,
  },
  data() {
    return {
      stage: 0,
      errors: {
        checkCompanyUri: null,
        singIn: null,
      },
      company: {
        id: null,
        uri: null,
        name: null,
        public_link: null,
      },
      email: null,
      password: null,
      loader: {
        stage1: false,
        stage2: false,
      },
      currentComponent: "signin",
    };
  },
  methods: {
    async checkCompanyUri() {
      this.loader.stage1 = true;
      const variables = {
        uri: this.company.uri,
      };

      try {
        const response = await this.$api.query({
          query: CompanyQuery,
          variables,
        });

        const company = response.data.company;
        if (!company) {
          this.errors.checkCompanyUri = this.$t(
            "message.validation.company.notExist"
          );
        } else {
          // Route to dashboard if company already authenticated
          if (has(this.companies, company.uri)) {
            this.$store.dispatch("activeCompany", company.uri);
            this.$store.dispatch("companyStatus", {
              isActive: company.status,
              subscriptionOver: false,
              graceHours: 0,
              hasPaycard: true,
            });
            this.$emit("companyExisted", company);
            this.loader.stage1 = false;
          } else {
            this.company.id = company.id;
            this.company.name = company.name;
            this.company.public_link = company.public_link;
            (this.company.pricing_version = company.pricing_version),
              (this.company.logo = company.logo);
            this.company.status = company.status;
            this.stage = 2;
          }
        }
        this.loader.stage1 = false;
      } catch (error) {
        this.errors.checkCompanyUri = "Something went wrong.";
        this.loader.stage1 = false;
      }
    },
    async singIn() {
      this.loader.stage2 = true;
      let credential = {
        username: this.email,
        password: this.password,
        company: this.company.uri,
      };
      const result = await login(credential);
      this.loader.stage2 = false;

      if (has(result, "error")) {
        this.errors.singIn = this.$t("message.validation.credentialNotMatch");
      } else {
        const user = await getUser(result.access_token);
        const { data } = await getWorkspaces(
          result.access_token,
          Workspaces2Query
        );
        let redirectWorkspaceId = null;
        const filteredWorkspaces = data.workspaces2.filter(
          (w) => !w.is_general && w.type !== "people" && w.type !== "personal"
        );
        if (filteredWorkspaces.length > 0) {
          redirectWorkspaceId = filteredWorkspaces[0].id;
        } else {
          redirectWorkspaceId = 1;
        }
        const shareData = {
          companyUri: this.company.uri,
          companyName: this.company.name,
          companyId: this.company.id,
          logo: this.company.logo,
          pricing_version: this.company.pricing_version,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          walkthrough: user.last_session_at <= user.created_at,
          redirectWorkspaceId,
          status: this.company.status,
          isActive: this.company.status === "active",
        };
        if (user.status === "inactive") {
          this.errors.singIn =
            user.company.user_id === user.id
              ? this.$t("message.validation.ownerInactive")
              : this.$t("message.validation.memberInactive");
        } else {
          this.$store.dispatch("addToCompanies", shareData);
          this.$store.dispatch("activeCompany", this.company.uri);
          this.$store.dispatch(
            "companyStatus",
            this.company.status === "active"
          );
          const checkCompany = await this.$api.mutate({
            mutation: CheckCompanyStatusMutation,
          });
          const status = {
            isActive:
              checkCompany.data.checkCompanyStatus.company_status === "active",
            subscriptionOver:
              checkCompany.data.checkCompanyStatus.subscription_over,
            trialOver: checkCompany.data.checkCompanyStatus.trial_over,
            graceHours: checkCompany.data.checkCompanyStatus.grace_hours,
            hasPaycard: checkCompany.data.checkCompanyStatus.hasPaycard,
          };
          this.$store.dispatch("companyStatus", status);

          this.$emit("success", shareData);
          let variables = {
            action: "login",
            user_id: user.id,
          };
          await this.$api.mutate({
            mutation: UserActionLogsMutation,
            variables,
          });
        }
      }
      this.loader.stage2 = false;
    },
    handleSwitchComponent(content) {
      this.currentComponent = content;
    },
    closeToRest() {
      this.$v.$reset();
      Object.assign(this.$data, initialState());
      this.proxyModel = false;
    },
    forgetCompanyHandler(data) {
      if (data) {
        this.$emit("forgetCompanyName");
        this.currentComponent = "signin";
      }
    },
    forgotPasswordSuccessHandler() {
      this.currentComponent = "signin";
      this.$emit("showSingInHandler", true);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      user: "user",
    }),
    isDisabledStage1() {
      if (this.$v.company.uri.$invalid || this.loader.stage1 === true) {
        return true;
      }
      return false;
    },
    isDisabledStage2() {
      if (
        this.$v.email.$invalid ||
        this.$v.password.$invalid ||
        this.loader.stage2 === true
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    proxyModel(val) {
      if (!val) return;
      this.$track.page("virtual/signin");
      requestAnimationFrame(() => {
        this.$refs.focus.focus();
      });
    },
    currentComponent() {
      this.$v.$reset();
      //   Object.assign(this.$data, initialState(to));
    },
  },
};
</script>
<style scoped>
.auth-center-section {
  margin-top: -150px;
}
.out-card-item {
  position: absolute;
  bottom: -50px;
}
.forgot-company-class {
  top: 12px;
  right: -3px;
}
</style>
