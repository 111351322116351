<template>
  <q-dialog
    content-class="footer-menu-dialog"
    :value="value"
    transition-hide="none"
    transition-show="none"
    persistent
  >
    <q-card class="container">
      <q-btn
        flat
        dense
        :icon="$icons.matClose"
        round
        size="10px"
        @click="closeDialog"
        class="close-btn"
        style="margin-right: 5px"
      />
      <q-card-section class="left-section">
        <div class="navigation-list">
          <div>
            <p
              :class="activeTab === 'timeLog' && 'selected-tab'"
              @click="navigateTo('timeLog')"
              v-if="checkVisible([1])"
            >
              Company timesheet
            </p>
            <p
              :class="activeTab === 'rolepermission' && 'selected-tab'"
              v-if="checkVisible([1, 2])"
              @click="navigateTo('rolepermission')"
            >
              Roles & permissions
            </p>
            <p
              :class="activeTab === 'manageMembers' && 'selected-tab'"
              v-if="checkVisible([1, 2])"
              @click="navigateTo('manageMembers')"
            >
              Manage members
            </p>
            <p
              :class="activeTab === 'importData' && 'selected-tab'"
              v-if="checkVisible([1])"
              @click="navigateTo('importData')"
            >
              Import
            </p>
          </div>
          <q-separator v-if="checkVisible([1, 2])"></q-separator>
          <div>
            <p
              :class="activeTab === 'accountSetting' && 'selected-tab'"
              v-if="checkVisible([1, 2, 3, 4])"
              @click="navigateTo('accountSetting')"
            >
              Change password
            </p>
            <p
              :class="activeTab === 'administration' && 'selected-tab'"
              v-if="checkVisible([1, 2])"
              @click="navigateTo('administration')"
            >
              Billing
            </p>
            <p
              :class="activeTab === 'userSetting' && 'selected-tab'"
              @click="navigateTo('userSetting')"
            >
              Edit profile
            </p>
          </div>
          <q-separator></q-separator>
          <div>
            <p
              :class="activeTab === 'notificationSetting' && 'selected-tab'"
              v-if="isVisible([1, 2, 3, 4])"
              @click="navigateTo('notificationSetting')"
            >
              Notifications settings
            </p>
          </div>
        </div>
        <div class="bottom-section">
          <div class="comapny-actions">
            <div>
              <q-avatar size="40px" v-if="currentCompany.logo">
                <img
                  :src="currentCompany.logo"
                  :alt="currentCompany.companyName"
                />
              </q-avatar>
              <q-avatar
                size="40px"
                color="blue-grey-4"
                text-color="white"
                class="text-bold app-text-normal"
                font-size="18px"
                v-else
              >
                {{ currentCompany.companyName | first2Char }}
              </q-avatar>
            </div>
            <div style="margin-left: 16px">
              <p class="company-title app-text-normal">
                {{ currentCompany.companyName }}
              </p>
              <p
                style="margin-top: 3px"
                class="edit-team-info"
                :class="activeTab === 'companysetting' && 'selected-tab'"
                @click="navigateTo('companysetting')"
              >
                Edit team info
              </p>
              <p style="color: #c66e88; margin-top: 8px" @click="signOut">
                Sign out
              </p>
            </div>
          </div>
          <q-separator style="margin-top: 25px"></q-separator>
          <q-btn
            label="Sign in to another team"
            no-caps
            class="switch-team-btn app-text-normal"
            @click="() => (loginModel = true)"
          />
        </div>
      </q-card-section>
      <q-separator vertical></q-separator>
      <q-card-section class="right-section">
        <company-timesheet-dialog
          v-if="activeTab === 'timeLog'"
          :fromCompany="true"
          :timelogs="getAllTimelogs"
          :board="currentWorkspaceObj.boards[0]"
          :workspace="currentWorkspaceObj"
        />
        <role-permissions-dialog v-if="activeTab === 'rolepermission'" />
        <manage-members-dialog
          :currentUser="user"
          v-if="activeTab === 'manageMembers'"
          :currentWorkspaceObj="currentWorkspaceObj"
        />
        <import-data-dialog
          :currentUser="user"
          v-if="activeTab === 'importData'"
          :company="activeCompany"
          :closeDialog="closeDialog"
        />
        <account-setting-dialog
          v-if="activeTab === 'accountSetting'"
          @close="manageDialogs('accountSetting')"
          @success="handelSettingChanges"
        />
        <administration-dialog
          v-if="activeTab === 'administration'"
          :currentWorkspace="currentWorkspaceObj"
          :company="activeCompany"
        />
        <user-setting-dialog
          v-if="activeTab === 'userSetting'"
          @success="handelSettingChanges"
        />
        <notification-setting-dialog
          v-if="activeTab === 'notificationSetting'"
          :currentUser="user"
          :companyId="activeCompany.companyId"
          @success="handelSettingChanges"
        />
        <company-setting-dialog
          v-if="activeTab === 'companysetting'"
          :userId="user.id"
          @success="handelSettingChanges"
        />
        <signin-dialog
          v-model="loginModel"
          v-if="loginModel"
          @success="handleLoginSuccess"
          @companyExisted="handleLoggedInCompany"
          @changePassword="handleChangePassword"
          @forgetCompanyName="forgetCompanyHandler"
          @showSingInHandler="showSingInHandler"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ManageDialogMixin from "@/mixins/SidebarMixins/ManageDialogMixin";
import { mapGetters } from "vuex";
import CompanyTimesheetDialog from "./CompanyTimesheetDialog.vue";
import RolePermissionsDialog from "./RolePermissionsDialog.vue";
import ManageMembersDialog from "./ManageMembersDialog.vue";
import ImportDataDialog from "./ImportDataDialog.vue";
import AccountSettingDialog from "./AccountSettingDialog.vue";
import AdministrationDialog from "./AdministrationDialog.vue";
import UserSettingDialog from "./UserSettingDialog.vue";
import NotificationSettingDialog from "./NotificationSettingDialog.vue";
import get from "lodash/get";
import CompanySettingDialog from "./CompanySettingDialog.vue";
import SigninDialog from "./SigninDialog.vue";
import SignInToAnotherCompanyMixin from "@/mixins/SidebarMixins/SignInToAnotherCompanyMixin";

export default {
  components: {
    CompanyTimesheetDialog,
    RolePermissionsDialog,
    ManageMembersDialog,
    AccountSettingDialog,
    AdministrationDialog,
    UserSettingDialog,
    NotificationSettingDialog,
    CompanySettingDialog,
    SigninDialog,
    ImportDataDialog,
  },
  data() {
    return {
      value: true,
      loginModel: false,
    };
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  props: [
    "currentWorkspaceId",
    "currentWorkspaceObj",
    "isVisible",
    "footerMenuDialog",
  ],
  mixins: [ManageDialogMixin, SignInToAnotherCompanyMixin],
  methods: {
    checkVisible(data) {
      return this.isVisible(data);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    signOut() {
      this.$emit("signOut", this.ownCompany);
    },
    navigateTo(item) {
      this.footerMenuDialog.selectedMenuItem = item;
    },
    handelSettingChanges(data) {
      if (data && data.message) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: data.message,
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      ownCompany: "activeCompany",
    }),
    currentCompany() {
      return this.companies[this.ownCompany];
    },
    workspaces() {
      const query = this.$api.getQuery("Workspaces2Query");
      return query.data && query.data.workspaces2;
    },
    getAllTimelogs() {
      let allTimelogs = [];
      if (this.workspaces) {
        this.workspaces.forEach((element) => {
          if (element.timelogs) {
            element.timelogs.forEach((timelog) => {
              allTimelogs.push(timelog);
            });
          }
        });
      }
      return allTimelogs;
    },
    activeCompany() {
      return get(this.companies, this.$route.params.company);
    },
    activeTab() {
      return this.footerMenuDialog.selectedMenuItem;
    },
  },
};
</script>
