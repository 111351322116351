<template>
  <div
    :class="[
      isMenu && 'hover-list-tile',
      workspace.type === 'people'
        ? 'list-tile people_workspace_spacing'
        : 'list-tile',
    ]"
    :data-id="workspace.id"
    :id="'anchorEl-workspace-' + workspace.id"
    style="margin-top: 6px; width: 100%"
    @click="redirect()"
  >
    <q-popup-proxy
      :value="true"
      v-if="currentWorkspaceId === workspace.id && user.onboarding_step == 1"
      persistent
      :target="'#anchorEl-workspace-' + currentWorkspaceId"
      anchor="top right"
      :content-class="'popover-proxy'"
    >
      <q-card
        content-class="arrow-up"
        class="popover-walkthrough arrow_box"
        id="popover"
        style="background-color: white; height: 320px"
      >
        <div class="icon">
          <q-btn :icon="$icons.mdiHelp" round size="30px" class="help-button">
          </q-btn>
        </div>
        <div class="content">
          <div class="header">
            <div class="titles">Workspaces</div>
            <div class="navigation-buttons">
              <div
                style="
                  margin-right: 15px;
                  cursor: pointer;
                  color: #007aff;
                  text-decoration: underline;
                "
                @click="updateOnboardingWalkthrough(4)"
              >
                Skip
              </div>
              <div class="selected-popop"></div>
              <div class="not-selected-popop"></div>
              <div class="not-selected-popop"></div>
              <q-btn
                class="arrow-item"
                :icon="$icons.matArrowForward"
                size="xs"
                round
                @click="updateOnboardingWalkthrough(2)"
              />
            </div>
          </div>
          <p style="color: #4e5463">
            Workspaces organize all of your messages, files, and tasks by
            department or projects.
          </p>
          <div class="video" @click="videoPlayer(579275845)">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="70px"
            />
          </div>
        </div>
      </q-card>
    </q-popup-proxy>
    <q-popup-proxy
      :value="true"
      v-if="
        currentWorkspaceId === workspace.id &&
        isShowWorkspaceMenu <= 1 &&
        user.onboarding_step === 3
      "
      persistent
      :target="'#anchorEl-workspace-' + currentWorkspaceId"
      anchor="bottom right"
      self="top left"
      :content-class="'popover-proxy'"
    >
      <q-card
        class="popover-walkthrough arrow_box"
        id="popover"
        style="background-color: white; height: 340px"
      >
        <div class="icon">
          <q-btn :icon="$icons.mdiHelp" round size="30px" class="help-button">
          </q-btn>
        </div>
        <div class="content">
          <div class="header">
            <div class="titles">Messages</div>
            <div class="navigation-buttons">
              <!-- <div style="margin-right: 15px; cursor: pointer; color:#007aff; text-decoration:underline" @click="updateOnboardingWalkthrough(4)">Skip</div> -->
              <div class="not-selected-popop"></div>
              <div class="not-selected-popop"></div>
              <div class="selected-popop"></div>
              <q-btn
                class="arrow-item"
                :icon="$icons.fasCheck"
                size="xs"
                round
                @click="updateOnboardingWalkthrough(4)"
              />
            </div>
          </div>
          <p style="color: #4e5463">
            Use Messages to chat with your team real-time, chat 1:1, and share
            files so teams can collaborate with more harmony and less
            disruption.
          </p>
          <div class="video" @click="videoPlayer(569191210)">
            <q-icon
              class="play"
              :name="$icons.matPlayArrow"
              color="primary"
              size="70px"
            />
          </div>
        </div>
      </q-card>
    </q-popup-proxy>
    <q-item-section :class="['list__tile__content']">
      <q-item-label
        :class="[
          hasWorkspaceNotification && 'notification',
          'row items-center workspace-title q-mb-xs',
        ]"
      >
        <div
          class="relative-position"
          style="margin-right: 11px"
          v-if="workspace.type === 'people' && oppChatUser"
        >
          <q-avatar
            @click="showUserInfo(oppChatUser[0])"
            class="cursor-pointer"
            size="25px"
            color="grey-4"
            text-color="grey-10"
          >
            <img
              :src="oppChatUser[0].pic"
              :alt="oppChatUser[0].first"
              v-if="oppChatUser[0] && oppChatUser[0].pic"
            />
            <!-- <span class="text-avatar-style an-14 demi-bold-text " v-else>
              {{ oppChatUser[0].first | firstChar
              }}{{ oppChatUser[0].last | firstChar }}
            </span> -->
            <avatar
              v-else
              :size="25"
              :customStyle="{ 'font-size': '12px', 'font-weight': '600' }"
              :username="oppChatUser[0].first + ' ' + oppChatUser[0].last"
            ></avatar>
          </q-avatar>
          <div
            :class="[
              isPeopleWorkspaceOnline(workspace)
                ? 'badge-icon online'
                : 'badge-icon ',
            ]"
          ></div>
        </div>
        <div
          class="relative-position"
          style="margin-right: 10px"
          v-if="workspace.type != 'people'"
        >
          <avatar
            :naresh-kothiya="index"
            :rounded="false"
            :size="22"
            :customStyle="{
              display: 'block',
              'font-size': '14px',
              'font-weight': '700',
              'border-radius': '3px',
              'font-family': 'Avenir Next',
              border: '1px solid ' + workspaceColors[index % 10].border,
            }"
            :color="workspaceColors[index % 10].color"
            :backgroundColor="workspaceColors[index % 10].background"
            :username="workspace.title | firstChar"
          ></avatar>
        </div>
        <span class="text-truncate" :style="computeMaxHeight">
          <router-link
            :to="{}"
            :style="{
              color: 'inherit',
              'text-decoration': 'none',
            }"
            v-tooltip
          >
            {{ workspace.title }}
          </router-link>
          <q-tooltip :offset="[0, -50]" v-if="isTruncated">
            {{ workspace.title }}
          </q-tooltip>
        </span>
        <q-icon
          v-if="hasMentionNotification"
          color="primary"
          style="margin-left: 3px"
          :name="$icons.matFiberManualRecord"
          size="12px"
        />
        <q-menu
          v-if="!isPeople"
          v-model="isMenu"
          context-menu
          content-style="padding: 0 9px;"
        >
          <q-list class="q-py-sm">
            <q-item
              style="min-height: 24px"
              @click="preferredWorkspace"
              clickable
              v-close-popup
            >
              <q-item-section
                >{{
                  workspace.is_preferred ? "Remove from" : "Add to"
                }}
                favorite</q-item-section
              >
            </q-item>
            <q-item
              clickable
              v-if="folders && folders.length"
              style="min-height: 24px"
            >
              <q-item-section> Move workspace </q-item-section>
              <q-item-section side>
                <q-icon :name="$icons.matKeyboardArrowRight" />
              </q-item-section>
              <q-menu
                auto-close
                anchor="top end"
                self="top start"
                content-style="padding: 0 9px;"
              >
                <q-list style="min-width: 150px" class="q-py-sm">
                  <q-item
                    v-if="workspaceFolder"
                    style="min-height: 24px"
                    clickable
                    @click="moveWorkspaceToFolder(null)"
                  >
                    <q-item-section> Out of folder </q-item-section>
                    <q-separator />
                  </q-item>
                  <template v-if="workspaceFolder">
                    <div class="text-bold text-subtitle1 q-ml-md q-mt-xs">
                      To folder
                    </div>
                    <template v-for="folder in folders">
                      <q-item
                        :key="folder.id"
                        style="min-height: 24px"
                        clickable
                        v-if="folder.id !== workspaceFolder.id"
                        @click="moveWorkspaceToFolder(folder.id)"
                      >
                        <q-item-section>
                          {{ folder.title }}
                        </q-item-section>
                      </q-item>
                    </template>
                  </template>
                  <template v-else>
                    <div
                      class="text-bold text-subtitle1 q-ml-md q-mt-xs"
                      v-if="folders.length > 0"
                    >
                      To folder:
                    </div>
                    <q-item
                      v-for="folder in folders"
                      :key="folder.id"
                      style="min-height: 24px"
                      clickable
                      @click="moveWorkspaceToFolder(folder.id)"
                    >
                      <q-item-section>
                        {{ folder.title }}
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </q-menu>
            </q-item>
            <q-separator class="q-my-sm" style="background: #e3e8ed" />
            <q-item
              style="min-height: 24px"
              @click="editView"
              clickable
              v-close-popup
              v-if="canChangeWorkspaceSetting || isWorkspaceOwner"
            >
              <q-item-section> Workspace settings </q-item-section>
            </q-item>
            <!-- <q-item
              style="min-height:24px"
              v-if="workspace.notification_level !== 'none'"
              @click="changeWorkspaceSetting('none')"
              clickable
              v-close-popup
            >
              <q-item-section>
                Mute workspace
              </q-item-section>
            </q-item>
            <q-item
              style="min-height:24px"
              v-else
              @click="changeWorkspaceSetting('all')"
              clickable
              v-close-popup
            >
              <q-item-section>
                Unmute workspace
              </q-item-section>
            </q-item> -->
            <q-item
              style="min-height: 24px"
              @click="openNotificationSettingDialog"
              clickable
              v-close-popup
            >
              <q-item-section> Change notifications </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="checkWorkspaceAccess">
              <q-item-section>Workspace e-mail</q-item-section>
            </q-item>
            <q-separator class="q-my-sm" style="background: #e3e8ed" />
            <q-item
              style="min-height: 24px"
              @click="timelogs.flag = true"
              clickable
              v-close-popup
            >
              <q-item-section> View timesheet </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="exportTimeLogCsv()">
              <q-item-section>Export timesheet</q-item-section>
            </q-item>
            <q-separator class="q-my-sm" style="background: #e3e8ed" />
            <q-item
              style="min-height: 24px"
              @click="checkExportConvoAccess"
              clickable
              v-close-popup
            >
              <q-item-section>Export conversation</q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px"
              class="text-red"
              @click="dialogHandler(true, 'leaveWorkspaceDialog')"
              v-if="!isWorkspaceOwner && !isGeneral"
              clickable
              v-close-popup
            >
              <q-item-section> Leave workspace </q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px"
              class="text-red"
              v-if="canDeleteWorkspace || (!isGeneral && isWorkspaceOwner)"
              @click="dialogHandler(true, 'deleteWorkspaceDialog')"
              clickable
              v-close-popup
            >
              <q-item-section>Delete workspace</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <q-menu v-if="isPeople" context-menu v-model="isMenu">
          <q-list v-if="isPeople" class="q-py-sm">
            <q-item
              style="min-height: 24px"
              @click="preferredWorkspace"
              clickable
              v-close-popup
            >
              <q-item-section
                >{{
                  workspace.is_preferred ? "Remove from" : "Add to"
                }}
                favorite</q-item-section
              >
            </q-item>
            <q-item
              clickable
              v-if="folders && folders.length"
              style="min-height: 24px"
            >
              <q-item-section> Move </q-item-section>
              <q-item-section side>
                <q-icon :name="$icons.matKeyboardArrowRight" />
              </q-item-section>
              <q-menu auto-close anchor="top end" self="top start">
                <q-list style="min-width: 150px">
                  <q-item
                    v-if="workspaceFolder"
                    style="min-height: 24px"
                    clickable
                    @click="moveWorkspaceToFolder(null)"
                  >
                    <q-item-section> Out of folder </q-item-section>
                  </q-item>
                  <q-separator />
                  <template v-if="workspaceFolder">
                    <div class="text-bold text-subtitle1 q-ml-md q-mt-xs">
                      To folder:
                    </div>
                    <template v-for="folder in folders">
                      <q-item
                        :key="folder.id"
                        style="min-height: 24px"
                        clickable
                        v-if="folder.id !== workspaceFolder.id"
                        @click="moveWorkspaceToFolder(folder.id)"
                      >
                        <q-item-section>
                          {{ folder.title }}
                        </q-item-section>
                      </q-item>
                    </template>
                  </template>
                  <template v-else>
                    <div
                      class="text-bold text-subtitle1 q-ml-md q-mt-xs"
                      v-if="folders.length > 0"
                    >
                      To folder:
                    </div>
                    <q-item
                      v-for="folder in folders"
                      :key="folder.id"
                      style="min-height: 24px"
                      clickable
                      @click="moveWorkspaceToFolder(folder.id)"
                    >
                      <q-item-section>
                        {{ folder.title }}
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </q-menu>
            </q-item>
            <q-separator class="q-my-sm" style="background: #e3e8ed" />
            <q-item
              style="min-height: 24px"
              @click="editView"
              clickable
              v-close-popup
            >
              <q-item-section> Workspace settings </q-item-section>
            </q-item>
            <!-- <q-item
                style="min-height:24px"
                v-if="workspace.notification_level !== 'none'"
                @click="changeWorkspaceSetting('none')"
                clickable
                v-close-popup
              >
                <q-item-section>
                  Mute workspace
                </q-item-section>
              </q-item>
              <q-item
                style="min-height:24px"
                v-else
                @click="changeWorkspaceSetting('all')"
                clickable
                v-close-popup
              >
                <q-item-section>
                  Unmute workspace
                </q-item-section>
              </q-item> -->
            <q-item
              style="min-height: 24px"
              @click="openNotificationSettingDialog"
              clickable
              v-close-popup
            >
              <q-item-section> Change notifications </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="checkWorkspaceAccess">
              <q-item-section>Workspace e-mail</q-item-section>
            </q-item>
            <q-separator class="q-my-sm" style="background: #e3e8ed" />
            <q-item
              style="min-height: 24px"
              @click="checkExportConvoAccess"
              clickable
              v-close-popup
            >
              <q-item-section>Export conversation</q-item-section>
            </q-item>
            <q-item
              style="min-height: 24px"
              class="text-red"
              @click="dialogHandler(true, 'deleteWorkspaceDialog')"
              clickable
              v-close-popup
            >
              <q-item-section>Remove conversation</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <div class="q-ml-xs" v-if="oppChatUser && oppChatUser[0].userStatus">
          <img
            style="width: 16px; vertical-align: middle"
            :src="'/static/react-icons/' + oppChatUser[0].userStatus.icon"
          />
          <q-tooltip>{{ oppChatUser[0].userStatus.name }}</q-tooltip>
        </div>
      </q-item-label>
      <q-item-label class="subitems-vb" caption v-if="isShowWorkspaceMenu > 1">
        <router-link
          v-if="workspace.show_tasks"
          :disabled="!workspace.boards[0].id"
          :to="{
            name: 'BoardView',
            params: {
              company: $route.params.company,
              workspace: workspace.id,
            },
          }"
          @click.native.stop
          tag="q-btn"
          :ripple="false"
          class="text-white sidebar-section-btn board-section"
          style="border"
        >
          <!-- <q-tooltip>
            Tasks
          </q-tooltip> -->
          <div class="row">
            <!-- <q-icon class="icon" color="white" :name="$icons.fasCheck">
            </q-icon> -->
            <span
              style="width: 100%"
              class="count"
              v-if="boardNotificationCount(workspace).length > 0"
            >
              <span v-if="boardNotificationCount(workspace).length > 9"
                >Tasks 9+</span
              >
              <span v-else>
                Tasks {{ boardNotificationCount(workspace).length }}
              </span>
            </span>
            <span style="width: 100%" class="count" v-else>
              <span> Tasks </span>
            </span>
          </div>
        </router-link>
        <router-link
          v-if="workspace.show_messages"
          :to="{
            name: 'MessagingView',
            params: {
              company: $route.params.company,
              workspace: workspace.id,
            },
          }"
          tag="q-btn"
          class="text-white sidebar-section-btn message-section"
          :class="[
            workspace.show_messages &&
              !workspace.show_files &&
              isShowWorkspaceMenu > 1 &&
              'media-section',
            workspace.show_messages &&
              !workspace.show_tasks &&
              isShowWorkspaceMenu > 1 &&
              'board-section',
          ]"
          :id="'workspace-messages-' + workspace.id"
          @click.native.stop
        >
          <!-- <q-tooltip>
            Messages
          </q-tooltip> -->
          <!-- <q-icon class="icon" color="white" :name="$icons.matChatBubble">
            </q-icon> -->
          <q-popup-proxy
            :value="true"
            v-if="
              currentWorkspaceId === workspace.id && user.onboarding_step === 3
            "
            persistent
            :target="'#workspace-messages-' + currentWorkspaceId"
            anchor="top right"
            self="top left"
            :content-class="'popover-proxy'"
            :offset="[10, 30]"
          >
            <q-card
              class="popover-walkthrough arrow_box"
              id="popover"
              style="background-color: white; height: 340px"
            >
              <div class="icon">
                <q-btn
                  :icon="$icons.mdiHelp"
                  round
                  size="30px"
                  class="help-button"
                >
                </q-btn>
              </div>
              <div class="content">
                <div class="header">
                  <div class="titles">Messages</div>
                  <div class="navigation-buttons">
                    <!-- <div style="margin-right: 15px; cursor: pointer; color:#007aff; text-decoration:underline" @click="updateOnboardingWalkthrough(4)">Skip</div> -->
                    <div class="not-selected-popop"></div>
                    <div class="not-selected-popop"></div>
                    <div class="selected-popop"></div>
                    <q-btn
                      class="arrow-item"
                      :icon="$icons.fasCheck"
                      size="xs"
                      round
                      @click="updateOnboardingWalkthrough(4)"
                    />
                  </div>
                </div>
                <p style="color: #4e5463">
                  Use Messages to chat with your team real-time, chat 1:1, and
                  share files so teams can collaborate with more harmony and
                  less disruption.
                </p>
                <div class="video" @click="videoPlayer(569191210)">
                  <q-icon
                    class="play"
                    :name="$icons.matPlayArrow"
                    color="primary"
                    size="70px"
                  />
                </div>
              </div>
            </q-card>
          </q-popup-proxy>
          <span
            class="count"
            v-if="collaborationNotificationCount(workspace).length > 0"
          >
            <span v-if="collaborationNotificationCount(workspace).length > 9"
              >Chat 9+</span
            >
            <span v-else>
              Chat {{ collaborationNotificationCount(workspace).length }}
            </span>
          </span>
          <span style="width: 100%" class="count" v-else>
            <span> Chat </span>
          </span>
        </router-link>
        <router-link
          v-if="workspace.show_files"
          :to="{
            name: 'MediaView',
            params: {
              company: $route.params.company,
              workspace: workspace.id,
            },
          }"
          tag="q-btn"
          class="text-white sidebar-section-btn media-section"
          @click.native.stop
        >
          <!-- <q-tooltip>
            Files
          </q-tooltip> -->
          <div class="row">
            <!-- <q-icon class="icon" color="white" :name="$icons.matFileCopy"> </q-icon> -->
            <span class="count">
              <span> Files </span>
            </span>
          </div>
        </router-link>
      </q-item-label>
    </q-item-section>
    <time-log-dialog
      v-if="timelogs.flag"
      :fromWorkspace="true"
      v-model="timelogs.flag"
      :timelogs="workspace.timelogs"
      :board="workspace.boards[0]"
      :workspace="workspace"
      :closeDialog="closeTimeLogDialog"
    />
    <workspace-create-dialog
      v-if="updateWorkspace.show"
      v-model="updateWorkspace.show"
      @success="updateWorkspace.show = false"
      :workspace="workspace"
      :members="members"
    />
    <workspace-notification-settings
      v-if="notificationSettingDialog"
      :workspace="workspace"
      :notificationSettingDialog="notificationSettingDialog"
      :closeDialog="closeNotificationSettingDialog"
    />
    <div v-if="deleteWorkspaceDialog.flag">
      <confirm-dialog
        v-model="deleteWorkspaceDialog.flag"
        title="Delete Workspace?"
        :question="`Are you sure you want to delete the ${workspace.title} Workspace ?`"
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'deleteWorkspaceDialog')"
        :successMethod="deleteWorkspace"
        :loading="deleteWorkspaceLoader"
      />
    </div>
    <div v-if="leaveWorkspaceDialog.flag">
      <confirm-dialog
        v-model="leaveWorkspaceDialog.flag"
        title="Leave Workspace ?"
        :question="`Are you sure you want to leave the ${workspace.title} Workspace ?`"
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'leaveWorkspaceDialog')"
        :successMethod="leaveWorkspace"
        :loading="leaveWorkspaceLoader"
      />
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :currentWorkspaceId="workspace.id"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <workspace-email
      v-model="workspaceEmailDialog.show"
      v-if="workspaceEmailDialog.show"
      :workspaceName="
        workspace.type == 'people' ? workspace.peopletitle : workspace.title
      "
      :companyUrl="currentCompany.companyUri"
      :closeDialog="clearEmailDialog"
    />
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    />
  </div>
</template>
<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { exportMessage, exportTimeLogs } from "@/services/auth";
import UserScope from "@/mixins/UserScope";
import WorkspaceCreateDialog from "@/components/Dialogs/WorkspaceCreateDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ProfileDialog from "@/components/ProfileDialog";
import WorkspaceNotificationSettings from "./WorkspaceNotificationSettings";
import TimeLogDialog from "@/components/Dialogs/TimeLogDialog.vue";
import BotMessenger from "@/mixins/BotMessenger";
import Avatar from "vue-avatar";

import {
  PreferredWorkspaceMutation,
  DeleteWorkspaceMutation,
  InvitationWorkspaceQuery,
  LeaveWorkspaceMutation,
  // UpdateNotificationLevelMutation,
  AddWorkspaceToFolderMutation,
  UpdateWorkspaceFolderMutation,
  RemoveWorkspaceFromFolderMutation,
  UpdateOnboardingStepMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
import WorkspaceEmail from "@/views/WorkspaceView/NavigationSidebar/WorkspaceEmail";
import VideoDialog from "../../../components/VuePlayer/VideoDialog.vue";

export default {
  name: "WorkspaceNavigation",
  props: [
    "workspace",
    "onlineMembers",
    "folders",
    "returnCurrentWorkspaceId",
    "index",
    "isPreferred",
  ],
  mixins: [UserScope, BotMessenger],
  components: {
    WorkspaceCreateDialog,
    ConfirmDialog,
    ProfileDialog,
    WorkspaceNotificationSettings,
    TimeLogDialog,
    WorkspaceEmail,
    VideoDialog,
    Avatar,
  },
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    members: {
      cacheKey: "MembersQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      showPopover: false,
      isTruncated: false,
      updateWorkspace: {
        show: false,
        type: "private",
      },
      deleteWorkspaceDialog: {
        flag: false,
      },
      leaveWorkspaceDialog: {
        flag: false,
      },
      profileDialog: {
        show: false,
        user: null,
      },
      workspaceEmailDialog: {
        show: false,
      },
      leaveWorkspaceLoader: false,
      deleteWorkspaceLoader: false,
      notificationSettingDialog: false,
      timelogs: {
        flag: false,
      },
      isMenu: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      workspaceColors: [
        {
          background: "#E9E7F4",
          border: "#978CCD",
          color: "#483897",
        },
        {
          background: "#D9DCE2",
          border: "#B3BAC5",
          color: "#42526E",
        },
        {
          background: "#99CAFF",
          border: "#66AFFF",
          color: "#0056B2",
        },
        {
          background: "#A1EFD7",
          border: "#44E0AE",
          color: "#0B7352",
        },
        {
          background: "#F5B9CA",
          border: "#F197AF",
          color: "#9C3752",
        },
        {
          background: "#BAB3DE",
          border: "#978CCD",
          color: "#3E3182",
        },
        {
          background: "#FFDAA5",
          border: "#FFB54B",
          color: "#57370A",
        },
        {
          background: "#FFC4B8",
          border: "#FF9B85",
          color: "#851800",
        },
        {
          background: "#B8FBFF",
          border: "#1EF1FF",
          color: "#007C85",
        },
        {
          background: "#B9B8FF",
          border: "#8785FF",
          color: "#030085",
        },
      ],
    };
  },
  methods: {
    redirect() {
      const path = `/companies/${this.$route.params.company}/workspaces/${this.workspace.id}/board`;
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    },
    checkExportConvoAccess() {
      let eligible = this.$checkEligiblity("export_conversation");
      if (eligible) {
        this.exportMessageCsv();
      }
    },
    checkWorkspaceAccess() {
      let eligible = this.$checkEligiblity("email_to_message");
      if (eligible) {
        this.workspaceEmailDialog.show = true;
      }
    },
    async moveWorkspaceToFolder(folderId) {
      if (this.workspaceFolder) {
        if (folderId) {
          const variables = {
            workspace_id: this.workspace.id,
            folder_id: folderId,
            old_folder_id: this.workspaceFolder.id,
          };
          const workspace = this.$api.getEntity("workspace", this.workspace.id);
          workspace.folder = workspace.folder.filter(
            (obj) => obj.id !== this.workspaceFolder.id
          );
          const folder = this.$api.getEntity("folder", folderId);
          workspace.folder.push(folder);
          await this.$api.mutate({
            mutation: UpdateWorkspaceFolderMutation,
            variables,
          });
        } else {
          const variables = {
            workspace_id: this.workspace.id,
            folder_id: this.workspaceFolder.id,
          };
          const workspace = this.$api.getEntity("workspace", this.workspace.id);
          workspace.folder = workspace.folder.filter(
            (obj) => obj.id !== this.workspaceFolder.id
          );
          await this.$api.mutate({
            mutation: RemoveWorkspaceFromFolderMutation,
            variables,
          });
        }
      } else {
        const variables = {
          workspace_ids: [this.workspace.id],
          folder_id: folderId,
        };
        const workspace = this.$api.getEntity("workspace", this.workspace.id);
        const folder = this.$api.getEntity("folder", folderId);
        workspace.folder.push(folder);
        await this.$api.mutate({
          mutation: AddWorkspaceToFolderMutation,
          variables,
        });
      }
    },
    async exportTimeLogCsv() {
      if (this.workspace.timelogs.length) {
        const res = await exportTimeLogs(
          this.workspace.id,
          this.currentCompany.accessToken
        );
        if (res && res.status_code === 200) {
          window.location = res.data;
        }
      } else {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "There are no hours tracked.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    // async changeWorkspaceSetting(notificationLevel) {
    //   const query = this.$api.getQuery("Workspaces2Query");
    //   if (query.data) {
    //     query.data.workspaces2.map((w) => {
    //       if (w.id === this.workspace.id) {
    //         w.notification_level = notificationLevel;
    //       }
    //     });
    //   }
    //   try {
    //     await this.$api.mutate({
    //       mutation: UpdateNotificationLevelMutation,
    //       variables: {
    //         workspace_id: this.workspace.id,
    //         notification_level: notificationLevel,
    //       },
    //     });
    //   } catch (err) {
    //     this.$q.notify({
    //       color: "negative",
    //       position: "top-right",
    //       message: "Something went wrong..",
    //       icon: this.$icons.matReportProblem,
    //     });
    //   }
    // },
    openNotificationSettingDialog() {
      this.notificationSettingDialog = !this.notificationSettingDialog;
    },
    closeNotificationSettingDialog() {
      this.notificationSettingDialog = false;
    },
    closeTimeLogDialog() {
      this.timelogs.flag = false;
    },
    dialogHandler(flag = true, dialog = "deleteWorkspaceDialog") {
      this[dialog] = {
        flag,
      };
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    clearEmailDialog() {
      this.workspaceEmailDialog.show = false;
    },
    async deleteWorkspace() {
      this.deleteWorkspaceLoader = true;
      const deletedWorkspaceId = this.userWorkspace.id;

      const variables = {
        id: deletedWorkspaceId,
      };
      const query = this.$api.getQuery("Workspaces2Query");
      await this.$api.mutate({
        mutation: DeleteWorkspaceMutation,
        variables,
      });
      this.deleteWorkspaceLoader = false;
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== deletedWorkspaceId
        );
      }

      this.$api.query({
        query: InvitationWorkspaceQuery,
        cacheKey: "InvitationWorkspaceQuery",
      });

      this.dialogHandler(false, "deleteWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.currentCompany.companyUri },
      });
    },
    async leaveWorkspace() {
      this.leaveWorkspaceLoader = true;
      const workspaceId = this.userWorkspace.id;
      const variables = {
        id: workspaceId,
      };
      await this.leavePublicWorkspaceAnnouncement({ workspaceId }); // mixin call
      await this.$api.mutate({
        mutation: LeaveWorkspaceMutation,
        variables,
      });
      this.leaveWorkspaceLoader = false;
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== workspaceId
        );
      }

      this.$api.query({
        query: InvitationWorkspaceQuery,
        cacheKey: "InvitationWorkspaceQuery",
      });

      this.dialogHandler(false, "leaveWorkspaceDialog");

      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.currentCompany.companyUri },
      });
    },
    async preferredWorkspace() {
      const variables = {
        workspace_id: this.userWorkspace.id,
      };
      try {
        const workspace = this.$api.getEntity(
          "workspace",
          this.userWorkspace.id
        );
        workspace.is_preferred = !workspace.is_preferred;
        await this.$api.mutate({
          mutation: PreferredWorkspaceMutation,
          variables,
        });
        mixpanel.track(
          `${
            workspace.is_preferred
              ? "Workspace Preferred Add"
              : "Workspace Preferred Remove"
          }`
        );
      } catch {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    isPeopleWorkspaceOnline(workspace) {
      let isOnline;

      workspace.users.forEach((u) => {
        if (this.user && u.id !== this.user.id) {
          isOnline =
            this.onlineMembers &&
            Object.keys(this.onlineMembers)
              .map((m) => Number(m))
              .includes(u.id);
        }
      });
      return isOnline;
    },
    editView() {
      this.updateWorkspace.show = !this.updateWorkspace.show;
    },
    is_user_mentioned(user, string) {
      const regex = new RegExp("(^|\\s)@" + user.username + "($|\\s)");
      const regexForAll = new RegExp("(^|\\s)@all($|\\s)");
      return regex.test(string) || regexForAll.test(string);
    },

    collaborationNotificationCount(workspace) {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope && n.action.scope.id === workspace.id)
          .filter((n) => n.action.object_type === "App\\Post");
        return notifications;
      }
      return [];
    },
    boardNotificationCount(workspace) {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope && n.action.scope.id === workspace.id)
          .filter(
            (n) =>
              n.action.type === "task-comment-created" ||
              n.action.type === "task-member-attached" ||
              n.action.type === "subtask-member-attached" ||
              n.action.type === "task-comment-reaction-added"
          );
        return notifications;
      }
      return [];
    },
    async exportMessageCsv() {
      const res = await exportMessage(
        this.workspace.id,
        this.currentCompany.accessToken
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    async updateOnboardingWalkthrough(stepValue) {
      let variables = {
        onboarding_step: stepValue,
      };
      this.user.onboarding_step = stepValue;
      await this.$api.mutate({
        mutation: UpdateOnboardingStepMutation,
        variables,
      });
      this.$mixpanelEvent(`walkthrough-${stepValue}`, {});
    },
    videoPlayer(video_id) {
      let media = {
        isVimeo: true,
        file: `https://player.vimeo.com/video/${video_id}`,
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    isShowWorkspaceMenu() {
      let count = 0;
      if (
        !this.workspace.show_tasks &&
        !this.workspace.show_messages &&
        !this.workspace.show_files
      ) {
        count++;
      }

      if (this.workspace.show_tasks) {
        count++;
      }

      if (this.workspace.show_messages) {
        count++;
      }

      if (this.workspace.show_files) {
        count++;
      }
      return count;
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    hasWorkspaceNotification() {
      if (this.userWorkspace.read_cursor) {
        return true;
      } else {
        const query = this.$api.getQuery(`NotificationsQuery`);
        if (query.data && this.workspace) {
          const notifications = query.data.notifications
            .filter((n) => !n.read)
            .filter(
              (n) => n.action.scope && n.action.scope.id === this.workspace.id
            );
          return notifications.length > 0;
        }
        return false;
      }
    },
    hasMentionNotification() {
      if (this.userWorkspace.read_cursor) {
        return true;
      } else {
        const query = this.$api.getQuery(`NotificationsQuery`);
        if (query.data && this.workspace) {
          const notifications = query.data.notifications
            .filter((n) => !n.read)
            .filter(
              (n) => n.action.scope && n.action.scope.id === this.workspace.id
            )
            .filter((n) => {
              const { action } = n;
              if (action.type === "task-comment-created" && action.object) {
                if (this.is_user_mentioned(this.user, action.object.comment)) {
                  return n;
                }
              } else if (action.type === "message-created") {
                if (this.is_user_mentioned(this.user, action.object.content)) {
                  return n;
                }
              }
            });
          return notifications.length > 0;
        }
        return false;
      }
    },
    switchingType() {
      if (this.workspace) {
        return this.workspace.type === "public" ? "private" : "public";
      }
      return "public";
    },
    computeMaxHeight() {
      if (
        this.workspace.is_preferred &&
        this.workspace.notification_level === "none" &&
        this.workspace.type === "private"
      ) {
        if (this.hasWorkspaceNotification && this.workspace.folder.length > 0) {
          return { "max-width": "140px" };
        }
        if (this.workspace.folder.length > 0) {
          return { "max-width": "140px" };
        }
        if (this.hasWorkspaceNotification) {
          return { "max-width": "155px" };
        }
        return { "max-width": "170px" };
      } else if (
        (this.workspace.type === "private" && this.workspace.is_preferred) ||
        (this.workspace.type === "private" &&
          this.workspace.notification_level === "none") ||
        (this.workspace.is_preferred &&
          this.workspace.notification_level === "none")
      ) {
        if (this.hasWorkspaceNotification && this.workspace.folder.length > 0) {
          return { "max-width": "140px" };
        }
        if (this.workspace.folder.length > 0) {
          return { "max-width": "160px" };
        }
        if (this.hasWorkspaceNotification) {
          return { "max-width": "165px" };
        }
        return { "max-width": "180px" };
      } else if (
        this.workspace.type === "private" ||
        this.workspace.notification_level === "none" ||
        this.workspace.is_preferred
      ) {
        if (this.hasWorkspaceNotification && this.workspace.folder.length > 0) {
          return { "max-width": "150px" };
        }
        if (this.workspace.folder.length > 0) {
          return { "max-width": "180px" };
        }
        if (this.hasWorkspaceNotification) {
          return { "max-width": "175px" };
        }
        return { "max-width": "200px" };
      } else {
        if (this.hasWorkspaceNotification && this.workspace.folder.length > 0) {
          return { "max-width": "180px" };
        }
        if (this.workspace.folder.length > 0) {
          return { "max-width": "200px" };
        }
        if (this.hasWorkspaceNotification) {
          return { "max-width": "200px" };
        }
        return { "max-width": "210px" };
      }
    },
    oppChatUser() {
      if (this.workspace.type === "people") {
        return this.workspace.users.filter((u) => u.id !== this.user.id);
      }
      return null;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    userWorkspace() {
      return this.workspaces.find((w) => w.id === Number(this.workspace.id));
    },
    isGeneral() {
      if (this.userWorkspace) {
        return (
          this.userWorkspace.type === "public" && this.userWorkspace.is_general
        );
      } else {
        return false;
      }
    },
    isWorkspaceOwner() {
      if (this.userWorkspace && this.user) {
        return this.user.id === this.userWorkspace.owner.id;
      }
      return false;
    },
    isPeople() {
      if (this.workspace) {
        return this.workspace.type === "people";
      } else {
        return false;
      }
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    workspaceFolder() {
      return this.workspace.folder && this.workspace.folder[0];
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
  },
  directives: {
    tooltip: {
      bind(el, binding, vnode) {
        el.tooltipHandler = (evt) => {
          let targetEl = evt.target;
          if (targetEl.parentElement.offsetWidth < targetEl.offsetWidth) {
            vnode.context.isTruncated = true;
          } else {
            vnode.context.isTruncated = false;
          }
        };
        el.addEventListener("mouseover", el.tooltipHandler);
      },
      unbind(el) {
        el.removeEventListener("mouseover", el.tooltipHandler);
      },
    },
  },
  mounted() {
    let selectedSubtasks = document.querySelectorAll(
      '[data-id="' + this.currentWorkspaceId + '"]'
    );
    let selectedSubtask = selectedSubtasks[selectedSubtasks.length - 1];
    if (selectedSubtask) {
      selectedSubtask.scrollIntoView();
    }
  },
};
</script>
