<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card
      style="flex:0 1 1240px;max-width:100%;height:100%;max-height:800px;"
    >
      <div class="text-right q-mt-sm q-mr-sm">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <div class="row create-vb-workspace">
        <!-- Left Column -->
        <div class="flex-1 text-black justify-start">
          <span class="an-bold-35 text-bold">
            Join a Public
            <br />Workspace
          </span>
          <div class="divider section-spacer"></div>
          <div class="medium-text an-20 mt-4">
            <span class="text-dark">
              To join a public workspace, type the workspace name or browse
              through the list on the right hand side.
            </span>
          </div>
        </div>
        <!-- Right Column -->
        <div class="flex-y flex-1">
          <div>
            <p class="secondary--text" v-if="errorMessage.flag">
              {{ errorMessage.text }}
            </p>
            <form v-on:submit.prevent="searchByTitle">
              <q-input
                v-model="search"
                autofocus
                outlined
                placeholder="Type the public workspace name here"
                ref="searchPublicWorkspace"
              >
                <template v-slot:prepend>
                  <q-icon color="grey-5" :name="$icons.matSearch" />
                </template>
                <!-- <template v-slot:append>
                  <q-icon
                    color="grey-5"
                    :name="$icons.matClose"
                    @click="() => clearWorkspaceSearchHandler()"
                    class="cursor-pointer"
                  />
                </template> -->
                 <div @click="()=>clearWorkspaceSearchHandler()">
                   <q-icon
                    color="grey-5"
                    :name="$icons.matClose"
                    class="cursor-pointer position"
                  />
                 </div>
              </q-input>
            </form>
          </div>
          <!-- Scrollable section -->
          <div
            class="flex-1 scroll-y q-px-xl q-pt-lg"
            v-infinite-scroll="fetchScrollHandler"
          >
            <q-list class="q-mt-lg " ref="joinpublicworkspace">
              <template v-for="workspace in publicWorkspaces">
                <q-item
                  :key="workspace.id"
                  @click.stop="join(workspace)"
                  :disabled="loader"
                  clickable
                  class="group-hover"
                >
                  <q-item-section avatar>
                    <q-avatar size="40px" color="grey-4" text-color="grey-10">
                      <span class="text-avatar-style an-14 demi-bold-text">
                        {{ workspace.title | first2Char }}
                      </span>
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label lines="1">
                      {{ workspace.title }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-icon
                      :name="$icons.matAddCircleOutline"
                      size="md"
                      color="grey-5"
                      class="group-hover-item"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
            <div class="full-width q-pr-sm text-center" v-if="isloading">
              <q-circular-progress
                indeterminate
                size="30px"
                :thickness="0.2"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import last from "lodash/last";
import errorHandler from "@/utils/ErrorHandler";
import UserScope from "@/mixins/UserScope";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import {
  PublicWorkspacesQuery,
  JoinPublicWorkspaceMutation,
  Workspaces2Query,
} from "@/gql";

export default {
  name: "JoinPublicWorkspaceDialog",
  props: ["workspace"],
  mixins: [UserScope, ProxyModelMixin],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    publicWorkspaces: {
      query: PublicWorkspacesQuery,
      cacheKey: "PublicWorkspacesQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      id: null,
      loader: false,
      search: null,
      monsterImg: {
        public: "/static/images/workspace/wp-public-bg-img.svg",
        private: "/static/images/workspace/wp-private-monster-bg.svg",
      },
      errorMessage: {
        flag: false,
        text: null,
      },
      isloading: false,
      filter: {
        state: false,
      },
    };
  },
  methods: {
    scollbar() {
      const ps = this.$refs.joinpublicworkspace;
      if (ps) ps.scrollTop = 0;
    },
    async join(workspace) {
      this.loader = true;
      let variables = {
        workspace_id: workspace.id,
      };

      try {
        const response = await this.$api.mutate({
          mutation: JoinPublicWorkspaceMutation,
          variables,
        });

        await this.$api.query({
          query: Workspaces2Query,
          cacheKey: "Workspaces2Query",
        });
        this.$eventBus.$emit("updateWorkspace", workspace.id);
        if (response) {
          const companyId = this.currentCompany
            ? this.currentCompany.companyId
            : 0;

          this.$emit("success", {
            workspaceId: workspace.id,
            user_id: this.user.id,
            companyId,
          });
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
    async fetchScrollHandler() {
      if (this.currentWorkspace <= 0) return;
      if (this.isloading === false) {
        this.isloading = true;
        const lastWorkspace = last(this.publicWorkspaces);
        const variables = {
          cursor: lastWorkspace.id,
          limit: 10,
        };

        const currentPublicWorkspaces = this.$api.getQueryByName(
          "publicWorkspaces"
        );
        const newPublicWorkspaces = await this.$api.query({
          query: PublicWorkspacesQuery,
          variables,
        });

        currentPublicWorkspaces.data.publicWorkspaces = [
          ...currentPublicWorkspaces.data.publicWorkspaces,
          ...newPublicWorkspaces.data.publicWorkspaces,
        ];

        this.isloading = false;
      }
    },
    searchByTitle() {
      if (this.search) {
        this.filter.state = true;
        this.filteredPublicWorkspaces();
        this.scollbar();
      }
    },
    async filteredPublicWorkspaces() {
      const variables = {
        offset: 0,
        title: this.search,
      };

      const currentPublicWorkspaces = this.$api.getQueryByName(
        "publicWorkspaces"
      );
      const newPublicWorkspaces = await this.$api.query({
        query: PublicWorkspacesQuery,
        variables,
      });

      currentPublicWorkspaces.data.publicWorkspaces = [
        ...newPublicWorkspaces.data.publicWorkspaces,
      ];
    },
    clearWorkspaceSearchHandler() {
      this.scollbar();
      this.search = null;
      this.filteredPublicWorkspaces();
      this.filter.state = false;
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;
      requestAnimationFrame(() => {
        this.$refs.searchPublicWorkspace.focus();
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.position{
  margin-top: 15px;
  font-size: 26px;
}
</style>
