import {
  DeleteWorkspaceMemberMutation,
  WorkspaceMembersQuery,
  WorkspaceAvailableUsersQuery,
  Workspaces2Query,
} from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  methods: {
    async removeMember() {
      this.confirmRemoveMember.loading = true;
      const workspaceMembers = this.workspaceMembers;
      this.isremoveWorkspaceMember = true;
      const variables = {
        workspace_id: this.currentWorkspace.id,
        user_id: this.confirmRemoveMember.id,
      };

      const response = await this.$api.mutate({
        mutation: DeleteWorkspaceMemberMutation,
        variables,
      });
      mixpanel.track("Workspace Memeber Add");
      this.confirmRemoveMember.flag = false;
      this.confirmRemoveMember.loading = false;
      this.$api.query({
        query: WorkspaceMembersQuery,
        cacheKey: `WorkspaceMembersQuery:${this.currentWorkspace.id}`,
        variables: {
          workspace_id: this.currentWorkspace.id,
        },
      });

      this.$api.query({
        query: WorkspaceAvailableUsersQuery,
        cacheKey: `WorkspaceAvailableUsersQuery:${this.currentWorkspace.id}`,
        variables: {
          offset: 0,
          workspace_id: this.currentWorkspace.id,
        },
      });

      await this.$api.query({
        query: Workspaces2Query,
        cacheKey: "Workspaces2Query",
      });

      if (response && response.data.deleteWorkspaceMember !== null) {
        this.isremoveWorkspaceMember = false;
        const removedUser = workspaceMembers.find(
          (a) => a.id === this.confirmRemoveMember.id
        );
        if (removedUser) {
          this.removeFromWorkspaceAnnouncement({
            workspaceId: this.currentWorkspace.id,
            removedUser,
          });
        }
      }
    },
  },
};
