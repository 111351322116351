<template>
  <q-toolbar
    flat
    dense
    class="rounded-borders "
    style="min-height:40px;height:43px;background:#FCFCFD;"
  >
    <div class="row flex-no-wrap items-center justify-between flex-1" @click.stop="$emit('closeNotificaiton')">
      <div class="row items-center flex-no-wrap">
        <q-icon
          style="margin-left: 10px"
          color="black"
          size="20px"
          class="q-mr-sm"
          :name="typeIcon"
        />
        <q-toolbar-title
          style="font-size:15px"
          shrink
          class="text-bold text-black q-px-none"
        >
          {{ currentWorkspace && currentWorkspace.title }}
        </q-toolbar-title>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button q-ml-sm"
          @click="toggleBoardBanner"
          v-if="taskView === 'board'"
        >
          <q-tooltip>Learn about board view</q-tooltip>
        </q-btn>
        <!-- <div
          v-if="!isPeople && !isSearchVisible"
          class="row items-center no-wrap q-ml-sm"
        >
          <div v-if="first5TaskMembers">
            <q-avatar
              color="grey-4"
              text-color="grey-10"
              size="27px"
              class="task-member-position cursor-pointer"
            >
              <q-tooltip>
                {{ user.first | capitalize }} {{ user.last | capitalize }}
              </q-tooltip>
              <img
                :class="[
                  boardFilterObj.filteredMember.includes(user)
                    ? 'board-member-avatar text selected'
                    : 'board-member-avatar text',
                ]"
                :src="user.pic"
                :alt="user.first"
                v-if="user.pic"
                class="board-member-avatar"
                @click="setFilterByMemberData(user)"
              />
              <div
                v-else
                :class="[
                  boardFilterObj.filteredMember.includes(user)
                    ? 'board-member-avatar text selected'
                    : 'board-member-avatar text',
                ]"
                @click="setFilterByMemberData(user)"
              >
                <span class="text-avatar-style an-10"
                  >{{ user.first | firstChar }}{{ user.last | firstChar }}</span
                >
              </div>
            </q-avatar>
            <q-avatar
              color="grey-4"
              text-color="grey-10"
              v-for="(member, index) in first5TaskMembers"
              :class="[
                index < first5TaskMembers.length - 1 ||
                workspaceMembers.length > 5
                  ? 'task-member-position'
                  : '',
                'cursor-pointer',
              ]"
              :key="`ontask-userseq-${index}`"
              size="27px"
            >
              <q-tooltip>
                {{ member.first | capitalize }} {{ member.last | capitalize }}
              </q-tooltip>
              <img
                :class="[
                  boardFilterObj.filteredMember.includes(member)
                    ? 'board-member-avatar text selected'
                    : 'board-member-avatar text',
                ]"
                :src="member.pic"
                :alt="member.first"
                v-if="member.pic"
                class="board-member-avatar"
                @click="setFilterByMemberData(member)"
              />
              <div
                v-else
                :class="[
                  boardFilterObj.filteredMember.includes(member)
                    ? 'board-member-avatar text selected'
                    : 'board-member-avatar text',
                ]"
                @click="setFilterByMemberData(member)"
              >
                <span class="text-avatar-style an-10"
                  >{{ member.first | firstChar
                  }}{{ member.last | firstChar }}</span
                >
              </div>
            </q-avatar>
            <q-avatar
              class="inborader avatar-circle-border-1px"
              size="27px"
              v-if="workspaceMembers.length > 5"
            >
              <span
                class="text-avatar-style an-10 black--text cursor-pointer"
                @click.stop="showMenu = !showMenu"
                >+{{ workspaceMembers.length - first5TaskMembers.length }}</span
              >
              <q-menu
                v-if="first5TaskMembers !== null"
                v-model="showMenu"
                content-class="filterby-member-menu"
              >
                <div class="sticky-header">
                  <div
                    class="close-btn cursor-pointer"
                    @click="showMenu = false"
                  >
                    <q-icon :name="$icons.matClose" class="close-icon" />
                  </div>
                  <div
                    class="assign-to q-py-sm"
                    style="font-size: 16px; font-family: 'Avenir Next Demi'"
                  >
                    Filter by members
                  </div>
                  <div class="search-member">
                    <q-input
                      autofocus
                      ref="search"
                      v-model="search"
                      outlined
                      placeholder="Search Member"
                      clearable
                      dense
                    >
                    </q-input>
                  </div>
                </div>
                <q-list class="q-py-sm member-list">
                  <q-item
                    clickable
                    v-for="member in workspaceMembersAvailableUsers"
                    v-bind:key="`member-list-${member.id}`"
                    class="cursor-pointer"
                    @click="setFilterByMemberData(member)"
                  >
                    <q-item-section side style="padding-right: 12px">
                      <q-avatar size="md" v-if="member.pic">
                        <img :src="member.pic" />
                      </q-avatar>
                      <q-avatar
                        size="md"
                        color="grey-4"
                        text-color="grey-10"
                        v-else
                      >
                        {{ member.first | firstChar
                        }}{{ member.last | firstChar }}
                      </q-avatar>
                    </q-item-section>
                    <q-item-section side style="padding-right: 0px">
                      <span class="filter-custom-checkbox">
                        <div
                          class="filter-selected-custom-checkbox"
                          v-if="boardFilterObj.filteredMember.includes(member)"
                        ></div>
                      </span>
                    </q-item-section>
                    <q-item-section>
                      {{ member.first | capitalize }}
                      {{ member.last | capitalize }}
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-avatar>
          </div>
          <div
            class="cursor-pointer booard-toolbar-clear-filters"
            @click="clearFilter"
            v-if="boardFilterObj.filteredMember.length"
          >
            <span>
              Clear filters
            </span>
          </div>
        </div> -->
      </div>
      <!-- Search bar -->
      <div
        v-if="isSearchVisible"
        class="row items-center board-toolbar-search q-mt-md"
      >
        <q-input
          dense
          :value="boardFilterObj.searchText"
          outlined
          autofocus
          placeholder="Search tasks"
          :clear-icon="$icons.matClose"
          class="q-mr-xs"
          @input="(val) => (searchText = val)"
          @keydown.enter.exact="boardFilterObj.searchText = searchText"
        >
          <template v-slot:prepend>
            <q-icon
              class="search-icon"
              style="margin-top:0px"
              size="xs"
              :name="$icons.matSearch"
            />
          </template>
          <template v-slot:append>
            <div
              v-if="boardFilterObj.searchText"
              @click="clearBoardSearchHandler"
              class="close-btn cursor-pointer"
            >
              <q-icon
                :name="$icons.matClose"
                class="absolute"
                style="font-size:12px"
              />
              <q-tooltip>Clear</q-tooltip>
            </div>
            <div class="cursor-pointer column items-center search-button-hover">
              <q-icon
                size="18px"
                style="margin-bottom:-9px"
                :name="$icons.mdiToggleSwitchOffOutline"
              />
              <q-icon size="18px" :name="$icons.mdiToggleSwitchOutline" />
              <!-- Search menu options -->
              <q-menu content-class="search-toggle">
                <div class="text-bold text-subtitle1 q-ml-md q-mt-xs">
                  Search in
                </div>
                <div class="column q-pr-md">
                  <q-toggle
                    size="40px"
                    v-model="boardSearchOptions.includeTaskName"
                    label="Task name"
                    color="primary"
                  />
                  <q-toggle
                    size="40px"
                    v-model="boardSearchOptions.includeCustomField"
                    label="Custom fields"
                    color="primary"
                  />
                </div>
              </q-menu>
            </div>
          </template>
        </q-input>
      </div>
      <div v-if="!isSearchVisible" class="taskView-tabs flex-no-wrap">
        <q-tabs
          @input="changeView"
          :value="taskView"
          dense
          no-caps
          align="justify"
        >
          <q-tab name="board" class="q-px-sm" label="Board"
            ><q-tooltip>View tasks as cards</q-tooltip></q-tab
          >
          <q-tab name="list" class="q-px-sm" label="List"
            ><q-tooltip>View tasks in list view</q-tooltip></q-tab
          >
          <q-tab name="calendar" class="q-px-sm" label="Calendar"
            ><q-tooltip>View tasks on a calendar</q-tooltip></q-tab
          >
          <q-tab name="timeline" class="q-px-sm" label="Timeline"
            ><q-tooltip>View tasks on a timeline</q-tooltip></q-tab
          >
        </q-tabs>
      </div>
      <div class="row items-center flex-no-wrap">
        <q-btn
          no-caps
          :icon="$icons.matVideoCall"
          class="q-mr-sm flex-no-wrap video-call-btn"
          dense
          padding="0"
          v-if="canPostMessageFromWorkspace"
          @click="showTooltip = false"
          label="Start call"
        >
          <q-tooltip v-if="showTooltip">Start a call</q-tooltip>
          <q-menu
          auto-close
          :offset="[45, 5]"
          content-class="document-help-menu"
          @before-hide="showTooltip = true"
        >
          <q-list>
            <q-item
              @click="openVideoDailog(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id, isPeople)"
              clickable
              :class="['items-center']"
              ><q-icon
                :name="$icons.matVideoCall"
                size="20px"
                class="q-mr-md"
              />
              Join call
            </q-item>
            <q-item
              @click="copyVideoCallLink(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id)"
              clickable
              :class="['items-center']"
            >
              <q-icon size="20px" class="q-mr-md" :name="$icons.matLink" />
              Copy call link
            </q-item>
          </q-list>
        </q-menu>
        </q-btn>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button"
          @click="toggleBanner"
          v-if="taskView === 'timeline'"
        >
          <q-tooltip>Learn about timeline view</q-tooltip>
        </q-btn>
        <!-- Filter -->
        <q-btn
          :color="filterMenu ? 'primary' : 'black'"
          flat
          dense
          size="sm"
          round
          :icon="$icons.matFilterList"
          class="toolbar-buttons"
        >
          <q-tooltip>
            Filter
          </q-tooltip>
          <q-menu
            v-model="filterMenu"
            content-class="overflow-auto"
            content-style="min-width:168px"
            style="max-width:200px"
            :offset="[50, 0]"
          >
            <q-list class="filter-menu">
              <div class="row items-center justify-between q-px-md q-mt-sm">
                <div class="text-bold text-subtitle1">
                  Filter
                </div>
                <div
                  @click="clearFilter"
                  v-if="shouldShow"
                  class="cursor-pointer text-red"
                >
                  Clear all
                </div>
              </div>
              <q-item
                :active="boardFilterObj.filteredMember.length > 0"
                class="q-py-sm q-px-md q-mb-xs"
                clickable
              >
                <q-item-section avatar>
                  <q-icon
                    class="menu-item-icon"
                    size="18px"
                    :name="$icons.matLocalOffer"
                  />
                </q-item-section>
                <q-item-section>
                  Members
                </q-item-section>
                <q-item-section side>
                  <q-icon :name="$icons.matKeyboardArrowRight" />
                </q-item-section>
                <q-menu
                  :offset="[-100, 0]"
                  v-if="workspaceMembersFuse"
                  v-model="showMenu"
                  content-class="filterby-member-menu"
                >
                  <div class="sticky-header">
                    <div
                      class="close-btn cursor-pointer"
                      @click="showMenu = false"
                    >
                      <q-icon :name="$icons.matClose" class="close-icon" />
                    </div>
                    <div
                      class="assign-to q-py-sm"
                      style="font-size: 16px; font-family: 'Avenir Next Demi'"
                    >
                      Filter by members
                    </div>
                    <div class="search-member">
                      <q-input
                        autofocus
                        ref="search"
                        v-model="search"
                        outlined
                        placeholder="Search Member"
                        clearable
                        dense
                      >
                        <!-- <template v-slot:prepend>
                              <div style="cursor: pointer">
                                <q-icon :name="$icons.matSearch" />
                              </div>
                            </template> -->
                      </q-input>
                    </div>
                  </div>
                  <q-list class="q-py-sm member-list">
                    <q-item
                      clickable
                      v-for="member in workspaceMembersAvailableUsers"
                      v-bind:key="`member-list-${member.id}`"
                      class="cursor-pointer"
                      @click="setFilterByMemberData(member)"
                    >
                      <q-item-section side style="padding-right: 12px">
                        <q-avatar size="md" v-if="member.pic">
                          <img :src="member.pic" />
                        </q-avatar>
                        <!-- <q-avatar
                          size="md"
                          color="grey-4"
                          text-color="grey-10"
                          v-else
                        >
                          {{ member.first | firstChar
                          }}{{ member.last | firstChar }}
                        </q-avatar> -->
                        <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                      </q-item-section>
                      <q-item-section side style="padding-right: 0px">
                        <span class="filter-custom-checkbox">
                          <div
                            class="filter-selected-custom-checkbox"
                            v-if="
                              boardFilterObj.filteredMember.includes(member)
                            "
                          ></div>
                        </span>
                      </q-item-section>
                      <q-item-section>
                        {{ member.first | capitalize }}
                        {{ member.last | capitalize }}
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>

              <q-item
                :active="boardFilterObj.taskFliter.label.length > 0"
                class="q-py-sm q-px-md q-mb-xs"
                clickable
              >
                <q-item-section avatar>
                  <q-icon
                    class="menu-item-icon"
                    size="18px"
                    :name="$icons.matLocalOffer"
                  />
                </q-item-section>
                <q-item-section>
                  Label
                </q-item-section>
                <q-item-section side>
                  <q-icon :name="$icons.matKeyboardArrowRight" />
                </q-item-section>
                <q-menu
                  :offset="[-100, 0]"
                  content-class="overflow-auto filter-label-menu"
                  max-height="235px"
                  max-width="168px"
                >
                  <q-list>
                    <template v-for="label in labels">
                      <q-item
                        clickable
                        :key="`board-filter-${label.id}`"
                        @click="setFilterLabelData(label)"
                      >
                        <q-item-section avatar>
                          <q-avatar
                            size="22px"
                            :style="{
                              'background-color': label.colorcode,
                            }"
                            :class="[
                              label !== boardFilterObj.taskFliter.label &&
                                'rounded-borders',
                              boardFilterObj.taskFliter.label.includes(label) &&
                                'selected-label-filter',
                            ]"
                            rounded
                          >
                            <q-icon
                              size="16px"
                              color="white"
                              :name="$icons.mdiCheckBold"
                              v-if="
                                boardFilterObj.taskFliter.label.includes(label)
                              "
                            />
                          </q-avatar>
                        </q-item-section>
                        <q-item-section
                          style="display: block;margin-top: 5px;"
                          class="ellipses"
                          >{{ label.name }}</q-item-section
                        >
                      </q-item>
                    </template>
                    <template v-for="label in myTaskLabel">
                      <q-item
                        clickable
                        :key="`board-filter-${label.id}`"
                        @click="setFilterLabelData(label)"
                      >
                        <q-item-section avatar>
                          <q-avatar
                            size="22px"
                            :style="{
                              'background-color': label.colorcode,
                            }"
                            :class="[
                              label !== boardFilterObj.taskFliter.label &&
                                'rounded-borders',
                              boardFilterObj.taskFliter.label.includes(label) &&
                                'selected-label-filter',
                            ]"
                            rounded
                          >
                            <q-icon
                              size="16px"
                              color="white"
                              :name="$icons.mdiCheckBold"
                              v-if="
                                boardFilterObj.taskFliter.label.includes(label)
                              "
                            />
                          </q-avatar>
                        </q-item-section>
                        <q-item-section
                          style="display: block;margin-top: 5px;"
                          class="ellipses"
                          >{{ label.name }}</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-list>
                </q-menu>
              </q-item>
              <q-item
                :active="boardFilterObj.duedate"
                @click="boardFilterObj.duedate = !boardFilterObj.duedate"
                class="q-py-sm q-px-md "
                clickable
              >
                <q-item-section avatar>
                  <q-icon
                    class="menu-item-icon"
                    size="18px"
                    :name="$icons.fasCalendarAlt"
                  />
                </q-item-section>
                <q-item-section>
                  Due date
                </q-item-section>
                <!-- <q-btn
                  @click="boardFilterObj.duedate = !boardFilterObj.duedate"
                  no-caps
                  ripple
                  align="left"
                  class="full-width"
                  color="blue-grey-1"
                  text-color="black"
                  size="md"
                >
                  <q-icon
                    v-if="boardFilterObj.duedate"
                    class="q-mr-sm"
                    color="black"
                    :name="$icons.matCheck"
                    size="sm"
                  />
                  Due Date
                </q-btn> -->
              </q-item>
              <q-separator class="q-my-xs" />
              <q-item
                :active="boardFilterObj.taskFliter.bookmarkFlag"
                @click="filterBookmakedHandler"
                class="q-py-sm q-px-md q-mb-xs"
                clickable
              >
                <q-item-section avatar>
                  <q-icon
                    class="menu-item-icon"
                    size="18px"
                    :name="$icons.matBookmark"
                  />
                </q-item-section>
                <q-item-section>
                  Bookmarked
                </q-item-section>
              </q-item>
              <q-item
                :active="myTaskFilterComputed"
                @click="boardFilterObj.myTaskFilter = !myTaskFilterComputed"
                class="q-py-sm q-px-md q-mb-xs"
                clickable
              >
                <q-item-section avatar>
                  <q-icon
                    class="menu-item-icon"
                    size="18px"
                    :name="$icons.mdiCheckBold"
                  />
                </q-item-section>
                <q-item-section>
                  My tasks
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-btn
          flat
          round
          dense
          size="md"
          :color="isSearchVisible ? 'primary' : 'black'"
          @click.stop="isSearchVisible = !isSearchVisible"
          :icon="$icons.matSearch"
          class="toolbar-buttons"
        >
          <q-tooltip> Search </q-tooltip>
        </q-btn>
        <q-btn
          flat
          round
          dense
          size="md"
          :style="{ color: drawerRight === 'info' ? '#007AFF' : 'black' }"
          @click.native.stop="toggleDrawerRight('info')"
          :icon="$icons.matInfo"
          class="toolbar-buttons"
        >
          <q-tooltip> About </q-tooltip>
        </q-btn>
        <q-btn
          flat
          fab-mini
          dense
          size="md"
          round
          color="black"
          style="margin-top: 2px"
          :icon="$icons.mdiDotsHorizontal"
          class="toolbar-buttons"
          @click="isTemplateOpen = true"
        >
          <q-tooltip>More</q-tooltip>
          <q-menu
            :content-class="[
              template.flag || confirmTemplate.flag || confirmApplyTemplate.flag
                ? 'q-py-sm board-index'
                : 'q-py-sm board-toolbar-menu',
            ]"
          >
            <q-list v-if="isTemplateOpen">
              <!-- <q-item
                clickable
                @mouseover="showActiveIcon = true"
                @mouseleave="showActiveIcon = false"
              > -->
              <!-- <q-item-section
                  side
                  top
                  style="
                    justify-content: center;
                    align-items: center;
                    padding-right: 14px;
                    padding-left: 4px;
                  "
                >
                  <img
                    v-if="showActiveIcon"
                    src="../../../public/static/images/template_white.svg"
                  />
                  <img
                    v-else
                    src="../../../public/static/images/template.svg"
                  />
                </q-item-section> -->
              <!-- <q-item-section> Board Templates </q-item-section> -->
              <!-- <q-item-section side>
                  <q-icon :name="$icons.matKeyboardArrowRight" />
                </q-item-section> -->
              <!-- <q-menu
                  :content-class="[
                    template.flag ||
                    confirmTemplate.flag ||
                    confirmApplyTemplate.flag
                      ? 'board-index board-template-menu'
                      : 'board-template-menu',
                  ]"
                  anchor="top end"
                  self="top start"
                >
                  <q-list dense>

                  </q-list>
                  <q-list   style="max-height: 200px; width: 200px">
                    <template v-if="boardTemplate && !boardTemplate.length">
                      <div

                        class="board-side-view"
                      >
                        <div>
                          <img
                            src="../../../public/static/images/bd_icon.svg"
                            alt="user.first"
                            class="board-member-avatar"
                          />
                        </div>
                        <div class="app-text">No board templates</div>
                        <div class="app-text-normal">Save board as template and it will show here.</div>
                      </div>
                    </template>
                    <template v-else v-for="(template, index) in boardTemplate">
                      <q-item
                        :key="`edit-template-${index}`"
                        clickable
                        @click.native="
                          confirmApplyTemplateHandler(
                            true,
                            template.id,
                            template.title
                          )
                        "
                        class="my-templates"
                      >
                        <q-item-section>{{ template.title }}</q-item-section>
                        <q-item-section avatar class="row flex-no-wrap trash">
                          <div
                            @click.stop="
                              confirmDeleteTemplate(
                                true,
                                template.id,
                                template.title
                              )
                            "
                          >
                            <q-icon
                              v-if="user.id === template.user_id"
                              size="13px"
                              :name="$icons.matDelete"
                            >
                            </q-icon>
                          </div>
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-list>
                </q-menu> -->
              <!-- </q-item> -->
              <!-- <q-item
                @click="openBoardTemplate"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Save Board as Template </q-item-section>
              </q-item> -->
              <!-- <q-item
                v-close-popup
                @click="dialogHandler(true, 'deleteBoardDialog')"
                clickable
                style="min-height: 24px"
                class="text-red"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Clear board </q-item-section>
              </q-item> -->
              <!-- <q-separator style="background: #e3e8ed" /> -->
              <q-item
                v-close-popup
                @click="openTimelogHendler"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top>
                  <q-icon :name="$icons.matSchedule" style="color: #000000" />
                </q-item-section>
                <q-item-section> View workspace timesheet </q-item-section>
              </q-item>
              <q-item
                clickable
                @click="checkTempAccess"
                v-if="canPostMessageFromWorkspace"
              >
                <q-item-section side top>
                  <q-icon :name="$icons.matDashboard" style="color: #000000" />
                </q-item-section>
                <q-item-section>Templates</q-item-section>
                <q-menu
                  :content-class="[
                    template.flag ||
                    confirmTemplate.flag ||
                    confirmApplyTemplate.flag
                      ? 'board-index board-template-menu'
                      : 'board-template-menu',
                  ]"
                  auto-close
                  v-model="openMenu"
                  v-if="openMenu"
                  anchor="top end"
                  self="top start"
                >
                  <q-item
                    @click="openBoardTemplate"
                    clickable
                    style="min-height: 24px"
                  >
                    <q-item-section class="app-text-normal">
                      Save Board as Template
                    </q-item-section>
                  </q-item>
                  <q-separator style="background: #e3e8ed" />
                  <q-list dense>
                    <q-item class="cursor-pointer " clickable>
                      <div class="my-temp">My templates</div>
                    </q-item>

                    <!-- <template>
                            <div
                              v-if="boardTemplate && boardTemplate.length > 0"
                              class="text-bold text-subtitle1 q-ml-md q-mt-xs"
                            >
                              My Templates
                            </div>
                          </template> -->
                  </q-list>
                  <q-list
                    style="max-height: 200px; width: 200px; overflow: auto;"
                  >
                    <template v-if="boardTemplate && !boardTemplate.length">
                      <div class="board-side-view">
                        <div>
                          <img
                            src="../../../public/static/images/bd_icon.svg"
                            alt="user.first"
                            class="board-member-avatar"
                          />
                        </div>
                        <div class="app-text">No board templates</div>
                        <div class="app-text-normal">
                          Save board as template and it will show here.
                        </div>
                      </div>
                    </template>
                    <template v-else v-for="(template, index) in boardTemplate">
                      <q-item
                        :key="`edit-template-${index}`"
                        clickable
                        @click.native="
                          confirmApplyTemplateHandler(
                            true,
                            template.id,
                            template.title
                          )
                        "
                        class="my-templates"
                      >
                        <q-item-section class="app-text-normal text-truncate">{{
                          template.title
                        }}
                        <q-tooltip>{{
                          template.title
                        }}</q-tooltip>
                        </q-item-section>
                        <q-item-section avatar class="row flex-no-wrap trash">
                          <div
                            @click.stop="
                              confirmDeleteTemplate(
                                true,
                                template.id,
                                template.title
                              )
                            "
                          >
                            <q-icon
                              v-if="user.id === template.user_id"
                              size="13px"
                              :name="$icons.matDelete"
                            >
                            </q-icon>
                          </div>
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-list>
                  <q-separator style="background: #e3e8ed" />
                  <q-item
                    v-close-popup
                    @click="dialogHandler(true, 'deleteBoardDialog')"
                    clickable
                    style="min-height: 24px"
                    class="text-red"
                  >
                    <q-item-section class="app-text-normal">
                      Clear board
                    </q-item-section>
                  </q-item>
                </q-menu>
              </q-item>
              <q-item
                @click="exportTimeLogCsv()"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Export time report </q-item-section>
              </q-item>
              <q-item
                @click="deleteTaskTimeLogs()"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Clear time report </q-item-section>
              </q-item>

              <q-separator class="q-my-sm" style="background: #e3e8ed" />
              <q-item
                style="min-height: 24px"
                @click="openNotificationSettingDialog"
                clickable
                v-close-popup
              >
                <q-item-section side top>
                  <q-icon
                    :name="$icons.matNotifications"
                    style="color: #000000"
                  />
                </q-item-section>
                <q-item-section> Change notifications </q-item-section>
              </q-item>
              <q-item
                style="min-height: 24px"
                v-if="currentWorkspace.notification_level !== 'none'"
                @click="changeWorkspaceSetting('none')"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Mute workspace </q-item-section>
              </q-item>
              <q-item
                style="min-height: 24px"
                v-else
                @click="changeWorkspaceSetting('all')"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Unmute workspace </q-item-section>
              </q-item>
              <q-separator class="q-my-sm" style="background: #e3e8ed" />
              <q-item
                @click="editView"
                v-if="
                  !isPeople && (canChangeWorkspaceSetting || isWorkspaceOwner)
                "
                clickable
                style="min-height: 24px"
                v-close-popup
              >
                <q-item-section side top>
                  <q-icon :name="$icons.matSettings" style="color: #000000" />
                </q-item-section>
                <q-item-section> Workspace settings </q-item-section>
              </q-item>
              <q-item
                @click="checkExportConvoAccess"
                style="min-height: 24px"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Export conversation </q-item-section>
              </q-item>
              <q-item
                @click="exportWorkspaceTask"
                style="min-height: 24px"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Export tasks as CSV </q-item-section>
              </q-item>
              <q-item
                @click="checkWorkspaceAccess"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Workspace e-mail </q-item-section>
              </q-item>
              <q-item
                v-close-popup
                @click="dialogHandler(true, 'deleteWorkspaceDialog')"
                v-if="!isGeneral && isWorkspaceOwner && !isPeople"
                style="min-height: 24px"
                class="text-red"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section> Delete workspace </q-item-section>
              </q-item>
              <q-separator
                v-if="!isWorkspaceOwner && !isGeneral"
                class="q-my-sm"
                style="background: #e3e8ed"
              />
              <q-item
                @click="dialogHandler(true, 'leaveWorkspaceDialog')"
                v-if="!isWorkspaceOwner && !isGeneral"
                style="min-height: 24px"
                clickable
                class="text-red"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Leave workspace
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
    <div v-if="confirmRemoveMember.flag">
      <confirm-dialog
        v-model="confirmRemoveMember.flag"
        title="Remove Member?"
        question="Are you sure you want to remove member from this workspace?"
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => confirmBoxHandler(false)"
        :successMethod="removeMember"
        :loading="false"
      />
    </div>
    <div v-if="clearNotificationConfirm">
      <confirm-dialog
        v-model="clearNotificationConfirm"
        title="Clear Notifications ?"
        question="Are you sure you want to clear notifications for this workspace board?"
        cancleText="Cancel"
        successText="Clear"
        :cancleMethod="() => clearNotificationConfirmHandler(false)"
        :successMethod="() => clearNotificationConfirmHandler(true)"
        :loading="false"
      />
    </div>
    <div v-if="clearBookmarkedConfirm">
      <confirm-dialog
        v-model="clearBookmarkedConfirm"
        title="Clear Bookmarked ?"
        question="Are you sure you want to clear bookmarked tasks for this workspace board?"
        cancleText="Cancel"
        successText="Clear"
        :cancleMethod="() => clearBookmarkedConfirmHandler(false)"
        :successMethod="() => clearBookmarkedConfirmHandler(true)"
        :loading="false"
      />
    </div>
    <div v-if="leaveWorkspaceDialog.flag">
      <confirm-dialog
        v-model="leaveWorkspaceDialog.flag"
        title="Leave Workspace ?"
        :question="
          `Are you sure you want to leave the ${currentWorkspace.title} Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'leaveWorkspaceDialog')"
        :successMethod="leaveWorkspace"
        :loading="false"
      />
    </div>
    <div v-if="deleteWorkspaceDialog.flag">
      <confirm-dialog
        v-model="deleteWorkspaceDialog.flag"
        title="Delete Workspace?"
        :question="
          `Are you sure you want to delete the <b>${currentWorkspace.title}</b> Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'deleteWorkspaceDialog')"
        :successMethod="deleteWorkspace"
        :loading="deleteWorkspaceLoader"
      />
    </div>
    <div v-if="deleteBoardDialog.flag">
      <confirm-dialog
        v-model="deleteBoardDialog.flag"
        title="Clear board"
        question="Are you sure you want to delete all of the tasks and columns from this board?"
        cancleText="Cancel"
        successText="Clear board"
        :cancleMethod="() => dialogHandler(false, 'deleteBoardDialog')"
        :successMethod="deleteBoard"
        :loading="deleteBoardLoader"
      />
    </div>
    <workspace-notification-settings
      v-if="notificationSettingDialog"
      :workspace="currentWorkspace"
      :notificationSettingDialog="notificationSettingDialog"
      :closeDialog="closeNotificationSettingDialog"
    />
    <workspace-create-dialog
      v-if="updateWorkspace.show"
      v-model="updateWorkspace.show"
      @success="updateWorkspace.show = false"
      :workspace="currentWorkspace"
    />
    <pending-invitations-dialog
      v-model="pendingInvitationDialog"
      v-if="pendingInvitationDialog"
      :workspace="currentWorkspace"
      :user="user"
    />
    <create-template-dialog
      v-if="template.flag"
      v-model="template.flag"
      :workspace="currentWorkspace"
      :closeDialog="closeTemplateDialog"
    />
    <div v-if="confirmTemplate.flag">
      <confirm-dialog
        class="q-py-md"
        v-model="confirmTemplate.flag"
        :title="`Delete ${confirmTemplate.title}?`"
        question=""
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => confirmDeleteTemplate(false)"
        :successMethod="deleteTemplate"
        :loading="confirmTemplateLoader"
      />
    </div>
    <div v-if="confirmApplyTemplate.flag">
      <confirm-dialog
        class="q-py-md"
        v-model="confirmApplyTemplate.flag"
        :title="`Replace board with ${confirmApplyTemplate.title}?`"
        :question="
          `This will delete your current board including all columns and tasks and load the <b>${confirmApplyTemplate.title}</b> board.`
        "
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => confirmApplyTemplateHandler(false)"
        :successMethod="applyTemplate"
        :loading="confirmApplyTemplateLoader"
      />
    </div>
    <workspace-email
      v-model="workspaceEmailDialog.show"
      v-if="workspaceEmailDialog.show"
      :workspaceName="
        currentWorkspace.type == 'people'
          ? currentWorkspace.peopletitle
          : currentWorkspace.title
      "
      :companyUrl="currentCompany.companyUri"
      :closeDialog="clearEmailDialog"
    />
  </q-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import UserScope from "@/mixins/UserScope";
import AddWorkspaceMemberMixin from "@/mixins/AddWorkspaceMemberMixin";
import BotMessenger from "@/mixins/BotMessenger";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import WorkspaceCreateDialog from "@/components/Dialogs/WorkspaceCreateDialog";
import PendingInvitationsDialog from "@/components/Dialogs/PendingInvitationsDialog";
import WorkspaceNotificationSettings from "@/views/WorkspaceView/NavigationSidebar/WorkspaceNotificationSettings";
import CreateTemplateDialog from "@/components/Dialogs/CreateTemplateDialog";

import WorkspaceEmail from "@/views/WorkspaceView/NavigationSidebar/WorkspaceEmail";

import {
  exportMessage,
  exportTimeLogs,
  exportBoardTask,
} from "@/services/auth";
import {
  PreferredWorkspaceMutation,
  UpdateNotificationsMutation,
  ClearBookmarkTaskMutation,
  LeaveWorkspaceMutation,
  DeleteWorkspaceMutation,
  UpdateWorkspaceMutation,
  UpdateNotificationLevelMutation,
  DeleteTaskTimeLogsMutation,
  BoardTemplateQuery,
  DeleteBoardTemplateMutation,
  ApplyBoardTemplateMutation,
  ClearBoardMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
import orderBy from "lodash/orderBy";

import Avatar from 'vue-avatar';

export default {
  name: "BoardToolbar",
  mixins: [UserScope, AddWorkspaceMemberMixin, BotMessenger],
  components: {
    ConfirmDialog,
    WorkspaceCreateDialog,
    PendingInvitationsDialog,
    WorkspaceNotificationSettings,
    CreateTemplateDialog,
    WorkspaceEmail,
    Avatar,
  },
  props: [
    "boardFilterObj",
    "toggleDrawerRight",
    "drawerRight",
    "taskView",
    "changeView",
    "openVideoDailog",
    "boardSearchOptions",
    "copyVideoCallLink",
  ],
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.currentWorkspace.boards[0].id}`;
      },
    },
    boardTemplate: {
      query: BoardTemplateQuery,
      cacheKey: "BoardTemplateQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      showTooltip: true,
      isTemplateOpen: false,
      showMenu: false,
      isSearchVisible: false,
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
      clearNotificationConfirm: false,
      clearBookmarkedConfirm: false,
      pendingInvitationDialog: false,
      boardBgList: [
        {
          gradient: "to right top, #ECEEf4, #ECEEf4",
          isDark: true,
        },
        {
          gradient: "to left top, #1C1A27, #3D1635, #801357, #B8235A, #E7627D",
          isDark: true,
        },
        { gradient: "to right top, #FF0066, #D41872, #A445B2", isDark: true },
        { gradient: "45deg , #FD5590, #F77062", isDark: true },
        { gradient: "40deg , #FC6076, #FF9944", isDark: true },
        { gradient: "to right top , #FF9A9E, #FAD0C4", isDark: true },
        { gradient: "-45deg , #FBAB7E, #F7CE68", isDark: true },
        { gradient: "-45deg , #E2D1C3, #FDFCFB", isDark: true },
        { gradient: "-40deg , #4D23AE, #6443D9, #5387FD", isDark: true },
        { gradient: "to right top, #4801FF, #7918F2, #AC32E4", isDark: true },
        {
          gradient:
            "-40deg, #505285, #585E92, #65689F, #7474B0, #7E7EBB, #8389C7, #9795D4, #A2A1DC, #B5AEE4",
          isDark: true,
        },
        { gradient: "to left top, #4481EB, #04BEFE", isDark: true },
        { gradient: "to left top, #C2E9FB, #A1C4FD", isDark: true },
        { gradient: "-35deg, #243949, #517FA4", isDark: true },
        { gradient: "-65deg, #0BA360, #3CBA92", isDark: true },
        { gradient: "to left top, #00E3AE, #9BE15D", isDark: true },
        { gradient: "-40deg, #8FD3F4, #84FAB0", isDark: true },
        { gradient: "-50deg, #ABECD6, #FBED96", isDark: true },
        { gradient: "to left top, #C1C161, #D4D4B1", isDark: true },
        { gradient: "to left top, #596164, #868F96", isDark: true },
        { gradient: "to left top, #C3CFE2, #F5F7FA", isDark: true },
        { gradient: "to right top, #D7FFFE, #FFFEFF", isDark: false },
        { gradient: "to right top, #FFF, #FFF", isWhite: true },
      ],
      deleteWorkspaceDialog: {
        flag: false,
      },
      leaveWorkspaceDialog: {
        flag: false,
      },
      updateWorkspace: {
        show: false,
        type: "private",
      },
      notificationSettingDialog: false,
      deleteWorkspaceLoader: false,
      confirmTemplateLoader: false,
      confirmApplyTemplateLoader: false,
      selectedMemebers: [],
      search: null,
      template: {
        flag: false,
      },
      confirmTemplate: {
        flag: false,
        id: null,
        title: null,
      },
      confirmApplyTemplate: {
        flag: false,
        id: null,
        title: null,
      },
      showActiveIcon: false,
      workspaceEmailDialog: {
        show: false,
      },
      deleteBoardDialog: {
        flag: false,
      },
      deleteBoardLoader: false,
      showBoardTemplate: false,
      planeDescription: null,
      openMenu: false,
      searchText: null,
      filterMenu: false,
    };
  },
  methods: {
    openTimelogHendler() {
      this.$eventBus.$emit("openTimelogs", true, this.currentWorkspace.timelogs, false, null, true);
    },
    async exportWorkspaceTask() {
      const res = await exportBoardTask(
        this.currentWorkspaceId,
        this.currentCompany.accessToken
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    checkTempAccess() {
      let eligible = this.$checkEligiblity("board_template");
      if (eligible) {
        this.openMenu = true;
      }
    },
    checkExportConvoAccess() {
      let eligible = this.$checkEligiblity("export_conversation");
      if (eligible) {
        this.exportMessageCsv();
      }
    },
    checkWorkspaceAccess() {
      let eligible = this.$checkEligiblity("email_to_message");
      if (eligible) {
        this.workspaceEmailDialog.show = true;
      }
    },
    closePlanExpiredDialog() {
      this.showBoardTemplate = false;
    },
    openBoardTemplate() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Save boards as templates to use across workspaces.";
        this.showBoardTemplate = true;

        //*************************************************************************************************
      } else {
        this.template.flag = true;
      }
      this.isTemplateOpen = false;
    },
    clearEmailDialog() {
      this.workspaceEmailDialog.show = false;
    },
    async deleteTemplate() {
      this.confirmTemplateLoader = true;
      const variables = {
        id: this.confirmTemplate.id,
      };
      await this.$api.mutate({
        mutation: DeleteBoardTemplateMutation,
        variables,
      });

      this.confirmTemplate.flag = false;

      const BoardTemplateQuery = this.$api.getQuery(`BoardTemplateQuery`);

      BoardTemplateQuery.data.boardTemplate = BoardTemplateQuery.data.boardTemplate.filter(
        (c) => c.id !== this.confirmTemplate.id
      );
      this.confirmTemplateLoader = false;
      mixpanel.track("Board Template deleted");
    },
    confirmDeleteTemplate(flag = true, id = null, title = null) {
      this.confirmTemplate = {
        flag,
        id,
        title,
      };
    },
    confirmApplyTemplateHandler(flag = true, id = null, title = null) {
      this.confirmApplyTemplate = {
        flag,
        id,
        title,
      };
    },
    async applyTemplate() {
      this.confirmApplyTemplateLoader = true;
      const variables = {
        template_id: this.confirmApplyTemplate.id,
        workspace_id: this.currentWorkspaceId,
      };

      await this.$api
        .mutate({
          mutation: ApplyBoardTemplateMutation,
          variables,
        })
        .then(async (response) => {
          if (response.data.applyBoardTemplate) {
            this.$q.notify({
              classes: "success-notification",
              position: "top-right",
              message: "Template application in progress. We'll notify you upon completion.",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ icon: this.$icons.matClose, color: "white" }],
            });
          }
        });
      this.confirmApplyTemplate.flag = false;
      this.confirmApplyTemplateLoader = false;
    },
    closeTemplateDialog() {
      this.template.flag = false;
    },
    async changeWorkspaceSetting(notificationLevel) {
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2.map((w) => {
          if (w.id === this.currentWorkspaceId) {
            w.notification_level = notificationLevel;
          }
        });
      }
      try {
        await this.$api.mutate({
          mutation: UpdateNotificationLevelMutation,
          variables: {
            workspace_id: this.currentWorkspaceId,
            notification_level: notificationLevel,
          },
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    openNotificationSettingDialog() {
      this.notificationSettingDialog = !this.notificationSettingDialog;
    },
    closeNotificationSettingDialog() {
      this.notificationSettingDialog = false;
    },
    async changeType() {
      const type =
        this.currentWorkspace.type === "public" ? "private" : "public";
      const variables = {
        id: this.currentWorkspace.id,
        type,
      };
      const workspaceEntity = this.$api.getEntity(
        "workspace",
        this.currentWorkspaceId
      );
      workspaceEntity.type = type;
      const res = await this.$api.mutate({
        mutation: UpdateWorkspaceMutation,
        variables,
      });

      if (res) {
        await this.changeWorkspaceType({
          workspaceId: this.currentWorkspaceId,
          type,
        });
      }
    },
    async exportMessageCsv() {
      if (this.currentCompany.status.subscriptionOver) {
        this.planeDescription = "Export entire conversation as CSV file";
        this.showBoardTemplate = true;
      } else {
        const res = await exportMessage(
          this.currentWorkspaceId,
          this.currentCompany.accessToken
        );
        if (res && res.status_code === 200) {
          window.location = res.data;
        }
      }
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    clearBoardSearchHandler() {
      this.boardFilterObj.searchText = null;
    },
    async preferredWorkspace() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
      };
      try {
        const workspace = this.$api.getEntity(
          "workspace",
          this.currentWorkspaceId
        );
        workspace.is_preferred = !workspace.is_preferred;
        await this.$api.mutate({
          mutation: PreferredWorkspaceMutation,
          variables,
        });
        mixpanel.track(
          `${
            workspace.is_preferred
              ? "Workspace Preferred Add"
              : "Workspace Preferred Remove"
          }`
        );
      } catch {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    filterMemberHandler(value) {
      if (value.length) {
        this.boardFilterObj.taskFliter.bookmarkFlag = false;
        this.boardFilterObj.taskFliter.filter = false;
        this.boardFilterObj.taskFliter.label = [];
        this.boardFilterObj.searchText = null;
        this.boardFilterObj.myTaskFilter = false;
      } else {
        this.boardFilterObj.filteredMember = [];
      }
    },
    setFilterByMemberData(value) {
      if (!this.boardFilterObj.filteredMember.includes(value)) {
        this.boardFilterObj.filteredMember.push(value);
      } else {
        let index = this.boardFilterObj.filteredMember.indexOf(value);
        this.boardFilterObj.filteredMember.splice(index, 1);
      }
      this.filterMemberHandler(this.boardFilterObj.filteredMember);
    },
    setFilterLabelData(label) {
      if (!this.boardFilterObj.taskFliter.label.includes(label)) {
        this.boardFilterObj.taskFliter.label.push(label);
      } else {
        let index = this.boardFilterObj.taskFliter.label.indexOf(label);
        this.boardFilterObj.taskFliter.label.splice(index, 1);
      }
      this.filteredTaskHandler(this.boardFilterObj.taskFliter.label);
    },
    filteredTaskHandler(value) {
      if (value.length) {
        this.boardFilterObj.taskFliter.filter = true;
        this.boardFilterObj.filteredMember = [];
        this.boardFilterObj.myTaskFilter = false;
        this.boardFilterObj.searchText = null;
      } else {
        this.boardFilterObj.taskFliter.filter = false;
        this.boardFilterObj.taskFliter.label = [];
      }
      this.boardFilterObj.taskFliter.bookmarkFlag = false;
    },
    filterBookmakedHandler() {
      this.boardFilterObj.taskFliter.bookmarkFlag = !this.boardFilterObj
        .taskFliter.bookmarkFlag;
      this.boardFilterObj.taskFliter.filter = this.boardFilterObj.taskFliter.bookmarkFlag;
      this.boardFilterObj.myTaskFilter = false;
      this.boardFilterObj.filteredMember = [];
      this.boardFilterObj.taskFliter.label = [];
    },
    clearFilter() {
      this.boardFilterObj.taskFliter.filter = false;
      this.boardFilterObj.taskFliter.label = [];
      this.boardFilterObj.filteredMember = [];
      this.boardFilterObj.taskFliter.bookmarkFlag = false;
      this.boardFilterObj.duedate = false;
      this.boardFilterObj.myTaskFilter = false;
    },
    async clearNotificationConfirmHandler(flag) {
      if (flag) {
        const query = this.$api.getQuery("NotificationsQuery");
        let notification_ids;
        if (query.data) {
          notification_ids = query.data.notifications
            .filter((n) => !n.read)
            .filter((n) => n.action.scope.id === this.currentWorkspaceId)
            .filter(
              (n) =>
                n.action.type === "task-comment-created" ||
                n.action.type === "task-member-attached"
            )
            .map((n) => {
              n.read = true;
              return n.id;
            });
        }
        if (notification_ids) {
          const variables = {
            notification_ids: notification_ids,
            read: true,
          };
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables,
          });
        }
      }
      this.clearNotificationConfirm = false;
    },
    clearBookmarkedConfirmHandler(flag) {
      if (flag) {
        this.clearAllBookmarked();
      }
      this.clearBookmarkedConfirm = false;
    },
    async clearAllBookmarked() {
      let task_ids = this.board.tasks
        .filter((o) => o.isbookmarked === true)
        .map((e) => e.id);
      if (task_ids > 0) {
        await this.$api.mutate({
          mutation: ClearBookmarkTaskMutation,
          variables: {
            task_ids,
          },
        });
        mixpanel.track("Task Bookmark Remove All");
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspaceId
        );
        workspaceEntity.hasbookmarked = 0;
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.tasks.forEach((t) => {
          t.isbookmarked = false;
        });
      }
    },
    dialogHandler(flag = true, dialog = "deleteWorkspaceDialog") {
      this[dialog] = {
        flag,
      };
    },
    editView() {
      this.updateWorkspace.show = !this.updateWorkspace.show;
    },
    async leaveWorkspace() {
      const workspaceId = this.currentWorkspace.id;
      const variables = {
        id: workspaceId,
      };
      await this.leavePublicWorkspaceAnnouncement({ workspaceId }); // mixin call
      await this.$api.mutate({
        mutation: LeaveWorkspaceMutation,
        variables,
      });
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== workspaceId
        );
      }
      this.dialogHandler(false, "leaveWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
    async deleteWorkspace() {
      this.deleteWorkspaceLoader = true;
      const deletedWorkspaceId = this.currentWorkspace.id;

      const variables = {
        id: deletedWorkspaceId,
      };
      const query = this.$api.getQuery("Workspaces2Query");
      await this.$api.mutate({
        mutation: DeleteWorkspaceMutation,
        variables,
      });
      this.deleteWorkspaceLoader = false;
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== deletedWorkspaceId
        );
      }

      this.dialogHandler(false, "deleteWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
    async deleteBoard() {
      this.deleteBoardLoader = true;

      const variables = {
        id: this.board.id,
      };

      await this.$api
        .mutate({
          mutation: ClearBoardMutation,
          variables,
        })
        .then(async (response) => {
          if (response.data.clearBoard) {
            window.location.reload();
          }
        });
      this.$router.push({
        name: "BoardView",
        params: {
          workspace: this.currentWorkspaceId,
          company: this.$route.params.company,
        },
      });
    },
    async exportTimeLogCsv() {
      if (this.currentWorkspace.timelogs.length) {
        const res = await exportTimeLogs(
          this.currentWorkspaceId,
          this.currentCompany.accessToken
        );
        if (res && res.status_code === 200) {
          window.location = res.data;
        }
      } else {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "There are no hours tracked.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
        user_id: 0,
        task_id: 0,
      };
      this.board.tasks = this.board.tasks.map((task) => {
        task.timelogs = [];
        return task;
      });
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });

      mixpanel.track("Task Time Logs Deleted");
    },
    toggleBanner() {
      this.$store.dispatch(
        "toggleTimelineBanner",
        !this.isTimelineBannerVisible
      );
      this.$mixpanelEvent("timeline-view-help", {});
    },
    toggleBoardBanner() {
      this.$store.dispatch("toggleBoardBanner", !this.isBoardBannerVisible);
      this.$mixpanelEvent("board-view-help", {});
    },
  },
  mounted() {
    this.boardFilterObj.filteredMember = [];
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
      isTimelineBannerVisible: "isTimelineBannerVisible",
      isBoardBannerVisible: "isBoardBannerVisible",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isGeneral() {
      if (this.currentWorkspace) {
        return (
          this.currentWorkspace.type === "public" &&
          this.currentWorkspace.is_general
        );
      } else {
        return false;
      }
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    preferredWorkspaces() {
      return this.workspaces && this.workspaces.filter((w) => w.is_preferred);
    },
    isPreferred() {
      if (this.preferredWorkspaces && this.currentWorkspace) {
        const preferredIds = this.preferredWorkspaces.map((element) => {
          return element.id;
        });
        return preferredIds.includes(this.currentWorkspace.id);
      } else {
        return false;
      }
    },
    type() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type;
      }
      return "public";
    },
    typeIcon() {
      let result;
      switch (this.type) {
        case "private":
          result = this.$icons.matLock;
          break;
        case "people":
          result = this.$icons.matPeople;
          break;
        default:
          result = this.$icons.fasBullhorn;
          break;
      }
      return result;
    },
    myTaskLabel() {
      return this.taskLabels.filter((c) => c.user_id === this.user.id);
    },
    labels() {
      return this.taskLabels.filter((c) => c.user_id === null);
      // this.taskLabels.forEach((label) => {
      //   if (label.color === "nolabel") {
      //     label.color = "#677484";
      //   }
      //   if (label.color === "complete") {
      //     label.color = "#62BDA0";
      //   }
      //   if (label.color === "inprogress") {
      //     label.color = "#FEBE36";
      //   }
      //   if (label.color === "pendingreview") {
      //     label.color = "#4DA1FF";
      //   }
      //   if (label.color === "notstarted") {
      //     label.color = "#8A75AE";
      //   }
      //   if (label.color === "overdue") {
      //     label.color = "#D75656";
      //   }
      //   if (label.color === "hightpriority") {
      //     label.color = "#FF3279";
      //   }
      //   if (label.color === "no") {
      //     label.color = "#transparent";
      //   }
      // });

      // return this.taskLabels;
    },
    first5TaskMembers() {
      return this.workspaceMembers &&
        this.workspaceMembers !== undefined &&
        this.workspaceMembers.length > 4
        ? this.workspaceMembers.slice(0, 4)
        : this.workspaceMembers;
    },
    workspaceMembersFuse() {
      if (this.workspaceMembers) {
        const orderedMembers = orderBy(
          [this.user, ...this.workspaceMembers],
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return this.workspaceMembers;
    },
    workspaceMembersAvailableUsers() {
      return this.search
        ? this.workspaceMembersFuse.filter((u) =>
            u.fullname.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.workspaceMembersFuse.hasOwnProperty("list")
        ? this.workspaceMembersFuse.list
        : this.workspaceMembersFuse;
    },
    myTaskFilterComputed() {
      return this.boardFilterObj.myTaskFilter;
    },
    switchingType() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "public" ? "Private" : "Public";
      }
      return "Public";
    },
    isPeople() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "people";
      } else {
        return false;
      }
    },
    shouldShow() {
      return (
        this.boardFilterObj.filteredMember.length > 0 ||
        this.boardFilterObj.taskFliter.label.length > 0 ||
        this.boardFilterObj.duedate ||
        this.boardFilterObj.taskFliter.bookmarkFlag ||
        this.myTaskFilterComputed
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.board-toolbar-menu {
  .q-item {
    padding: 8px 25px !important;
    .q-item__section--side {
      padding-right: 12px;
    }
  }
  .q-separator {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
}
</style>

<style>
.templates {
  color: #007aff;
  background-color: #dbecff !important;
  font-family: "Avenir Next Demi";
  font-weight: 500;
  margin-right: 12px;
  height: 25px;
}
.templates:hover {
  color: white;
  background-color: #007aff !important;
  font-family: "Avenir Next Demi";
}
.templates .q-btn__content {
  flex-wrap: nowrap;
  .q-icon{
    font-size: 16px;
    margin-right: 10px;
  }
}
.templates .q-btn__wrapper {
  padding: 0px;
  min-height: 0;
  font-size: 12px;
}
.block {
  padding: 0;
}
.my-temp {
  font-weight: 500;
  font-family: "Avenir Next Demi";
}
.on-right {
  margin-left: 6px;
}
</style>
