<template>
  <div>
    <div class="input-info-btn-popover">
      <q-input
        ref="input"
        :name="name"
        v-bind:value="value"
        @input="emitValue($event), $v.password.$touch()"
        @blur="onBlur"
        type="password"
        outlined
        class="q-mt-sm"
        :class="[defaultClass]"
        bottom-slots
        :dense="isDense"
        :error="fieldErrors('password').length > 0"
        no-error-icon
      >
        <template v-slot:error>
          <div class="text-negative">
            {{
              fieldErrors("password").length > 0
                ? fieldErrors("password")[0]
                : ""
            }}
          </div>
        </template>
      </q-input>
      <v-popover
        autoHide
        :handleResize="true"
        offset="10px"
        class="no-height"
        placement="bottom-start"
        :open="popover"
        popoverClass="vb-info-popover"
        :style="isDense && { top: '0' }"
      >
        <q-btn
          flat
          size="md"
          round
          style="color: #d2e1f1"
          :icon="$icons.matInfo"
          :class="!isDense && 'q-mt-xs'"
          @mouseenter="popover = true"
          @mouseleave="popover = false"
        />
        <template slot="popover">
          <div class="vb-popover-information">
            <q-icon
              :name="$icons.matInfo"
              color="white"
              size="md"
              class="absolute"
            />
            <div class="info-container">
              <p>
                Password must be at least 8 characters
                <br />
                <span class="demi-bold-pink">Recommended to include:</span>
              </p>
              <ul class="password-ul">
                <li class="demi-bold password-strength-li">
                  1+ uppercase letters
                  <span class="demi-bold-pink">(A, B, C)</span>
                </li>
                <li class="demi-bold password-strength-li">
                  1+ special characters
                  <span class="demi-bold-pink">(&,!,@)</span>
                </li>
                <li class="demi-bold password-strength-li">
                  1+ numeric characters
                  <span class="demi-bold-pink">(1,2,3)</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </v-popover>
    </div>

    <div
      class="Password__badge"
      v-bind:class="passwordCountClass"
      v-cloak
      v-if="badge"
    >
      {{ passwordAccesive }}/18
    </div>

    <div
      class="password-progress mb-3"
      :style="isDense && { 'margin-top': '10px' }"
    >
      <q-linear-progress
        :color="progreeClass"
        :indeterminate="query"
        size="6px"
        class="progress-bar"
        v-model="passwordStrength"
        track-color="blue-grey-3"
      />
      <div class="progress-meter-separator flex-x">
        <div v-for="i in 4" :key="i" class="flex-x flex-1">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { VPopover } from "v-tooltip";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import { validPassword } from "@/utils/validators";

export default {
  name: "PasswordStrength",
  props: {
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "At least 8 characters",
    },
    placeholder: {
      type: String,
      default: "*Please enter your Password",
    },
    value: {
      type: String,
    },
    name: {
      type: String,
      default: "password",
    },
    required: {
      type: Boolean,
      default: true,
    },
    secureLength: {
      type: Number,
      default: 8,
    },
    badge: {
      type: Boolean,
      default: true,
    },
    defaultClass: {
      type: String,
      default: "",
    },
    isDense: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin],
  components: {
    VPopover,
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      validPassword,
      maxLength: maxLength(128),
    },
  },
  validationMessages: {
    password: {
      required: "message.validation.password.required",
      minLength: "message.validation.password.min",
      maxLength: "message.validation.password.max",
      validPassword: "message.validation.password.lowscorePassword",
    },
  },
  data() {
    return {
      popover: false,
      error: false,
      query: false,
      show: true,
      maxLimit: 128,
    };
  },
  methods: {
    emitValue(value) {
      this.password = value;
      this.$emit("input", value);
    },
    onBlur() {
      this.$v.password.$touch();
      this.$emit("onBlur", this.password);
    },
  },
  computed: {
    passwordStrength() {
      if (this.password) {
        let score = 0;
        let fscore = 0;
        const rgularExp = {
          containsNumber: /\d+/,
          containsAlphabetCapital: /[A-Z]/,
          containsAlphabetSmall: /[a-z]/,
          containsSpecialChars: /['^£$%&*()}{!@#~?><>,|=_+¬-]/,
        };
        if (rgularExp.containsNumber.test(this.password)) {
          score++;
        }
        if (rgularExp.containsAlphabetCapital.test(this.password)) {
          score++;
        }
        if (rgularExp.containsSpecialChars.test(this.password)) {
          score++;
        }
        if (this.password.length >= this.secureLength) {
          score++;
        }

        fscore = score * 25;
        this.$emit("next", { score: fscore });
        return fscore / 100;
      }
      return 0;
    },
    passwordAccesive() {
      if (!this.password) return 0;
      return (
        this.password &&
        (this.password.length > this.maxLimit
          ? this.maxLimit
          : this.password.length)
      );
    },
    passwordCountClass() {
      return {
        "text-green": this.password && this.password.length <= this.maxLimit,
        "text-red": this.password && this.password.length > this.maxLimit,
      };
    },
    progreeClass() {
      let result;
      switch (this.passwordStrength) {
        case 0.25:
          result = "red-8";
          break;
        case 0.5:
          result = "warning";
          break;
        case 0.75:
          result = "warning";
          break;
        case 1:
          result = "teal-14";
          break;
        default:
          result = "";
      }
      return result;
    },
    password: {
      get() {
        return this.value;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {
        return this.val;
      },
    },
  },
};
</script>

<style lang="stylus">
[v-cloak] {
  display: none;
}

.Password {
  max-width: 400px;
  margin: 0 auto;
}

.Password__badge {
  float: right;
  position: relative;
  bottom: 20px;
  right: 10px;
  color: #000;
  padding: 3px;
  height: 15px;
  font-size: 14px;
  line-height: 1.1;
}

.has-error {
  background: red;
}

.is-success {
  background: #1bbf1b;
}

.password-progress {
  position: relative;
  width: 100%;
  margin-bottom: 0px;
}

.progress-bar {
  width: 100%;
  position: absolute !important;
  background: #E0E7EF;
  border-radius: 3px;
  z-index: 1;
  margin: 0;
}

.progress-meter-separator {
  position: absolute;
  height: 7px;
  min-height: 7px !important;
  max-height: 7px;
  z-index: 10;
  background: transparent;
  width: 100%;
}

.progress-meter-separator .flex-x {
  border-right: 5px solid #FFF;
  /* border-left: 5px solid #FFF; */
}

.progress-meter-separator .flex-x:last-child {
  border: none;
}
.flex-1{
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    min-height: 0;
  }
.password-ul {
  padding-left: 0;
}

li.password-strength-li {
  list-style: none;
}
</style>
