<template>
  <div>
    <div class="text-center my-4" v-if="mediaLoading && media.length === 0">
      <q-circular-progress
        indeterminate
        size="40px"
        :thickness="0.2"
        color="primary"
        class="q-mb-md"
      />
    </div>
    <div v-else>
      <div
        class="row justify-between items-center q-px-md my-mdd q-mb-sm"
        style="flex: no"
      >
        <div>
          <q-btn
            @click="fromComputer"
            :disabled="!canUploadMedia"
            size="14px"
            flat
            no-caps
            :icon="$icons.matAdd"
            label="Add Files"
            class="sidebarFileAddbtn"
          />
          <q-btn
            @click="checkMediaFolderAccess"
            :disabled="!canUploadMedia"
            size="14px"
            flat
            no-caps
            :icon="$icons.mdiFolderPlusOutline"
            label="New Folder"
            class="sidebarFileAddbtn"
          />
        </div>
      </div>
      <q-linear-progress
        v-if="progress"
        color="green"
        :value="progress"
        size="4px"
        rounded
        style="margin-top: 10px"
      />
      <div
        class="walkthrough walkthrough-small"
        v-if="user.onboarding.is_files"
      >
        <div class="icon">
          <q-icon
            :name="$icons.mdiFileMultiple"
            size="24px"
            style="color: #007aff"
          />
        </div>
        <div class="content">
          <p class="bold">View all files shared in this workspace and more</p>
          <p>
            Add multiple files to this workspace or access files allready
            shared.
          </p>
          <div class="video" @click="videoPlayer({}, true)">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="55px"
            />
          </div>
        </div>
        <div class="close" @click="closeFileskWalkthroughBanner">
          <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
        </div>
      </div>
      <div>
        <div
          v-if="imageList.length === 0 && folderList.length === 0"
          class="row justify-center my-mdd"
        >
          <div class="text-center q-pa-sm" style="background: #f7f9fc">
            <div class="q-pt-md">
              <div>
                <q-avatar
                  :style="{
                    background: 'rgba(0, 196, 134, 0.2)',
                  }"
                >
                  <q-icon
                    color="teal-14"
                    size="24px"
                    :name="$icons.matDescription"
                  />
                </q-avatar>
                <div
                  class="title nolabel--text q-mt-sm q-mb-xs an-15 medium-text"
                >
                  Files
                </div>
                <span class="nolabel--text an-15 regular-text">
                  There are no files shared
                  <br />in this workspace.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-auto" v-infinite-scroll="fetchScrollHandler">
          <div :style="{ 'max-height': 'calc(100vh - 206px)' }">
            <template v-for="folder in folderList">
              <div :key="folder.id" class="group-background-hover">
                <q-menu context-menu auto-close content-style="min-width:150px">
                  <q-list>
                    <q-item
                      class="q-mb-sm"
                      @click="checkShareFolderAccess(folder)"
                      clickable
                    >
                      Attach to message
                    </q-item>
                    <q-separator />
                    <q-item
                      v-close-popup
                      @click="uploadFileToFolder(folder.id)"
                      clickable
                    >
                      <q-item-section> Upload files </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      @click="openRenameFolderDialog(folder)"
                      class="q-mb-sm"
                      v-close-popup
                    >
                      <q-item-section> Rename </q-item-section>
                    </q-item>
                    <q-separator />
                    <!-- <q-item clickable v-close-popup>
                      <q-item-section>
                        Download
                      </q-item-section>
                    </q-item> -->
                    <q-item
                      @click="copyMoveFileFolderHandler([folder], false, true)"
                      avatar
                      clickable
                      v-close-popup
                    >
                      <q-item-section> Copy </q-item-section>
                    </q-item>
                    <q-item
                      @click="copyMoveFileFolderHandler([folder], true, true)"
                      clickable
                      v-close-popup
                    >
                      <q-item-section> Move </q-item-section>
                    </q-item>
                    <q-item
                      @click="deleteFolderHandler(folder.id)"
                      clickable
                      class="text-red"
                      v-close-popup
                    >
                      <q-item-section> Delete </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
                <q-item
                  avatar
                  class="group-background-hover-item group-hover sidebar-folder-block q-mb-sm q-py-xs q-px-md q-pa-none border-radius6"
                >
                  <div
                    class="row full-width cursor-pointer"
                    @click="openFolder(folder)"
                  >
                    <q-item-section class="q-pr-none" avatar>
                      <q-avatar size="35px" rounded>
                        <div class="relative-position">
                          <q-icon
                            :name="$icons.matFolder"
                            class="post-folder-icon"
                          />
                          <div class="post-folder-count">
                            {{
                              folder.media_count > 9
                                ? "9+"
                                : folder.media_count
                                ? folder.media_count
                                : 0
                            }}
                          </div>
                        </div>
                      </q-avatar>
                    </q-item-section>
                    <q-item-section class="q-ml-md">
                      <q-item-label class="text-black file-name" v-tooltip>
                        {{ folder.title }}
                        <q-tooltip v-if="isTruncated">
                          {{ folder.title }}
                        </q-tooltip>
                      </q-item-label>
                    </q-item-section>
                  </div>
                  <div
                    class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item row item-center"
                    style="z-index: 100"
                  >
                    <div
                      v-if="folder.isbookmarked"
                      @click.stop="removeMediaFolderBookmark(folder)"
                    >
                      <q-icon
                        size="20px"
                        class="cursor-pointer"
                        color="pink"
                        :name="$icons.matBookmark"
                      >
                        <q-tooltip> Remove from Saved items </q-tooltip>
                      </q-icon>
                    </div>
                    <div
                      v-if="!folder.isbookmarked"
                      @click.stop="addMediaFolderBookmark(folder)"
                    >
                      <q-icon
                        size="20px"
                        class="cursor-pointer"
                        color="black"
                        :name="$icons.matBookmarkBorder"
                      >
                        <q-tooltip> Add to Saved items </q-tooltip>
                      </q-icon>
                    </div>
                  </div>
                </q-item>
              </div>
            </template>
            <template v-for="attachment in imageList">
              <div class="group-background-hover" :key="'list' + attachment.id">
                <list-view
                  :media="attachment"
                  :fullview="fullview"
                  :currentUser="user"
                  :pdfView="pdfView"
                  :videoPlayer="videoPlayer"
                  :canRemoveMedia="canRemoveMedia"
                  :activeCompany="activeCompany"
                  :currentCompanyId="currentCompany.companyId"
                  :currentWorkspaceId="currentWorkspace"
                  :deleteFile="deleteFile"
                  :audioPlayer="audioPlayer"
                  :addMediaBookmark="addMediaBookmark"
                  :removeMediaBookmark="removeMediaBookmark"
                  :fromComputer="fromComputer"
                  :copyMoveFileHandler="copyMoveFileFolderHandler"
                  :clickonmoreoption="clickonmoreoption"
                  :editDoc="editDoc"
                  @renameFile="openRenameFileDialog"
                ></list-view>
              </div>
            </template>
          </div>
        </div>
        <div class="full-width q-pr-sm text-center" v-if="isloading">
          <q-circular-progress
            indeterminate
            size="30px"
            :thickness="0.2"
            color="primary"
          />
        </div>
      </div>
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="mediaSidebarFileUploadCloseHandler"
      @setProgress="setProgress"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="imageList"
      :ishide="ishide"
      :labels="taskLabels"
      :moreOption="moreOption"
      :generatedPointPixels="returnpoints()"
      :commentListing="returnCommentListing"
      :currentUser="user"
      :dialogMedia="dialogMedia"
      :isWorkspaceOwner="isWorkspaceOwner"
      :resetDialog="() => resetDialog()"
      @sendComment="sendComment"
      @updateComment="updateComment"
      @updatepositions="updatepositions"
      @clickonhidebutton="ishide = !ishide"
      @clickonmoreoption="clickonmoreoption"
      @deleteMediaComment="deleteMediaComment"
      @deleteCommentPoint="deleteCommentPoint"
      @closeMediaModel="closeMediaModel"
      @setActiveMedia="setActiveMedia"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
    ></image-viewer-dialog>
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="taskLabels"
      :folderList="folderList"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="moveCopyFilFolderHandler"
      :currentWorkspaceId="currentWorkspace"
    />
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="imageList"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    />
    <pdf-view-dialog
      :fileExt="pdfFileExt"
      v-if="showpdfModel"
      v-model="showpdfModel"
      @resetPdfDialog="resetPdfDialog"
      :pdfMediaData="pdfMediaData"
    />
    <confirm-dialog
      v-if="confirm"
      v-model="confirm"
      title="Delete File?"
      question="Are you sure you want to delete this file?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="removeMediaHandler"
      :loading="false"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
    <create-media-folder-dialog
      v-if="createMediaFolderDialog.flag"
      v-model="createMediaFolderDialog.flag"
      :renameFolder="renameFolderDetails"
      :loading="createMediaFolderDialog.loading"
      :createFolderHandler="
        renameFolderDetails ? renameFolderHandler : createFolderHandler
      "
      :cancelMethod="cancel"
    />

    <vue-dropzone-file-upload
      hidden
      ref="folderFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
    ></vue-dropzone-file-upload>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import last from "lodash/last";
import map from "lodash/map";

import UserScope from "@/mixins/UserScope";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import FolderMethodMixin from "@/mixins/FolderMethodMixin";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import ListView from "@/views/WorkspaceView/InfoSidebar/Files/ListView";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";

import {
  NewMediaQuery,
  GetNewMediaListMutation,
  UpdateUserOnboardingMutation,
  CreateMediaFolderMutation,
} from "@/gql";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";

export default {
  name: "Files",
  mixins: [
    UserScope,
    MediaMethodMixin,
    FileExtensionMixin,
    FolderMethodMixin,
    DocumentEditorMixin,
  ],
  api: {
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspace}`;
      },
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspace}`;
      },
    },
  },
  components: {
    ListView,
    VideoDialog,
    PdfViewDialog,
    ImageViewerDialog,
    ConfirmDialog,
    AudioDialog,
    VueDropzoneFileUpload,
    PostCopyMoveFileFolder,
    CreateMediaFolderDialog,
    FolderDetailsDialog,
    UpdateFileDialog,
    DocumentEditorDialog,
  },
  data() {
    return {
      dialogMedia: null,
      showModal: false,
      pdfMediaData: null,
      showpdfModel: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      audioMediaData: null,
      displayAudioPlayer: false,
      showAudioModel: false,
      ishide: false,
      isloading: false,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      confirm: false,
      deleteFileId: null,
      gridMediaId: "",
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
      progress: 0,
      mediaId: null,
      copyMoveDialog: {
        data: [],
        isFolder: false,
        isMoveAction: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      createMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      isTruncated: false,
      renameFolderDetails: null,
      deleteFolder: {
        id: null,
        flag: false,
        loader: false,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
    };
  },
  methods: {
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    checkMediaFolderAccess() {
      let eligible = this.$checkEligiblity("file_folders");
      if (eligible) {
        this.createMediaFolderDialog.flag = true;
      }
    },
    checkShareFolderAccess(folder) {
      let eligible = this.$checkEligiblity("share_folders");
      if (eligible) {
        this.attachFolderToPost([folder]);
      }
    },
    async createFolderHandler(title) {
      this.createMediaFolderDialog.loading = true;
      try {
        const variables = {
          title,
          workspace_id: this.currentWorkspace,
        };
        const response = await this.$api.mutate({
          mutation: CreateMediaFolderMutation,
          variables,
        });
        if (response) {
          const mediaFoldersQuery = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          const mediaFolder = {
            ...response.data.createMediaFolder,
            media_count: 0,
          };
          if (mediaFoldersQuery.data && mediaFoldersQuery.data.mediaFolders) {
            mediaFoldersQuery.data.mediaFolders.push(mediaFolder);
          } else {
            mediaFoldersQuery.data.mediaFolders = [mediaFolder];
          }
        }
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
      }
    },
    cancel() {
      this.createMediaFolderDialog.flag = false;
      this.createMediaFolderDialog.loading = false;
      this.renameFolderDetails = null;
    },
    deleteFile(mediaId) {
      this.confirm = true;
      this.deleteFileId = mediaId;
    },
    removeMediaHandler() {
      this.isFullView = false;
      this.removeMedia(this.deleteFileId);
      this.confirm = false;
    },
    routeToMedia() {
      if (this.$route.name !== "MediaView") {
        this.$router.push({
          params: {
            company: this.currentCompany.companyUri,
            workspace: this.currentWorkspace,
          },
          name: "MediaView",
        });
      }
    },
    fullview(media) {
      this.dialogMedia = media;
      this.showModal = true;
      this.gridMediaId = media.id;
    },
    videoPlayer(media, isVimeo) {
      if (isVimeo) {
        media = {
          isVimeo: true,
          file: "https://player.vimeo.com/video/569666322",
        };
      }
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    pdfView(media) {
      this.pdfMediaData = media;
      this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    resetPdfDialog() {
      this.showpdfModel = false;
      this.pdfMediaData = null;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    fetchScrollHandler() {
      if (this.isloading === false) {
        this.fetchMoreMedia();
      }
    },
    async fetchMoreMedia() {
      this.isloading = true;
      const lastMedia = last(this.media);
      const variables = {
        workspace_id: this.currentWorkspace,
        cursor: lastMedia.id,
        cursor_type: "prev",
        limit: 10,
      };

      const currentMedia = this.$api.getQueryByName("media");
      const newMedia = await this.$api.query({
        query: NewMediaQuery,
        variables,
      });

      if (newMedia.data.newMedia.length === 0) {
        this.removeloadButton = true;
      } else {
        currentMedia.data.newMedia = [
          ...currentMedia.data.newMedia,
          ...newMedia.data.newMedia,
        ];
      }

      this.isloading = false;
    },
    async mediaSidebarFileUploadCloseHandler(fileList) {
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;
      const mediaIds = fileList ? map(fileList, (o) => o.id) : [];
      if (mediaIds && mediaIds.length) {
        const response = await this.$api.mutate({
          mutation: GetNewMediaListMutation,
          variables: {
            ids: mediaIds,
          },
        });

        const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
        query.data.newMedia.push(...response.data.getNewMediaList);
      }
      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
      }
      this.mediaId = null;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer(media_id) {
      if (typeof media_id === "number") {
        this.mediaId = media_id;
      }
      this.$refs.vueFileUploader.openFileDialog();
    },
    async closeFileskWalkthroughBanner() {
      this.user.onboarding.is_files = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_files",
          flag_value: false,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    userWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspace))
      );
    },
    imageList: {
      get() {
        if (this.media)
          return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
        return [];
      },
    },
    pdfFileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    canRemoveMedia() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    canUploadMedia() {
      return this.canPostMessageFromWorkspace || this.isWorkspaceOwner;
    },
    isWorkspaceOwner() {
      if (this.userWorkspace && this.user) {
        return this.user.id === this.userWorkspace.owner.id;
      }
      return false;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(orderBy(this.mediaFolders, ["title"], ["asc"]), "id");
      } else {
        return [];
      }
    },
  },
};
</script>
