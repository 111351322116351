import AssignTaskNotification from "./AssignTaskNotification";
import AssignSubtaskNotification from "./AssignSubtaskNotification";
import MentionedInTaskCommentNotification from "./MentionedInTaskCommentNotification";
import NewPostNotification from "./NewPostNotification";
import NewPostReactionNotification from "./NewPostReactionNotification";
import NewTaskCommentReactionNotification from "./NewTaskCommentReactionNotification";

export default {
  NewPostNotification,
  NewPostReactionNotification,
  AssignTaskNotification,
  AssignSubtaskNotification,
  MentionedInTaskCommentNotification,
  NewTaskCommentReactionNotification,
};
