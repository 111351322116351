<template>
  <div>
    <administration
      v-if="view === 'Administration'"
      :paycard="payCard"
      :invoices="companyInvoices"
      :payCardLoading="payCardLoading"
      @changecard="view = 'AddNewPayCard'"
      @registercard="view = 'RegisterPayCard'"
      @changeaddress="view = 'EditBillingAddress'"
      @closeAccount="closeAccount = true"
      :currentWorkspace="currentWorkspace"
      :company="company"
    />
    <add-new-pay-card
      v-if="view === 'AddNewPayCard'"
      :paycard="payCard"
      @back="view = 'Administration'"
      @replaceCard="replaceCardHandler"
      :loader="loader"
      :invalidCodeMessage="invalidCodeMessage"
    />
    <edit-billing-address
      v-if="view === 'EditBillingAddress'"
      :paycard="payCard"
      @back="view = 'Administration'"
      :loader="loader"
      @updateAddress="updateCustomerAddress"
      :invalidCodeMessage="invalidCodeMessage"
    />
    <register-pay-card
      v-if="view === 'RegisterPayCard'"
      :paycard="payCard"
      @back="view = 'Administration'"
      :loader="loader"
      @registerCardEvent="registerCardEventHandler"
      :invalidCodeMessage="invalidCodeMessage"
    />
    <close-account
      v-model="closeAccount"
      :invoices="companyInvoices"
      :isClosing="isClosing"
      :cancelAccountHandler="cancelAccountHandler"
      @closeDialog="closeAccount = false"
    />
  </div>
</template>

<script>
import errorHandler from "@/utils/ErrorHandler";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import Administration from "@/components/AdministrationWizard/Administration";
import RegisterPayCard from "@/components/AdministrationWizard/RegisterPayCard";
import CloseAccount from "@/components/AdministrationWizard/CloseAccount";
import AddNewPayCard from "@/components/AdministrationWizard/AddNewPayCard";
import EditBillingAddress from "@/components/AdministrationWizard/EditBillingAddress";

import {
  PayCardQuery,
  InvoicesQuery,
  CreateNewPayCardMutation,
  UpdateCustomerAddressMutation,
  AddNewPayCardMutation,
  DeleteAccountMutation,
} from "@/gql";

export default {
  name: "AdministrationDialog",
  mixins: [ProxyModelMixin],
  props: ["currentWorkspace", "company"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    payCard: {
      query: PayCardQuery,
      cacheKey: "PayCardQuery",
      defaultValue: null,
    },
    invoices: {
      query: InvoicesQuery,
      cacheKey: "InvoicesQuery",
      defaultValue: null,
    },
  },
  components: {
    Administration,
    RegisterPayCard,
    AddNewPayCard,
    EditBillingAddress,
    CloseAccount,
  },
  data() {
    return {
      view: "Administration",
      loader: false,
      invalidCodeMessage: null,
      closeAccount: false,
      isClosingLoader: false,
    };
  },
  methods: {
    closeAndReset() {
      this.view = "Administration";
      this.proxyModel = false;
    },
    async replaceCardHandler(token) {
      if (token) {
        this.loader = true;
        this.invalidCodeMessage = null;
        try {
          const response = await this.$api.mutate({
            mutation: CreateNewPayCardMutation,
            variables: {
              token,
            },
          });
          if (
            response.data.createNewPayCard &&
            response.data.createNewPayCard.error === null
          ) {
            this.loader = false;
            this.view = "Administration";
          } else {
            this.invalidCodeMessage = response.data.createNewPayCard.error;
            this.loader = false;
          }
        } catch (error) {
          this.invalidCodeMessage = errorHandler.getErrorMessage(
            error,
            this,
            "message.CardDeclined"
          );
          this.loader = false;
        }
      }
    },
    async updateCustomerAddress(address) {
      if (address) {
        this.loader = true;
        this.invalidCodeMessage = null;
        try {
          const response = await this.$api.mutate({
            mutation: UpdateCustomerAddressMutation,
            variables: {
              address,
            },
          });
          if (response.data) {
            this.loader = false;
            this.view = "Administration";
          } else {
            this.invalidCodeMessage = this.$t("message.CardDeclined");
          }
        } catch (error) {
          this.invalidCodeMessage = errorHandler.getErrorMessage(
            error,
            this,
            "message.CardDeclined"
          );
          this.loader = false;
        }
      }
    },
    async registerCardEventHandler(args) {
      this.loader = true;
      const variables = {
        ...args,
        first: this.user.first,
        email: this.user.email,
      };
      try {
        const response = await this.$api.mutate({
          mutation: AddNewPayCardMutation,
          variables,
        });

        this.$api.query({
          query: PayCardQuery,
          cacheKey: "PayCardQuery",
        });

        if (
          response.data.addNewPayCard &&
          response.data.addNewPayCard.error === null
        ) {
          this.loader = false;
          this.view = "Administration";
          this.successHandler(this.$t("message.newPaycardAdded"));
        } else {
          this.invalidCodeMessage = response.data.addNewPayCard.error;
          this.loader = false;
        }
      } catch (error) {
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.validation.CardDeclined"
        );
        this.loader = false;
      }
    },
    async cancelAccountHandler(variables) {
      this.isClosingLoader = true;
      const response = await this.$api.mutate({
        mutation: DeleteAccountMutation,
        variables,
      });
      if (response.data.deleteAccount) {
        this.isClosingLoader = false;
        this.$store.dispatch("signoutFromCompany", this.company.companyUri);
        this.$router.push("/");
      }
    },
    successHandler(message) {
      this.$q.notify({
        classes: 'success-notification',
        position: "top-right",
        message,
        icon: this.$icons.matAnnouncement,
      });
    },
  },
  computed: {
    isClosing() {
      return this.isClosingLoader;
    },
    companyInvoices() {
      return this.invoices;
    },
  },
};
</script>