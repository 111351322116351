<template>
  <q-card
    class="bg-white"
    style="flex:0 1 1240px;max-width:100%;height:100%; font-family: 'Avenir Next';"
  >
    <div class="header" style="padding: 30px 40px 0px 20px">
      <div class="tabs">
        <div
          :class="tab === 'tab-Members' && 'selected-tab'"
          @click="changeTab('tab-Members')"
        >
          Manage Members {{members.length}}
        </div>
        <div
          :class="tab === 'tab-Invitations' && 'selected-tab'"
          @click="changeTab('tab-Invitations')"
        >
          Manage Invitations
        </div>
      </div>
      <div class="options-and-search">
        <div>
          <q-btn
            label="Options"
            :icon-right="$icons.matArrowDropDown"
            size="14px"
            style="right:17px; background: #007aff; color: white"
            v-if="manageUsersOptions"
          >
            <q-menu content-class="overflow-auto" auto-close>
              <q-list>
                <template v-if="canDeactivateUserAccount">
                  <q-item
                    v-for="item in dropdown"
                    :key="item.title"
                    @click="item.action"
                    clickable
                  >
                    <q-item-section>
                      {{ item.title }}
                    </q-item-section>
                  </q-item>
                </template>
                <q-item
                  v-for="role in roleList"
                  :key="role.id"
                  @click="$eventBus.$emit('changeRoleBulk', role)"
                  clickable
                >
                  <q-item-section>
                    <q-item-label lines="1">
                      Change Role to
                      <strong>{{ role.name | capitalize }}</strong>
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  @click="$eventBus.$emit('deleteUsersDialogHandler')"
                  v-if="canDeleteUserAccount"
                  clickable
                >
                  <q-item-section class="text-red-9">
                    Delete Selected Users
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-btn
            label="Options"
            :icon-right="$icons.matArrowDropDown"
            size="14px"
            style="right:35px; background: #007aff; color: white"
            v-if="manageInvitationsOptions"
          >
            <q-menu content-class="overflow-auto" auto-close>
              <q-list>
                <q-item
                  v-for="item in dropdownManageInvitations"
                  :key="item.title"
                  @click="item.action"
                  clickable
                >
                  <q-item-section>{{ item.title }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <div class="search-member">
          <q-input
            autofocus
            ref="search"
            v-model="search"
            outlined
            placeholder="Search Member"
            clearable
            dense
            v-if="tab === 'tab-Members'"
          >
            <template v-slot:prepend>
              <q-icon :name="$icons.matSearch" />
            </template>
          </q-input>
        </div>
      </div>
    </div>
    <div>
      <div class="col-grow col-6 q-pb-sm" style="max-height:580px">
        <!-- Scrollable Section for tab components -->
        <div class="col-grow scroll-y scroll-y fill-height">
          <q-tab-panels class="fill-height" v-model="tab" animated>
            <q-tab-panel
              v-for="(item, i) in tabItems"
              :key="i"
              :name="'tab-' + item.name"
              style="padding: 0"
            >
              <div class=" fill-height">
                <div class="v-card__text fill-height" style="padding: 0">
                  <component
                    :is="item.component"
                    :currentUser="currentUser"
                    :search="search"
                    @handlePermssion="handlePermssionComponent"
                    :currentWorkspaceObj="currentWorkspaceObj"
                    @userMemberSays="userMemberSaysHandler"
                    @userInvitationSays="userInvitationSaysHandler"
                  ></component>
                </div>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
  </q-card>
</template>
<script>
import ManageUsers from "@/components/SettingSection/ManageUsers";
import ManageInvitations from "@/components/SettingSection/ManageInvitations";
import UserScope from "@/mixins/UserScope";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";

export default {
  name: "ManageMembersDialog",
  props: ["currentWorkspaceObj", "currentUser"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    roles: {
      cacheKey: "RolesQuery",
      defaultValue: null,
    },
    members: {
      cacheKey: "MembersQuery",
      defaultValue: null,
    },
  },
  mixins: [UserScope, ProxyModelMixin],
  components: {
    ManageUsers,
    ManageInvitations,
  },
  data() {
    return {
      tabItems: [
        {
          name: "Members",
          component: ManageUsers,
        },
        {
          name: "Invitations",
          component: ManageInvitations,
        },
      ],
      toggleComponent: true,
      dropdown: [
        {
          title: "Activate Selected Users",
          color: "",
          action: this.changeStatusActiveBulk,
        },
        {
          title: "Deactivate Selected Users",
          color: "",
          action: this.changeStatusDeactiveBulk,
        },
      ],
      dropdownManageInvitations: [
        {
          title: "Resend Invitation to Selected Users",
          action: this.resendInviteBulk,
        },
      ],
      cardActionOptions: {
        manageUser: false,
        manageInvitations: false,
      },
      isComponent: "Members",
      tab: "tab-Members",
      search: "",
    };
  },
  methods: {
    handlePermssionComponent() {
      this.toggleComponent = false;
    },
    userMemberSaysHandler(args) {
      this.cardActionOptions.manageUser = args;
    },
    userInvitationSaysHandler(args) {
      this.cardActionOptions.manageInvitations = args;
    },
    componentClickHandler(args) {
      this.isComponent = args.name;
    },
    changeStatusActiveBulk() {
      this.$eventBus.$emit("changeStatusActiveBulk");
    },
    changeStatusDeactiveBulk() {
      this.$eventBus.$emit("changeStatusDeactiveBulk");
    },
    resendInviteBulk() {
      this.$eventBus.$emit("resendInviteBulk");
    },
    changeTab(tab) {
      this.tab = tab;
      switch (tab) {
        case "tab-Members":
          this.isComponent = "Members";
          break;
        case "tab-Invitations":
          this.isComponent = "Invitations";
      }
    },
  },
  computed: {
    userRole() {
      if (this.user && this.user.roles && this.user.roles.length > 0) {
        return this.user.roles[0];
      }
      return null;
    },
    roleList() {
      if (this.roles) {
        return this.roles.filter((role) => {
          if (this.userRole) return this.userRole.id <= role.id;
          return false;
        });
      }
      return null;
    },
    manageUsersOptions() {
      const conditions =
        this.canDeactivateUserAccount ||
        this.canDeleteUserAccount ||
        this.canChangeUserRole;
      return (
        conditions &&
        this.cardActionOptions.manageUser &&
        this.isComponent === "Members"
      );
    },
    manageInvitationsOptions() {
      return (
        this.cardActionOptions.manageInvitations &&
        this.isComponent === "Invitations"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
tr:hover {
  background: transparent !important;
}
.monster {
  bottom: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  .tabs {
    display: flex;
    div {
      color: #b8c1cb;
      font-family: "Avenir Next";
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
    .selected-tab {
      color: #007aff !important;
    }
    :first-child {
      margin-right: 20px;
    }
  }
}
.options-and-search {
  display: flex;
  align-items: center;
  .q-btn {
    height: 35px;
  }
}
</style>
