<template>
  <q-item
    avatar
    class="group-background-hover-item group-hover sidebar-media-block q-mb-sm q-py-xs q-px-md q-pa-none border-radius6"
  >
    <div class="row full-width cursor-pointer" @click="selectViewMode(media)">
      <q-menu context-menu content-class="overflow-auto">
        <q-list>
          <q-item clickable v-if="canRemove">
            <q-item-section>
              Share
            </q-item-section>
            <q-item-section side>
              <q-icon :name="$icons.matKeyboardArrowRight" />
            </q-item-section>
            <q-menu auto-close anchor="top end" self="top start">
              <q-list style="min-width:150px">
                <q-item @click="attachToMessage(media)" clickable>
                  Attach to message
                </q-item>
                <q-item
                  v-if="!media.is_document_section"
                  @click.stop="$copyTextToClipboard(media.file)"
                  clickable
                >
                  <q-item-section>
                    Copy Link
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-item>
          <q-item
            v-if="canRemove"
            clickable
            @click="copyMoveFileHandler([media], true)"
            v-close-popup
          >
            <q-item-section>
              Move
            </q-item-section>
          </q-item>
          <q-item
            clickable
            class="q-mb-sm"
            @click="copyMoveFileHandler([media], false)"
            v-close-popup
          >
            <q-item-section>
              Copy
            </q-item-section>
          </q-item>
          <q-item
            clickable
            class="q-mb-sm"
            @click="$emit('renameFile', media)"
            v-close-popup
          >
            <q-item-section>
              Rename
            </q-item-section>
          </q-item>
          <q-item
            v-if="media.is_document_section && !media.is_read_only && (currentUser && currentUser.id === media.user_id)"
            @click="updateReadOnlyHandler(true)"
            clickable
            class="q-mb-sm"
            v-close-popup
          >
            <q-item-section>
              Set to read only
            </q-item-section>
          </q-item>
          <q-item
            v-else-if="media.is_document_section && media.is_read_only  && (currentUser && currentUser.id === media.user_id)"
            @click="updateReadOnlyHandler(false)"
            clickable
            class="q-mb-sm"
            v-close-popup
          >
            <q-item-section>
              Remove read only
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item
            clickable
            @click="fromComputer(mediaId)"
            v-if="canRemove && media.type.match(/image.*/)"
            v-close-popup
          >
            <q-item-section>
              Replace image
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click.stop="downloadFile(mediaId)"
            v-if="isS3"
            v-close-popup
          >
            <q-item-section>
              Download
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="addComment(media)"
            v-if="isImg && canRemove"
            v-close-popup
          >
            <q-item-section>
              Add Comment
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="openNextTabLink(media)"
            v-if="media.file_resource === 'dropbox'"
            v-close-popup
          >
            <q-item-section>
              Open Dropbox Link
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="openNextTabLink(media)"
            v-if="media.file_resource === 'google'"
            v-close-popup
          >
            <q-item-section>
              Open GoogleDrive Link
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="deleteFile(mediaId)"
            class="text-red"
            v-if="
              canRemove || (currentUser && currentUser.id === media.user_id)
            "
            v-close-popup
          >
            <q-item-section>
              Delete File
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <q-item-section class="q-pr-none media-thumbnail" avatar>
        <q-avatar size="35px" rounded>
          <img :src="imgSrc" alt="avatar" style="border-radius: 6px" />
          <div
            v-if="isVideo && !isDropbox"
            class="row justify-center items-center absolute video-icon"
          >
            <div class="text-center flex-1">
              <q-btn
                :icon="$icons.mdiFileVideoOutline"
                style="color: #FF001F;"
                size="16px"
                round
                dense
                padding="0"
                flat
              />
            </div>
          </div>
          <div
            class="absolute media-comment-badge-info-sidebar-file-list"
            v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
            :style="
              media.commentPoint.length == completedMediaPoints.length
                ? `background: #15d89a`
                : ''
            "
          >
            <span>
              {{
                completedMediaPoints.length + "/" + media.commentPoint.length
              }}
            </span>
          </div>
        </q-avatar>
      </q-item-section>
      <q-item-section class="q-ml-md">
        <q-item-label class="text-black file-name" v-tooltip>
          {{ media.filename }}
          <q-tooltip v-if="isTruncated">
            {{ media.filename }}
          </q-tooltip>
        </q-item-label>
        <q-item-label
          style="font-size:11px;color:#575D64"
          class="group-hover-item"
          caption
        >
          {{ media.size | filesizeFormat }}mb |
          {{ fileSharedDate }}</q-item-label
        >
      </q-item-section>
      <q-item-section side>
        <div>
          <q-icon
            v-if="media.isbookmarked"
            @click.stop="removeMediaBookmark(media)"
            size="18px"
            class="cursor-pointer"
            color="pink"
            :name="$icons.matBookmark"
          >
            <q-tooltip>
              Remove from Saved items
            </q-tooltip>
          </q-icon>
        </div>
      </q-item-section>
    </div>
    <div
      class="task-menu-option row items-center thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item"
      style="z-index: 100"
    >
      <div
        v-if="media.isbookmarked"
        @click.stop="removeMediaBookmark(media)"
        class="cursor-pointer"
      >
        <q-icon size="20px" color="pink" :name="$icons.matBookmark">
          <q-tooltip>
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <div
        v-if="!media.isbookmarked"
        @click.stop="addMediaBookmark(media)"
        class="cursor-pointer"
      >
        <q-icon size="20px" color="black" :name="$icons.matBookmarkBorder">
          <q-tooltip>
            Add to Saved items
          </q-tooltip>
        </q-icon>
      </div>
    </div>
  </q-item>
</template>
<script>
import Vue from "vue";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";

import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";

import { UpdateMediaMutation } from "@/gql";
export default {
  name: "ListView",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "fullview",
    "canRemoveMedia",
    "pdfView",
    "videoPlayer",
    "activeCompany",
    "currentCompanyId",
    "currentWorkspaceId",
    "deleteFile",
    "audioPlayer",
    "addMediaBookmark",
    "removeMediaBookmark",
    "fromComputer",
    "copyMoveFileHandler",
    "clickonmoreoption",
    "currentUser",
    "editDoc",
  ],
  data() {
    return {
      isTruncated: false,
    };
  },
  methods: {
    async updateReadOnlyHandler(flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: this.media.id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", this.media.id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag ? "Document added read only mode." : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    openNextTabLink(media) {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(media.file);
      else window.open(media.file, "_blank");
    },
    addComment(media) {
      this.fullview(media);
      const value = {
        title: "Add Comment",
        gridMediaId: media.id,
        media: media,
        forscroll: "no",
        x: 0,
        y: 0,
      };
      this.clickonmoreoption(value);
    },
    selectViewMode(media) {
      if (this.isImg) {
        this.fullview(media);
      } else if (
        !this.isImg &&
        !this.isVideo &&
        !this.isAudio &&
        !this.isZip &&
        this.isS3
      ) {
        //this.pdfView(media);
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(media.file);
        else window.open(media.file, "_blank");
      } else if (this.isVideo && this.isS3) {
        this.videoPlayer(media);
      } else if (this.isAudio) {
        this.audioPlayer(media);
      } else if (media.is_document_section) {
        this.editDoc(media);
      } else {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(media.file);
        else window.open(media.file, "_blank");
      }
    },
    async downloadFile(id) {
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    attachmentPreviewHandler(mediaLink) {
      if (this.isImg) {
        this.fullview(mediaLink);
      } else if (this.isPdf) {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(mediaLink);
        else this.pdfView({ file: mediaLink });
      }
    },
    attachToMessage(media) {
      let value = {};
      const files = this.$store.getters.workspaceEditorMedia(
        this.currentCompanyId,
        this.currentWorkspaceId
      );

      if (files) {
        value = files;
      }
      media["verifyKey"] = "fromShared";
      Vue.set(value, media.id, media);
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.currentCompanyId,
        workspaceId: this.currentWorkspaceId,
      });
      if (this.$route.name !== "MessagingView") {
        this.$router.push(
          `/companies/${this.activeCompany}/workspaces/${this.currentWorkspaceId}/messaging`
        );
      }
    },
  },
  computed: {
    mediaId() {
      return this.media.id;
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    isS3() {
      return this.media.file_resource === "s3";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isPdf() {
      return this.media.type.match(/pdf.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      return this.mediaPreview.type;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    fileSharedDate() {
      return format(fromUnixTime(this.media.created_at), "MMM. do @hh:mm aaaa");
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
  },
};
</script>

<style lang="stylus" scoped>
.media-card-bg {
  background: #E6E9EE;
}
</style>
