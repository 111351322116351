<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white upgrade-plan">
      <div
        v-if="!plans"
        class="column items-center justify-center"
        style="height:500px;"
      >
        <q-circular-progress
          indeterminate
          :thickness="0.1"
          size="75px"
          color="primary"
          class="q-ma-md"
        />
      </div>
      <div v-else>
        <div class="close-btn-bg">
          <q-btn
            flat
            dense
            :icon="$icons.matClose"
            round
            size="10px"
            @click="closeDialog"
            class="close-btn"
            style="margin-right: 5px"
          />
        </div>
        <!-- <div class="row title">
          <span class="q-mx-auto app-text">UPGRADE TODAY</span>
        </div> -->
        <div class="monthly-view">
          <div class="q-mt-sm q-mb-sm">
            <div class="switch-place">
              <div class="yearly-monthly-switch">
                <div
                  class="spacing app-text cursor-pointer "
                  :class="toggleValue && 'on-selected'"
                  @click="toggleValue = true"
                >
                  <span>Billed Yearly</span><br/>
                  <span>Save 30%</span>
                </div>

                <div
                  class="spacing app-text cursor-pointer"
                  style="position:relative;height: 98%;align-items: center;display: flex;justify-content: center;"
                  :class="!toggleValue && 'on-selected'"
                  @click="toggleValue = false"
                >
                  Billed Monthly
                </div>
                <!-- <div v-if="!toggleValue" class="tag">
                  <div class="app-text-normal">Pay annually</div>
                  <div class="app-text">& save 30%</div>
                </div> -->
              </div>
            </div>
            <!-- <div class="plan-toggle q-mb-sm"> -->
            <!-- <label class="app-text-normal" :class="!toggleValue && 'current-active'">Monthly</label>
            <q-toggle v-model="toggleValue" color="primary" keep-color/>
            <label class="app-text-normal" :class="toggleValue && 'current-active'">Yearly</label> -->
            <!-- </div> -->
            <!-- <div class="plan-description app-text-normal"><label class="text-content">Pay annually and save 45%</label></div> -->
          </div>
          <div
            class="row q-mt-lg "
            style="justify-content:center; display: grid;grid-auto-flow: column;"
          >
            <div v-for="(planData, i) in plans" :key="i">
              <div
                class="plan-detail col"
                style="position:relative; margin-left:5px; margin-right:5px;"
                :style="[
                  checkCurrentPlan(planData) && {
                    border: `solid 5px ${planData.color}`,
                  },
                ]"
              >
                <!-- <div
                  class="most-pop app-text-normal"
                  v-if="planData.is_popular"
                >
                  Most popular
                </div> -->
                <div
                  class="title app-text-normal"
                  :style="{ color: planData.color }"
                >
                {{ planData.name }}
                </div>
                <div v-if="planData.planPricing[0] && planData.planPricing[0].amount > 0">
                  <div
                    class="app-text plan-name"
                    :style="{ color: planData.color }"
                  >
                    {{
                      "$" + planData.planPricing[0].amount.toFixed(2)
                    }}
                  </div>
                  <div
                    class="text-center app-text"
                    style="    font-size: 18px;font-weight: bold;"
                  >
                    {{ planData.display_text }}
                  </div>
                </div>
                <div
                  class="row q-mt-md"
                  v-if="planData.name_tag == 'v5_free'"
                >
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/profile.svg" /></div>
                      <div class="box-name">{{planData.user_limit}} users</div>
                    </div>
                    <div class="flex plan-view-box q-mr-sm">
                      <div class="box-image">
                        <img src="/static/media-icons/checkmark.svg" />
                      </div>
                      <div class="box-name">Task</div>
                    </div>
                    <div class="flex plan-view-box q-mr-sm">
                      <div class="box-image">
                        <img src="/static/media-icons/chat-bubble.svg" />
                      </div>
                      <div class="box-name">Chat</div>
                  </div>
                </div>
                <div
                  class="row q-mt-md"
                  v-if="planData.name_tag == 'v5_pro'"
                >
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/video-call.svg" />
                    </div>
                    <div class="box-name">Calls</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/checkmark-blue.svg" />
                    </div>
                    <div class="box-name">Task</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/chat-bubble-blue.svg" />
                    </div>
                    <div class="box-name">Chat</div>
                  </div>
                </div>
                <div
                  class="row q-mt-md"
                  v-if="planData.name_tag == 'v5_pro'"
                >
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/doc-file.svg" />
                    </div>
                    <div class="box-name">Docs</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/tracking.svg" />
                    </div>
                    <div class="box-name">Time Tracking</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/picture.svg" />
                    </div>
                    <div class="box-name">Visual Comments</div>
                  </div>
                </div>
                <div
                  class="row q-mt-md"
                  v-if="planData.name_tag == 'v5_pro'"
                >
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/custom-field.svg"/>
                    </div>
                    <div class="box-name">Custom Fields</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/folder.svg" />
                    </div>
                    <div class="box-name">Folders & Subsections</div>
                  </div>
                  <div class="flex plan-view-box q-mr-sm">
                    <div class="box-image">
                      <img src="/static/media-icons/dashboard.svg" />
                    </div>
                    <div class="box-name">Reporting</div>
                  </div>
                </div>
                <div class="row justify-center align-center q-my-xl">
                  <div class="text-center" style="width: 100%;" >
                    <q-btn
                      v-if="checkCurrentPlan(planData)"
                      label="Current Plan"
                      no-caps
                      class="plan-btn"
                      :style="{ 'background' : planData.name_tag == 'v5_free' ? 'linear-gradient(222.86deg, #27CE92 0%, #44E0AE 100%)' : 'linear-gradient(222.86deg, #419CFF 0%, #007AFF 100%)'}"
                    >
                      <q-icon :name="$icons.matArrowForward" size="xs" class="q-ml-sm" />
                    </q-btn>
                    <q-btn
                      v-else
                      :label="planData.id > 1 ? `Upgrade` : `Free Forever`"
                      no-caps
                      class="plan-btn"
                      :style="{ 'background' : planData.name_tag == 'v5_free' ? 'linear-gradient(222.86deg, #27CE92 0%, #44E0AE 100%)' : 'linear-gradient(222.86deg, #419CFF 0%, #007AFF 100%)'}"
                      @click="generateStripeBillingPortalLink(planData)"
                    >
                      <q-icon :name="$icons.matArrowForward" size="xs" class="q-ml-sm" />
                    </q-btn>
                  </div>
                </div>
                <ul
                  class="checkmark text-center app-text-normal q-pb-sm"
                  style="position:relative;"
                >
                  <div
                    v-if="planData.display_sub_text"
                    class="app-text"
                    style="padding-bottom:8px"
                  >
                    {{ planData.display_sub_text }}
                  </div>
                  <div
                    v-for="(data, i) in planData.description.split(',')"
                    :key="i"
                  >
                    <li v-if="i%2" :style="{ 'background-color': planColors[planData.name_tag].itemBackgroundColorLight, 'color': planColors[planData.name_tag].itemColor }">{{ data }}</li>
                    <li v-else :style="{ 'background-color': planColors[planData.name_tag].itemBackgroundColor, 'color': planColors[planData.name_tag].itemColor }">{{ data }}</li>
                  </div>
                </ul>
                <div
                  class="q-mt-lg coupon-merge relative-postion"
                  :style="
                    planData.is_popular
                      ? { bottom: '120px' }
                      : { bottom: '109px' }
                  "
                  v-if="planData.isInputShow"
                >
                  <q-input
                    outlined
                    dense
                    clearable
                    placeholder="Coupon code"
                    autofocus
                    @input="(val) => (planData.coupenCode = val)"
                    class="coupon-input app-text-normal"
                    :value="planData.coupenCode"
                    clear-icon="false"
                  />
                  <q-btn
                    v-if="planData.coupenCode"
                    label="APPLY"
                    size="md"
                    no-caps
                    class="absolute q-px-sm apply-btn"
                    @click="applyCode(planData)"
                  />
                  <q-btn
                    v-if="!planData.coupenCode"
                    label="CANCEL"
                    size="md"
                    no-caps
                    class="absolute q-px-sm cancel-btn"
                    @click="
                      (planData.isInputShow = false),
                        (planData.coupon_code_visible = true)
                    "
                  />
                </div>
                <!-- <q-btn
                  v-if="checkCurrentPlan(planData)"
                  dense
                  no-caps
                  padding="5px 5px"
                  class="upgrade-btn q-mt-md q-mb-md app-text"
                  :color="planData.color"
                  :style="{ 'background-color': planData.color }"
                  style="bottom:0px;"
                  size="md"
                  label="Current Plan"
                  disable
                />
                <q-btn
                  v-else
                  dense
                  no-caps
                  padding="5px 5px"
                  class="upgrade-btn q-mt-md q-mb-md app-text relative-postion"
                  :color="planData.color"
                  :style="{ 'background-color': planData.color }"
                  style="bottom:0px;"
                  size="md"
                  :label="planData.id > 1 ? `Upgrade` : `Free`"
                  @click="generateStripeBillingPortalLink(planData)"
                /> -->
                <div class="row justify-center align-center q-my-md">
                  <div class="text-center" style="width: 100%;" >
                    <q-btn
                      v-if="checkCurrentPlan(planData)"
                      label="Current Plan"
                      no-caps
                      class="plan-btn"
                      :style="{ 'background' : planData.name_tag == 'v5_free' ? 'linear-gradient(222.86deg, #27CE92 0%, #44E0AE 100%)' : 'linear-gradient(222.86deg, #419CFF 0%, #007AFF 100%)'}"
                    >
                      <q-icon :name="$icons.matArrowForward" size="xs" class="q-ml-sm" />
                    </q-btn>
                    <q-btn
                      v-else
                      :label="planData.id > 1 ? `Upgrade` : `Free Forever`"
                      no-caps
                      class="plan-btn"
                      :style="{ 'background' : planData.name_tag == 'v5_free' ? 'linear-gradient(222.86deg, #27CE92 0%, #44E0AE 100%)' : 'linear-gradient(222.86deg, #419CFF 0%, #007AFF 100%)'}"
                      @click="generateStripeBillingPortalLink(planData)"
                    >
                      <q-icon :name="$icons.matArrowForward" size="xs" class="q-ml-sm" />
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="q-mt-lg refund-desc q-px-md text-center app-text">
            * No refunds. If you choose to cancel your premium subscription
            before the end of your expiry date, you will continue to have access
            to all plan specific premium features until the expiration date.
          </div>

          <div
            class="text-center app-text compare-plan cursor-pointer"
            @click="compareSwitch"
          >
            Compare plans
          </div>
        </div>
      </div>
    </q-card>
    <confirm-dialog
      v-if="isDialogOpen"
      v-model="isDialogOpen"
      title="Change plan"
      :question="dialogTitle"
      cancleText="Cancel"
      successText="Change"
      :cancleMethod="() => (isDialogOpen = false)"
      :successMethod="() => changePlan()"
      :loading="isLoader"
    />
    <contact-sales-dialog
      v-model="isSalesDialogOpen"
      v-if="isSalesDialogOpen"
      :closeSalesDialog="closeSalesDialog"
    ></contact-sales-dialog>
    <compare-plan-dialog
      v-model="isComparePlanDialogOpen"
      v-if="isComparePlanDialogOpen"
      :closeSalesDialog="closeSalesDialog"
      :prisingPlanData="plans"
      :comparePlanData="comparePlanData"
      :compareSwitch="compareSwitch"
      :toggleValue="toggleValue"
    ></compare-plan-dialog>
  </q-dialog>
</template>

<script>
import { getBillingPortalUrl } from "@/services/auth";
import {
  // CancelSubscriptionMutation,
  UpgradeSubscriptionMutation,
  PlansQuery,
  UpdatePlanNewMutation,
  CheckDowngradePlanMutation,
  CheckCouponMutation,
  PlanQuery,
  FeaturesQuery,
  CompanyQuery,
} from "@/gql";
import ContactSalesDialog from "@/components/Dialogs/ContactSalesDialog";
import ComparePlanDialog from "@/components/Dialogs/ComparePlanDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

export default {
  name: "UpgradePlansDialog",
  props: [
    "value",
    "closeDialog",
    "currentWorkspaceId",
    "currentCompany",
    "currentCompanyToken",
  ],
  components: {
    ContactSalesDialog,
    ComparePlanDialog,
    ConfirmDialog,
  },
  api: {
    plans: {
      defaultValue: null,
      cacheKey() {
        return `PlansQuery:${this.toggleValue ? 2 : 1}`;
      },
      query: PlansQuery,
      variables() {
        return {
          filter_id: this.toggleValue ? 2 : 1,
        };
      },
    },
  },
  mounted() {
    if (this.currentCompany.subscription.base_plan.includes("month")) {
      this.toggleValue = false;
    }
    this.findPlanData();
  },
  data() {
    return {
      planColors:{
          v5_free:{
            itemColor:'#0E8C64',
            itemBackgroundColor:'#ECFFF9',
            itemBackgroundColorLight:'#e9fff880',
          },
          v5_pro:{
            itemColor:'#0062CC',
            itemBackgroundColor:'#F2F8FF',
            itemBackgroundColorLight:'#F2F8FF80',
          }
        },
      toggleValue: true,
      isLoading: false,
      isComparePlanOpen: false,
      isSalesDialogOpen: false,
      scrollPosition: 0,
      isComparePlanDialogOpen: false,
      data: null,
      isDialogOpen: false,
      dialogTitle: "",
      dataOfPlan: null,
      isLoader: false,
      comparePlanData: [
        {
          title: "Common features",
          data: [
            ["Unlimited workspaces/projects", true, true],
            ["Public, Private & 1on1 workspaces", true, true],
            ["Unlimited space", true, true],
            ["Read receipts", true, true],
            ["Task-boards", true, true],
            ["My tasks", true, true],
            ["Dedicated file section", true, true],
            ["In-app learn section & tutorials", true, true],
            ["Native iOS and Android apps", true, true],
            ["Mac & Windows aps", true, true],
            ["Web access", true, true],
          ],
        },
        {
          title: "Premium features",
          data: [
            ["Saved items", false, true],
            ["Workspace folders", false, true],
            ["File folders", false, true],
            ["Visual comments", false, true],
            ["Activity log", false, true],
            ["Native time tracking", false, true],
            ["Video call", false, true],
          ],
        },
        {
          title: "Reporting",
          data: [
            ["Team dashboard", false, true],
            ["My work", false, true],
          ],
        },
        {
          title: "Real-time chat",
          data: [
            ["Task from message", false, true],
            ["E-mail to message", false, true],
            ["Export conversation", false, true],
            ["Share folders", false, true],
          ],
        },
        {
          title: "Project management",
          data: [
            ["E-mail to task", false, true],
            ["Board view (kanban)", true, true],
            ["List view", false, true],
            ["Calendar view", false, true],
            ["Timeline view", false, true],
            ["Custom labels", false, true],
            ["Board backgrounds", false, true],
            ["Board template", false, true],
            ["Assign member to subtask", false, true],
            ["Task cover", false, true],
            ["Copy & duplicate tasks", false, true],
            ["Private tasks", false, true],
          ],
        },
        {
          title: "Files & folder management",
          data: [
            ["File folders", false, true],
            ["Folder sections", false, true],
            ["Visual comments", false, true],
          ],
        },
        {
          title: "Support",
          data: [
            ["Priority support", false, true],
            ["Dedicated product specialist", false, true],
            ["Custom onboarding & education", false, true],
          ],
        },
        {
          title: "Integrations",
          data: [
            ["Dropbox", true, true],
            ["Google drive", true, true],
            ["Slack", true, true],
            ["Asana", true, true],
            ["Trello", true, true],
            ["Monday", true, true],
            ["Clickup", true, true],
            ["Jira", true, true],
          ],
        },
      ],
    };
  },
  methods: {
    async findPlanData() {
      const findCompanyData = await this.$api.query({
        query: PlansQuery,
        variables() {
          return {
            filter_id: 2,
          };
        },
      });
      this.data = findCompanyData.data.plans;
    },
    async changePlan() {
      let data = this.dataOfPlan;
      const variables = {
        plan_id: data.id,
        pricing_id: data.planPricing[0].id,
      };
      this.isLoader = true;
      const checkDowngradePlanResponse = await this.$api.mutate({
        mutation: CheckDowngradePlanMutation,
        variables,
      });
      if (checkDowngradePlanResponse.data.checkDowngradePlan) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Plan already downgraded. You can downgrad plan only once.",
          icon: this.$icons.matAnnouncement,
        });
        this.isLoader = false;
        this.isDialogOpen = false;
        return false;
      }
      this.isLoader = true;
      const response = await this.$api.mutate({
        mutation: UpdatePlanNewMutation,
        variables,
      });
      if (response && response.data && response.data.updatePlanNew) {
        this.isLoader = false;
        if (response.data.updatePlanNew.subscription.is_downgrade_plan) {
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message:
              "Plan was downgraded successfully. You will continue to have access until your next billing cycle, after which your account will be downgraded.",
            icon: this.$icons.matAnnouncement,
          });
        } else {
          const findCompanyData = await this.$api.query({
            query: CompanyQuery,
            cacheKey: "CompanyQuery",
          });

          const variables = {
            plan_id: findCompanyData.data.company.subscription.plan_id || 1,
          };
          const response = await this.$api.query({
            query: PlanQuery,
            cacheKey: "PlanQuery",
            variables,
          });

          const allFeatures = await this.$api.query({
            query: FeaturesQuery,
            cacheKey: "FeaturesQuery",
          });

          this.$store.dispatch("subscriptionPlanData", response.data);
          this.$store.dispatch("allFeatureList", allFeatures);

          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Plan upgraded successfully it applied from now.",
            icon: this.$icons.matAnnouncement,
          });
        }
        this.currentCompany.subscription =
          response.data.updatePlanNew.subscription;
        this.loadingConfirm = false;
        this.isSuccess = true;
        this.isDialogOpen = false;
        this.$store.dispatch("videoPlayerData", {
          isDialogShow: false,
          videoUrl: "",
        });
        window.location.reload();
      }
    },

    checkCurrentPlan(plan) {
      if (
        plan.planPricing[0] &&
        this.currentCompany.subscription.base_plan ===
          plan.planPricing[0].price_id
      ) {
        return true;
      }
      // } else if (
      // 	this.currentCompany.subscription.plan &&
      // 	this.currentCompany.subscription.plan.is_unlimited &&
      // 	this.currentCompany.subscription.plan.is_unlimited === plan.is_unlimited
      // ) {
      // 	return true;
      // }
      return false;
    },
    applyCode(planData) {
      planData.isInputShow = false;
      planData.coupon_code_visible = false;

      this.plans.map((result) => {
        if (planData.name !== result.name) {
          (result.couponCodeInputVisible = false), (result.coupenCode = "");
          result.isInputShow = false;
        }
      });
      this.data.map((resultData, i) => {
        if (
          resultData.coupon_code_visible &&
          resultData.coupon_code_visible === true
        ) {
          this.plans[i].coupon_code_visible = true;
        }
      });

      this.checkCouponCode(planData);
    },
    async checkCouponCode(planData) {
      if (planData.coupenCode !== "") {
        const variables = {
          coupon_code: planData.coupenCode,
        };

        try {
          const response = await this.$api.mutate({
            mutation: CheckCouponMutation,
            variables,
          });
          if (response.data.checkCoupon) {
            this.$q.notify({
              position: "top-right",
              message: "Coupon entered successfully",
              classes: "success-notification",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
            });
          } else {
            this.$q.notify({
              color: "negative",
              position: "top-right",
              message: "Coupon entered is not valid",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ color: "white" }],
            });
          }
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Coupon entered is not valid",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ color: "white" }],
          });
        }
      }
    },
    compareSwitch() {
      this.isComparePlanDialogOpen = !this.isComparePlanDialogOpen;
    },
    scrollToElement() {
      var element = document.getElementById("compare");
      element.scrollIntoView({ behavior: "smooth" });
    },
    closeSalesDialog() {
      this.isSalesDialogOpen = false;
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: this.video,
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async generateStripeBillingPortalLink(data) {
      this.isLoading = true;
      let priceId = data.planPricing[0].id;
      let coupenCode = data.coupenCode;
      if (this.currentCompany.subscription.plan_id <= 1) {
        let res = await getBillingPortalUrl(
          this.currentCompanyToken,
          this.currentWorkspaceId,
          priceId,
          coupenCode
        );
        if (res.url) {
          this.$store.dispatch("videoPlayerData", {
            isDialogShow: false,
            videoUrl: "",
          });
          window.location = res.url;
        } else {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: res.message,
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      } else {
        const activeMember = this.currentCompany.members.filter(
          (member) =>
            member.status === "active" &&
            member.roles[0] &&
            member.roles[0].name !== "guest"
        );
        const inActiveMember = this.currentCompany.members.filter(
          (member) => member.status !== "active"
        );
        const guestMember = this.currentCompany.members.filter(
          (member) =>
            member.roles[0] &&
            member.roles[0].name === "guest" &&
            member.status === "active"
        );

        let amount =
          data.planPricing[0].amount *
          data.planPricing[0].validity *
          activeMember.length;

        this.dialogTitle = `Are you sure you want to change the plan to ${data.name}? You currently have ${activeMember.length}  active members, ${guestMember.length} guests and ${inActiveMember.length} inactive members. Your monthly bill will be  $${amount} dollars.`;
        this.isDialogOpen = true;
        this.dataOfPlan = data;

        // if(checkDowngradePlanResponse.data.checkDowngradePlan) {
      }
    },
    async downgradePlan(data) {
      if (data.planName === "UNLIMITED") {
        this.isSalesDialogOpen = true;
      }
      // const response = await this.$api.mutate({
      //   mutation: CancelSubscriptionMutation,
      //   variables: {
      //     company_id:this.currentCompany.id,
      //   },
      // });
      // if (response.data.cancelSubscription) {
      //   this.$q.notify({
      //         classes: 'success-notification',
      //         position: "top-right",
      //         message: "Plan downgraded successfully.",
      //         icon: this.$icons.matAnnouncement,
      //       });
      //   this.currentCompany.subscription.is_downgrade_plan = true;
      //   this.closeDialog();
      // }
    },
    async upgradeCurrentPlan() {
      const response = await this.$api.mutate({
        mutation: UpgradeSubscriptionMutation,
        variables: {
          company_id: this.currentCompany.id,
        },
      });
      if (response.data.upgradeSubscription) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Plan upgrade successfully.",
          icon: this.$icons.matAnnouncement,
        });
        this.currentCompany.subscription.is_downgrade_plan = false;
        this.closeDialog();
      }
    },
  },
};
</script>
<style scoped>
.close-btn {
  position: fixed;
  font-size: 10px;
  z-index: 10;
  margin-right: 5px;
}
.yearly-monthly-switch {
  display: flex !important;
  justify-content: center !important;
  border-radius: 50px;
  border: solid;
  width: 35%;
  text-align: center;
  border: solid 3px #007aff;
  background-color: #fff;
}
.switch-place {
  display: flex !important;
  justify-content: center !important;
}
.spacing {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #006EE5;
  font-size: 17px;
}
.on-selected {
  background-color: #007aff;
  /* font-size: 14px; */
  font-weight: bold;
  color: #fff;
  margin: 1px;
}
.tag {
  position: absolute;
  top: 45px;
  right: 30%;
  background-color: #007aff;
  border-radius: 18px;
  color: #fff;
  padding: 10px 25px;
}
</style>
