<template>
  <q-dialog :value="true" persistent>
    <q-card class="plan-expired-dialog">
      <div class="close" @click="closeDialog">
        <div class="icon">
          <q-icon
            :name="$icons.matClose"
            size="20px"
            style="cursor: pointer"
          ></q-icon>
        </div>
      </div>
      <div class="main-content">
        <div class="title">
          <p class="app-text">{{ title }}</p>
        </div>
        <div class="content">
          <!-- <p class="bold">For the next 15-days you have full-access to all features</p> -->
          <p>{{ description }}</p>
        </div>
        <div class="video" style="border-radius: 7px" @click="videoPlayer">
          <q-icon
            :name="$icons.matPlayArrow"
            color="primary"
            size="80px"
          ></q-icon>
        </div>
        <q-btn
          v-if="isAdmin"
          dense
          no-caps
          padding="5px 5px"
          class="plane-btn q-mt-md"
          color="primary"
          size="md"
          label="Upgrade today"
          clickable
          @click="upgradePlan.flag = true"
        />
        <q-btn
          v-else
          dense
          no-caps
          padding="5px 5px"
          class="plane-btn q-mt-md"
          color="primary"
          size="md"
          label="Please contact your admin"
          @click="closeDialog"
        />
      </div>
    </q-card>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="imageList"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    >
    </video-dialog>
    <upgrade-plans-dialog
      v-if="upgradePlan.flag"
      v-model="upgradePlan.flag"
      :closeDialog="closeUpgradePlanDialog"
      :currentCompany="company"
      :currentWorkspaceId="1"
      :currentCompanyToken="currentCompany.accessToken"
    />
  </q-dialog>
</template>

<script>
import VideoDialog from "../VuePlayer/VideoDialog.vue";
import UpgradePlansDialog from "@/components/Dialogs/UpgradePlansDialog";
import { mapGetters } from "vuex";
import get from "lodash/get";
import { getCustomerPortalUrl } from "@/services/auth";

export default {
  props: ["title", "description", "video"],
  data() {
    return {
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      isLoading: false,
      upgradePlan: {
        flag: false,
      },
    };
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  components: {
    VideoDialog,
    UpgradePlansDialog,
  },
  methods: {
    closeUpgradePlanDialog() {
      this.upgradePlan.flag = false;
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: this.video,
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    closeDialog() {
      this.$emit("closeDialog", null);
    },
    async generateStripeCustomerPortalLink() {
      this.isLoading = true;
      let res = await getCustomerPortalUrl(
        this.currentCompany.accessToken,
        this.returnCurrentWorkspaceId
      );
      window.location = res.url;
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      ownCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.ownCompany) {
        return get(this.companies, this.ownCompany);
      } else {
        return null;
      }
    },
    isAdmin() {
      return this.user && this.user.roles[0].label === "Admin";
      // return find(this.user.roles, o => o.name === "admin");
    },
  },
};
</script>
