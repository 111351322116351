<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card
      style="width: 277px;"
      class="q-px-lg q-py-md bg-white create-folder"
    >
      <div class="close-btn-bg close-btn">
        <!-- <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        /> -->
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="black"
          round
          size="sm"
          @click="closeDialog"
        />
      </div>
      <!-- <div class="row title">
        <span class="q-mx-auto">Create Workspace</span>
      </div> -->
      <div class="search-member q-px-none">
        <q-input
          autofocus
          ref="search"
          outlined
          :placeholder="
            this.platform == 'slack' ? 'Search Channel' : 'Search Board'
          "
          v-model="search"
          clearable
          dense
        >
          <template v-slot:prepend>
            <q-icon :name="$icons.matSearch" />
          </template>
        </q-input>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        {{ this.selectCardLabel }}
      </div>
      <div>
        <q-list style="overflow: auto; max-height: 250px;">
          <q-item
            clickable
            @click="selectAll(!isSelectAll)"
            style="min-height:24px;"
            class="q-px-none"
            v-if="!search"
          >
            <q-item-section side style="padding-right: 12px">
              <span class="workspace-selection-checkbox">
                <div
                  class="selected-workspace-selection-checkbox"
                  v-if="isSelectAll"
                ></div>
              </span>
            </q-item-section>
            <q-item-section>
              SELECT ALL THE BOARDS
            </q-item-section>
          </q-item>
          <template v-for="card in filteredBoardList">
            <q-item
              :key="card.id || card.gid"
              clickable
              @click="selectCard(card)"
              style="min-height:24px;"
              class="q-px-none"
            >
              <q-item-section side style="padding-right: 12px">
                <span class="workspace-selection-checkbox">
                  <div
                    class="selected-workspace-selection-checkbox"
                    v-if="selectedBoardIds.includes(card.id || card.gid)"
                  ></div>
                </span>
              </q-item-section>
              <q-item-section
                class="text-truncate"
                style="display:block"
                v-tooltip
              >
                {{ card.name }}
                <q-tooltip v-if="isTruncated">
                  {{ card.name }}
                </q-tooltip>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </div>
      <div class="q-px-sm">
        <q-btn
          dense
          no-caps
          class="q-mt-md full-width"
          color="primary"
          size="md"
          :label="selectCardButton"
          @click="createWorkspaceHandler"
          :loading="isLoading"
          :disabled="!checkValidation"
        />
      </div>
      <import-progress-dialog
        v-if="importProgressDialog.flag"
        v-model="importProgressDialog.flag"
        :closeImportProgress="closeImportProgress"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import { createWorkspace } from "@/services/auth";
import ImportProgressDialog from "@/components/Dialogs/ImportProgressDialog";
import groupBy from "lodash/groupBy";
// import getUnixTime from "date-fns/getUnixTime";

export default {
  name: "CreateTrelloWorkspaceDialog",
  props: [
    "card",
    "value",
    "closeDialog",
    "company",
    "isFrom",
    "workspace",
    "platform",
    "accessToken",
  ],
  api: {},
  components: {
    ImportProgressDialog,
  },
  data() {
    return {
      boardList: null,
      isLoading: false,
      title: null,
      insertData: false,
      selectedBoardIds: [],
      isSelectAll: false,
      selectedCard: [],
      importProgressDialog: {
        flag: false,
      },
      isTruncated: false,
      search: null,
      selectCardLabel: null,
      selectCardButton: null,
    };
  },
  mounted() {
    this.boardList = this.card;
    this.selectCardLabel =
      this.platform == "slack"
        ? "Select the " +
          this.platform.charAt(0).toUpperCase() +
          this.platform.slice(1) +
          " channels you want to import:"
        : "Select the " +
          this.platform.charAt(0).toUpperCase() +
          this.platform.slice(1) +
          " boards you want to import:";
    this.selectCardButton =
      this.platform == "slack" ? "import channels" : "import boards";
    //this.selectedCard = this.boardList[0];
  },
  methods: {
    closeImportProgress() {
      this.importProgressDialog.flag = false;
      this.closeDialog();
    },
    selectAll(value) {
      this.isSelectAll = value;
      if (value) {
        this.selectedBoardIds = this.boardList.map((i) => i.id || i.gid);
        return;
      }
      this.selectedBoardIds = [];
    },
    selectCard(card) {
      this.isSelectAll = false;
      if (this.selectedBoardIds.includes(card.id)) {
        this.selectedBoardIds = this.selectedBoardIds.filter(function(item) {
          return item !== card.id || card.gid;
        });
      } else {
        this.selectedBoardIds.push(card.id || card.gid);
      }
    },
    async createWorkspaceHandler() {
      if (this.selectedBoardIds.length > 0) {
        if (this.platform == "monday") {
          this.boardList.forEach((element) => {
            if (this.selectedBoardIds.includes(element.id)) {
              this.selectedCard.push(element);
            }
          });
          try {
            const response = await createWorkspace(
              {
                workspaces: this.selectedCard,
                token: this.accessToken,
                type: this.platform,
              },
              this.company.accessToken
            );
            if (response.exception) {
              throw new Error(response.message);
            }
            this.importProgressDialog.flag = true;
          } catch (error) {
            this.importProgressDialog.flag = false;
            if (error.message === "WorkspaceTitleAlreadyTaken") {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: this.$t("message.WorkspaceTitleAlreadyTaken"),
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            } else {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: "Something went wrong.Please try again!",
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            }
          }
        } else if (this.platform == "slack") {
          this.boardList.forEach((element) => {
            if (this.selectedBoardIds.includes(element.id)) {
              this.selectedCard.push(element);
            }
          });
          try {
            const response = await createWorkspace(
              {
                workspaces: this.selectedCard,
                token: this.accessToken,
                type: this.platform,
              },
              this.company.accessToken
            );
            if (response.exception) {
              throw new Error(response.message);
            }
            this.importProgressDialog.flag = true;
          } catch (error) {
            this.importProgressDialog.flag = false;
            if (error.message === "WorkspaceTitleAlreadyTaken") {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: this.$t("message.WorkspaceTitleAlreadyTaken"),
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            } else {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: "Something went wrong.Please try again!",
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            }
          }
        } else if (this.platform == "asana") {
          //console.log(this.selectedCard);

          this.boardList.forEach((element) => {
            if (this.selectedBoardIds.includes(element.gid)) {
              this.selectedCard.push(element);
            }
          });
          try {
            const response = await createWorkspace(
              {
                workspaces: this.selectedCard,
                token: this.accessToken,
                type: this.platform,
              },
              this.company.accessToken
            );
            if (response.exception) {
              throw new Error(response.message);
            }
            this.importProgressDialog.flag = true;
          } catch (error) {
            this.importProgressDialog.flag = false;
            if (error.message === "WorkspaceTitleAlreadyTaken") {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: this.$t("message.WorkspaceTitleAlreadyTaken"),
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            } else {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: "Something went wrong.Please try again!",
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            }
          }
        } else if (this.platform == "clickup") {
          this.boardList.forEach((element) => {
            if (this.selectedBoardIds.includes(element.id)) {
              this.selectedCard.push(element);
            }
          });
          try {
            const response = await createWorkspace(
              {
                workspaces: this.selectedCard,
                token: this.accessToken,
                type: this.platform,
              },
              this.company.accessToken
            );
            if (response.exception) {
              throw new Error(response.message);
            }
            this.importProgressDialog.flag = true;
          } catch (error) {
            this.importProgressDialog.flag = false;
            if (error.message === "WorkspaceTitleAlreadyTaken") {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: this.$t("message.WorkspaceTitleAlreadyTaken"),
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            } else {
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: "Something went wrong.Please try again!",
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            }
          }
        } else {
          this.importProgressDialog.flag = true;
          let promises = [];
          this.boardList.forEach(async (element) => {
            if (this.selectedBoardIds.includes(element.id) && element.lists) {
              for (let i = 0; i < element.lists.length; i++) {
                promises.push(
                  this.$trello.get(
                    "lists/" +
                      element.lists[i].id +
                      "/cards?fields=id,name,desc,idBoard,idList,closed"
                  )
                );
                // element.lists[i].cards = cardResponse;
              }
              this.selectedCard.push(element);
              return promises;
            }
          });
          Promise.all(promises)
            .then((res) => {
              let taskList = res.flat();
              let listArr = this.selectedCard.map((card) => card.lists).flat();
              listArr.forEach((list) => {
                taskList.forEach((task) => {
                  if (task.idList === list.id) {
                    if (list.cards) {
                      list.cards.push(task);
                    } else {
                      list.cards = [task];
                    }
                  }
                });
              });
              listArr = groupBy(listArr, "idBoard");
              this.selectedCard.forEach((card) => {
                card.lists = listArr[card.id];
              });
            })
            .then(async () => {
              if (this.selectedCard) {
                try {
                  const response = await createWorkspace(
                    {
                      workspaces: this.selectedCard,
                      token: this.$trello.token(),
                      key: "2102859d78cc0c3f2be3553d568f755b",
                      type: this.platform,
                    },
                    this.company.accessToken
                  );
                  if (response.exception) {
                    throw new Error(response.message);
                  }
                } catch (error) {
                  this.importProgressDialog.flag = false;
                  if (error.message === "WorkspaceTitleAlreadyTaken") {
                    this.$q.notify({
                      color: "negative",
                      position: "top-right",
                      message: this.$t("message.WorkspaceTitleAlreadyTaken"),
                      icon: this.$icons.matReportProblem,
                      actions: [{ icon: this.$icons.matClose, color: "white" }],
                    });
                  } else {
                    this.$q.notify({
                      color: "negative",
                      position: "top-right",
                      message: "Something went wrong.Please try again!",
                      icon: this.$icons.matReportProblem,
                      actions: [{ icon: this.$icons.matClose, color: "white" }],
                    });
                  }
                }
              }
            })
            .catch(() => {
              this.importProgressDialog.flag = false;
              this.$q.notify({
                color: "negative",
                position: "top-right",
                message: "Something went wrong!Please try again.",
                icon: this.$icons.matReportProblem,
                actions: [{ icon: this.$icons.matClose, color: "white" }],
              });
            });
        }
      }
    },
  },
  computed: {
    checkValidation() {
      return this.selectedBoardIds.length > 0;
    },
    filteredBoardList() {
      if (this.search) {
        return this.boardList.filter((card) =>
          card.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.boardList;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 0 0px 0;
}
.workspace-selection-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #b8c1cb;
  border-radius: 4px;
}

.selected-workspace-selection-checkbox {
  background: #007aff;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 2px;
}

// .q-item:hover {
//   .selected-workspace-selection-checkbox {
//     background: #ffffff;
//   }
// }
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
