import { get } from "lodash";
import { mapGetters } from "vuex";
import getUnixTime from "date-fns/getUnixTime";
import { CreateBotPostMutation } from "@/gql";

export default {
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: "",
    },
  },
  methods: {
    async joinPublicWorkspaceAnnouncement(data) {
      const post = `@${this.authUser.username} joined this workspace`;
      await this.botCreatePost(data.workspaceId, post);
    },
    async leavePublicWorkspaceAnnouncement(data) {
      const post = `@${this.authUser.username} left this workspace`;
      await this.botCreatePost(data.workspaceId, post);
    },
    async removeFromWorkspaceAnnouncement(data) {
      const post = `@${this.authUser.username} removed @${data.removedUser.username}`;
      await this.botCreatePost(data.workspaceId, post);
    },
    async addMemberToWorkspaceAnnouncement(data) {
      const post = `@${this.authUser.username} added @${data.joinedMember.username}`;

      await this.botCreatePost(data.workspaceId, post);
    },
    async changeWorkspaceType(data) {
      const post = `@${this.authUser.username} has converted to ${data.type} workspace`;
      await this.botCreatePost(data.workspaceId, post);
    },
    async botCreatePost(workspaceId, post, media = [], mediaIds = []) {
      if ((post && post.trim() !== "") || media.length > 0) {
        const variables = {
          workspace_id: workspaceId,
          content: post,
          media: mediaIds,
        };
        const OptID = getUnixTime(new Date());

        const optimisticResponseCreatePost = {
          id: OptID,
          __typename: "post",
          content: variables.content,
          media: media,
          user_id: this.user.id,
          can_delete: false,
          is_bot: false,
          user: this.user,
          created_at: getUnixTime(new Date()),
          updated_at: getUnixTime(new Date()),
          forwardedPost: null,
          reactionset: [],
          reactiondata: [],
          seens: [],
        };

        const query = this.$api.getQuery(
          `MessagesQuery:${this.currentWorkspace}`
        );
        if (query.data) {
          query.data.messages.unshift(optimisticResponseCreatePost);
        }

        const response = await this.$api.mutate({
          mutation: CreateBotPostMutation,
          variables,
        });

        if (query.data) {
          query.data.messages = query.data.messages.map((post) =>
            post === optimisticResponseCreatePost
              ? response.data.createBotPost
              : post
          );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      inSessionCompanyBm: "activeCompany",
      authCompaniesBm: "auth",
    }),
    botMessageCurrentCompany() {
      if (this.authCompaniesBm && this.inSessionCompanyBm) {
        return get(this.authCompaniesBm, this.inSessionCompanyBm);
      } else {
        return null;
      }
    },
    authUser() {
      return this.user;
    },
  },
};
