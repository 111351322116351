<template>
  <q-dialog
    v-model="notificationSettingDialog"
    transition-hide="none"
    transition-show="none"
  >
    <div
      class="bg-white relative-position workspace-notification-setting-dialog"
      style="min-width:319px;padding: 20px 20px 20px 25px"
    >
      <div class="absolute-top-right q-mr-sm" style="margin-top: 5px">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="black"
          round
          size="md"
          @click="closeDialog"
        />
      </div>
      <div class="title">
        <span>Notifications</span>
      </div>
      <div style="margin-top: 20px">
        <q-item-label lines="1" style="margin-top: 10px; margin-bottom: 10px" class="row" v-for="option in options" v-bind:key="option.value" @click="changeNotificationSetting(option.value)">
          <span class="filter-custom-checkbox">
            <div
              v-if="option.value === notificationSetting"
              class="filter-selected-custom-checkbox"
            ></div>
          </span>
          <span>
            {{option.label}}
          </span>
        </q-item-label>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { UpdateNotificationLevelMutation } from "@/gql";
export default {
  name: "WorkspaceNotificationSettings",
  props: ["workspace", "notificationSettingDialog", "closeDialog"],
  data() {
    return {
      options: [
        {
          label: "Every new message",
          value: "all",
        },
        {
          label: "Just @mentions",
          value: "mentions",
        },
        {
          label: "Nothing",
          value: "none",
        },
      ],
    };
  },
  methods: {
    async changeNotificationSetting(val) {
      try {
        this.workspace.notification_level = val;
        await this.$api.mutate({
          mutation: UpdateNotificationLevelMutation,
          variables: {
            workspace_id: this.workspace.id,
            notification_level: val,
          },
        });
        this.closeDialog();
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
        this.closeDialog();
      }
    },
  },
  computed: {
    notificationSetting() {
      return this.workspace.notification_level;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-family: "Avenir Next" !important;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #000000;
} 
</style>