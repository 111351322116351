<template>
  <div
    class="row group-hover group-background-hover-item q-px-md relative-position flex-no-wrap task-timer"
    style="padding-top:6px;padding-bottom:6px"
  >
    <q-menu context-menu auto-close>
      <q-list>
        <!-- <q-item @click="edit(task)" clickable>
          <q-item-section>
            Edit Task
          </q-item-section>
        </q-item> -->
        <q-item
          @click="
            $copyTextToClipboard(copyTaskLink);
            isTaskCopy = true;
          "
          clickable
        >
          <q-item-section>Copy task link</q-item-section>
        </q-item>
        <q-separator class="q-my-sm" style="background:#E3E8ED;" />
        <q-item @click="duplicateTask(task)" clickable>
          <q-item-section>
            Duplicate Task
          </q-item-section>
        </q-item>
        <q-item
          @click="openTimelogHendler"
          clickable
          v-if="isVisible([1, 2, 3])"
        >
          <q-item-section
            v-if="task && task.timelogs && task.timelogs.length"
            >View time report</q-item-section
          >
          <q-item-section v-else>Add time report</q-item-section>
        </q-item>
        <q-item
          @click="exportTimeLogCsv(task.id)"
          v-if="task && task.timelogs && task.timelogs.length"
          clickable
        >
          <q-item-section>
            Export time report
          </q-item-section>
        </q-item>
        <q-separator class="q-my-sm" style="background:#E3E8ED;" />
        <q-item
          @click="deleteTaskConfirmBoxHandler(task)"
          clickable
          class="text-red"
        >
          <q-item-section>
            Delete Task
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <div style="z-index:1" class="q-mr-md">
      <div
        v-if="task.completed"
        class="cursor-pointer"
        @click="changeCompleteStatus(task)"
      >
        <div class="task-icon relative-position">
          <q-icon size="15px" style="color:#15d89a" :name="$icons.fasCheck" />
        </div>
        <q-tooltip>
          Mark Task InComplete
        </q-tooltip>
      </div>
      <div
        v-else
        class="cursor-pointer notification-sidebar-outline-dot "
        @click="changeCompleteStatus(task)"
      >
        <q-tooltip>
          Mark Task Complete
        </q-tooltip>
      </div>
    </div>
    <div>
      <div
        @click="edit(task)"
        class="dont-break-out "
        :class="[task.completed && 'strike']"
        style="margin-right:33px;font-size:15px"
      >
        {{ task.title }}
        <q-icon
          v-if="hasNotification(task.id)"
          color="pink"
          size="8px"
          :name="$icons.matFiberManualRecord"
          style="margin-top:-5px"
        />
        <q-tooltip>
          {{
            task.owner.id === user.id
              ? "Created By You"
              : "Assigned by " + task.owner.first + " " + task.owner.last
          }}
        </q-tooltip>
      </div>
      <div class="q-mt-sm">
        <div
          @click="createTimer()"
          class="total-time row items-center flex-no-wrap cursor-pointer relative-position"
          v-if="totalWorkTime && !activeTimelog"
        >
          <q-icon :name="$icons.matPlayCircleFilled" size="20px" />
          <span
            class="q-ml-sm time-text row items-center"
            style="margin-top:1px;"
            v-if="totalWorkTime"
            >{{ totalWorkTime }}</span
          >
          <div
            class="time-log-edit-delete row items-center"
            style="top:-1px;right:-1px"
          >
            <div
              class="cursor-pointer edit-icon"
              @click.stop="openTimelogHendler"
            >
              <q-icon size="16px" class="q-ml-xs" :name="$icons.matEdit" />
              <q-tooltip>Edit Time Report</q-tooltip>
            </div>
            <div
              class="delete-icon row items-center"
              clickable
              @click.stop="deleteTaskTimeLogs"
            >
              <q-icon size="16px" class="q-mr-xs" :name="$icons.matDelete" />
              <q-tooltip>Delete Time Report</q-tooltip>
            </div>
          </div>
        </div>
        <timer
          :startTime="activeTimelog.start_date"
          v-if="activeTimelog && user.id === activeTimelog.owner.id"
          :stopTimer="stopTimerHandler"
          :cardTask="task"
          :deleteTaskTimeLogs="deleteTaskTimeLogs"
          @openTimelogDialog="openTimelogHendler"
          :hideEditButton="true"
        />
      </div>
    </div>
    <div
      class="cursor-pointer"
      @click="removeTaskBookmark(task.id)"
      v-if="task.isbookmarked"
      style="position:absolute;right:15px;z-index:10"
    >
      <q-icon
        :name="$icons.matBookmark"
        color="pink"
        class="cursor-pointer"
        size="15px"
        style="z-indx:11"
      />
    </div>
    <div
      class="row sidebar-tasks-thread-icons thread-icons q-px-xs group-hover-item"
      style="position:absolute;right:15px;z-index:10"
    >
      <div
        v-if="!activeTimelog && !totalWorkTime"
        @click="createTimer"
        class="cursor-pointer q-mr-xs icon"
      >
        <q-icon
          v-if="!task.completed"
          size="20px"
          style="color:#B1BCC8;"
          :name="$icons.matPlayCircleFilled"
        />
        <q-tooltip>Track time</q-tooltip>
      </div>
      <div
        class="cursor-pointer icon"
        @click="removeTaskBookmark(task.id)"
        v-if="task.isbookmarked"
      >
        <q-icon
          :name="$icons.matBookmark"
          color="pink"
          class="cursor-pointer"
          size="15px"
          style="z-indx:11"
        />
        <q-tooltip>Remove from saved items</q-tooltip>
      </div>
      <div
        class="cursor-pointer icon"
        @click="addTaskBookmark(task.id)"
        v-if="!task.isbookmarked"
      >
        <q-icon
          :name="$icons.matBookmarkBorder"
          class=" thread-icon"
          size="15px"
        />
        <q-tooltip>Add to saved items</q-tooltip>
      </div>
    </div>
    <stop-timer-confirmation-dialog
      v-if="stopTimerConfirmation.flag"
      v-model="stopTimerConfirmation.flag"
      :timelogId="stopTimerConfirmation.id"
      :stopTimer="stopTimer"
      @closeDialog="
        stopTimerConfirmation.flag = false;
        stopTimerConfirmation.id = null;
      "
    />
    <start-new-timer-dialog
      v-if="startNewTimer.flag"
      v-model="startNewTimer.flag"
      :activeTaskId="startNewTimer.activeTaskId"
      :createTimer="createTimer"
      :currentCompany="currentCompany"
      @closeDialog="
        startNewTimer.flag = false;
        startNewTimer.activeTaskId = null;
      "
    />
  </div>
</template>
<script>
import TimerMixin from "@/mixins/TimerMixin";
import Timer from "@/components/Timer";
import StopTimerConfirmationDialog from "@/components/Dialogs/StopTimerConfirmationDialog";
import StartNewTimerDialog from "@/components/Dialogs/StartNewTimerDialog";
import UserScope from "@/mixins/UserScope";

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "PublicTask",
  mixins: [TimerMixin, UserScope],
  props: [
    "task",
    "board",
    "changeCompleteStatus",
    "edit",
    "duplicateTask",
    "deleteTaskConfirmBoxHandler",
    "workspace",
    "addTaskBookmark",
    "removeTaskBookmark",
    "hasNotification",
  ],
  components: {
    Timer,
    StartNewTimerDialog,
    StopTimerConfirmationDialog,
  },
  data() {
    return {
      timelogs: {
        flag: false,
      },
      startNewTimer: {
        flag: false,
        activeTaskId: null,
      },
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
      isTaskCopy: false,
    };
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    copyTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.workspace.id}/board?task=${this.task.id}&workspace=${this.workspace.id}&workspaceName=${this.workspace.title}`;
      return taskLink;
    },
  },
  methods:{
    openTimelogHendler() {
      this.$eventBus.$emit("openTimelogs", true, this.task.timelogs, true, this.task, false);
    },
  }
};
</script>
