<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white upgrade-plan">
      <div class="close-btn-bg">
        
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          round
          size="10px"
          @click="compareSwitch"
          class="close-btn"
          style="margin-right: 5px"
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto app-text">Compare plans</span>
      </div>
      <div class="monthly-view">
        <div > 
        <table class="sticky-plan" >
            <tr> 
            <td style="width:16%">
            </td>  
            <td  v-for="(planData,idea) in prisingPlanData" :key="idea" style="width:16%">
           <div class="title text-center app-text-normal" :style="{color:planData.color}">{{planData.name}}</div>
            <!-- <div class="text-center app-text-normal">{{planData.user_limit==="0"?"Unlimited":planData.user_limit}} users included</div> -->
            <div
              class="most-pop app-text-normal"
              v-if="planData.is_popular"
            >
              Most popular
            </div>
            <div class="app-text plan-name" :style="{color:planData.color}">{{planData.planPricing[0] ? planData.planPricing[0].amount === 0 ? "FREE" : '$'+(planData.planPricing[0].amount).toFixed(2) : planData.is_unlimited ? "UNLIMITED" :"FREE"}}</div>
            <div class="text-center app-text" :style="{color:planData.color}">{{planData.display_text}}</div>
            </td>
            </tr>
          </table>
        <div v-for="(planData,i) in comparePlanData" :key="i" class="table">
          <div class="feature-heading app-text">
            {{planData.title}}   
          </div>
          <table style="width:100% " >
            <tr v-for="(data,index) in planData.data" :key="index"> 
            <td v-for="(dataChild,ind) in data" :key="ind" style="width:16%" class="feature" :class="ind === 0 ? 'text-left' : 'text-center'">
              <div v-if="typeof(dataChild)==='string'" class="app-text">
                {{dataChild}}
              </div>

              <div class="tick" v-if="dataChild===true">
                <q-icon :name="$icons.fasCheck" size="15px" />
              </div>
              <div class="cross" v-if="dataChild===false">
                <q-icon :name="$icons.mdiCloseThick" size="19px" />
              </div>
            </td>
            </tr>
          </table>
        </div>
      </div>

 
        </div>
    </q-card>
     
  </q-dialog>
    
</template>

<script>
export default {
  name: "ComparePlanDialog",
  props: ["value","comparePlanData","prisingPlanData","compareSwitch","toggleValue"],
  }
</script>
 <style scoped>
  .close-btn{
    position: fixed;
    font-size: 10px;
    z-index: 10;
    margin-right: 5px;
  }
  .most-pop {
      padding-top: 5px;
      margin: 0px auto;
      width: 40%;
      height: 30px;
      color: #ffffff;
      text-align: center;
      border-radius: 3px;
      background-color: #007aff;
    }
</style>