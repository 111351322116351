<template>
  <q-toolbar
    flat
    dense
    class="rounded-borders"
    style="min-height: 40px; height: 43px;background:#FCFCFD;"
  >
    <div
      class="row flex-no-wrap items-center justify-between q-pl-sm"
      style="flex: 1"
      @click.stop="$emit('closeNotificaiton')"
    >
      <div class="row items-center flex-no-wrap">
        <q-toolbar-title
          style="font-size: 15px"
          shrink
          class="text-bold text-black q-px-none row items-center"
        >
          <div class="taskView-tabs">
            <div
              class="tab-content"
              v-if="isAdmin && activeView === 'AdminView'"
            >
              <q-avatar
                size="28px"
                style="margin-bottom: 2px; margin-right: 8px"
              >
                <q-icon :name="$icons.mdiAccountMultipleOutline" size="sm" />
              </q-avatar>
              <span>My team</span>
            </div>
            <div class="tab-content" v-else>
              <div
                v-if="activeUserId !== user.id"
                class="back-to-team cursor-pointer q-mr-sm"
                @click="$router.push({ name: 'AdminView' })"
              >
                <q-icon :name="$icons.mdiChevronLeft" size="20px" />
              </div>
              <q-avatar
                size="28px"
                v-if="activeUser && activeUser.pic"
                style="margin-bottom: 2px; margin-right: 8px"
              >
                <img
                  v-if="activeUser"
                  :src="activeUser.pic"
                  :alt="activeUser.first"
                />
              </q-avatar>
              <!-- <q-avatar
                size="28px"
                color="grey-4"
                text-color="grey-10"
                v-else
                style="margin-bottom: 2px; margin-right: 8px"
              >
                {{ activeUser && activeUser.first | firstChar
                }}{{ activeUser && activeUser.last | firstChar }}
              </q-avatar> -->
              <avatar v-else :size="28" :customStyle="{'font-size':'12px', 'font-weight':'600', 'margin-right':'8px'}" :username="activeUser.first+' '+activeUser.last"></avatar>
              <span>
                {{
                  activeUserId === user.id
                    ? "My tasks"
                    : activeUser &&
                      (activeUser.first + " " + activeUser.last) | capitalize
                }}
              </span>
            </div>
          </div>
        </q-toolbar-title>
      </div>
      <div class="row flex-1 items-center no-wrap q-mr-sm justify-end">
        <div
          style="max-width: 370px; min-width: 150px"
          class="row flex-1 flex-no-wrap items-center mywork-toolbar-search q-mx-md"
        >
          <q-input
            v-model="myworkSearch.memberSearchText"
            dense
            outlined
            autofocus
            placeholder="Filter members"
            :clear-icon="$icons.matClose"
            class="q-mr-xs flex-1"
            v-if="activeView === 'AdminView'"
          >
            <template v-slot:prepend>
              <q-icon
                style="margin-top: 0px"
                size="xs"
                :name="$icons.matSearch"
              />
            </template>
          </q-input>
          <q-input
            v-model="myworkSearch.taskSearchText"
            dense
            outlined
            autofocus
            placeholder="Filter tasks"
            :clear-icon="$icons.matClose"
            class="q-mr-xs filter-task-input flex-1"
            v-else
          >
            <template v-slot:prepend>
              <q-icon
                style="margin-top: 0px"
                size="xs"
                :name="$icons.matSearch"
              />
            </template>
          </q-input>
          <div
            v-if="myworkSearch.taskSearchText || myworkSearch.memberSearchText"
            @click="clearSearchHandler"
            class="text-black cursor-pointer"
            style="font-size: 11px"
          >
            Clear results
          </div>
        </div>
        <div
          class="date-filter row flex-no-wrap justify-around items-center cursor-pointer q-mr-md"
        >
          <div class="filter-label row flex-no-wrap items-end">
            <span class="q-mr-sm">
              <q-icon :name="$icons.matDateRange" size="18px" />
            </span>
            <span>
              {{ myWorkDateFilter }}
            </span>
          </div>
          <span>
            <q-icon
              size="15px"
              class="q-ml-xs"
              style="color: #42526e; opacity: 0.4"
              :name="$icons.matKeyboardArrowDown"
            />
          </span>
          <q-menu auto-close>
            <q-list>
              <q-item @click="setMyWorkDateFilter('All time')" clickable>
                <q-item-section> All time </q-item-section>
              </q-item>
              <q-item @click="setMyWorkDateFilter('This week')" clickable>
                <q-item-section> This week </q-item-section>
              </q-item>
              <q-item @click="setMyWorkDateFilter('Today')" clickable>
                <q-item-section> Today </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </div>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button"
          @click="toggleMyWorkWalkthroughBanner"
        >
          
          <q-tooltip content-class="tooltip-q" anchor="bottom left" self="top left" >{{$route.name == "AdminView" ? 'Learn about My team section' : 'Learn about My tasks section'}}</q-tooltip>
        </q-btn>
      </div>
    </div>
  </q-toolbar>
</template>
<script>
import orderBy from "lodash/orderBy";
import { UpdateUserOnboardingMutation,UsersTaskQuery } from "@/gql";
import UserScope from "@/mixins/UserScope";
import Avatar from 'vue-avatar';

export default {
  name: "MyWorkToolbar",
  mixins: [UserScope],
  props: ["myWorkDateFilter", "setMyWorkDateFilter", "myworkSearch"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    usersTask: {
      query: UsersTaskQuery,
      cacheKey: "UsersTaskQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      search: null,
      profileDialog: {
        show: false,
        user: null,
      },
      isAdminEligible: null,
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.checkTeamDashboardAccess();
    }
  },
  methods: {
    checkTeamDashboardAccess() {
      let eligible = this.$checkSpecificFeature("team_dashboard");
      if (eligible) {
        this.isAdminEligible = true;
      }
    },
    checkTeamDashboardAccessDialog() {
      let eligible = this.$checkEligiblity("team_dashboard");
      if (eligible) {
        this.isAdminEligible = true;
      }
    },
    async toggleMyWorkWalkthroughBanner() {
      this.user.onboarding.is_mywork = !this.user.onboarding.is_mywork;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_mywork",
          flag_value: this.user.onboarding.is_mywork,
        },
      });
    },
    clearSearchHandler() {
      this.myworkSearch.taskSearchText = null;
      this.myworkSearch.memberSearchText = null;
    },
  },
  components:{
    Avatar
  },
  computed: {
    isAdmin() {
      return this.user && this.user.roles.find((o) => o.name === "admin");
    },
    activeView() {
      return this.$route.name;
    },
    companyMembers() {
      return this.usersTask;
    },
    companyMembersFuse() {
      if (this.usersTask) {
        const orderedMembers = orderBy(
          this.usersTask,
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return this.usersTask;
    },
    filteredCompanyMembers() {
      if (this.companyMembersFuse) {
        return this.search
          ? this.companyMembersFuse.filter((u) =>
              u.first.toLowerCase().includes(this.search.toLowerCase())
            )
          : this.companyMembersFuse.hasOwnProperty("list")
          ? this.companyMembersFuse.list
          : this.companyMembersFuse;
      }
      return null;
    },
    dateFilterLabel() {
      return this.myWorkDateFilter;
    },
    activeUserId() {
      if (this.$route.params.userId) {
        return Number(this.$route.params.userId);
      } else {
        return this.user.id;
      }
    },
    activeUser() {
      if (this.user.id !== this.activeUserId) {
        return (
          this.companyMembers &&
          this.companyMembers.find((m) => m.id === this.activeUserId)
        );
      }
      return this.user;
    },
  },
};
</script>
<style lang="scss" scoped>
.date-filter {
  width: 135px;
  height: 29px;
  background-color: #e9ecf2;
  border-radius: 3px;
  .filter-label {
    font-size: 12px;
    font-weight: 600;
    color: #677484;
  }
}
.new-task-btn {
  width: 104px;
  height: 29px;
  border-radius: 5px;
  font-size: 12px !important;
}
.tab-content {
  display: flex;
  align-items: center;
  .q-btn {
    margin-left: 5px;
  }
}
.tab-active {
  background: #dbecff !important;
  color: #007aff !important;
  .q-icon {
    color: #007aff !important;
  }
}
.taskView-tabs .q-tab:hover {
  color: #007aff !important;
}
.all-mem {
  margin-right: 10px;
}
.back-to-team {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(219, 236, 255) !important;
  color: rgb(0, 122, 255) !important;
  border-radius: 50%;
}
</style>
