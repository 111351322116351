<template>
  <div class="field-width">
    <form v-on:submit.prevent="$v.$invalid ? null : submit()">
      <div class="forgot-password-title q-mb-xs app-text-normal">
        Forgot password
      </div>
      <div class="forgot-password-description app-text-normal">
        Type your e-mail to reset your password.
      </div>
      <div class="input-label app-text-normal">Email</div>
      <q-input
        autofocus
        ref="focus"
        type="text"
        v-model="email"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
        :error="fieldErrors('email').length > 0"
        bottom-slots
        no-error-icon
        dense
        outlined
        class="app-text-normal"
      >
        <template v-slot:error>
          <div class="text-negative">
            {{ fieldErrors("email").length > 0 ? fieldErrors("email")[0] : "" }}
          </div>
        </template>
      </q-input>
      <div class="text-negative" v-if="errorMessage.flag">
        {{ errorMessage.text }}
      </div>
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        label="Submit"
        padding="0"
        class="signin-btn-class app-text-normal"
        :loading="loader"
        :disabled="$v.$invalid || loader"
      />
    </form>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import errorHandler from "@/utils/ErrorHandler";
import { SendPasswordResetEmail } from "@/gql";

export default {
  name: "Step2",
  props: ["companyUri"],
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
  },
  data() {
    return {
      loader: false,
      email: null,
      forgotPasswordImage:
        "https://s3-us-west-1.amazonaws.com/vabotu/default/sign_in_lock_icon.svg",
      errorMessage: {
        flag: false,
        text: null,
      },
    };
  },
  methods: {
    async submit() {
      this.loader = true;
      const variables = {
        email: this.email,
        company_uri: this.companyUri,
      };
      this.$api
        .mutate({
          mutation: SendPasswordResetEmail,
          variables,
        })
        .then((res) => {
          if (res.data.sendPasswordResetEmail) {
            this.loader = false;
            this.$emit("next", { email: this.email });
          } else {
            if (res.errors[0].message === "UserNotFoundError") {
              this.loader = false;

              this.errorMessage = {
                flag: true,
                text: "Email not registered with this team",
              };
            }
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = {
            flag: true,
            text: errorHandler.getErrorMessage(
              error,
              this,
              "message.UserNotFoundError"
            ),
          };
        });
    },
    resetErrorMessage() {
      this.errorMessage = {
        flag: false,
        text: null,
      };
    },
  },
};
</script>
