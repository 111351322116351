<template>
  <div class="row q-py-lg q-px-md" style="flex-wrap:nowrap">
    <!-- Right Column -->
    <div class="column">
      <!-- Payment Method -->
      <div class="administration">
        <div class="row">
          <div class="col q-px-md">
            <div class="payment-method">
              <div class="a-title q-mb-md">Billing</div>
              <div
                class="details q-px-md q-py-md"
                v-if="
                  currentCompany.subscription.base_plan === 'v4_free' ||
                    currentCompany.subscription.base_plan === 'v3_free'
                "
              >
                <q-btn
                  @click="upgradePlan.flag = true"
                  label="Upgrade plan"
                  color="primary"
                  class="payment-btn"
                  no-caps
                />
              </div>
              <div
                class="details rounded-borders bg-grey q-px-md q-py-md"
                v-else-if="!paymentMethod.flag && stripecard"
              >
                <div class="name">
                  {{ stripecard.name | capitalize }}
                </div>
                <div class="card q-mb-sm">
                  <span>xxxx-xxxx-xxxx-</span>
                  <span>{{ stripecard.last4 }}</span>
                </div>
                <q-btn
                  @click="paymentMethod.flag = true"
                  label="Update payment"
                  color="primary"
                  class="payment-btn"
                  no-caps
                />
              </div>
              <div
                class="details rounded-borders bg-grey q-px-md q-py-md"
                v-if="paymentMethod.flag"
              >
                <add-new-pay-card
                  v-if="paymentMethod.flag"
                  :paycard="paycard"
                  @replaceCard="replaceCardHandler"
                  :loader="loader"
                  :invalidCodeMessage="invalidCodeMessage"
                  @closeCardDialog="closeCardDialogHandler"
                />
              </div>
            </div>
            <!-- <div class="payment-method q-mt-md">
              <div class="a-title q-mb-md">Coupon code</div>
              <div class="details rounded-borders bg-grey q-px-md q-py-md">
                <div class="row" v-if="currentCompany.subscription.couponcodetext == '' || showCoupenCodeSection">
                  <div class="flex-1">
                    <q-input
                      v-model="couponcode"
                      @keyup.delete="couponCodeDeleteEventHandler"
                      outlined
                      placeholder="Type coupon code here"
                      type="text"
                      dense
                      autofocus
                      no-error-icon
                      bg-color="white"
                      :class="[couponcodeMessage !== null ? 'no-details-input' : '']"
                    >
                    </q-input>
                  </div>
                  <div class="q-ml-sm flex-1">
                    <q-btn
                      color="primary"
                      dense
                      no-caps
                      label="Apply"
                      class="payment-btn"
                      :loading="loader"
                      :disabled="!couponcode"
                      @click.native="applyCoupon"
                    />
                  </div>
                </div>
                <div class="row" v-else style="align-items: center;">
                  <div class="flex-1" style="color: #000;font-weight: 500;">
                    {{currentCompany.subscription.couponcodetext}}
                  </div>
                  <div class="q-ml-sm flex-1">
                    <q-btn
                      color="primary"
                      dense
                      no-caps
                      label="New Coupon"
                      class="payment-btn"
                      @click.native="showCoupenCodeSection = true"
                    />
                  </div>
                </div>
                <p
                    v-if="couponcodeMessage"
                    class="mt-1"
                    :class="[
                      isValidCouponcode === true ? 'active--text' : 'pink--text',
                    ]"
                  >
                    {{ couponcodeMessage }}
                  </p>
              </div>
            </div> -->
            <div class="payment-method q-mt-md">
              <div class="a-title q-mb-md">
                Current plan:
                <span>{{ this.currentCompany.subscription.plan.name }}</span>
              </div>
              <div class="details rounded-borders bg-grey q-px-md q-py-md">
                <div class="description q-mb-sm">
                  <p class="a-mb-sm">
                    To downgrade or upgrade your plan, click the Change plan
                    button bellow
                  </p>
                </div>
                <q-btn
                  v-if="
                    currentCompany.subscription.base_plan === 'v4_free' ||
                      currentCompany.subscription.base_plan === 'v3_free'
                  "
                  @click="upgradePlan.flag = true"
                  label="Upgrade plan"
                  color="primary"
                  class="payment-btn"
                  no-caps
                />
                <q-btn
                  v-else
                  color="primary"
                  class="payment-btn"
                  label="Change plan"
                  no-caps
                  @click.native="openUpgradePlan"
                />
              </div>
            </div>

            <div class="payment-method q-mt-md">
              <div class="a-title q-mb-md">
                {{
                  this.currentCompany.subscription.base_plan.includes("monthly")
                    ? "Monthly"
                    : "Annualy"
                }}
                team plan
              </div>
              <div class="details rounded-borders bg-grey q-px-md q-py-md">
                <div class="description q-mb-sm">
                  <p class="a-mb-sm" v-html="teamPlanSection()"></p>
                </div>
              </div>
            </div>

            <div class="payment-method q-mt-md">
              <div class="a-title q-mb-md">Account deletion</div>
              <div class="details rounded-borders bg-grey q-px-md q-py-md">
                <div class="description q-mb-sm">
                  <p class="a-mb-sm">
                    Deleting your account will delete all your data. Once you
                    delete your account, you cannot undo this.
                  </p>
                  <p>
                    Deleting your account will stop the payments only once your
                    billing cycle ends. If you have questions please write to us
                    at care@heycollab.com
                  </p>
                </div>
                <q-btn-toggle
                  class="q-pa-none"
                  color="transparent"
                  size="md"
                  dense
                  v-if="closeAccount"
                  v-model="toggle_multiple"
                  :options="[
                    { value: 'one', slot: 'one' },
                    { value: 'two', slot: 'two' },
                  ]"
                >
                  <template v-slot:one>
                    <q-btn
                      dense
                      no-caps
                      color="red-9"
                      text-color="white"
                      size="15px"
                      class="q-px-sm"
                      style="margin-right:-10px"
                      @click.stop="$emit('closeAccount')"
                      label="Sure?"
                    />
                  </template>
                  <template v-slot:two>
                    <q-btn
                      dense
                      no-caps
                      size="15px"
                      color="grey-10"
                      text-color="white"
                      class="q-px-sm"
                      @click.stop="cancelAccountConfirmOne"
                      label="Cancel"
                    />
                  </template>
                </q-btn-toggle>
                <q-btn
                  v-else
                  color="primary"
                  class="text-red"
                  flat
                  label="Delete your account"
                  @click.native="closeAccountConfirmOne"
                />
              </div>
            </div>
          </div>
          <div class="col q-px-md">
            <div class="payment-method" style="height:100%;">
              <div class="a-title q-mb-md">Invoice history</div>
              <div
                class="details rounded-borders bg-grey q-px-md q-py-md scroll-y"
                style="max-height:95%;height:95%;flex-wrap: nowrap;"
              >
                <div
                  class="row"
                  v-for="(invoice, i) in invoicesList"
                  :key="`invoice-${i}`"
                  style="width:440px;color: #677484;"
                >
                  <div class="flex" style="width:160px;">
                    {{ invoice.period_end | billdate }}
                  </div>
                  <div class="flex-1" style="width:140px;">
                    ${{ (invoice.total / 100) | currencyFormat }}
                  </div>
                  <div class="flex-1" style="width:140px;">
                    <span
                      @click="downloadFile(invoice.id)"
                      class="text-underline an-15 regular-text blue-text cursor-pointer"
                      >PDF</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="!payCardLoading">
          <div class="pa-3" v-if="stripecard">
            <div class="an-14 regular-text nolabel--text pb-3">
              Payment Method
            </div>
            <div v-if="stripecard.name">
              <span class="an-15 medium-text">
                {{ stripecard.name | capitalize }}
              </span>
            </div>
            <div class="mt-1 an-14 regular-text">
              <span>xxxx-xxxx-xxxx-</span>
              <span>{{ stripecard.last4 }}</span>
            </div>
            <div>
              <q-btn
                @click.native="$emit('changecard')"
                label="Upadate Payment"
                color="blue-grey-13"
                no-caps
              />
            </div>
          </div>
          <div class="pa-3" v-else>
            <div class="an-14 regular-text nolabel--text pb-3">
              Payment Method
            </div>
            <div>
              <q-btn
                color="blue-grey-13"
                no-caps
                label="Add Payment Card"
                @click.native="$emit('registercard')"
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="row justify-center q-pt-md" v-else>
          <q-circular-progress
            indeterminate
            size="50px"
            :thickness="0.15"
            color="primary"
          />
        </div> -->
        <!-- <div class="pa-3">
          <div class="an-14 regular-text nolabel--text pb-3">Coupon Code</div>
          <div class="row">
            <q-input
              v-model="couponcode"
              style="min-width: 250px"
              @keyup.delete="couponCodeDeleteEventHandler"
              autofocus
              outlined
              :class="[couponcodeMessage !== null ? 'no-details-input' : '']"
            />
            <q-btn
              :icon="$icons.matCheck"
              text-color="white"
              color="blue-grey-13"
              size="md"
              class="q-py-sm"
              @click="applyCoupon"
            >
              <q-tooltip>
                Apply Coupen
              </q-tooltip>
            </q-btn>
          </div>
          <p
            v-if="couponcodeMessage"
            class="mt-1"
            :class="[
              isValidCouponcode === true ? 'active--text' : 'pink--text',
            ]"
          >
            {{ couponcodeMessage }}
          </p>
        </div> -->
      </div>

      <!-- Billing Info -->
      <!-- <div class="rounded-borders bg-blue-grey-1 mt-1" v-if="stripecard">
        <div class="pa-3">
          <div class="an-14 regular-text nolabel--text pb-3">Billing Info</div>
          <div>
            <span>{{ address.street_one }},</span>
            <br />
            <span
              v-if="address.street_two !== '' && address.street_two !== null"
            >
              {{ address.street_two }},
              <br />
            </span>
            <span>{{ address.city }},</span>
            <br />
            <span>{{ address.zipcode }}</span>
            <span class="ml-1">{{ address.state }}</span>
            <br />
            <span>{{ address.country }}</span>
            <br />
          </div>
          <q-btn
            color="blue-grey-13"
            no-caps
            label="Update"
            @click.native="$emit('changeaddress')"
          />
        </div>
      </div> -->

      <!-- Invoice History -->
      <!-- <div class="rounded-borders bg-blue-grey-1 mt-1" v-if="stripecard">
        <div class="pa-3">
          <div class="an-14 regular-text nolabel--text pb-3">
            Invoice History
          </div>
          <div
            class="row"
            v-for="(invoice, i) in invoicesList"
            :key="`invoice-${i}`"
          >
            <div class="flex-1">{{ invoice.period_end | billdate }}</div>
            <div class="flex-1">
              ${{ (invoice.total / 100) | currencyFormat }}
            </div>
            <div class="flex-1">
              <span
                @click="downloadFile(invoice.id)"
                class="text-underline an-15 regular-text error--text cursor-pointer"
                >PDF</span
              >
            </div>
          </div>
        </div>
      </div> -->
      <!--  Account Cancellation -->
      <!-- <div class="rounded-borders bg-blue-grey-1 mt-1">
        <div class="pa-3">
          <div class="an-14 regular-text nolabel--text pb-3">
            Account Cancellation
          </div>
          <div class="subheading">
            Please note, canceling your account will delete all of the content
            created in Heycollab. This means all of your messages, images,
            boards and tasks will be removed. Once you cancel your account, you
            cannot undo this.
          </div>
          <div class="subheading mt-2">
            Canceling your account will also stop the monthly payments being
            deducted from your card.
          </div>
          <div class="subheading my-2">
            If you have any questions or concerns, please write to us at
            <a
              href="mailto:care@heycollab.com"
              target="_top"
              class="secondary--text"
              >care@heycollab.com</a
            >
          </div>
          <q-btn-toggle
            class="q-pa-none"
            color="transparent"
            size="md"
            dense
            v-if="closeAccount"
            v-model="toggle_multiple"
            :options="[
              { value: 'one', slot: 'one' },
              { value: 'two', slot: 'two' },
            ]"
          >
            <template v-slot:one>
              <q-btn
                dense
                no-caps
                color="red-9"
                text-color="white"
                size="15px"
                class="q-px-sm"
                style="margin-right:-10px"
                @click.stop="$emit('closeAccount')"
                label="Sure?"
              />
            </template>
            <template v-slot:two>
              <q-btn
                dense
                no-caps
                size="15px"
                color="grey-10"
                text-color="white"
                class="q-px-sm"
                @click.stop="cancelAccountConfirmOne"
                label="Cancel"
              />
            </template>
          </q-btn-toggle>
          <q-btn
            v-else
            color="red-6"
            no-caps
            label="Close Account"
            @click.native="closeAccountConfirmOne"
          />
        </div>
      </div> -->
    </div>
    <upgrade-plans-dialog
      v-if="upgradePlan.flag"
      v-model="upgradePlan.flag"
      :closeDialog="closeUpgradePlanDialog"
      :currentWorkspaceId="currentWorkspace.id"
      :currentCompany="currentCompany"
      :currentCompanyToken="company.accessToken"
    />
  </div>
</template>

<script>
import errorHandler from "@/utils/ErrorHandler";
import { getDownloadUrl } from "@/services/downloadS3File";
import { CheckCouponCodeMutation, ApplyCouponMutation } from "@/gql";
import AddNewPayCard from "@/components/AdministrationWizard/AddNewPayCard";
import UpgradePlansDialog from "@/components/Dialogs/UpgradePlansDialog";

import { CreateNewPayCardMutation } from "@/gql";

import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";

export default {
  name: "Administration",
  props: [
    "paycard",
    "invoices",
    "payCardLoading",
    "currentWorkspace",
    "company",
  ],
  data() {
    return {
      toggle_multiple: [0, 1],
      closeAccount: false,
      couponcode: "",
      isValidCouponcode: null,
      couponBtnColor: "greybtn",
      couponcodeMessage: null,
      paymentMethod: {
        flag: false,
      },
      loader: false,
      invalidCodeMessage: null,
      upgradePlan: {
        flag: false,
      },
      showCoupenCodeSection: false,
    };
  },
  api: {
    currentCompany: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  components: {
    AddNewPayCard,
    UpgradePlansDialog,
  },

  methods: {
    teamPlanSection() {
      let planPrice = this.currentCompany.subscription.plan.planPricing.filter(
        (c) => c.price_id === this.currentCompany.subscription.base_plan
      );
      planPrice = planPrice.length
        ? planPrice[0].amount * planPrice[0].validity
        : 0;
      const activeMember = this.currentCompany.members.filter(
        (member) =>
          member.status === "active" &&
          member.roles[0] &&
          member.roles[0].name !== "guest"
      );
      let allMemberPrice = planPrice * activeMember.length;
      const inActiveMember = this.currentCompany.members.filter(
        (member) => member.status !== "active"
      );
      const guestMember = this.currentCompany.members.filter(
        (member) =>
          member.roles[0] &&
          member.roles[0].name === "guest" &&
          member.status === "active"
      );
      return (
        "You have " +
        activeMember.length +
        " active members, " +
        inActiveMember.length +
        " inactive users and " +
        guestMember.length +
        " guest. Your plan will renew on <b>" +
        format(
          fromUnixTime(this.currentCompany.subscription.current_period_end),
          "MMMM do, yyyy"
        ) +
        "</b> for <b>$" +
        allMemberPrice +
        "</b> ($" +
        planPrice +
        "/member)."
      );
    },
    openUpgradePlan() {
      // if(this.currentCompany.subscription.is_downgrade_plan) {
      //   this.$q.notify({
      //       color: "negative",
      //       position: "top-right",
      //       message: this.$t("message.planDowngraded"),
      //       icon: this.$icons.matReportProblem,
      //     });
      // } else {
      this.upgradePlan.flag = true;
      //}
    },
    closeUpgradePlanDialog() {
      this.upgradePlan.flag = false;
    },
    async replaceCardHandler(token) {
      if (token) {
        this.loader = true;
        this.invalidCodeMessage = null;
        try {
          const response = await this.$api.mutate({
            mutation: CreateNewPayCardMutation,
            variables: {
              token,
            },
          });
          if (
            response.data.createNewPayCard &&
            response.data.createNewPayCard.error === null
          ) {
            this.loader = false;
            this.paymentMethod.flag = false;
            this.$q.notify({
              classes: "success-notification",
              position: "top-right",
              message: "Payment method updated successfully.",
              icon: this.$icons.matAnnouncement,
            });
          } else {
            this.invalidCodeMessage = response.data.createNewPayCard.error;
            this.loader = false;
          }
        } catch (error) {
          this.invalidCodeMessage = errorHandler.getErrorMessage(
            error,
            this,
            "message.CardDeclined"
          );
          this.loader = false;
        }
      }
    },
    closeCardDialogHandler() {
      this.paymentMethod.flag = false;
    },
    closeAccountConfirmOne() {
      this.toggle_multiple = [0, 1];
      this.closeAccount = true;
    },
    cancelAccountConfirmOne() {
      this.closeAccount = false;
      this.toggle_multiple = [0, 1];
    },
    async checkCouponCode() {
      if (this.couponcode !== "") {
        const variables = {
          coupon_code: this.couponcode,
        };
        try {
          const response = await this.$api.mutate({
            mutation: CheckCouponCodeMutation,
            variables,
          });
          if (response) {
            this.isValidCouponcode = true;
            this.couponcodeMessage = `Congrats! ${response.data.verifyCouponCode}`;
          }
        } catch (error) {
          this.isValidCouponcode = false;
          this.couponcodeMessage = this.$t(
            "message.validation.invalidCouponCode"
          );
          this.couponcode = "";
        }
      }
    },
    couponCodeDeleteEventHandler() {
      this.isValidCouponcode = null;
      this.couponcodeMessage = null;
    },
    async applyCoupon() {
      if (this.couponcode !== "") {
        const variables = {
          coupon_code: this.couponcode,
        };
        try {
          const response = await this.$api.mutate({
            mutation: ApplyCouponMutation,
            variables,
          });
          if (response) {
            this.isValidCouponcode = false;
            if (response.data.applyCoupon) {
              this.couponcode = "";
              this.couponcodeMessage = `Congrats! ${response.data.applyCoupon}`;
              this.currentCompany.subscription.couponcodetext =
                response.data.applyCoupon;
              this.showCoupenCodeSection = false;
            } else {
              this.couponcodeMessage = this.$t(
                "message.validation.invalidCouponCode"
              );
            }
          }
        } catch (error) {
          this.isValidCouponcode = false;
          this.couponcodeMessage = errorHandler.getErrorMessage(
            error,
            this,
            this.$t("message.validation.invalidCouponCode")
          );
        }
      }
    },
    async downloadFile(id) {
      const response = await getDownloadUrl(id, this.$api, "invoice");
      if (response !== null) {
        window.location = response;
      }
    },
  },
  computed: {
    payCardData() {
      return this.paycard;
    },
    stripecard() {
      return this.payCardData ? this.payCardData.stripecard : null;
    },
    address() {
      return this.payCardData ? this.payCardData.address : null;
    },
    invoicesList() {
      return this.invoices;
    },
  },
  watch: {
    couponcode(to) {
      if (to !== "") {
        this.couponBtnColor = "active";
      } else {
        this.couponBtnColor = "greybtn";
      }
    },
  },
};
</script>
