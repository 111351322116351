<template>
  <div class="overflow-auto">
    <div style="max-height: calc(100vh - 110px);" class=" mytasks">
      <div
        class="walkthrough walkthrough-small"
        v-if="user.onboarding.is_private_tasks"
      >
        <div class="icon">
          <q-icon :name="$icons.fasTasks" size="24px" style="color: #007aff" />
        </div>
        <div class="content">
          <p class="bold">Each workspace has a My tasks section</p>
          <p>
            Tasks created here are only visible to you and will not appear on
            the task-board.
          </p>
          <div class="video" @click="videoPlayer">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="55px"
            />
          </div>
        </div>
        <div class="close" @click="closePrivateTaskskWalkthroughBanner">
          <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
        </div>
      </div>
      <template>
        <private-list
          :key="reRenderCount"
          :board="board"
          :cardTasks="privateCardTasks"
          :currentWorkspaceId="currentWorkspaceId"
          :boardCards="boardCards"
          :currentWorkspace="currentWorkspace"
          :isWorkspaceOwner="isWorkspaceOwner"
          :imageList="imageList"
          :workspaceMembers="workspaceMembers"
          :reactions="reactions"
          :taskLabels="taskLabels"
          :user="user"
          @reRender="reRenderCount++"
        />
      </template>
      <template v-if="myTasksList && myTasksList.length">
        <template v-for="card in boardCards">
          <public-list
            :key="card.id"
            v-if="publicCardTasks[card.id]"
            :card="card"
            :board="board"
            :cardTasks="publicCardTasks[card.id]"
            :currentWorkspaceId="currentWorkspaceId"
            :currentWorkspace="currentWorkspace"
            :isWorkspaceOwner="isWorkspaceOwner"
            :imageList="imageList"
            :workspaceMembers="workspaceMembers"
            :reactions="reactions"
            :taskLabels="taskLabels"
            :user="user"
            @reRender="reRenderCount++"
          />
        </template>
      </template>
    </div>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";

import PublicList from "@/views/WorkspaceView/InfoSidebar/MyTasks/PublicList";
import PrivateList from "@/views/WorkspaceView/InfoSidebar/MyTasks/PrivateList";
import { UpdateUserOnboardingMutation } from "@/gql";
import VideoDialog from "@/components/VuePlayer/VideoDialog.vue";

export default {
  name: "MyTasks",
  api: {
    board: {
      defaultValue: [],
      cacheKey() {
        return `BoardQuery:${this.currentWorkspace.boards[0].id}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    privateTasks: {
      cacheKey() {
        return `PrivateTasksQuery:${this.currentWorkspace.boards[0].id}`;
      },
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
  },
  components: {
    PublicList,
    PrivateList,
    VideoDialog,
  },
  data() {
    return {
      reRenderCount: 0,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  methods: {
    async closePrivateTaskskWalkthroughBanner() {
      this.user.onboarding.is_private_tasks = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_private_tasks",
          flag_value: false,
        },
      });
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/569212052",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    boardCards: {
      get() {
        if (this.board)
          return orderBy(uniqBy(this.board.cards, "id"), ["sequence"], ["asc"]);
        return null;
      },
    },
    myTasksList() {
      if (this.board) {
        const list =
          this.board.tasks &&
          this.board.tasks.filter((o) =>
            o.members.map((m) => m.id).includes(this.user.id)
          );
        return uniqBy(list, "id");
      }
      return null;
    },
    publicTasks() {
      return this.myTasksList.filter((t) => t.card_id);
    },
    privateTasks() {
      return this.privateTasks;
    },
    publicCardTasks: {
      get() {
        return groupBy(orderBy(this.publicTasks, ["sequence"]), "card_id");
      },
    },
    privateCardTasks: {
      get() {
        return orderBy(this.privateTasks, ["sequence"]);
      },
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      } else {
        return [];
      }
    },
  },
};
</script>
