<template>
  <div
    class="group-hover relative-position q-mb-sm notification-wrapper q-py-sm"
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <q-menu context-menu content-class="overflow-auto" auto-close>
      <q-list>
        <q-item clickable v-if="!notification.read" @click="updateNotification()">
          <q-item-section> Mark read </q-item-section>
          <!-- <q-item-section v-else> Mark unread </q-item-section> -->
        </q-item>
        <q-item
          @click="
            $copyTextToClipboard(copyTaskLink);
            isTaskCopy = true;
          "
          clickable
        >
          <q-item-section>Copy task link</q-item-section>
        </q-item>
        <q-item @click="edit(notificationTask)" clickable>
          <q-item-section> Edit Task </q-item-section>
        </q-item>
        <q-item clickable @click="changeCompleteStatus">
          <q-item-section v-if="!notificationTask.completed">
            Complete Task
          </q-item-section>
          <q-item-section v-else> Mark as Incomplete </q-item-section>
        </q-item>
        <!-- <q-item clickable @click="updateNotification()">
        <q-item-section v-if="!notification.read">
          Mark read
        </q-item-section>
        <q-item-section v-else>
          Mark unread
        </q-item-section>
      </q-item> -->
        <q-item clickable @click="routeTo('BoardView', actionScope.id)">
          <q-item-section> Go to workspace </q-item-section>
        </q-item>
        <q-item clickable @click="deleteNotification">
          <q-item-section class="text-red">
            Delete Notification
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <div class="row flex-no-wrap q-pl-md">
      <div class="q-mr-md">
        <div
          @click="edit(actionObject.task)"
          class="task-comment-notification-icon row justify-center"
        >
          <q-icon
            :name="$icons.mdiCommentCheck"
            color="white"
            class="self-center"
            size="20px"
          />
          <q-tooltip> Task: {{ actionObject.task.title }} </q-tooltip>
        </div>
      </div>
      <div class="row" style="width: 100%">
        <div class="row justify-between" style="width: 100%">
          <div class="cursor-pointer" @click="showUserInfo(actionSubject)">
            <span
              style="font-size: 15px"
              class="text-subtitle2 text-blue-grey-10"
            >
              {{ actionTakenByFullName }}
            </span>
          </div>
          <div class="row items-center q-mr-md">
            <div
              class="an-13 regular-text thread-icon q-mr-sm"
              style="color: #15d89a"
              v-if="actionObject.is_replied"
            >
              Replied
            </div>
            <div v-if="actionObject.task.isbookmarked">
              <q-icon
                @click="removeTaskBookmark()"
                size="xs"
                class="cursor-pointer"
                color="pink"
                :name="$icons.matBookmark"
              >
                <q-tooltip> Remove from Saved items </q-tooltip>
              </q-icon>
            </div>
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md"
            style="right: 0"
          >
            <q-btn
              flat
              :icon="$icons.matSentimentSatisfied"
              size="10px"
              class="thread-icon"
              rounded
              dense
            >
              <q-menu
                content-class="overflow-auto apply-width"
                auto-close
                anchor="bottom middle"
                self="top middle"
              >
                <div class="card react-emoji-card">
                  <img
                    v-for="(emoji, index) in reactions"
                    :key="index"
                    :src="'/static/react-icons/' + emoji.icon"
                    alt="ANGRY"
                    @click="
                      reactOnPost({
                        comment: actionObject,
                        reaction: emoji,
                        fromNotification: true,
                        noticationId: notification.id,
                      })
                    "
                    class="react-emoji-icon q-mr-sm cursor-pointer"
                  />
                </div>
              </q-menu>
            </q-btn>
            <!-- <q-btn
              v-if="notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark unread
              </q-tooltip>
            </q-btn>
            <q-btn
              v-else
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark read
              </q-tooltip>
            </q-btn> -->
            <div
              v-if="actionObject.task.isbookmarked"
              @click="removeTaskBookmark()"
              class="cursor-pointer"
            >
              <q-icon size="xs" color="pink" :name="$icons.matBookmark">
                <q-tooltip> Remove from Saved items </q-tooltip>
              </q-icon>
            </div>
            <div
              v-if="!actionObject.task.isbookmarked"
              @click="addTaskBookmark()"
              class="cursor-pointer"
            >
              <q-icon size="xs" :name="$icons.matBookmarkBorder">
                <q-tooltip> Add to Saved items </q-tooltip>
              </q-icon>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <div
            v-if="notificationContent.length >= 165 && readMoreToggle === false"
            v-html="trucateContet(notificationContent)"
            class="q-mr-md notification-content dont-break-out"
          ></div>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
            v-html="notificationContent"
            class="q-mr-md notification-content dont-break-out"
          ></div>
          <span
            class="text-primary cursor-pointer"
            @click="readMoreToggle = true"
            v-show="
              notificationContent.length >= 165 && readMoreToggle === false
            "
          >
            read more
          </span>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
          >
            <div
              v-if="
                (actionObject.media && actionObject.media.length) ||
                (actionObject.mediafolder && actionObject.mediafolder.length)
              "
              class="q-mb-md"
            >
              <sub-task-media
                v-if="actionObject.media"
                type="taskComment"
                :postId="actionObject.id"
                :comment="actionObject"
                :attachments="actionObject.media"
                :canRemoveMedia="
                  canRemoveMessageFromWorkspace ||
                  (user && user.id === actionSubject.id)
                "
                :forceDisableRemove="
                  actionObject.comment === '' && actionObject.media.length <= 1
                "
                :commentId="actionObject.id"
                :labels="labels"
                :user="user"
                :workspace="notificationWorksape"
                :isWorkspaceOwner="isWorkspaceOwner"
                :removeMedia="removeMedia"
                :height="88"
                :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                :openFolder="openFolder"
                :deleteFolderHandler="deleteFolderHandler"
                :editDoc="editDoc"
                @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
                @deleteCommentPoint="
                  (data) => $emit('deleteCommentPoint', data)
                "
                @sendComment="(data) => $emit('sendComment', data)"
                @updatepositions="(data) => $emit('updatepositions', data)"
                @updateMediaCommentPointLabel="
                  (data) => $emit('updateMediaCommentPointLabel', data)
                "
                @deleteMediaComment="
                  (data) => $emit('deleteMediaComment', data)
                "
                @updateComment="(data) => $emit('updateComment', data)"
              />
            </div>
            <!-- Forwarded Post -->
            <div
              class="reply-comment q-pa-md q-mt-xs q-mr-xs"
              :style="
                !Array.isArray(actionObject.commentreactiondata) &&
                Object.keys(actionObject.commentreactiondata).length !== 0 &&
                actionObject.commentreactiondata.constructor === Object
                  ? { 'margin-bottom': '15px' }
                  : { 'margin-bottom': '15px' }
              "
              v-if="ForwardComment"
            >
              <div class="flex-x">
                <div
                  class="flex-1 q-mt-xs"
                  :class="[ForwardComment.media.length === 0 && 'q-mb-md']"
                >
                  <div class="messaging-user-detail-chip">
                    <span
                      @click="showUserInfo(ForwardComment.owner)"
                      class="user-name"
                    >
                      {{ ForwardComment.owner.first }}
                    </span>
                    <span class="time">
                      <span
                        v-if="
                          ForwardComment.updated_at > ForwardComment.created_at
                        "
                      >
                        {{ ForwardComment.created_at | gettime }}
                        <span class="capitalize-text an-12">(Edited)</span>
                      </span>
                      <span v-else>
                        {{ ForwardComment.created_at | gettime }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-html="ForwardCommentContent"
                    class="post-content dont-break-out editor-prototype mt-1"
                  ></div>
                </div>
              </div>
              <div
                v-if="ForwardComment.media.length > 0"
                style="margin-bottom: 20px"
              >
                <sub-task-media
                  v-if="ForwardComment.media"
                  type="taskComment"
                  :postId="ForwardComment.id"
                  :attachments="ForwardComment.media"
                  :canRemoveMedia="
                    canRemoveMessageFromWorkspace ||
                    (user && user.id === ForwardComment.owner.id)
                  "
                  :forceDisableRemove="
                    ForwardComment.comment === '' &&
                    ForwardComment.media.length <= 1
                  "
                  :commentId="ForwardComment.id"
                  :labels="labels"
                  :user="user"
                  :workspace="notificationWorksape"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :removeMedia="removeMedia"
                  :height="90"
                  :editDoc="editDoc"
                  :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                  @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                  @clickonmoreoption="
                    (data) => $emit('clickonmoreoption', data)
                  "
                  @deleteCommentPoint="
                    (data) => $emit('deleteCommentPoint', data)
                  "
                  @sendComment="(data) => $emit('sendComment', data)"
                  @updatepositions="(data) => $emit('updatepositions', data)"
                  @updateMediaCommentPointLabel="
                    (data) => $emit('updateMediaCommentPointLabel', data)
                  "
                  @deleteMediaComment="
                    (data) => $emit('deleteMediaComment', data)
                  "
                  @updateComment="(data) => $emit('updateComment', data)"
                />
              </div>
            </div>
          </div>
          <!-- Reactions List badges -->
          <div
            class="q-mb-sm"
            v-if="
              !Array.isArray(actionObject.commentreactiondata) &&
              Object.keys(actionObject.commentreactiondata).length !== 0 &&
              actionObject.commentreactiondata.constructor === Object &&
              !isNotificationVisible
            "
          >
            <div class="row inline">
              <template
                v-for="(
                  reactiondata, index
                ) in actionObject.commentreactiondata"
              >
                <div :key="index" class="q-ml-xs">
                  <div class="relative-position reaction-icon row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                      @click="
                        reactOnPost({
                          comment: actionObject,
                          reaction: index,
                          fromNotification: true,
                          notication: notication,
                        })
                      "
                    />
                    <div class="text-black" style="font-size: 12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height: 30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center">
                            <div class="q-mr-sm">
                              <q-avatar
                                color="blue-grey-11"
                                text-color="grey-10"
                                size="26px"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <!-- <span
                                  class="text-avatar-style"
                                  style="font-size: 9px"
                                  v-else
                                >
                                  {{ item.first | firstChar
                                  }}{{ item.last | firstChar }}
                                </span> -->
                                <avatar v-else :size="26" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="time" style="font-size: 12px">
          {{ dateTimeFormat() }}
          <span
            class="text-primary cursor-pointer"
            style="font-size: 16px"
            @click="readMoreToggle = false"
            v-if="readMoreToggle"
          >
            read less
          </span>
        </div>
      </div>
    </div>

    <div v-if="delete_media.flag">
      <confirm-dialog
        v-model="delete_media.flag"
        title="Delete File ?"
        question="Are you sure you want to detach the media?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
        :loading="false"
      />
    </div>
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="actionScope.id"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="actionScope.id"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="folderList"
      :authToken="currentCompany.accessToken"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false, 'task_comment_id')"
      :successMethod="() => deleteFolderDialogHandler(true, 'task_comment_id')"
      :loading="deleteFolder.loader"
    />
  </div>
</template>
<script>
import { compile } from "@/components/Editor/MarkupUtils";
//import ReplyEditor from "@/components/Editor/ReplyEditor";
// import TaskDialog from "@/views/BoardView/TaskDialog";
import SubTaskMedia from "@/components/Miscellaneous/SubTaskMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";

import NotificationNavMixin from "@/mixins/NotificationNavMixin";
import UserScope from "@/mixins/UserScope";
// import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
// import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import NotificationActionsMixin from "@/mixins/NotificationActionsMixin";
import Avatar from 'vue-avatar';

import {
  DetachCommentMediaMutation,
  UpdateTaskMutation,
  CreateTaskCommentMutation,
  AddTaskBookmarkMutation,
  RemoveTaskBookmarkMutation,
  UpdateNotificationsMutation,
  DeleteNotificationsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
export default {
  name: "MentionedInTaskCommentNotification",
  mixins: [
    NotificationNavMixin,
    // BoardsMethodMixin,
    UserScope,
    // MediaMethodMixin,
    NotificationActionsMixin,
  ],
  props: [
    "notification",
    "reactions",
    "mentions",
    "labels",
    "isWorkspaceOwner",
    "showUserInfo",
    "setActiveId",
    "activeId",
    "isNotificationVisible",
    "activeTab",
    "closeReplyEditorHandler",
    "editDoc",
    "reactOnPost"
  ],
  api: {
    media: {
      defaultValue: null,
      cacheKey() {
        return `MediaQuery:${this.notificationWorksape}`;
      },
    },
  },
  components: {
    //ReplyEditor,
    // TaskDialog,
    SubTaskMedia,
    ConfirmDialog,
    PostCopyMoveFileFolder,
    FolderDetailsDialog,
    //SubTaskDialog,
    Avatar,
  },
  data() {
    return {
      replyCommentData: null,
      delete_media: {
        id: null,
        post: null,
        flag: null,
        type: null,
      },
      readMoreToggle: false,
    };
  },
  mounted() {
    this.preloadImage();
  },
  methods: {
    preloadImage() {
      this.reactions.forEach((emoji) => {
        var img = new Image();
        img.src = "/static/react-icons/" + emoji.icon;
      });
    },
    removeMedia(postId, mediaId, type) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
      this.delete_media.type = type;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        if (this.delete_media.type === "taskComment") {
          const variables = {
            task_comment_id: this.delete_media.post,
            media: [this.delete_media.id],
          };

          await this.$api.mutate({
            mutation: DetachCommentMediaMutation,
            variables,
          });
        }
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    async updateReplyLabel() {
      this.notification.action.object.is_replied = true;
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async changeCompleteStatus() {
      const variables = {
        id: this.notificationTask.id,
        completed: !this.notificationTask.completed,
      };

      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        mixpanel.track("Task Update");
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteNotification() {
      const variables = {
        notification_ids: [this.notification.id],
      };
      try {
        const query = this.$api.getQuery("NotificationsQuery");
        query.data.notifications = query.data.notifications.filter(
          (n) => n.id !== this.notification.id
        );
        await this.$api.mutate({
          mutation: DeleteNotificationsMutation,
          variables,
        });
        mixpanel.track("Notification Delete");
      } catch (error) {
        throw new Error(error);
      }
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    async createShareComment(comment) {
      const variables = {
        task_id: this.actionObject.task_id,
        workspace_id: this.notificationWorksape,
        comment: comment.text,
        task_comment_id: this.actionObject.id,
        tagged_user_ids: comment.tagged_user_ids,
        media: comment.media,
        media_urls: comment.media_urls,
      };
      if (!this.notification.read) {
        this.updateNotification();
      }
      this.updateReplyLabel();
      const response = await this.$api.mutate({
        mutation: CreateTaskCommentMutation,
        variables,
      });
      mixpanel.track("Task Comment Create", { reply: true });
      const taskEntity = this.$api.getEntity("task", this.actionObject.task_id);
      taskEntity.comments.push(response.data.createTaskComment);
    },
    async replyComment(comment) {
      this.setActiveId(this.notification.id);
      this.replyCommentData = comment;
    },
    async addTaskBookmark() {
      try {
        const variables = {
          task_id: this.actionObject.task_id,
        };

        const task = this.$api.getEntity("task", this.actionObject.task_id);
        task.isbookmarked = !task.isbookmarked;

        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Add");
        this.$mixpanelEvent("save-task", {});
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    async removeTaskBookmark() {
      try {
        const variables = {
          task_id: this.actionObject.task_id,
        };
        const task = this.$api.getEntity("task", this.actionObject.task_id);
        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
  },
  computed: {
    copyTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.currentCompany.companyUri}/workspaces/${this.actionScope.id}/board?task=${this.notificationTask.id}&workspace=${this.actionScope.id}`;
      return taskLink;
    },
    workspaceMembers() {
      const workspace = this.$api.getEntity("workspace", this.actionScope.id);
      return workspace.users;
    },
    ForwardComment() {
      return this.actionObject && this.actionObject.sharedTaskComment;
    },
    ForwardCommentContent() {
      return compile(
        this.actionObject && this.actionObject.sharedTaskComment.comment
      );
    },
    notificationTask() {
      return this.actionObject.task;
    },
    currentBoardId() {
      if (this.actionScope) {
        return this.actionScope.boards[0].id;
      }
      return null;
    },
    board() {
      return this.$api.getEntity("board", this.currentBoardId);
    },
    notificationWorksape() {
      return this.actionScope.id;
    },
    notificationContent() {
      return compile(this.actionObject.comment);
    },
    reactionData() {
      return Array.isArray(this.actionObject.commentreactiondata)
        ? null
        : this.actionObject.commentreactiondata;
    },
    taskOwner() {
      return this.actionObject.task.owner;
    },
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
  },
};
</script>
