<template>
	<div v-if="cardTasks" style="margin-top:23px">
		<div class="q-mb-sm row justify-between items-center q-px-md">
			<div class="row flex-no-wrap full-width justify-between">
				<div class="row  flex-no-wrap">
					<div @click="changeExpansionState" class="cursor-pointer">
						<q-icon
							:name="$icons.matKeyboardArrowDown"
							size="17px"
							:class="[isCardExpanded && 'right q-mt-xs', 'rotate ']"
						/>
					</div>
					<div>
						<q-icon
							style="margin-top:3px"
							class="q-mr-sm"
							:name="$icons.matDashboard"
							size="24px"
						/>
					</div>
					<div
						@click="redirectToBoard"
						class="text-bold cursor-pointer"
						style="font-size:17px"
					>
						{{ card.title }}
						<q-tooltip>
							Public tasks
						</q-tooltip>
					</div>
					<div style="margin-left: 3px">
						<q-btn
							dense
							no-caps
							round
							flat
							size="sm"
							@click="isVisible2 = !isVisible2"
							v-if="canPostMessageFromWorkspace"
						>
							<q-icon size="16px" color="black" :name="$icons.matAdd" />
							<q-tooltip>
								New Task
							</q-tooltip>
						</q-btn>
					</div>
				</div>
				<div>
					<q-btn
						size="md"
						:icon="$icons.matMoreHoriz"
						flat
						round
						padding="1px"
						color="black"
						v-if="canPostMessageFromWorkspace"
					>
						<q-menu auto-close>
							<q-list>
								<q-item
									class="text-red "
									@click="clearCompletedTaskConfirmBoxHandler"
									clickable
								>
									<q-item-section>
										Clear complete tasks
									</q-item-section>
								</q-item>
								<q-item class="text-red " clickable>
									<q-item-section @click="deleteCardDeleteConfirmBoxHandler">
										Delete list
									</q-item-section>
								</q-item>
							</q-list>
						</q-menu>
					</q-btn>
				</div>
			</div>
		</div>
		<template v-if="filteredTodos">
			<div style="transition: height ease 5s" v-if="!isCardExpanded">
				<div
					v-sortable="{
						group: 'myTask',
						onAdd: onDragAddOrUpdate,
						onUpdate: onDragAddOrUpdate,
						onRemove: onDragRemove,
						emptyInsertThreshold: 100,
					}"
				>
					<q-input
						v-if="isVisible2"
						autofocus
						rounded
						input-class="new-task-input"
						input-style="width: 85%"
						v-model="newTask.title"
						standout="bg-blue-grey-1 elevation-0 "
						class="q-px-md q-mb-lg relative-position"
						placeholder="Type task name"
						@blur="handleBlur(newTask.title, 'top')"
						@keydown.enter.exact="
							createTask(newTask, 'top');
							$event.preventDefault();
						"
						dense
					>
						<div
							class="create-task-btn absolute"
							:class="[
								newTask.title && newTask.title.length > 0
									? 'active-bg'
									: 'disable-bg',
							]"
							@click="createTask(newTask, 'top')"
						>
							<q-icon
								size="sm"
								class="text-white"
								style="margin-top:3px"
								:name="$icons.mdiCheckBold"
							/>
						</div>
					</q-input>
					<div
						class="group-background-hover"
						style="transition: all ease 0.5s"
						v-for="task in filteredTodos"
						:key="task.id"
						:data-id="task.id"
					>
						<public-task
							:task="task"
							:board="board"
							:user="user"
							:workspace="currentWorkspace"
							:changeCompleteStatus="changeCompleteStatus"
							:edit="edit"
							:duplicateTask="duplicateTask"
							:hasNotification="hasNotification"
							:deleteTaskConfirmBoxHandler="deleteTaskConfirmBoxHandler"
							:addTaskBookmark="addTaskBookmark"
							:removeTaskBookmark="removeTaskBookmark"
						/>
					</div>
					<!-- <div class="relative-position">
          <q-icon size="20px" name="add" class="input-prepend-icon" />
          <input class="new-task-input" placeholder="New Task" autofocus />
        </div> -->
				</div>
			</div>
		</template>
		<div
			v-if="!isAddTaskVisible && !isCardExpanded && canPostMessageFromWorkspace"
			class="text-center q-mb-lg"
		>
			<q-btn dense no-caps @click="isAddTaskVisible = !isAddTaskVisible">
				<q-icon
					class="q-mr-sm"
					size="16px"
					color="black"
					:name="$icons.matAdd"
				/>
				<span class="text-black" style="font-weight:500;font-size:15px">
					Add Task
				</span>
			</q-btn>
		</div>
		<q-input
			v-if="isAddTaskVisible"
			input-class="new-task-input"
			input-style="width: 85%"
			autofocus
			rounded
			v-model="newTask.title"
			standout="bg-blue-grey-1 elevation-0 "
			class="q-px-md q-mb-lg relative-position"
			placeholder="Type task name"
			@blur="handleBlur(newTask.title, 'bottom')"
			@keydown.enter.exact="
				createTask(newTask, 'bottom');
				$event.preventDefault();
			"
			dense
		>
			<div
				class="create-task-btn absolute"
				:class="[
					newTask.title && newTask.title.length > 0
						? 'active-bg'
						: 'disable-bg',
				]"
				@click="createTask(newTask, 'bottom')"
			>
				<q-icon
					size="sm"
					class="text-white"
					style="margin-top:3px"
					:name="$icons.mdiCheckBold"
				/>
			</div>
		</q-input>
		<profile-dialog
			v-model="profileDialog.show"
			v-if="profileDialog.show"
			:recepient="userProfileDialog"
			:user="user"
			:currentWorkspaceId="currentWorkspaceId"
			@close="clearUserInfoDialog"
		></profile-dialog>
		<duplicate-task-dialog
			v-if="cloneTask.flag"
			v-model="cloneTask.flag"
			:task="taskdata"
			:closeDialog="closeCloneTaskDialog"
		/>
		<div v-if="confirmBox.taskRemove.id && confirmBox.taskRemove.flag">
			<confirm-dialog
				v-model="confirmBox.taskRemove.flag"
				title="Delete task?"
				question="Deleting this task will also delete all the sub-tasks and comments in it."
				cancleText="Cancel"
				successText="Delete"
				:cancleMethod="resetTaskRemoveConfirmbox"
				:successMethod="deleteTask"
				:loading="confirmBox.taskRemove.loader"
			/>
		</div>
		<change-list-confirm-dialog
			v-if="moveToPublicListConfirm.flag"
			v-model="moveToPublicListConfirm.flag"
			:title="'Move to' + card.title + ' Board list?'"
			message="Task will be visible in board section"
			:cancleMethod="cancelPublicListHandler"
			:successMethod="moveToPublicList"
			:loading="moveToPublicListConfirm.loader"
		/>
		<confirm-dialog
			v-if="clearCompletedTaskConfirm.flag"
			v-model="clearCompletedTaskConfirm.flag"
			title="Delete Completed Tasks?"
			question="Are you sure you want to delete all completed tasks?"
			cancleText="Cancel"
			successText="Delete"
			:cancleMethod="resetClearCompletedTaskConfirmBox"
			:successMethod="clearCompletedTaskFromColumn"
			:loading="false"
		/>
		<confirm-dialog
			v-if="cardRemoveConfirm.flag"
			v-model="cardRemoveConfirm.flag"
			title="Delete Column?"
			question="Deleting this column will also delete all the tasks in it."
			cancleText="Cancel"
			successText="Delete"
			:cancleMethod="resetCardDeleteConfirmBox"
			:successMethod="deleteCard"
			:loading="false"
		/>
		<video-dialog
			v-if="showVideoModel"
			v-model="showVideoModel"
			:resetVideoDialog="resetVideoDialog"
			:videoMediaData="videoMediaData"
			:displayPlayer="displayPlayer"
		></video-dialog>
		<q-separator style="background:#e3e8ed;" />
	</div>
</template>
<script>
const soundFile = require("@/assets/completeTaskSound.wav");

import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";
import last from "lodash/last";
import head from "lodash/head";

import ProfileDialog from "@/components/ProfileDialog";
// import TaskDialog from "@/views/BoardView/TaskDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ChangeListConfirmDialog from "./ChangeListConfirmDialog";
import PublicTask from "./PublicTask.vue";

import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";

import UserScope from "@/mixins/UserScope";

import {
	AddTaskBookmarkMutation,
	RemoveTaskBookmarkMutation,
	UpdateTaskMutation,
	UpdateNotificationsMutation,
	CreateTaskMutation,
	AddMemberToTaskMutation,
	DeleteCardMutation,
	BoardQuery,
	PrivateTasksQuery,
} from "@/gql";
import mixpanel from "@/mixpanel";

import DuplicateTaskDialog from "@/components/Dialogs/DuplicateTaskDialog.vue";
import VideoDialog from "@/components/VuePlayer/VideoDialog.vue";

export default {
	name: "PublicList",
	mixins: [BoardsMethodMixin, MediaMethodMixin, UserScope],
	props: [
		"currentWorkspace",
		"currentWorkspaceId",
		"board",
		"cardTasks",
		"isWorkspaceOwner",
		"imageList",
		"workspaceMembers",
		"reactions",
		"taskLabels",
		"card",
	],
	components: {
		ProfileDialog,
		// TaskDialog,
		ConfirmDialog,
		ChangeListConfirmDialog,
		PublicTask,
		DuplicateTaskDialog,
		VideoDialog,
	},
	data() {
		return {
			sound: null,
			profileDialog: {
				show: false,
				user: null,
			},
			taskDialog: false,
			task: null,
			isAddTaskVisible: false,
			isVisible2: false,
			movedTask: null,
			newTask: {
				title: null,
				html: null,
				details: null,
				labels: [],
				card_id: this.card.id,
			},
			confirmBox: {
				taskRemove: {
					flag: false,
					loader: false,
					id: null,
				},
			},
			moveToPublicListConfirm: {
				flag: false,
				loader: false,
			},
			clearCompletedTaskConfirm: {
				flag: false,
				id: null,
				loader: false,
			},
			cardRemoveConfirm: {
				flag: false,
				id: null,
				loader: false,
			},
			cloneTask: {
				flag: false,
			},
			taskdata: null,
			showVideoModel: false,
			displayPlayer: false,
			videoMediaData: null,
			showPrivateTask: false,
			planeDescription: null,
		};
	},
	mounted() {
		this.sound = new Audio(soundFile);
		const cardExpansionState = this.$store.getters.isMyTaskCardExpanded(
			this.currentWorkspaceId,
			this.card.id
		);
		if (!cardExpansionState) {
			this.$store.dispatch("setMyTaskTabCardExpansionState", {
				workspaceId: this.currentWorkspaceId,
				cardId: this.card.id,
				flag: false,
			});
		}
	},
	methods: {
		onDragRemove(event) {
			event.item.remove();
		},
		onDragAddOrUpdate(event) {
			const { item, oldIndex, newIndex } = event;
			const task = this.$api.getEntity("task", item.dataset.id);

			//condition for personal list task
			if (!task.card_id) {
				this.moveToPublicListConfirm.flag = true;
				this.movedTask = event;
			}

			if (task.card_id) {
				const tmp = this.filteredTodos ? [...this.filteredTodos] : [];
				if (event.from === event.to) tmp.splice(oldIndex, 1);
				tmp.splice(newIndex, 0, task);
				task.card_id = this.card.id;
				task.sequence = RandomPosition.between(
					tmp[newIndex - 1]
						? tmp[newIndex - 1].sequence
						: RandomPosition.first(),
					tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
				);
				task.completed = false;
				this.$api.mutate({
					mutation: UpdateTaskMutation,
					variables: {
						id: task.id,
						sequence: task.sequence,
						card_id: this.card.id,
					},
				});
			}
		},
		async moveToPublicList() {
			const { item, newIndex } = this.movedTask;
			this.moveToPublicListConfirm.loader = true;
			const task = this.$api.getEntity("task", item.dataset.id);
			const tmp = this.filteredTodos ? [...this.filteredTodos] : [];
			tmp.splice(newIndex, 0, task);
			task.card_id = this.card.id;
			task.sequence = RandomPosition.between(
				tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
				tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
			);
			task.completed = false;
			const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
			const privateTasksQuery = this.$api.getQuery(
				`PrivateTasksQuery:${this.board.id}`
			);
			privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
				(t) => t.id !== task.id
			);
			const updatedTask = {
				...task,
			};
			boardQuery.data.board.tasks.push(updatedTask);
			const response = await this.$api.mutate({
				mutation: UpdateTaskMutation,
				variables: {
					id: task.id,
					sequence: task.sequence,
					card_id: this.card.id,
				},
			});
			if (response) {
				this.$api.query({
					query: BoardQuery,
					variables() {
						return {
							id: this.board.id,
						};
					},
					cacheKey() {
						return `BoardQuery:${this.board.id}`;
					},
				});
				this.$api.query({
					query: PrivateTasksQuery,
					variables() {
						return {
							board_id: this.board.id,
						};
					},
					cacheKey() {
						return `PrivateTasksQuery:${this.board.id}`;
					},
				});
			}
			this.cancelPublicListHandler();
		},
		cancelPublicListHandler() {
			this.$emit("reRender");
			this.moveToPublicListConfirm = {
				flag: false,
				loader: false,
			};
		},
		changeExpansionState() {
			this.$store.dispatch("setMyTaskTabCardExpansionState", {
				workspaceId: this.currentWorkspaceId,
				cardId: this.card.id,
				flag: !this.isCardExpanded,
			});
		},
		redirectToBoard() {
			if (this.$route.name !== "BoardView") {
				this.$router.push({
					name: "BoardView",
					params: {
						company: this.$route.params.company,
						workspace: this.currentWorkspaceId,
					},
				});
			}
		},
		edit(task, isOpen = null) {
			const obj = {
				task,
				isOpen,
			};
			this.$eventBus.$emit("openTaskDialog", obj);
		},
		closeDialog() {
			this.$eventBus.$emit("closeTaskDialog");
		},
		showUserInfo(user) {
			this.profileDialog.show = true;
			this.profileDialog.user = user;
		},
		clearUserInfoDialog() {
			this.profileDialog.show = false;
			this.profileDialog.user = null;
		},
		clearCompletedTaskConfirmBoxHandler() {
			this.clearCompletedTaskConfirm = {
				flag: true,
				id: this.card.id,
			};
		},
		clearCompletedTaskFromColumn() {
			const ids = this.board.tasks
				.filter(
					(o) => o.card_id === this.clearCompletedTaskConfirm.id && o.completed
				)
				.map((a) => a.id);
			if (ids && ids.length) {
				this.deleteTaskByIdHandler(ids);
			}
			this.resetClearCompletedTaskConfirmBox();
		},
		resetClearCompletedTaskConfirmBox() {
			this.clearCompletedTaskConfirm = {
				flag: false,
				id: null,
				loader: false,
			};
		},
		deleteCardDeleteConfirmBoxHandler() {
			this.cardRemoveConfirm = {
				flag: true,
				id: this.card.id,
			};
		},
		async deleteCard() {
			const variables = {
				id: this.card.id,
			};
			const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

			boardQuery.data.board.cards = boardQuery.data.board.cards.filter(
				(c) => c.id !== this.card.id
			);
			await this.$api.mutate({
				mutation: DeleteCardMutation,
				variables,
			});
			mixpanel.track("Card Delete");
			this.resetCardDeleteConfirmBox();
		},
		resetCardDeleteConfirmBox() {
			this.cardRemoveConfirm = {
				flag: false,
				id: null,
				loader: false,
			};
		},
		async addTaskBookmark(taskId) {
			try {
				const variables = {
					task_id: taskId,
				};
				const task = this.$api.getEntity("task", taskId);
				task.isbookmarked = !task.isbookmarked;
				const workspaceEntity = this.$api.getEntity(
					"workspace",
					this.currentWorkspace.id
				);
				workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
				await this.$api.mutate({
					mutation: AddTaskBookmarkMutation,
					variables,
					skipUpdates: true,
				});
				// mixpanel.track("Task Bookmark Add");
				this.$mixpanelEvent("save-task", {});
			} catch (error) {
				const workspaceEntity = this.$api.getEntity(
					"workspace",
					this.currentWorkspace.id
				);
				workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
				throw new Error(error);
			}
		},
		async removeTaskBookmark(taskId) {
			try {
				const variables = {
					task_id: taskId,
				};
				const task = this.$api.getEntity("task", taskId);
				task.isbookmarked = !task.isbookmarked;
				const workspaceEntity = this.$api.getEntity(
					"workspace",
					this.currentWorkspaceId
				);
				workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
				await this.$api.mutate({
					mutation: RemoveTaskBookmarkMutation,
					variables,
					skipUpdates: true,
				});
				mixpanel.track("Task Bookmark Remove");
			} catch (error) {
				const workspaceEntity = this.$api.getEntity(
					"workspace",
					this.currentWorkspaceId
				);
				workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
				throw new Error(error);
			}
		},
		async changeCompleteStatus(task) {
			const variables = {
				id: task.id,
				completed: !task.completed,
			};
			task.completed = !task.completed;
			this.sound.currentTime = 0;
			this.sound.play();
			try {
				await this.$api.mutate({
					mutation: UpdateTaskMutation,
					variables,
					skipUpdates: true,
				});
				mixpanel.track("Task Update");
			} catch (error) {
				throw new Error(error);
			}
		},
		async updateNotification(task, isRead) {
			if (isRead) {
				const query = this.$api.getQuery(`NotificationsQuery`);
				let readNotificationIds = [];
				if (query.data) {
					query.data.notifications
						.filter((n) => n.read)
						.map((n) => {
							if (
								n.action.type === "task-comment-created"
									? n.action.object.task_id === task.id
									: n.action.object_id === task.id
							) {
								readNotificationIds.push(n.id);
								n.read = !n.read;
							}
						});
				}
				await this.$api.mutate({
					mutation: UpdateNotificationsMutation,
					variables: {
						notification_ids: readNotificationIds,
						read: false,
					},
				});
				mixpanel.track("Notification Unread");
			} else {
				const query = this.$api.getQuery(`NotificationsQuery`);
				let readNotificationIds = [];
				if (query.data) {
					query.data.notifications
						.filter((n) => !n.read)
						.map((n) => {
							if (
								n.action.type === "task-comment-created"
									? n.action.object.task_id === task.id
									: n.action.object_id === task.id
							) {
								readNotificationIds.push(n.id);
								n.read = !n.read;
							}
						});
				}
				await this.$api.mutate({
					mutation: UpdateNotificationsMutation,
					variables: {
						notification_ids: readNotificationIds,
						read: true,
					},
				});
				mixpanel.track("Notification Read");
			}
		},
		hasNotification(id) {
			const query = this.$api.getQuery(`NotificationsQuery`);
			if (query.data) {
				const notifications = query.data.notifications
					.filter((n) => !n.read)
					.filter((n) =>
						n.action.type === "task-comment-created" ||
						n.action.type === "subtask-member-attached"
							? n.action.object.task_id === id
							: n.action.object_id === id
					);
				return notifications.length > 0;
			}
			return false;
		},
		async createTask(task, position) {
			const cardTasks = this.cardTasks;
			const activeTasks = cardTasks
				? cardTasks.filter((task) => !task.completed)
				: cardTasks;
			const activeTasksOrdered = activeTasks
				? orderBy(activeTasks, ["sequence"], ["asc"])
				: activeTasks;
			let sequence;
			if (activeTasksOrdered === undefined) {
				if (position === "top") {
					sequence = RandomPosition.between(
						RandomPosition.first(),
						head(cardTasks).sequence
					);
				} else {
					sequence = activeTasksOrdered
						? RandomPosition.between(
								last(activeTasksOrdered).sequence,
								RandomPosition.last()
							)
						: RandomPosition.between(
								RandomPosition.first(),
								RandomPosition.last()
							);
				}
			} else {
				if (position === "top") {
					sequence =
						activeTasksOrdered.length > 0
							? RandomPosition.between(
									RandomPosition.first(),
									head(cardTasks).sequence
								)
							: RandomPosition.between(
									RandomPosition.first(),
									RandomPosition.last()
								);
				} else {
					sequence =
						activeTasksOrdered.length > 0
							? RandomPosition.between(
									last(activeTasksOrdered).sequence,
									RandomPosition.last()
								)
							: RandomPosition.between(
									RandomPosition.first(),
									RandomPosition.last()
								);
				}
			}
			const variables = {
				...task,
				sequence,
				card_id: this.card.id,
				board_id: this.board.id,
			};

			const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

			this.newTask.title = null;
			const response = await this.$api.mutate({
				mutation: CreateTaskMutation,
				variables,
			});
			if (response) {
				boardQuery.data.board.tasks.push(response.data.createTask);

				const variables = {
					workspace_id: this.currentWorkspaceId,
					task_id: response.data.createTask.id,
					user_id: this.user.id,
				};
				const taskEntity = this.$api.getEntity(
					"task",
					response.data.createTask.id
				);
				const memberEntity = this.$api.getEntity("user", this.user.id);
				taskEntity.members.push(memberEntity);
				await this.$api.mutate({
					mutation: AddMemberToTaskMutation,
					variables,
				});
			}
			mixpanel.track("Task Create", { from: "Infobar" });
		},
		closePlanExpiredDialog() {
			this.showPrivateTask = false;
		},
		duplicateTask(task) {
			if (
				this.currentCompany.status &&
				this.currentCompany.status.subscriptionOver
			) {
				this.planeDescription =
					"Duplicate tasks across workspaces including task-details and sub-tasks.";
				this.showPrivateTask = true;
			} else {
				this.taskdata = task;
				this.cloneTask.flag = true;
			}
		},

		handleBlur(newTaskTitle, position) {
			if (!newTaskTitle || !newTaskTitle.length) {
				switch (position) {
					case "top":
						this.isVisible2 = false;
						break;
					case "bottom":
						this.isAddTaskVisible = false;
						break;
				}
			}
		},
		closeCloneTaskDialog() {
			this.cloneTask.flag = false;
		},
		videoPlayer() {
			let media = {
				isVimeo: true,
				file: "https://player.vimeo.com/video/569212052",
			};
			this.videoMediaData = media;
			this.displayPlayer = true;
			this.showVideoModel = true;
		},
		resetVideoDialog() {
			this.displayPlayer = false;
			setTimeout(() => {
				this.showVideoModel = false;
			}, 10);
		},
	},
	computed: {
		filteredTodos() {
			return orderBy(this.cardTasks, "completed");
		},
		userProfileDialog() {
			return this.profileDialog.user;
		},
		isCardExpanded() {
			if (
				this.$store.getters.isMyTaskCardExpanded(
					this.currentWorkspaceId,
					this.card.id
				)
			) {
				return this.$store.getters.isMyTaskCardExpanded(
					this.currentWorkspaceId,
					this.card.id
				).isExpanded;
			}
			return false;
		},
	},
};
</script>

<style scoped>
.create-task-btn {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	right: -8px;
	top: 4px;
	bottom: 0;
	justify-content: center;
	display: flex;
}
</style>
