<template>
  <q-btn
    flat
    :icon="$icons.matFilterList"
    padding="2px"
    size="sm"
    color="black"
    round
    dense
  >
    <q-tooltip>
      Filter
    </q-tooltip>
    <q-menu v-model="messageFilter.model">
      <q-list>
        <div class="text-bold filter-header flex">
          <span>Filter by</span>
          <q-btn
            round
            flat
            style="color: #626f7d; background-color: #e7eaed; height: 15px; width: 15px"
            :icon="$icons.matClose"
            class="q-ml-xs q-pt-none"
            size="5px"
            @click="messageFilter.model = false"
          />
        </div>
        <q-separator></q-separator>
        <q-item clickable>
          <q-item-section>Member</q-item-section>
          <q-item-section side>
            <q-icon :name="$icons.matKeyboardArrowRight" />
          </q-item-section>
          <q-menu
            anchor="top end"
            self="top start"
            content-class="overflow-auto q-px-md assign-member-menu"
            id="assign-subtask-menu"
            content-style="border-radius: 4px"
          >
            <div class="sticky-header q-pt-sm">
              <div class="search-member" style="margin-top: 10px">
                <q-input
                  autofocus
                  ref="search"
                  v-model="search"
                  outlined
                  placeholder="Search Member"
                  dense
                >
                </q-input>
              </div>
            </div>
            <q-list class="q-py-sm member-list">
              <template v-for="user in availableMembers">
                <q-item
                  class="q-px-none q-py-xs member-item items-center"
                  :key="user.id"
                  @click.stop="selectMember(user)"
                  clickable
                >
                  <q-item-section class="member-avatar" avatar>
                    <q-avatar size="32px" v-if="user.pic">
                      <img :src="user.pic" :alt="user.value.first" />
                    </q-avatar>
                    <!-- <q-avatar
                      size="32px"
                      color="grey-4"
                      text-color="grey-10"
                      v-else
                    >
                      {{ user.first | firstChar }}{{ user.last | firstChar }}
                    </q-avatar> -->
                    <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                  </q-item-section>
                  <q-item-section class="member-name" v-tooltip>
                    {{ user.first | capitalize }}
                    {{ user.last | capitalize }}
                    <q-tooltip v-if="isTruncated">
                      {{ user.first | capitalize }}
                      {{ user.last | capitalize }}
                    </q-tooltip>
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
          </q-menu>
        </q-item>
        <q-item clickable>
          <q-item-section>Date</q-item-section>
          <q-item-section side>
            <q-icon :name="$icons.matKeyboardArrowRight" />
          </q-item-section>
          <q-menu anchor="top end" self="top start">
            <div class=" dp-class overflow-hidden bg-white">
              <div class="v-card__text q-pa-none q-pt-sm">
                <q-date
                  mask="YYYY-MM-DD"
                  :options="optionsFn"
                  v-model="messageFilter.dates"
                  range
                  color="blue-13"
                  class="date-picker-calendar"
                />
              </div>
              <q-card-actions>
                <q-btn
                  size="16px"
                  no-caps
                  class="q-px-md bg-primary text-white"
                  :disabled="!messageFilter.dates"
                  @click="dateFilterHandler"
                  label="Apply"
                />
              </q-card-actions>
            </div>
          </q-menu>
        </q-item>
        <q-separator></q-separator>
        <q-item clickable @click="collapsePosts">
          <q-item-section>
            Collapse messages
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import format from "date-fns/format";
import orderBy from "lodash/orderBy";
import Avatar from 'vue-avatar';

export default {
  name: "FilterMessage",
  props: ["user", "workspace"],
  api: {
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspace}`;
      },
    },
  },
  beforeCreate() {
    this.$eventBus.$off("clearFilter");
  },
  beforeDestroy() {
    this.$eventBus.$off("clearFilter");
  },
  created() {
    this.$eventBus.$on("clearFilter", this.clearFilter);
  },
  data() {
    return {
      messageFilter: {
        model: false,
        member: null,
        dates: null,
      },
      FILTER: {
        DATE: "date",
        MEMBER: "member",
      },
      search: null,
      isTruncated: false,
    };
  },
  components:{
    Avatar,
  },
  methods: {
    // changeDateRange(range) {
    //   this.messageFilter.dates = range;
    // },
    optionsFn(date) {
      return date <= format(new Date(), "yyyy/MM/dd");
    },
    collapsePosts() {
      this.$eventBus.$emit("toggle_collapse");
    },
    memberFilterHandler() {
      this.messageFilter.dates = null;
      if (this.messageFilter.member) {
        this.messageFilter.model = false;
        this.$eventBus.$emit("filter_message", {
          type: this.FILTER.MEMBER,
          variables: this.messageFilter.member,
        });
      }
    },
    dateFilterHandler() {
      this.messageFilter.model = false;
      this.messageFilter.member = null;
      this.$eventBus.$emit("filter_message", {
        type: this.FILTER.DATE,
        variables: this.messageFilter.dates,
      });
    },
    clearFilter() {
      this.messageFilter = {
        model: false,
        member: null,
        dates: "",
      };
    },
    selectMember(user) {
      this.messageFilter.member = user.value;
      this.memberFilterHandler();
    },
  },
  computed: {
    currentWorkspace() {
      return this.workspace && this.workspace.id;
    },
    memberFilterList() {
      let memberList = [];

      if (this.user) {
        memberList.push({
          text: this.user.first + " " + this.user.last,
          value: {
            id: this.user.id,
            name: this.user.first + " " + this.user.last,
          },
          pic: this.user.pic,
          first: this.user.first,
          last: this.user.last,
        });
      }

      if (this.workspaceMembers) {
        this.workspaceMembers.forEach((member) => {
          memberList.push({
            text: member.first + " " + member.last,
            value: {
              id: member.id,
              name: member.first + " " + member.last,
            },
            pic: member.pic,
            first: member.first,
            last: member.last,
          });
        });
      }
      const orderedMembers = orderBy(
        memberList,
        ["first", "last"],
        ["asc", "asc"]
      );
      return orderedMembers;
    },
    availableMembers() {
      return this.search
        ? this.memberFilterList.filter((u) =>
            u.fullname.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.memberFilterList.hasOwnProperty("list")
        ? this.memberFilterList.list
        : this.memberFilterList;
    },
  },
};
</script>
<style scoped>
.filter-header {
  height: 25px;
  width: 150px;
  justify-content: space-between;
  margin: 5px 7px;
  align-items: center;
}
</style>
