import { mapGetters } from "vuex";
import { fromUnixTime, getUnixTime } from "date-fns";
import get from "lodash/get";

import { exportTimeLogs } from "@/services/auth";
import {
  CreateTaskTimeLogMutation,
  UpdateTaskTimeLogMutation,
  DeleteTaskTimeLogsMutation,
  UpdateCompanySettingsMutation,
} from "@/gql";
export default {
  methods: {
    async createTimer() {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      if (!companyEntity.start_task_id && !companyEntity.start_time_log_id) {
        const variables = {
          start_date: getUnixTime(new Date()),
          end_date: getUnixTime(new Date()),
          task_id: this.task.id,
          workspace_id: this.workspace.id,
          is_start: true,
        };
        let newTaskTimeLog = await this.$api.mutate({
          mutation: CreateTaskTimeLogMutation,
          variables,
        });

        const companyEntity = this.$api.getEntity(
          "company",
          this.currentCompany.companyId
        );

        const taskEntity = this.$api.getEntity("task", this.task.id);
        companyEntity.start_task_id = this.task.id;
        companyEntity.start_time_log_id =
          newTaskTimeLog.data.createTaskTimeLog.id;
        taskEntity.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
        this.workspace.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
        this.task.activetimelog = newTaskTimeLog.data.createTaskTimeLog;
        const companyVariables = {
          name: companyEntity.name,
          start_task_id: this.task.id,
          start_time_log_id: newTaskTimeLog.data.createTaskTimeLog.id,
        };
        await this.$api.mutate({
          mutation: UpdateCompanySettingsMutation,
          variables: companyVariables,
        });
        this.$mixpanelEvent('track-time',companyVariables)
      } else {
        this.startNewTimer.flag = true;
        this.startNewTimer.activeTaskId = companyEntity.start_task_id;
      }
    },
    stopTimerHandler(data) {
      this.stopTimerConfirmation.flag = true;
      this.stopTimerConfirmation.id = data.id;
    },
    async stopTimer(timelogId) {
      const variables = {
        end_date: getUnixTime(new Date()),
        id: timelogId,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });
      this.task.activetimelog = null;
      const taskEntity = this.$api.getEntity("task", this.task.id);

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == timelogId
      );
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;

      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.workspace.id,
        task_id: this.task.id,
        user_id: this.user.id,
      };
      const taskEntity = this.$api.getEntity("task", this.task.id);

      taskEntity.timelogs = [];
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });
    },
    closeTimeLogDialog() {
      this.timelogs.flag = false;
    },
    timeCaclulate(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      return Math.floor((dateFuture - dateNow) / 1000);
    },
    convertIntoTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      //seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      return days * 24 + hours + "h " + minutes + "m";
    },
    async exportTimeLogCsv(taskId) {
      const res = await exportTimeLogs(
        this.workspace.id,
        this.currentCompany.accessToken,
        taskId
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    totalWorkTime() {
      let taskTotalTime;
      if (this.task.timelogs?.length) {
        let timeInSecond = 0;
        this.task.timelogs.forEach((timelog) => {
          timeInSecond += this.timeCaclulate(
            timelog.start_date,
            timelog.end_date
          );
        });
        taskTotalTime = this.convertIntoTime(timeInSecond);
        return taskTotalTime;
      }
      return null;
    },

    activeTimelog() {
      return this.task.activetimelog;
    },
  },
};
