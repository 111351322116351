<template>
  <q-menu
    max-height="300px"
    anchor="top end"
    self="top start"
    content-style="padding: 0 14px"
  >
    <div class="header">
      Add workspaces to folder
    </div>
    <q-list style="overflow: auto; max-height: 250px;">
      <template v-for="workspace in remainedWorkspaces">
        <q-item
          :key="workspace.id"
          clickable
          @click="toggleWorkspaceFolder(workspace)"
          style="min-height:24px;"
        >
          <q-item-section side style="padding-right: 12px">
            <span class="workspace-selection-checkbox">
              <div
                class="selected-workspace-selection-checkbox"
                v-if="
                  workspace.folder[0] && workspace.folder[0].id === folder.id
                "
              ></div>
            </span>
          </q-item-section>
          <q-item-section>
            {{ workspace.title }}
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </q-menu>
</template>

<script>
export default {
  name: "AddWorkspaceToFolder",
  props: [
    "remainedWorkspaces",
    "folder",
    "addWorkspaceToFolder",
    "removeWorkspaceFromFolder",
  ],
  methods: {
    toggleWorkspaceFolder(workspace) {
      if (workspace.folder[0] && workspace.folder[0].id === this.folder.id) {
        this.removeWorkspaceFromFolder(workspace);
      } else {
        this.addWorkspaceToFolder(workspace);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workspace-selection-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #b8c1cb;
  border-radius: 4px;
}

.selected-workspace-selection-checkbox {
  background: #007aff;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 2px;
}

.q-item:hover {
  .selected-workspace-selection-checkbox {
    background: #ffffff;
  }
}

.header {
  text-align: center;
  font-size: 16px;
  font-family: "Avenir Next";
  font-weight: bold;
  padding: 20px 0 15px 0;
}
</style>
