<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="bg-white move-to-board-confirmation">
      <div class="title text-center">
        <span class="q-mx-auto">{{ title }}</span>
      </div>
      <div class="text-dark text-center question">
        {{ message }}
      </div>
      <div class="row justify-around">
        <q-btn
          label="Cancel"
          color="black"
          class="q-ma-none"
          size="14px"
          style="width:100px"
          flat
          no-caps
          @click.stop="cancleMethod()"
        />
        <q-btn
          class="q-ma-none"
          size="14px"
          color="primary"
          text-color="white"
          label="OK"
          style="width:100px"
          @click.stop="successMethod()"
          :loading="loading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ChangeListConfirmDialog",
  props: [
    "value",
    "title",
    "message",
    "cancleMethod",
    "successMethod",
    "loading",
  ],
};
</script>
