<template>
  <div>
    <!-- <verify-company
      @next="handleCompanyUriCheckSuccess"
      v-if="stage <= 1"
    ></verify-company> -->
    <template v-if="fromInsideCompany">
      <step-1-dialog
        @next="handlePassCodeSuccess"
        :companyUri="companyDetail.uri"
        v-if="stage <= 1"
      />
      <step-2-dialog
        @next="handlePasscodeVeficationSuccess"
        :email="email"
        :companyUri="companyDetail.uri"
        v-if="stage === 2"
      />
      <step-3-dialog
        @success="handleResetPasswordSuccess"
        :code="code"
        :companyUri="companyDetail.uri"
        :email="email"
        v-if="stage === 3"
      />
    </template>
    <template v-else>
      <send-passcode
        @next="handlePassCodeSuccess"
        :companyUri="companyDetail.uri"
        v-if="stage <= 1"
      ></send-passcode>
      <verify-passcode
        @next="handlePasscodeVeficationSuccess"
        :email="email"
        :companyUri="companyDetail.uri"
        v-if="stage === 2"
      ></verify-passcode>
      <password-reset
        @success="handleResetPasswordSuccess"
        :code="code"
        :companyUri="companyDetail.uri"
        :email="email"
        v-if="stage === 3"
      ></password-reset>
    </template>
  </div>
</template>
<script>
//import VerifyCompany from "@/components/ForgotPasswordWizard/Step1";
import Step1Dialog from "@/components/ForgotPasswordWizard/Step1Dialog";
import Step2Dialog from "@/components/ForgotPasswordWizard/Step2Dialog";
import Step3Dialog from "@/components/ForgotPasswordWizard/Step3Dialog";
import SendPasscode from "@/components/ForgotPasswordWizard/Step2";
import VerifyPasscode from "@/components/ForgotPasswordWizard/Step3";
import PasswordReset from "@/components/ForgotPasswordWizard/Step4";

export default {
  name: "ForgotPassword",
  props: ["companyDetail", "showTopCompanyName", "fromInsideCompany"],
  components: {
    //VerifyCompany,
    SendPasscode,
    VerifyPasscode,
    PasswordReset,
    Step1Dialog,
    Step2Dialog,
    Step3Dialog,
  },
  data() {
    return {
      stage: 0,
      company: {
        id: null,
        uri: null,
        name: null,
      },
      email: null,
      code: null,
    };
  },
  methods: {
    stageHandler() {
      if (this.stage > 1) {
        this.stage -= 1;
      }
    },
    handleCompanyUriCheckSuccess(data) {
      this.company = data;
      this.stage = 2;
    },
    handlePassCodeSuccess(data) {
      if (!this.fromInsideCompany) {
        this.showTopCompanyName(false);
      }
      this.email = data.email;
      this.stage = 2;
    },
    handlePasscodeVeficationSuccess(data) {
      this.showCompanyName = false;
      this.stage = 3;
      this.code = data.code;
    },
    handleResetPasswordSuccess() {
      this.$emit("success");
    },
    switchComponent(component) {
      this.$emit("switchComponent", component);
    },
  },
};
</script>
<style scoped>
.signin {
  color: #6969e7 !important;
}
.reset-top-padding .stepper__content {
  padding-top: 0 !important;
}
.back-btn {
  width: 50px;
}
</style>
