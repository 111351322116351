<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card style="flex:0 1 780px;max-width:100%;">
      <div class="text-right">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="black"
          round
          size="sm"
          @click="proxyModel = false"
          style="right: 10px;top: 10px;"
        />
      </div>
      <div class="row q-px-xl q-pb-l">
        <div class="col-grow column">
          <div class="title">Invite New People</div>

          <!-- Scrollable Section for tab components -->
          <div class="col-grow scroll-y">
            <form v-on:submit.prevent class="rounded-borders">
              <q-select
                placeholder="Type upto 5 e-mail addresses"
                bg-color="white"
                clearable
                outlined
                multiple
                use-input
                use-chips
                hide-dropdown-icon
                @focusout.native="handleBlur"
                @keydown="handleKeyDown"
                input-debounce="0"
                color="blue-grey-2"
                new-value-mode="add-unique"
                v-model="emails"
                style="margin-bottom: 20px"
                class="invite-memeber-select"
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    removable
                    color="blue-grey-2"
                    text-color="dark"
                    @remove="scope.removeAtIndex(scope.index)"
                    :tabindex="scope.tabindex"
                    size="14px"
                    class="selected-chip"
                  >
                    {{ scope.opt }}
                  </q-chip>
                </template>
                <template v-slot:prepend>
                  <q-icon
                    :name="$icons.matMail"
                    size="xs"
                    color="blue-grey-1"
                  />
                </template>
              </q-select>
              <p class="secondary--text mb-0" v-if="!workspaceList">
                Before inviting someone to Heycollab, you need to create a
                workspace.
              </p>
              <q-select
                v-model="invitation.workspaces"
                bg-color="white"
                placeholder="Invite to which workspace(s)"
                input-debounce="0"
                color="dark"
                :options="filterOptions"
                @filter="filterFn"
                @clear="clearSelectedWorkspaces"
                ref="select"
                @input="checkVals"
                emit-value
                map-options
                use-input
                use-chips
                clearable
                outlined
                multiple
                popup-content-class="overflow-auto invite-memeber-select"
                popup-content-style="max-height: 310px;"
                class="invite-memeber-select"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:before-options>
                  <div class="fix-option">
                    <q-item @click="selectAll(!isSelectAll)" clickable>
                      <q-item-section avatar>
                        <q-checkbox
                          :value="isSelectAll"
                          @input="selectAll(!isSelectAll)"
                          size="sm"
                          color="primary"
                        />
                      </q-item-section>
                      <q-item-section>
                        Invite to all workspaces
                      </q-item-section>
                    </q-item>
                    <q-separator style="height:2px" />
                  </div>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                    <template>
                      <q-item-section avatar>
                        <q-checkbox
                          v-model="invitation.workspaces"
                          :val="scope.opt.value"
                          size="sm"
                          color="primary"
                        />
                      </q-item-section>
                      <q-item-section>
                        {{ scope.opt.text }}
                      </q-item-section>
                    </template>
                  </q-item>
                </template>
                <template v-slot:selected-item="scope">
                  <q-chip
                    removable
                    color="blue-grey-2"
                    text-color="dark"
                    @remove="scope.removeAtIndex(scope.index)"
                    :tabindex="scope.tabindex"
                    size="14px"
                    class="selected-chip"
                  >
                    {{ scope.opt.text }}
                  </q-chip>
                </template>
              </q-select>
            </form>
            <div class="rounded-borders bg-blue-grey" style="margin-top: 20px" v-if="!currentWorkspaceId">
              <div class="q-mb-sm invite-title">
                Invite to team link
              </div>
              <div class="q-mb-md">
                Use the link below to grant access to public workspaces. For specific workspace invitations, enter the email addresses above and choose the desired workspaces.
              </div>
              <div class="invite-link">
                <q-input
                  v-model.trim="publicLink"
                  ref="copytext"
                  outlined
                  readonly
                  class="invite-link-text-box"
                />
                <q-btn
                  :color="isLinkCopy ? 'teal-14' : 'indigo-12'"
                  :label="isLinkCopy ? 'Copied' : 'Copy'"
                  size="md"
                  no-caps
                  class="invite-link-button"
                  @click="
                    $copyTextToClipboard(publicLink);
                    isLinkCopy = true;
                  "
                  style="width: 17.5%"
                />
              </div>
            </div>
            <div v-show="company && invitations">
              <div
                class="mt-4 pink--text"
                v-if="
                  (company.subscription.base_plan === 'v3_studio' &&
                    invitations.length >= 30) ||
                    (company.subscription.base_plan === 'v3_agency' &&
                      invitations.length >= 75)
                "
              >
                This invitation has addition and it will be charge 2 $ extra
              </div>
            </div>
          </div>
          <div v-if="errorWarning" class="mt-4 pink--text">
            {{ errorWarning }}
          </div>
          <div
            class="row"
            style="flex-flow: row-reverse; margin-top: 20px; margin-bottom: 20px;"
          >
            <div class="text-xs-right">
              <q-btn
                no-caps
                label="Send"
                text-color="white"
                class="q-px-xl send-button"
                :loading="loader"
                @click.native="sendInvitation"
                :disabled="
                  $v.$invalid ||
                    !isReadyToSend ||
                    !verifyCaptch ||
                    getEmailsLength > 5
                "
              />
            </div>
            <!-- <div
              style="margin-top: -14px;margin-right: 10px;"
              v-show="!$v.$invalid && isReadyToSend && !verifyCaptch"
            >
              <re-captch ref="recaptcha" @verify="submit"></re-captch>
            </div> -->
          </div>
        </div>
      </div>
    </q-card>
    <upgrade-plan-dialog
      @close="proxyModel = false"
      v-if="upgradePlanDialog"
      v-model="upgradePlanDialog"
    />
  </q-dialog>
</template>
<script>
import compact from "lodash/compact";
import { email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import serviceProvider from "@/services/ServiceProvider";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import UserScope from "@/mixins/UserScope";

// import ReCaptch from "@/components/ReCaptch";
import errorHandler from "@/utils/ErrorHandler";
import UpgradePlanDialog from "@/components/Dialogs/UpgradePlanDialog";
import {
  InvitationWorkspaceQuery,
  InvitationsQuery,
  CompanyQuery,
} from "@/gql";
import { mapGetters } from "vuex";

export default {
  name: "InvitePeopleDialog",
  props: ["currentWorkspaceId"],
  mixins: [UserScope, validationMixin, ProxyModelMixin],
  api: {
    invitationWorkspace: {
      query: InvitationWorkspaceQuery,
      cacheKey: "InvitationWorkspaceQuery",
    },
    invitations: {
      query: InvitationsQuery,
      cacheKey: "InvitationsQuery",
    },
    company: {
      cacheKey: "CompanyQuery",
    },
  },
  validations: {
    emails: {
      $each: {
        email,
      },
    },
  },
  validationMessages: {
    emails: {
      required: "message.validation.email.email",
    },
  },
  components: {
    //ReCaptch,
    UpgradePlanDialog,
  },
  data() {
    return {
      error: null,
      loader: false,
      emails: [],
      // model: [],
      invitation: {
        workspaces: [],
      },
      verifyCaptch: false,
      upgradePlanDialog: false,
      captchaToken: null,
      errorWarning: null,
      filterOptions: null,
      isLinkCopy: false,
      uri: null,
      inviteLink: null,
      inviteAll: false,
      isSelectAll: false,
    };
  },
  mounted() {
    if (this.currentWorkspaceId)
      this.invitation.workspaces = [this.currentWorkspaceId];
    this.fetchCompany();
    let that = this;
    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute("6LdwD90cAAAAAIeZv76sAVlX_Va7h71k7kPCPezl", {
          action: "contact",
        })
        .then(function(token) {
          that.submit(token);
        });
    });
  },
  methods: {
    checkVals(v) {
      this.$refs.select.hidePopup();
      this.$refs.select.blur();
      if (v) {
        this.isSelectAll =
          v.length > 0 && v.length < this.workspaceList.length
            ? null
            : v.length === this.workspaceList.length
            ? true
            : false;
      } else {
        this.isSelectAll = false;
      }
    },
    selectAll(value) {
      this.isSelectAll = value;
      if (value) {
        this.invitation.workspaces = this.workspaceList.map((i) => i.value);
        return;
      }
      this.invitation.workspaces = [];
    },
    async fetchCompany() {
      const variables = {
        uri: this.companies[this.activeCompany].companyUri,
      };
      const response = await this.$api.query({
        query: CompanyQuery,
        variables,
      });
      const company = response.data.company;

      if (company) {
        this.inviteLink = company.public_link;
        this.uri = company.uri;
      }
    },
    handleKeyDown(event) {
      if (
        event.code === "Comma" ||
        event.code === "Semicolon" ||
        event.code === "Tab" ||
        event.code === "Space"
      ) {
        event.preventDefault();
        const enter = new KeyboardEvent("keydown", { keyCode: 13 });
        event.target.dispatchEvent(enter);
      }
    },
    handleBlur(event) {
      if (event.target.value && event.target.value.trim()) {
        this.emails.push(event.target.value);
      }
    },
    filterFn(val, update) {
      update(() => {
        if (val === "") {
          this.filterOptions = this.workspaceList;
        } else {
          const needle = val.toLowerCase();
          this.filterOptions = this.workspaceList.filter(
            (v) => v.text.toLowerCase().indexOf(needle) > -1
          );
        }
      });
    },
    // addEmail(val) {
    //   if (val.length > 0 && (val.includes(",") || val.includes(";"))) {
    //     const model = (this.emails || []).slice();
    //     val
    //       .split(/[,;]+/)
    //       .map((v) => v.trim())
    //       .filter((v) => v.length > 0)
    //       .forEach((v) => {
    //         if (model.includes(v) === false) {
    //           model.push(v);
    //         }
    //       });
    //     this.emails = model;
    //   }
    // },
    submit(response) {
      if (response) {
        this.verifyCaptch = true;
        this.captchaToken = response;
      }
    },
    clearForm() {
      this.$v.$reset();
      this.loader = false;
      this.emails = [];
      this.invitation.workspaces = [];
      this.verifyCaptch = false;
      this.captchaToken = null;
    },
    sendInvitation() {
      if (this.verifyCaptch && this.captchaToken) {
        const vm = this;
        vm.loader = true;
        serviceProvider
          .sendInvitationToUser(
            vm.emails,
            vm.invitation.workspaces,
            this.captchaToken,
            this.$api
          )
          .then((result) => {
            if (result && !result.errors) {
              this.$emit("success", {
                emails: this.emails,
                workspaces: this.invitation.workspaces,
              });
              this.$mixpanelEvent("invitation-sent", {});
            } else {
              this.loader = false;
              if(result.errors && result.errors[0].message === "InvitationLimitExided") {
                this.upgradePlanDialog = true;
                this.errorWarning =
                  "Your invitation limit exceeded. To invite more people, Upgrade Your Plan.";
              } else {
                console.log("result.errors", result);
                this.$q.notify({
                  color: "negative",
                  position: "top-right",
                  message: result.errors[0].message,
                  timeout: 2500,
                  icon: this.$icons.matAnnouncement,
                  actions: [{ icon: this.$icons.matClose, color: "white" }],
                });
              }
            }
            vm.clearForm();
            // window.grecaptcha.reset();
          })
          .catch((error) => {
            const message = errorHandler.getErrorMessage(
              error,
              this,
              this.$t("message.validation.invalidCouponCode")
            );
            if (message === "message.InvitationLimitExided") {
              this.upgradePlanDialog = true;
              this.errorWarning =
                "Your invitation limit exceeded. To invite more people, Upgrade Your Plan.";
              this.loader = false;
            }
          });
      }
    },
    clearSelectedWorkspaces() {
      this.invitation.workspaces = [];
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    getEmailsLength() {
      if (this.emails) {
        return this.emails.length;
      }
      return 0;
    },
    isReadyToSend() {
      if (
        compact(this.emails).length > 0 &&
        this.invitation.workspaces.length > 0
      ) {
        return true;
      }
      return false;
    },
    filterWorkspace() {
      if (this.invitationWorkspace) {
        return this.invitationWorkspace.filter(
          (workspace) =>
            (this.canInviteMemberToPublicWorkspace &&
              workspace.type === "public") ||
            (this.canInviteMemberToPrivateWorkspace &&
              workspace.type === "private")
        );
      } else {
        return [];
      }
    },
    workspaceList() {
      if (this.invitationWorkspace) {
        return this.filterWorkspace.map((workspace) => {
          return {
            text: workspace.title,
            value: workspace.id,
          };
        });
      } else {
        return [];
      }
    },
    publicLink() {
      if (this.uri && this.inviteLink) {
        const invitaionLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.uri}/invitation?type=public&secret=${this.inviteLink}`;
        return invitaionLink;
      } else {
        return "";
      }
    },
  },
  watch: {
    workspaceList(val) {
      this.filterOptions = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}
.send-button {
  background-color: #007aff;
  font-size: 14px;
  font-weight: 500;
  width: 182px;
  height: 45px;
  font-family: "Avenir Next";
}
.bg-blue-grey {
  padding: 19px 24px 26px;
  background: #F5F8FE !important;
  font-family: "Avenir Next";
  color: #232C3B;
  font-size: 15px;
  border-radius: 5px;
}
.invite-title{
  font-weight: 600;
}
.invite-link {
  display: flex;
  border-radius: 5px;
  border: 2px solid #C1DCF3;
  background: #FFF;
  .q-field__control{
    background: #fff;
  }
}
.invite-link-button {
  background-color: #dbecff !important;
  border-left: solid 2px #c1dcf3;
  color: #007aff !important;
}
</style>
