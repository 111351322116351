<template>
  <q-card style="padding: 30px 50px 10px 20px" class="bg-white">
    <div class="footer-menu-dialog-title">
      Import
    </div>
    <div class="row col-grow justify-between change-password">
      <form class="field-width" v-on:submit.prevent>
        <div class="step-title q-mb-md q-mt-sm" style="font-size:16px">
          Select a platform below to get started
        </div>
        <!-- <div class="join-label-text">You’ll also be able to this later.</div> -->
        <div>
          <div class="row import-data">
            <div
              id="trello"
              :class="[
                platform === 'trello' ? 'active-card' : 'inactive-card',
                'row items-center justify-center q-px-md q-py-xs card',
              ]"
              clickable
              @click.stop="selectPlatform('trello')"
            >
              <q-icon
                :name="$icons.mdiTrello"
                :style="
                  platform === 'trello'
                    ? { color: '#085cd5' }
                    : { color: '#929398' }
                "
                size="25"
              />
              <div class="justify-center items-center flex q-ml-xs card-text">
                Trello
              </div>
            </div>
            <div
              id="asana"
              :class="[
                platform === 'asana' ? 'active-card' : 'inactive-card',
                'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
              ]"
              clickable
              @click.stop="selectPlatform('asana')"
            >
              <!-- <q-icon
                :name="$icons.mdiSlack"
                :style="
                  platform === 'asana'
                    ? { color: '#E01E5A' }
                    : { color: '#929398' }
                "
                size="25"
              /> -->
              <img class="image" :src="asana.logo" />
              <div class="justify-center items-center flex q-ml-xs card-text">
                Asana
              </div>
            </div>
            <div
              id="slack"
              :class="[
                platform === 'slack' ? 'active-card' : 'inactive-card',
                'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
              ]"
              clickable
              @click.stop="selectPlatform('slack')"
            >
              <q-icon
                :name="$icons.mdiSlack"
                :style="
                  platform === 'slack'
                    ? { color: '#E01E5A' }
                    : { color: '#929398' }
                "
                size="25"
              />
              <div class="justify-center items-center flex q-ml-xs card-text">
                Slack
              </div>
            </div>
          </div>
          <div class="row import-data q-mt-md">
            <div
              id="clickup"
              :class="[
                platform === 'clickup' ? 'active-card' : 'inactive-card',
                'row items-center justify-center q-px-md q-py-xs card',
              ]"
              clickable
              @click.stop="selectPlatform('clickup')"
            >
              <img class="image" :src="clickup.logo" />
              <div class="justify-center items-center flex q-ml-xs card-text">
                ClickUp
              </div>
            </div>
            <div
              id="monday"
              :class="[
                platform === 'monday' ? 'active-card' : 'inactive-card',
                'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
              ]"
              clickable
              @click.stop="selectPlatform('monday')"
            >
              <img class="image" :src="monday.logo" />
              <div class="justify-center items-center flex q-ml-xs card-text">
                Monday
              </div>
            </div>
          </div>
          <div class="q-mt-lg">
            <q-btn
              type="submit"
              no-caps
              text-color="white"
              padding="0"
              :label="
                'Import from ' +
                  platform.charAt(0).toUpperCase() +
                  platform.slice(1)
              "
              class="footer-dialog-ok-btn"
              @click="authoriseTrelloUser"
              :loading="this.cloneCard.loader"
              v-if="platform"
            />
          </div>
        </div>
      </form>
      <create-trello-workspaceDialog
        v-if="cloneCard.flag"
        v-model="cloneCard.flag"
        :card="cloneCard.card"
        :closeDialog="closeWorkspaceDialog"
        :company="company"
        isFrom="adminstration"
        :platform="platform"
        :accessToken="accessToken"
      />
      <monday-dialog
        v-if="monday.dialog"
        v-model="monday.dialog"
        :apiToken="monday.apiToken"
        @closeDialog="closeMondayDialog"
        @getProjectList="getProjectList"
      />
    </div>
    <div class="q-mt-md">
      <other-platform-import-data-history
        v-for="history in importDataHistoryList"
        :key="history.id"
        :history="history"
      />
    </div>
  </q-card>
</template>

<script>
import CreateTrelloWorkspaceDialog from "@/components/Dialogs/CreateTrelloWorkspaceDialog";
import MondayDialog from "@/components/Dialogs/MondayDialog";
import OtherPlatformImportDataHistory from "@/components/OtherPlatformImportDataHistory";
import { ImportDataQuery } from "@/gql";
import {
  getAsanaUserToken,
  getChannelList,
  getSpaceList,
  mondayLogin,
} from "@/services/auth";
export default {
  name: "ImportDataDialog",
  props: ["company", "closeDialog"],
  components: {
    CreateTrelloWorkspaceDialog,
    OtherPlatformImportDataHistory,
    MondayDialog,
  },
  api: {
    importDataHistories: {
      query: ImportDataQuery,
      defaultValue: null,
      cacheKey() {
        return `ImportDataQuery`;
      },
    },
  },
  data() {
    return {
      loader: false,
      platform: null,
      cloneCard: {
        flag: false,
        card: null,
        loader: false,
      },
      asana: {
        code: null,
        window: null,
        logo: "/static/images/logo/asana-logo-grey.svg",
      },
      clickup: {
        logo: "/static/images/logo/clickup-grey.svg",
      },
      monday: {
        dialog: false,
        apiToken: null,
        logo: "/static/images/logo/monday-grey.svg",
      },
      accessToken: null,
    };
  },
  methods: {
    async getProjectList(token) {
      let response = await mondayLogin(
        {
          access_token: token,
        },
        this.company.accessToken
      );

      if (response.projects) {
        this.monday.dialog = false;
        this.asana.code = null;
        this.accessToken = response.accessToken;
        this.cloneCard.card = response.projects;
        this.cloneCard.flag = true;
        this.cloneCard.loader = false;
      }
    },
    closeMondayDialog() {
      this.monday.dialog = false;
    },
    async authoriseTrelloUser() {
      if (this.platform === "trello") {
        this.$trello.authorize({
          type: "popup",
          name: "Heycollab",
          scope: {
            read: true,
            write: true,
          },
          persist: false,
          expiration: "never",
          success: this.authenticationSuccess,
          error: this.authenticationFailure,
        });
      } else {
        this.asana.window = null;
        let url;
        switch (this.platform) {
          case "asana":
            url =
              "https://app.asana.com/-/oauth_authorize?response_type=code&client_id=1202183321006260&redirect_uri=" +
              window.location.origin +
              "/assets/html/asana.html&state=" +
              window.location.origin +
              "&electronPopup=true&display_ui=always";
            break;
          case "slack":
            url =
              "https://slack.com/oauth/v2/authorize?client_id=1886146514784.3379326397110&scope=channels:history,channels:read,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,users:read,chat:write&user_scope=channels:history,channels:read,chat:write,groups:history,groups:read,identify,im:history,im:read,mpim:history,mpim:read,users:read,files:read&redirect_uri=" +
              window.location.origin +
              "/assets/html/slack.html&state=" +
              window.location.origin;
            break;
          case "clickup":
            url =
              "https://app.clickup.com/api?client_id=WRL2P4LOKAOS7SEEFUCQB9LIKT25DZDI&redirect_uri=" +
              window.location.origin +
              "/assets/html/clickup.html&state=" +
              window.location.origin;
            break;
          default:
            url = "";
        }

        if (url) {
          this.asana.window = window.open(
            url,
            "authWin",
            "width=500px,height=700px"
          );
          window.addEventListener("message", this.setCode, false);
        }
        if (this.platform === "monday") {
          this.monday.dialog = true;
        }
      }
    },
    async setCode(event) {
      if (typeof event.data.code == "undefined") {
        return false;
      }

      if (!this.asana.code) {
        this.asana.window.close();
        if (this.asana.window) {
          this.asana.window = null;
        }
        //window.close();
        this.cloneCard.loader = true;
        this.asana.code = event.data.code;
        let response = null;
        if (event.data.type === "slack") {
          response = await getChannelList(
            {
              code: this.asana.code,
            },
            this.company.accessToken
          );
        } else if (event.data.type === "asana") {
          response = await getAsanaUserToken(
            {
              code: this.asana.code,
            },
            this.company.accessToken
          );
        } else if (event.data.type === "clickup") {
          response = await getSpaceList(
            {
              code: this.asana.code,
            },
            this.company.accessToken
          );
        } else if (event.data.type === "monday") {
          response = await mondayLogin(
            {
              code: this.asana.code,
            },
            this.company.accessToken
          );
        }

        if (response.projects) {
          this.asana.code = null;
          this.accessToken = response.accessToken;
          this.cloneCard.card = response.projects;
          this.cloneCard.flag = true;
          this.cloneCard.loader = false;
        }
      }
      return true;
    },
    async setSlackCode(event) {
      if (typeof event.data.code == "undefined") {
        return false;
      }
      if (!this.slack.code) {
        this.slack.window.close();
        this.cloneCard.loader = true;
        this.slack.code = event.data.code;
        const response = await getChannelList(
          {
            code: this.slack.code,
          },
          this.company.accessToken
        );
        if (response.projects) {
          this.slack.code = null;
          this.accessToken = response.accessToken;
          this.cloneCard.card = response.projects;
          this.cloneCard.flag = true;
          this.cloneCard.loader = false;
        }
      }
      return true;
    },
    async setClickupCode(event) {
      if (typeof event.data.code == "undefined") {
        return false;
      }

      if (!this.clickup.code) {
        this.clickup.window.close();
        this.cloneCard.loader = true;
        this.clickup.code = event.data.code;
        const response = await getSpaceList(
          {
            code: this.clickup.code,
          },
          this.company.accessToken
        );
        if (response.projects) {
          this.clickup.code = null;
          this.accessToken = response.accessToken;
          this.cloneCard.card = response.projects;
          this.cloneCard.flag = true;
          this.cloneCard.loader = false;
        }
      }
      return true;
    },
    authenticationSuccess() {
      this.cloneCard.loader = true;
      this.getBoards();
    },
    async getBoards() {
      try {
        const memberResponse = await this.$trello.get(
          "tokens/" + this.$trello.token() + "/member"
        );
        if (memberResponse) {
          let boardData = [];
          Promise.all(
            memberResponse.idBoards.map(async (element) => {
              const cardResponse = await this.$trello.get(
                "boards/" + element + "/?lists=all&members=all"
              );
              return cardResponse.closed !== true && cardResponse;
            })
          ).then((boards) => {
            boards.forEach((board) => {
              if (board) {
                board.members = board.members.filter((o) => {
                  return o.id !== memberResponse.id;
                });
                board.lists = board.lists.filter((o) => {
                  return o.closed !== true;
                });
                boardData.push(board);
              }
            });
            this.cloneCard.card = boardData;
            this.cloneCard.flag = true;
            this.cloneCard.loader = false;
          });
        }
      } catch (error) {
        this.cloneCard.loader = false;
      }
    },
    closeWorkspaceDialog() {
      this.closeDialog();
      this.$trello.setToken(null);
      this.cloneCard.flag = false;
      this.cloneCard.card = null;
    },
    authenticationFailure() {
      this.$q.notify({
        color: "negative",
        position: "top-right",
        message: "Authorisation failure!",
        icon: this.$icons.matReportProblem,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    selectPlatform(name) {
      if (name === "asana") {
        this.asana.logo = "/static/images/logo/asana-logo.svg";
      } else {
        this.asana.logo = "/static/images/logo/asana-logo-grey.svg";
      }

      if (name === "clickup") {
        this.clickup.logo = "/static/images/logo/clickup.svg";
      } else {
        this.clickup.logo = "/static/images/logo/clickup-grey.svg";
      }

      if (name === "monday") {
        this.monday.logo = "/static/images/logo/monday.svg";
      } else {
        this.monday.logo = "/static/images/logo/monday-grey.svg";
      }
      this.platform = name;
    },
  },
  computed: {
    importDataHistoryList() {
      return this.importDataHistories ? this.importDataHistories : [];
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  cursor: pointer;
  height: 62px;
  width: 165px;
  border-radius: 5px;
  .card-text {
    font-size: 25px;
    font-weight: 600;
  }
}
.history-card {
  border: 1px solid #929398;
  background: #e8edf1;
  color: #2b3c58;
}
.inactive-card {
  color: #929398;
  background: #e8edf1;
  border: 1px solid #929398;
}
.active-card {
  color: #2b3c58;
  background: #ffffff;
  cursor: pointer;
  border: 2px solid #006eff;
}
</style>
