<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card
      style="flex:0 1 1240px;max-width:100%;height:100%;max-height:800px;"
    >
      <div class="text-right q-mt-sm q-mr-sm">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <div class="row create-vb-workspace">
        <!-- Left Column -->
        <div class="flex-1 justify-start">
          <span class="an-bold-35 wp-title">Direct Message</span>
          <div class="divider section-spacer"></div>
          <div class="medium-text an-20 mt-4">
            <span class="text-dark">
              Find members of your company that you can message with by typing
              their name using the search field on the right. </span
            >These messages are one on one and private.
          </div>
        </div>
        <!-- Right Column -->
        <div class="flex-y flex-1">
          <div>
            <p class="secondary--text" v-if="errorMessage.flag">
              {{ errorMessage.text }}
            </p>
            <q-input
              v-model="search"
              autofocus
              outlined
              placeholder="Find members and start a conversation"
              ref="peoplesearchbar"
            />
          </div>
          <!-- Scrollable section -->
          <div class="flex-1 scroll-y px-5">
            <q-list class="q-mt-lg " separator>
              <template v-for="user in companyMembers">
                <q-item
                  :key="user.id"
                  @click.stop="create(user.id)"
                  :disabled="loader"
                  clickable
                  class="group-hover"
                >
                  <q-item-section avatar>
                    <q-avatar size="40px" color="grey-4" text-color="grey-10">
                      <img
                        :src="user.pic"
                        :alt="user.first"
                        v-if="user && user.pic"
                      />
                      <!-- <span
                        class="text-avatar-style an-14 demi-bold-text "
                        v-else
                      >
                        {{ user.first | firstChar }}{{ user.last | firstChar }}
                      </span> -->
                      <avatar v-else :size="40" :customStyle="{'font-size':'16px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label lines="1">
                      <strong>{{ user.username }}</strong>
                      {{ user.first | capitalize }} {{ user.last | capitalize }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-icon
                      :name="$icons.matAddCircleOutline"
                      size="md"
                      color="grey-5"
                      class="group-hover-item"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
          </div>
        </div>
        <img :src="monsterImg.private" alt="Monster" class="monster" />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import errorHandler from "@/utils/ErrorHandler";
import UserScope from "@/mixins/UserScope";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import { CreatePeopleWorkspaceMutation, Workspaces2Query } from "@/gql";
import mixpanel from "@/mixpanel";
import Avatar from 'vue-avatar';

export default {
  name: "CreatePeopleWorkspaceDialog",
  props: ["workspace", "members"],
  mixins: [UserScope, ProxyModelMixin],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      id: null,
      loader: false,
      search: null,
      monsterImg: {
        public: "/static/images/workspace/wp-public-bg-img.svg",
        private: "/static/images/workspace/wp-private-monster-bg.svg",
      },
      errorMessage: {
        flag: false,
        text: null,
      },
    };
  },
  components:{
    Avatar
  },
  methods: {
    async create(member) {
      this.loader = true;
      let variables = {
        user_id: member,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CreatePeopleWorkspaceMutation,
          variables,
        });
        mixpanel.track("Workspace Create", { type: "people" });
        this.loader = false;
        if (response) {
          const newWorkspace = response.data.createPeopleWorkspace;
          const query = this.$api.getQuery("Workspaces2Query");
          const peopleWorkspace = {
            __typename: "workspace",
            ...response.data.createPeopleWorkspace,
            folder: [],
            hasbookmarked: 0,
            is_preferred: false,
            notification_level: "all",
            read_cursor: 0,
            timelogs: [],
            unreadMessagePoint: null,
            boards: [
              {
                privateTasks: [],
                tasks: [],
                workspace_id: response.data.createPeopleWorkspace.id,
                __typename: "board",
              },
            ],
          };
          if (query.data && query.data.workspaces2) {
            query.data.workspaces2.unshift(peopleWorkspace);
          } else {
            query.data.workspaces2 = [peopleWorkspace];
          }
          this.$eventBus.$emit("updateWorkspace", newWorkspace.id);
          this.$emit("success", newWorkspace.id);
          await this.$api.query({
            query: Workspaces2Query,
            cacheKey: "Workspaces2Query",
          });
        }
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    companyMembers() {
      if (this.members) {
        let search = this.search ? this.search.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1").toLowerCase() : "";
        // return this.members.filter((member) => {
        //   return (
        //     member.email.match(search) ||
        //     member.first.toLowerCase().match(search) ||
        //     member.last.toLowerCase().match(search)
        //   );
        //  });
        return this.members.filter((u) => u.fullname.toLowerCase().includes(search.toLowerCase()));
      } else {
        return [];
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;
      requestAnimationFrame(() => {
        this.$refs.peoplesearchbar.focus();
      });
    },
  },
};
</script>
<style lang="stylus" scoped></style>
