<template>
  <div class="relative-position pa-4">
    <!-- <q-btn
      flat
      dense
      size="12px"
      :icon="$icons.matClose"
      round
      no-caps
      class="inborader--text q-mr-sm q-mt-sm absolute-top-right"
      @click.native.stop="closeForgotCompanyHandler"
    /> -->
    <div class="justify-center px-3">
      <div class="text-center">
        <img
          class="my-2"
          src="https://s3-us-west-1.amazonaws.com/vabotu/default/question_mark_icon.svg"
          alt="Vabotu"
          height="145px"
        />
        <h2 class="headline-text my-3">Don't Know the Team Name ?</h2>
        <p class="headline-caption-text text-subtitle1">
          Provide your e-mail address below & we'll email you the teams that you
          are a part of.
        </p>
      </div>
      <div class="mt-4">
        <form v-on:submit.prevent="$v.$invalid ? null : submit()">
          <p
            class="pink--text"
            v-if="emailExisted && !loader"
            v-text="$t('message.validation.email.notExist')"
          ></p>
          <q-input
            v-model="email"
            @blur="$v.email.$touch()"
            placeholder="Your company email address"
            type="text"
            color="primary"
            bottom-slots
            :error="fieldErrors('email').length > 0"
            no-error-icon
          >
            <template v-slot:error>
              <div class="text-pink" style="margin-top:-4px">
                {{
                  fieldErrors("email").length > 0 ? fieldErrors("email")[0] : ""
                }}
              </div>
            </template>
          </q-input>
          <q-btn
            type="submit"
            color="primary"
            class="full-width q-py-sm"
            label="Send"
            no-caps
            :loading="loader"
            :disabled="$v.$invalid || loader"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import { SendForgotCompanyMailMutation } from "@/gql";

export default {
  name: "ForgotCompany",
  mixins: [validationMixin],
  props: ["closeForgotCompanyHandler"],
  validations: {
    email: { required, email },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
  },
  data() {
    return {
      loader: false,
      email: null,
      emailExisted: false,
    };
  },
  methods: {
    async submit() {
      this.emailExisted = false;
      this.loader = true;
      const variables = {
        email: this.email,
      };
      try {
        const response = await this.$api.mutate({
          mutation: SendForgotCompanyMailMutation,
          variables,
        });

        if (response.data.sendForgotCompanyMail) {
          this.$emit("success", response.data.sendForgotCompanyMail);
        } else {
          this.emailExisted = true;
        }

        this.loader = false;
      } catch (error) {
        this.loader = false;
      }

      this.loader = false;
    },
  },
};
</script>
