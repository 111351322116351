<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card
      style="width: 277px;"
      class="q-px-lg q-py-md bg-white create-folder"
    >
      <div class="close-btn-bg close-btn">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="black"
          round
          size="sm"
          @click="closeImportProgress"
        />
      </div>
      <!-- <div class="row title">
        <span class="q-mx-auto">Create Workspace</span>
      </div> -->
      <div class="text-bold q-mt-sm q-mb-sm ">
        Your import is in progress!
      </div>
      <div class="q-mt-sm q-mb-sm ">
        We'll email you as soon as it is done.
      </div>
      <div>
        <q-btn
          dense
          no-caps
          padding="3px 15px 3px 15px"
          class="create-task-btn q-mt-md full-width"
          color="primary"
          size="md"
          label="Ok"
          style="border-radius:6px"
          @click="closeImportProgress"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ImportProgressDialog",
  props: ["value", "closeImportProgress"],
  api: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
