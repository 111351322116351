<template>
  <div class="q-pa-md">
    <div class="text-center">
      <img class="mt-3" :src="forgotPasswordImage" height="145" />
      <h2 class="headline-text my-3">Set New Password</h2>
    </div>
    <div class="mt-4">
      <form v-on:submit.prevent="$v.$invalid ? null : submit()">
        <password
          v-model="password"
          :badge="false"
          hint
          @next="handlePasswordScoreEvent"
          required
        ></password>
        <q-input
          v-model="repeatPassword"
          @input="$v.repeatPassword.$touch()"
          @blur="$v.repeatPassword.$touch()"
          placeholder="Confirm New Password"
          type="password"
          bottom-slots
          outlined
          class="q-mt-sm"
          :error="fieldErrors('repeatPassword').length > 0"
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-pink">
              {{
                fieldErrors("repeatPassword").length > 0
                  ? fieldErrors("repeatPassword")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
        <q-btn
          type="submit"
          color="primary"
          class="full-width q-py-sm q-mt-sm"
          label="SET NEW PASSWORD"
          :loading="loader"
          :disabled="$v.$invalid"
        />
      </form>
      <p class="pink--text" v-if="invalidPassword">
        Password security is very low
      </p>
    </div>
  </div>
</template>
<script>
import validationMixin from "@/mixins/validationMixin";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Password from "@/components/PasswordStrength.vue";
import { validPassword } from "@/utils/validators";
import { ResetPasswordMutation } from "@/gql";

export default {
  props: ["email", "companyUri", "code"],
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
      validPassword,
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  validationMessages: {
    password: {
      required: "message.validation.password.required",
      minLength: "message.validation.password.min",
      validPassword: "message.validation.password.validPassword",
    },
    repeatPassword: {
      sameAsPassword: "message.validation.password.confirm",
    },
  },
  components: {
    Password,
  },
  data() {
    return {
      loader: false,
      password: null,
      repeatPassword: null,
      passwordScore: 0,
      invalidPassword: null,
      forgotPasswordImage:
        "https://s3-us-west-1.amazonaws.com/vabotu/default/dark_clock_no-circle.svg",
    };
  },
  methods: {
    handlePasswordScoreEvent(data) {
      this.passwordScore = data.score;
    },
    async submit() {
      this.loader = true;
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
        company_uri: this.companyUri,
        password: this.password,
      };

      try {
        await this.$api.mutate({
          mutation: ResetPasswordMutation,
          variables,
        });

        this.$emit("success", { next: true });
        this.loader = false;
      } catch (error) {
        this.loader = false;
        if (error.graphQLErrors[0].message === "PasswordInvalid") {
          this.invalidPassword = true;
        }
      }
    },
  },
};
</script>
