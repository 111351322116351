<template>
  <q-card>
    <q-card
      style="background:#fff;min-width: 987px;"
      class="q-px-lg  company-timesheet"
      v-if="timelogs.length || isAddTaskTime"
    >
      <div class="q-pt-md sticky-header">
        <div class="row justify-between q-mt-sm">
          <div class="row items-center">
            <div class="text-bold q-mr-lg" style="font-size:16px">
              Timesheet
            </div>
            <div class="row">
              <div class="hourly-rate">
                <q-input
                  v-model="hourlyRate"
                  outlined
                  dense
                  @keypress="onlyNumber"
                  placeholder="Hourly rate"
                  autofocus
                >
                  <template v-slot:prepend>
                    <q-icon :name="$icons.matAttachMoney" />
                  </template>
                  <template v-slot:append>
                    <q-btn
                      label="Apply"
                      size="sm"
                      no-caps
                      text-color="white"
                      class="apply-btn"
                      @click="applyHourlyPrice()"
                    />
                  </template>
                </q-input>
              </div>
            </div>
            <div
              @click="
                selectedUser = null;
                selectedDate = null;
              "
              v-if="selectedUser || selectedDate"
              class="text-primary q-mx-sm cursor-pointer"
            >
              Clear filter
            </div>
          </div>
          <div class="row items-center">
            <div class="row" v-if="user.roles[0].id === 1">
              <q-btn
              :icon="$icons.fasUser"
              no-caps
              label="User"
              dense
              class="q-px-sm time-log-date-user-btn q-mr-sm"
              flat
              size="12px"
            >
              <q-menu
                v-model="menu"
                content-class="q-px-md overflow-auto assign-member-menu"
              >
                <div class="sticky-header">
                  <div class="close-btn cursor-pointer" @click="menu = false">
                    <q-icon :name="$icons.matClose" class="close-icon" />
                  </div>
                  <div class="assign-to q-py-sm">
                    Filter by
                  </div>
                  <div class="search-member">
                    <q-input
                      v-model="search"
                      outlined
                      dense
                      clearable
                      placeholder="Search Member"
                      autofocus
                    >
                      <template v-slot:prepend>
                        <q-icon :name="$icons.matSearch" />
                      </template>
                    </q-input>
                  </div>
                </div>
                <q-list class="q-py-sm member-list">
                  <template v-for="user in fileteredUsers">
                    <q-item
                      class="q-px-none q-py-xs member-item items-center"
                      :key="user.id"
                      @click="
                        selectedUser = user;
                        menu = false;
                      "
                      clickable
                    >
                      <q-item-section class="member-avatar" avatar>
                        <q-avatar size="32px" v-if="user.pic">
                          <img :src="user.pic" :alt="user.first" />
                        </q-avatar>
                        <!-- <q-avatar
                          size="32px"
                          color="grey-4"
                          text-color="grey-10"
                          v-else
                        >
                          {{ user.first | firstChar
                          }}{{ user.last | firstChar }}
                        </q-avatar> -->
                        <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                      </q-item-section>
                      <q-item-section class="member-name" v-tooltip>
                        {{ user.first | capitalize }}
                        {{ user.last | capitalize }}
                        <q-tooltip>
                          {{ user.first | capitalize }}
                          {{ user.last | capitalize }}
                        </q-tooltip>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </q-menu>
              </q-btn>
            </div>
            <div class="row">
              <q-btn
                :icon="$icons.fasCalendar"
                @click="datePickermenu = true"
                no-caps
                label="Date"
                dense
                class="q-mr-sm q-px-sm time-log-date-filter time-log-date-user-btn"
                flat
                size="12px"
              >
              </q-btn>
              <div>
                <q-menu
                  v-model="datePickermenu"
                  max-height="auto"
                  content-class="overflow-auto date-picker-calendar"
                >
                  <q-date
                    mask="YYYY-MM-DD"
                    :value="selectedDate"
                    today-btn
                    @input="updatedDate"
                    color="blue-13"
                  >
                  </q-date>
                </q-menu>
              </div>
            </div>
            <div class="row">
              <q-btn
                dense
                flat
                round
                color="grey-10"
                :icon="$icons.matMoreHoriz"
                size="12px"
                class="q-mr-xs"
              >
                <q-menu auto-close>
                  <q-list>
                    <q-item
                      @click="confirmRemoveTimesheet.flag = true;"
                      clickable
                    >
                      <q-item-section>
                        Clear
                      </q-item-section>
                    </q-item>
                    <q-item
                      @click="exportTimeLogCsv"
                      clickable
                    >
                      <q-item-section>
                        Export CSV
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </div>
        </div>
        <q-btn
          label="Add session manually"
          color="primary"
          text-color="white"
          class="full-width q-mb-xs q-mt-md"
          style="border-radius:6px"
          v-if="!fromWorkspace && !fromCompany"
          @click="addTaskTimeLog = true"
        />
        <add-task-time-log
          v-if="addTaskTimeLog"
          v-model="addTaskTimeLog"
          :addTaskTimeLog="addTaskTimeLog"
          :taskData="taskData"
          :closeAddingTimeLog="() => onCloseAddingTimeLog()"
          :addTaskTimeLogEventHandler="
            (data) => addTaskTimeLogEventMyHandler(data)
          "
        >
        </add-task-time-log>
      </div>
      <div class="q-py-md">
        <template v-for="(week, index) in sortedWeekGroup">
          <div class="q-mb-md week-table-head" :key="'weeks' + index">
            <div
              :key="'week' + index"
              class="week-table row items-center justify-between q-px-lg q-py-sm"
            >
              <div>
                {{
                  isThisWeek(fromUnixTime(week[0].start_date), {
                    weekStartsOn: 1,
                  })
                    ? "This week"
                    : getWeek(new Date(), {
                        weekStartsOn: 1,
                      }) -
                        getWeek(fromUnixTime(week[0].start_date), {
                          weekStartsOn: 1,
                        }) ===
                      1
                    ? format(
                        startOfWeek(fromUnixTime(week[0].start_date), {
                          weekStartsOn: 1,
                        }),
                        "LLL dd"
                      ) +
                      " - " +
                      format(
                        endOfWeek(fromUnixTime(week[0].start_date), {
                          weekStartsOn: 1,
                        }),
                        "LLL dd"
                      )
                    : format(
                        startOfWeek(fromUnixTime(week[0].start_date), {
                          weekStartsOn: 1,
                        }),
                        "LLL dd"
                      ) +
                      " - " +
                      format(
                        endOfWeek(fromUnixTime(week[0].start_date), {
                          weekStartsOn: 1,
                        }),
                        "LLL dd"
                      )
                }}
              </div>
              <div class="row">
                <div>
                  <span class="q-mr-xs">Total pay </span
                  >${{ getWeekTotalHourlyRate(week) }}
                </div>
                <div class="q-mx-sm">|</div>
                <div>
                  <span class="q-mr-xs">Weekly hours </span
                  >{{ getWeekTotalHours(week) }}
                </div>
              </div>
            </div>
            <q-table
              :key="index"
              :data="week"
              :columns="fromCompany ? companyColumns : columns"
              hide-bottom
              separator="none"
              flat
              class="bg-white q-pa-sm timlog-table"
              :rows-per-page-options="[0]"
            >
              <template v-slot:body="props">
                <q-tr
                  :key="`e_${props.row.id}`"
                  no-hover
                  :props="props"
                  class="group-hover"
                  :class="[props.length - 1 === index && 'q-pb-sm']"
                >
                  <q-td auto-width :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }" >
                    <q-btn size="xs" color="primary" class="add-remove-btn" round dense @click="props.expand = !props.expand" :icon="props.expand ? $icons.fasMinus : $icons.fasPlus " />
                  </q-td>
                  <q-td
                    :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }"
                    key="workspace_name"
                    class="workspace-col"
                    v-if="fromCompany"
                  >
                    <label>
                      {{ getWorkspaceName(props.row.workspace_id) }}
                      <q-tooltip>
                        {{ getWorkspaceName(props.row.workspace_id) }}
                      </q-tooltip>
                    </label>
                  </q-td>
                  <q-td :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }" key="user" class="user-column">
                    <div
                      @click="showUserInfo(props.row.owner)"
                      class="row items-center cursor-pointer"
                    >
                      <q-item-section avatar clickable>
                        <q-avatar size="36px" v-if="props.row.owner.pic">
                          <img
                            :src="props.row.owner.pic"
                            :alt="props.row.owner.first"
                          />
                          <q-tooltip>
                            {{ props.row.owner.first }} {{ props.row.owner.last }}
                          </q-tooltip>
                        </q-avatar>
                        <avatar v-else :size="36" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="props.row.owner.first+' '+props.row.owner.last">
                        <q-tooltip>
                          {{ props.row.owner.first }}
                          {{ props.row.owner.last }}
                        </q-tooltip>
                      </avatar>
                      </q-item-section>
                    </div>
                  </q-td>
                  <q-td :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }" key="date" class="date-column">
                    {{ format(fromUnixTime(props.row.start_date), "EEE LLL dd") }}
                  </q-td>
                  <q-td
                    key="task"
                    @click="edit(props.row.task, null, props.row.workspace_id)"
                    class="task-col cursor-pointer text-primary"
                    :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }"
                  >
                    {{ getTaskName(props.row.task_id) }}
                  </q-td>
                  <q-td key="duration" class="duration-col " :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }">
                    {{ timeCaclulate(props.row.start_date, props.row.end_date) }}
                  </q-td>
                  <q-td key="hourlyRate" class="duration-col" :style="week.length - 1 === props.rowIndex && { 'border-bottom': 'none' }">
                    {{
                      hourlyRateCaclulate(
                        props.row.start_date,
                        props.row.end_date,
                        props.row.owner.hourly_rate
                      )
                    }}
                  </q-td>
                </q-tr>
                <q-tr
                :key="`edit_${props.row.id}`"
                v-show="props.expand" :props="props"
                >
                  <q-td></q-td>
                  <q-td colspan="4" key="description" class="task-desc-col">
                    <div class="q-mb-xs">
                      <span style="color: #68758B;font-size:12px">What did you work on?</span>
                    </div>
                    <div>
                      <q-input
                        style="border: 1px solid #B3BAC5;border-radius: 3px;color: #42526E;"
                        rows="3"
                        dense
                        class="timelog-desc"
                        outlined
                        placeholder="Description"
                        @input="(val) => handleTimelogDescription(val, props.row)"
                        :value="props.row.description"
                        bg-color="white"
                        type="textarea"
                      />
                    </div>
                </q-td>
                  <q-td colspan="1" key="timeRange" class="timerange-col" style="vertical-align: top;">
                    <div class="row flex-no-wrap">
                      <div>
                        <div class="q-mb-xs">
                          <span style="color: #68758B;font-size:12px">Started</span>
                        </div>
                        <input
                          class="q-mr-sm time-input bg-white"
                          type="time"
                          @input="handleTimelogStarttime"
                          :value="
                            format(fromUnixTime(props.row.start_date), 'HH:mm')
                          "
                        />
                      </div>
                    <div>
                      <div class="q-mb-xs">
                        <span style="color: #68758B;font-size:12px">Ended</span>
                      </div>
                      <input
                        class="q-mr-sm time-input bg-white"
                        type="time"
                        @input="handleTimelogEndttime"
                        :value="format(fromUnixTime(props.row.end_date), 'HH:mm')"
                      />
                    </div>
                    </div>
                  </q-td>
                  <q-td key="hourlyRate" class="duration-col" style="vertical-align: bottom;">
                    <div class="row q-mb-xs">
                      <q-btn
                        style="width: 88px;"
                        label="Done"
                        size="md"
                        no-caps
                        color="primary"
                        class="apply-btn"
                        @click="updateTaskTimelog(props.row, props)"
                      />
                    </div>
                    <div class="row">
                      <q-btn
                        style="width: 88px;"
                        label="Delete"
                        size="md"
                        no-caps
                        flat
                        color="primary"
                        class="text-red"
                        @click="confirmBoxHandler(true, props.row)"
                      />
                    </div>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </template>
        <q-separator class="q-my-md" style="background:#E3E8ED;" />
        <div
          class="row items-center justify-between q-px-lg q-py-sm"
        >
          <div></div>
          <div class="row">
            <div class="q-mr-sm">
              <span style="color:#94a6ba" class="q-mr-xs">Total cost:</span
              >${{ getTimlogsTotalHourlyRate() }}
            </div>
            <div>
              <span style="color:#94a6ba" class="q-mr-xs">Total time:</span
              >{{ getTimlogsTotalHours() }}
            </div>
          </div>
        </div>
      </div>
    </q-card>
    <q-card
      class="q-px-lg  company-timesheet"
      style="background:#f2f6f8;min-width: 700px;"
      v-else
    >
      <div class="text-center q-pa-lg">
        <div class="close-btn-bg q-mr-sm" style="right: 0px;">
          <q-btn
            dense
            flat
            padding="0"
            size="sm"
            style="color:#a3a9b1;margin-left: 2px;margin-top:-2px; right: 0px;"
            :icon="$icons.matClose"
            @click="closeDialog"
          />
        </div>
        <q-avatar :style="{ background: '#DFEFFF' }" size="42px">
          <q-icon style="color:#007AFF" :name="$icons.matSchedule" />
        </q-avatar>
        <div class="text-blue-grey-7 an-15 regular-text q-mt-md">
          There are no hours tracked.
        </div>
      </div>
    </q-card>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :currentWorkspaceId="workspace.id"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <confirm-dialog
      v-if="confirmRemoveTimesheet.flag"
      v-model="confirmRemoveTimesheet.flag"
      title="Delete time report?"
      question="Are you sure you want to delete all your time entries?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmTimesheetHandler(false)"
      :successMethod="deleteTaskTimeLogs"
      :loading="false"
    />
    <confirm-dialog
      v-if="confirmRemoveTimelog.flag"
      v-model="confirmRemoveTimelog.flag"
      title="Remove time report?"
      question="Are you sure you want to delete your time log?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteTaskTimeLog"
      :loading="false"
    />
  </q-card>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
// import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";

import map from "lodash/map";
import getWeek from "date-fns/getWeek";
import getYear from "date-fns/getYear";
import parseISO from "date-fns/parseISO";
import fromUnixTime from "date-fns/fromUnixTime";
import isThisWeek from "date-fns/isThisWeek";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";

// import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
// import MediaMethodMixin from "@/mixins/MediaMethodMixin";
// import TaskDialog from "@/views/BoardView/TaskDialog";
import ProfileDialog from "@/components/ProfileDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { exportTimeLogs } from "@/services/auth";
import Avatar from 'vue-avatar'

import {
  DeleteTaskTimeLogsMutation,
  UpdateTaskTimeLogMutation,
  CreateTaskTimeLogMutation,
  DeleteTaskTimeLogMutation,
  UpdateHourlyRateMutation,
} from "@/gql";
import AddTaskTimeLog from "@/components/AddTaskTimeLog";
// import DescriptionEditDialog from "@/components/Dialogs/DescriptionEditDialog";
import mixpanel from "@/mixpanel";
import { getUnixTime } from "date-fns/esm";
export default {
  name: "CompanyTimesheetDialog",
  // mixins: [BoardsMethodMixin, MediaMethodMixin],
  props: [
    "value",
    "taskObj",
    "timelogs",
    "workspace",
    "closeDialog",
    "fromWorkspace",
    "fromCompany",
    "isAddTaskTime",
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.taskWorksapce.id}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.taskWorksapce.id}`;
      },
    },
  },
  mounted() {
    this.hourlyRate = this.user && this.user.hourly_rate;
  },
  components: {
    // TaskDialog,
    ProfileDialog,
    AddTaskTimeLog,
    // DescriptionEditDialog,
    ConfirmDialog,
    Avatar,
  },
  data() {
    return {
      format,
      fromUnixTime,
      getWeek,
      isThisWeek,
      startOfWeek,
      endOfWeek,
      menu: false,
      timelogDescription: null,
      timelogStarttime: null,
      timelogEndtime: null,
      search: null,
      selectedUser: null,
      selectedDate: null,
      profileDialog: {
        show: false,
        user: null,
      },
      isDescriptionDialogOpen: false,
      expandedRows: [],
      columns: [
        {
          name: "user",
          align: "left",
          label: "User",
          //classes: 'user-column',
          headerClasses: "user-column",
        },
        {
          name: "date",
          align: "left",
          label: "Date",
          headerClasses: "date-column",
        },
        {
          name: "task",
          align: "left",
          label: "Task",
          field: "",
          headerClasses: "task-col",
        },
        {
          name: "description",
          align: "left",
          label: "Description",
          field: "",
          headerClasses: "task-desc-col",
        },
        {
          name: "timeRange",
          align: "left",
          label: "Time range",
          headerClasses: "timerange-col",
        },
        {
          name: "hourlyRate",
          align: "left",
          label: "$ Total/rate",
          headerClasses: "hourlyrate-col",
        },
        {
          name: "duration",
          align: "left",
          label: "Duration",
          headerClasses: "duration-col",
        },
      ],
      companyColumns: [
      {
          name: "black",
          align: "left",
          label: "",
          //classes: 'user-column',
          headerClasses: "",
        },
        {
          name: "workspace_name",
          align: "left",
          label: "Workspace",
          headerClasses: "workspace-col",
        },
        {
          name: "user",
          align: "left",
          label: "User",
          //classes: 'user-column',
          headerClasses: "user-column",
        },
        {
          name: "date",
          align: "left",
          label: "Date",
          headerClasses: "date-column",
        },
        {
          name: "task",
          align: "left",
          label: "Task",
          field: "",
          headerClasses: "task-desc-col",
        },
        {
          name: "duration",
          align: "left",
          label: "Duration",
          headerClasses: "duration-col",
        },
        {
          name: "hourlyRate",
          align: "left",
          label: "Earnings",
          headerClasses: "hourlyrate-col",
        },
      ],
      addTaskTimeLog: false,
      taskData: null,
      datePickermenu: false,
      date: null,
      taskWorksapce: this.workspace,
      board: null,
      editorFocused: null,
      hourlyRate: null,
      confirmRemoveTimesheet:{
        flag:false,
      },
      confirmRemoveTimelog: {
        flag: false,
        data: null,
      },
    };
  },
  methods: {
    confirmBoxHandler(flag = true, data = null) {
      this.confirmRemoveTimelog = {
        flag,
        data,
      };
    },
    confirmTimesheetHandler(flag){
      this.confirmRemoveTimesheet.flag = flag;
    },
    async applyHourlyPrice() {
      if(this.user.hourly_rate != this.hourlyRate) {
        let variables = {
          hourly_rate: this.hourlyRate,
        };
        this.user.hourly_rate = this.hourlyRate;
        await this.$api.mutate({
          mutation: UpdateHourlyRateMutation,
          variables,
        });
      }
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    hourlyRateCaclulate(startDate, endDate, userRate) {
      if(userRate) {
        let dateFuture = fromUnixTime(endDate);
        let dateNow = fromUnixTime(startDate);

        let seconds = Math.floor((dateFuture - dateNow) / 1000);
        var sec_num = parseInt(seconds, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num / 60) % 60;
        
        return '$'+((hours + (minutes / 60)) * userRate).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      return "$0.00";
    },
    getTotalHourlyRate(timelogs) {
        let total = 0;
        timelogs.forEach((timelog) => {
          let dateFuture = fromUnixTime(timelog.end_date);
          let dateNow = fromUnixTime(timelog.start_date);

          let seconds = Math.floor((dateFuture - dateNow) / 1000);
          var sec_num = parseInt(seconds, 10);
          var hours = Math.floor(sec_num / 3600);
          var minutes = Math.floor(sec_num / 60) % 60;
          total += (hours + (minutes / 60)) * timelog.owner.hourly_rate;
        });
        return '$'+ total ? (total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0';
    },
    getWeekTotalHourlyRate(week) {
      // console.log("week", week);
      var merged = [].concat.apply([], week);
      let total = 0;
      merged.forEach((timelog) => {
          // console.log("timelog", timelog);
          let dateFuture = fromUnixTime(timelog.end_date);
          let dateNow = fromUnixTime(timelog.start_date);

          let seconds = Math.floor((dateFuture - dateNow) / 1000);
          var sec_num = parseInt(seconds, 10);
          var hours = Math.floor(sec_num / 3600);
          var minutes = Math.floor(sec_num / 60) % 60;
          total += timelog.owner.hourly_rate ? (hours + (minutes / 60)) * timelog.owner.hourly_rate : 0;
        });
        // var second = seconds % 60;
        return '$'+ total ? (total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0';
    },
    getTimlogsTotalHourlyRate() {
      let total = 0;
      this.timelogs.forEach((timelog) => {
        let dateFuture = fromUnixTime(timelog.end_date);
        let dateNow = fromUnixTime(timelog.start_date);

        let seconds = Math.floor((dateFuture - dateNow) / 1000);
        let sec_num = parseInt(seconds, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor(sec_num / 60) % 60;
        total += timelog.owner.hourly_rate ? (hours + (minutes / 60)) * timelog.owner.hourly_rate : 0;
      });
      return '$'+ total ? total.toFixed(2) : '0';
    },
    closeDescDialog() {
      this.isDescriptionDialogOpen = false;
    },
    handleEditorFocus(id) {
      this.editorFocused = id;
      this.isDescriptionDialogOpen = true;
    },
    getTimlogsTotalHours() {
      let seconds = 0;
      this.timelogs.forEach((timelog) => {
        let dateFuture = fromUnixTime(timelog.end_date);
        let dateNow = fromUnixTime(timelog.start_date);

        let tmp = Math.floor((dateFuture - dateNow) / 1000);
        seconds += tmp;
      });
      var sec_num = parseInt(seconds, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      // var second = seconds % 60;
      return hours + "h " + minutes + "m ";
    },
    updatedDate(date) {
      this.selectedDate = date;
      this.datePickermenu = false;
    },
    rowExpansionHandler(id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter((row) => row !== id);
      } else {
        this.expandedRows.push(id);
      }
    },
    edit(task, isOpen = null, workspaceId) {
      if (this.fromCompany) {
        this.taskWorksapce = this.$api.getEntity("workspace", workspaceId);
      }
      this.board = this.$api.getEntity(
        "board",
        this.taskWorksapce.boards[0].id
      );
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    closeTaskDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    async updateTaskTimelog(timelog, data) {
      let variables;

      if (this.timelogStarttime) {
        const updatedStartime = getUnixTime(
          parseISO(
            format(fromUnixTime(timelog.start_date), "yyyy-MM-dd") +
              " " +
              this.timelogStarttime
          )
        );
        if (timelog.end_date > updatedStartime) {
          variables = {
            ...variables,
            start_date: updatedStartime,
          };
          
        } else {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Invalid time value",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          return false;
        }
      }
      
      if (this.timelogEndtime) {
        const updatedEndime = getUnixTime(
          parseISO(
            format(fromUnixTime(timelog.end_date), "yyyy-MM-dd") +
              " " +
              this.timelogEndtime
          )
        );
        if (timelog.start_date < updatedEndime) {
          variables = {
            ...variables,
            end_date: updatedEndime,
          };
        } else {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Invalid time value",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          return false;
        }
      }

      if (this.timelogDescription) {
        variables = {
          ...variables,
          description: this.timelogDescription,
        };
      }
      
      if(variables) {
        variables = {
          ...variables,
          id: timelog.id,
        };
        await this.$api.mutate({
          mutation: UpdateTaskTimeLogMutation,
          variables,
        });

        const response = await this.$api.mutate({
          mutation: UpdateTaskTimeLogMutation,
          variables,
        });

        if(response.data.updateTaskTimeLog) {
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Timesheet updated.",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          data.expand = false;
        }

      }
      this.editorFocused = null;
      this.timelogDescription = null;
      this.timelogEndtime = null;
      this.timelogStarttime = null;
      
    },
    async updateStartTime(timelog) {
      if (this.timelogStarttime) {
        const updatedStartime = getUnixTime(
          parseISO(
            format(fromUnixTime(timelog.start_date), "yyyy-MM-dd") +
              " " +
              this.timelogStarttime
          )
        );
        if (timelog.end_date > updatedStartime) {
          const variables = {
            start_date: updatedStartime,
            id: timelog.id,
          };
          await this.$api.mutate({
            mutation: UpdateTaskTimeLogMutation,
            variables,
          });
        } else {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Invalid time value",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.timelogStarttime = null;
    },
    async updateEndTime(timelog) {
      if (this.timelogEndtime) {
        const updatedEndime = getUnixTime(
          parseISO(
            format(fromUnixTime(timelog.end_date), "yyyy-MM-dd") +
              " " +
              this.timelogEndtime
          )
        );
        if (timelog.start_date < updatedEndime) {
          const variables = {
            end_date: updatedEndime,
            id: timelog.id,
          };
          await this.$api.mutate({
            mutation: UpdateTaskTimeLogMutation,
            variables,
          });
        } else {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Invalid time value",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.timelogEndtime = null;
    },
    handleTimelogStarttime(e) {
      this.timelogStarttime = e.target.value;
    },
    handleTimelogEndttime(e) {
      this.timelogEndtime = e.target.value;
    },
    async handleTimelogDescription(val) {
      this.timelogDescription = val;
    },
    async updateTimelogDescription(timelog) {
      if (this.timelogDescription) {
        const variables = {
          description: this.timelogDescription,
          id: timelog.id,
        };
        await this.$api.mutate({
          mutation: UpdateTaskTimeLogMutation,
          variables,
        });
      }
      this.editorFocused = null;
      this.timelogDescription = null;
    },
    getWeekTotalHours(week) {
      var merged = [].concat.apply([], week);
      let seconds = 0;
      merged.forEach((timelog) => {
        let dateFuture = fromUnixTime(timelog.end_date);
        let dateNow = fromUnixTime(timelog.start_date);

        let tmp = Math.floor((dateFuture - dateNow) / 1000);
        seconds += tmp;
      });
      var sec_num = parseInt(seconds, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      // var second = seconds % 60;
      return hours + "h " + minutes + "m ";
    },
    getTotalHours(timelogs) {
      let seconds = 0;
      timelogs.forEach((timelog) => {
        let dateFuture = fromUnixTime(timelog.end_date);
        let dateNow = fromUnixTime(timelog.start_date);

        let tmp = Math.floor((dateFuture - dateNow) / 1000);
        seconds += tmp;
      });
      var sec_num = parseInt(seconds, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      // var second = seconds % 60;
      return hours + "h " + minutes + "m ";
    },
    getTaskName(taskId) {
      return this.$api.getEntity("task", taskId).title;
    },
    getWorkspaceName(workspaceId) {
      return this.$api.getEntity("workspace", workspaceId).title;
    },
    getOptWorkspaceName(name) {
      return name.substring(0, 10);
    },
    timeCaclulate(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      let seconds = Math.floor((dateFuture - dateNow) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      return days * 24 + hours + "h " + minutes + "m ";
    },
    timeCalculateWithoutSeconds(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      let seconds = Math.floor((dateFuture - dateNow) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      return days * 24 + hours + "h " + minutes + "m";
    },
    async deleteTaskTimeLogs() {
      if (this.fromWorkspace) {
        const variables = {
          workspace_id: this.taskWorksapce.id,
          user_id: this.user.id,
          task_id: 0,
        };
        this.taskWorksapce.timelogs = [];
        await this.$api.mutate({
          mutation: DeleteTaskTimeLogsMutation,
          variables,
        });
      } else if (this.fromCompany) {
        let userId = this.user.roles[0].id === 1 ? 0 : this.user.id;
        const variables = {
          workspace_id: 0,
          user_id: userId,
          task_id: 0,
        };

        await this.$api.mutate({
          mutation: DeleteTaskTimeLogsMutation,
          variables,
        });
        const query = this.$api.getQuery("Workspaces2Query");
        if (query.data && query.data.workspaces2) {
          query.data.workspaces2.forEach((key, value) => {
            query.data.workspaces2[value].timelogs = [];
            if (query.data.workspaces2[value].boards) {
              query.data.workspaces2[value].boards.forEach(
                (element, elementKey) => {
                  if (element.tasks) {
                    query.data.workspaces2[value].boards[
                      elementKey
                    ].tasks = element.tasks.map((task) => {
                      task.timelogs = [];
                      return task;
                    });
                  }
                }
              );
            }
          });
        }
      } else {
        const variables = {
          task_id: this.taskObj.id,
          user_id: this.user.id,
        };
        const taskEntity = this.$api.getEntity("task", this.taskObj.id);
        taskEntity.timelogs = [];
        await this.$api.mutate({
          mutation: DeleteTaskTimeLogsMutation,
          variables,
        });
      }
      this.confirmRemoveTimesheet.flag = false;
    },
    async exportTimeLogCsv() {
      if(this.timelogs.length > 0) {
        let res;
        let userId = this.selectedUser ? this.selectedUser.id : null;
        if (this.fromWorkspace) {
          res = await exportTimeLogs(
            this.taskWorksapce.id,
            this.currentCompany.accessToken,
            0,
            false,
            userId,
            this.selectedDate,
            this.hourlyRate,
          );
        } else if (this.fromCompany) {
          res = await exportTimeLogs(
            this.taskWorksapce.id,
            this.currentCompany.accessToken,
            0,
            true,
            0,
            "",
            this.hourlyRate,
          );
        } else {
          res = await exportTimeLogs(
            this.taskWorksapce.id,
            this.currentCompany.accessToken,
            this.taskObj.id,
            false,
            userId,
            this.selectedDate,
            this.hourlyRate,
          );
        }
        if (res && res.status_code === 200) {
          window.location = res.data;
        }
      } else {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "No hours tracked.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    onCloseAddingTimeLog() {
      setTimeout(() => {
        var vm = this;
        vm.addTaskTimeLog = false;
      }, 200);
    },
    async deleteTaskTimeLog(expandedRow = null) {
      
      let data = this.confirmRemoveTimelog.data;

      this.confirmRemoveTimelog.flag = false;
      
      const variables = {
        id: data.id,
      };
      const workspace = this.$api.getEntity("workspace", data.workspace_id);
      workspace.timelogs = workspace.timelogs.filter((c) => c.id !== data.id);
      if (this.fromWorkspace) {
        this.taskWorksapce.timelogs = this.taskWorksapce.timelogs.filter(
          (c) => c.id !== data.id
        );
      } else {
        const taskEntity = this.$api.getEntity("task", data.task_id);
        taskEntity.timelogs = taskEntity.timelogs.filter(
          (c) => c.id !== data.id
        );
        if (expandedRow) {
          this.expandedRows.push(expandedRow[1].id);
        }
      }

      await this.$api.mutate({
        mutation: DeleteTaskTimeLogMutation,
        variables,
      });
    },
    async addTaskTimeLogEventMyHandler(data) {
      const variables = {
        start_date: getUnixTime(new Date(data.start_date)),
        end_date: getUnixTime(new Date(data.end_date)),
        task_id: this.taskObj.id,
        workspace_id: this.taskWorksapce.id,
        is_start: false,
      };

      let newTaskTimeLog = await this.$api.mutate({
        mutation: CreateTaskTimeLogMutation,
        variables,
      });

      const taskEntity = this.$api.getEntity("task", this.taskObj.id);
      taskEntity.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
      this.workspace.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
      this.rowExpansionHandler(newTaskTimeLog.data.createTaskTimeLog.id);
      mixpanel.track("Task Time Log Add");
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    // taskMemberIds() {
    //   return this.taskObj.members.map((m) => m.id);
    // },
    availableUsersFuse() {
      if (this.workspaceMembers) {
        const orderedMembers = orderBy(
          [this.user, ...this.workspaceMembers],
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return [this.user];
    },
    fileteredUsers() {
      return this.search
        ? this.availableUsersFuse.filter((u) =>
            u.fullname.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.availableUsersFuse.hasOwnProperty("list")
        ? this.availableUsersFuse.list
        : this.availableUsersFuse;
    },
    isWorkspaceOwner() {
      if (this.taskWorksapce && this.user) {
        return this.user.id === this.taskWorksapce.owner.id;
      }
      return false;
    },

    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    fileredTimelogs() {
      let timelogs;
      if (this.user.roles[0].id > 1) {
        timelogs = this.timelogs.filter((o) => {
          return o.owner.id === this.user.id;
        });
        
        if (this.selectedDate) {
          timelogs = this.timelogs.filter((o) => {
            return (
              format(fromUnixTime(o.start_date), "yyyy-MM-dd") ===
              this.selectedDate
            );
          });
        }
        
        return timelogs;
      }
      if (this.selectedUser) {
        timelogs = this.timelogs.filter((o) => {
          return o.owner.id === this.selectedUser.id;
        });
        //return timelogs;
      }
      if (this.selectedDate) {
        timelogs = this.timelogs.filter((o) => {
          return (
            format(fromUnixTime(o.start_date), "yyyy-MM-dd") ===
            this.selectedDate
          );
        });
        //return timelogs;
      }

      if (this.selectedUser && this.selectedDate) {
        timelogs = this.timelogs.filter((o) => {
          return (
            o.owner.id === this.selectedUser.id &&
            format(fromUnixTime(o.start_date), "yyyy-MM-dd") ===
              this.selectedDate
          );
        });
        //return timelogs;
      }
      if (this.selectedUser || this.selectedDate) {
        return timelogs;
      }
      return this.timelogs;
    },
    // weekGroup() {
    //   return reduce(
    //     groupBy(
    //       orderBy(this.fileredTimelogs, ["start_date"], ["desc"]),
    //       (timelog) => {
    //         return format(
    //           fromUnixTime(timelog["start_date"]),
    //           "EEE LLL dd yyyy"
    //         );
    //       }
    //     ),
    //     (acc, date) => {
    //       // create a composed key: 'year-week'
    //       const yearWeek = `${getYear(
    //         fromUnixTime(date[0]["start_date"])
    //       )}-${getWeek(fromUnixTime(date[0]["start_date"]))}`;

    //       // add this key as a property to the result object
    //       if (!acc[yearWeek]) {
    //         acc[yearWeek] = [];
    //       }

    //       // push the current date that belongs to the year-week calculated befor
    //       acc[yearWeek].push(date);

    //       return acc;
    //     },
    //     {}
    //   );
    // },
    weekGroup() {
      return reduce(
          orderBy(this.fileredTimelogs, ["start_date"], ["desc"]),
        (acc, date) => {
          // create a composed key: 'year-week'
          const yearWeek = `${getYear(
            fromUnixTime(date["start_date"])
          )}-${getWeek(fromUnixTime(date["start_date"]))}`;

          // add this key as a property to the result object
          if (!acc[yearWeek]) {
            acc[yearWeek] = [];
          }

          // push the current date that belongs to the year-week calculated befor
          acc[yearWeek].push(date);

          return acc;
        },
        {}
      );
    },
    sortedWeekGroup() {
      return map(this.weekGroup);
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      } else {
        return [];
      }
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
  },
};
</script>
<style scoped>
.hide-scroll {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
