<template>
  <div>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      v-model="proxyModel"
    >
      <q-card style="width: 450px;" class="text-center q-px-md q-py-sm">
        <div class="text-right">
          <q-btn
            flat
            dense
            :icon="$icons.matClose"
            color="grey"
            round
            size="md"
            @click="proxyModel = false"
          />
        </div>
        <div class="q-py-md">
          <span class="text-weight-bold" style="font-size:20px"
            >Maximum number of users reached</span
          >
          <div>
            You've reached the maximumm number of users. To invite more users
            upgrade your plan.
          </div>
        </div>
        <div class="q-mb-md">
          <q-btn
            no-caps
            color="teal-14"
            label="Upgrade Plan"
            class="q-px-sm"
            @click=" upgradePlan.flag = true;"
          />
        </div>
      </q-card>
    </q-dialog>
    <plan-details-dialog
      v-if="planDetailDialog"
      v-model="planDetailDialog"
      @closePlnDialog="proxyModel = false"
      :upgradePlanDialog="proxyModel"
    />
    <upgrade-plans-dialog
      v-if="upgradePlan.flag"
      v-model="upgradePlan.flag"
      :closeDialog="closeUpgradePlanDialog"
      :currentWorkspaceId="currentWorkspaceId"
      :currentCompany="company"
      :currentCompanyToken="currentCompany.accessToken"
    />
  </div>
</template>
<script>
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import UpgradePlansDialog from "@/components/Dialogs/UpgradePlansDialog";

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "UpgradePlanDialog",
  mixins: [ProxyModelMixin],
  components: {
    UpgradePlansDialog,
  },
  beforeDestroy() {
    this.$emit("close");
  },
  data() {
    return {
      planDetailDialog: false,
      upgradePlan: {
        flag: false,
      },
    };
  },
  api: {
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      ownCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.ownCompany) {
        return get(this.companies, this.ownCompany);
      } else {
        return null;
      }
    },
    activeCompany() {
      return get(this.companies, this.$route.params.company);
    },
    returnCurrentWorkspaceId() {
      return Number(
        this.$route.params.workspace && this.$route.params.workspace
      );
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
  },
  methods:{
    closeUpgradePlanDialog() {
      this.upgradePlan.flag = false;
    },
  }
};
</script>
