<template>
  <div
    class="group-hover q-mb-sm relative-position notification-wrapper q-py-sm"
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <div class="row flex-no-wrap q-pl-md">
      <div class="q-mr-md">
        <div
          @click="edit(notificationTask)"
          class="task-notification-icon row justify-center"
        >
          <q-icon
            :name="$icons.fasCheck"
            color="white"
            class=" self-center"
            size="20px"
          />
          <q-tooltip>
            {{ notificationTask.title }}
          </q-tooltip>
        </div>
      </div>
      <div class="row" style="width:100%">
        <div class="row justify-between" style="width:100%">
          <div
            class="an-15 demi-bold-text task-title dont-break-out"
            style="font-weight: 500;"
            @click="edit(notificationTask)"
          >
            {{ actionObject.title }}
            <q-tooltip> Assigned by {{ actionTakenByFullName }} </q-tooltip>
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md q-px-xs"
            style="right:0"
          >
            <q-btn
              size="sm"
              :icon="$icons.matMoreHoriz"
              flat
              round
              dense
              class="thread-icon"
            >
              <q-menu content-class="overflow-auto" auto-close>
                <q-list>
                  <q-item clickable @click="changeCompleteStatus">
                    <q-item-section v-if="!actionObject.completed">
                      Complete subtask
                    </q-item-section>
                    <q-item-section v-else>
                      Mark as Incomplete
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-if="!notification.read" @click="updateNotification()">
                    <q-item-section>
                      Mark read
                    </q-item-section>
                    <!-- <q-item-section v-else>
                      Mark unread
                    </q-item-section> -->
                  </q-item>
                  <q-item
                    clickable
                    @click="routeTo('BoardView', actionScope.id)"
                  >
                    <q-item-section>
                      Go to workspace
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="deleteNotification">
                    <q-item-section class="text-red">
                      Delete Notification
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
        <div class="time" style="font-size:12px;">
          {{ dateTimeFormat() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationNavMixin from "@/mixins/NotificationNavMixin";

import {
  UpdateSubtaskMutation,
  UpdateNotificationsMutation,
  DeleteNotificationsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  name: "AssignSubtaskNotification",
  mixins: [NotificationNavMixin],
  props: [
    "notification",
    "reactions",
    "user",
    "mentions",
    "labels",
    "isWorkspaceOwner",
    "showUserInfo",
  ],
  api: {
    media: {
      defaultValue: null,
      cacheKey() {
        return `MediaQuery:${this.actionScope.id}`;
      },
    },
  },
  methods: {
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };

      this.$eventBus.$emit("openTaskDialog", obj);
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async deleteNotification() {
      const variables = {
        notification_ids: [this.notification.id],
      };
      try {
        const query = this.$api.getQuery("NotificationsQuery");
        query.data.notifications = query.data.notifications.filter(
          (n) => n.id !== this.notification.id
        );
        await this.$api.mutate({
          mutation: DeleteNotificationsMutation,
          variables,
        });
        mixpanel.track("Notification Delete");
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeCompleteStatus() {
      const variables = {
        id: this.actionObject.id,
        completed: !this.actionObject.completed,
      };

      try {
        await this.$api.mutate({
          mutation: UpdateSubtaskMutation,
          variables,
        });
        mixpanel.track("Task Subtask Update");
      } catch (error) {
        throw new Error(error);
      }
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
  },
  computed: {
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
    notificationTask() {
      if (this.actionObject.task) {
        return this.actionObject.task;
      } else {
        const task = this.$api.getEntity("task", this.actionObject.task_id);
        return task;
      }
    },
    currentBoardId() {
      if (this.actionScope) {
        return this.actionScope.boards[0].id;
      }
      return null;
    },
    board() {
      return this.$api.getEntity("board", this.currentBoardId);
    },
  },
};
</script>
