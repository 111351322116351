<template>
  <div @click.stop="$emit('closeNotificaiton')">
    <q-toolbar
      flat
      dense
      class="rounded-borders bg-white justify-between "
      style="min-height:40px;height:43px"
    >
      <div class="row items-center">
        <q-icon
          style="margin-left:10px;color:#ffa21e"
          size="20px"
          class="q-mr-sm"
          :name="$icons.matBookmark"
        />
        <q-toolbar-title
          shrink
          class="text-bold text-black q-px-none"
          style="font-size:15px"
        >
          Saved items
        </q-toolbar-title>
      </div>
      <q-btn
        :icon="$icons.mdiHelp"
        round
        size="sm"
        class="help-button"
        @click="displayBanner"
      >
        <q-tooltip>Learn about saved items</q-tooltip>
      </q-btn>
    </q-toolbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SavedItemsToolbar",
  methods: {
    displayBanner() {
      this.$store.dispatch("toggleSavedItemsBanner", !this.isBannerVisible);
    },
  },
  computed: {
    ...mapGetters({
      isBannerVisible: "isBannerVisible",
    }),
  },
};
</script>
