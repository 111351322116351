<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card class="card-body">
      <div class="text-right q-mt-sm q-mr-sm">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="black"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <form
        v-on:submit.prevent="$v.$invalid ? null : submit()"
        class="flex-x flex-1"
      >
        <div class="flex-x flex-1 create-vb-workspace">
          <!-- Left Column -->
          <div class="left-column">
            <span style="font-weight:900" class="an-bold-35 wp-title">{{
              workspace ? "Update a" : "Create a"
            }}</span>
            <div class="relative-position">
              <span
                :class="[
                  typeswitch ? 'active--text' : 'pink--text',
                  'an-bold-35',
                ]"
                style="font-weight:900"
                >{{ typeswitch ? "Public" : "Private" }}</span
              >
              <div class="workspace-type-switch">
                <q-toggle
                  v-model="typeswitch"
                  color="teal-14"
                  :disable="!canSwitch"
                />
              </div>
              <div
                :class="[
                  typeswitch
                    ? 'vb-popover-static-active'
                    : 'vb-popover-static-secondary',
                  'left-arrow',
                ]"
              >
                <div v-if="typeswitch">
                  <span class="text-bold">Anyone</span> on your team can view
                  and join this conversation.
                </div>
                <div v-else>
                  Only members you invite to this workspace will be able to view
                  or join this workspace
                </div>
              </div>
            </div>
            <span style="font-weight:900" class="an-bold-35 wp-title"
              >Workspace</span
            >
            <div class="divider section-spacer"></div>
            <div class="medium-text an-20 mt-4">
              <span class="text-dark">
                Messages, tasks and files are organized in workspaces, which you
                can make public or private. Create a workspace for clients,
                projects, departments...or just about anything you want.
              </span>
            </div>
          </div>
          <!-- Right Column -->
          <div class="right-column">
            <div class="flex-y scroll-y justify-center full-height">
              <p class="secondary--text" v-if="errorMessage.flag">
                {{ errorMessage.text }}
              </p>
              <div class="column flex-1 flex-no-wrap">
                <div>
                  <q-input
                    v-model="title"
                    @blur="$v.title.$touch()"
                    @input="$v.title.$touch()"
                    @focus="resetErrorMessage"
                    v-on:keyup="replaceSpace"
                    placeholder="*Workspace name"
                    outlined
                    class=""
                    bottom-slots
                    ref="focusname"
                    :error="fieldErrors('title').length > 0"
                    no-error-icon
                    autofocus
                    :disabled="workspace && workspace.is_general === 1"
                  >
                    <template v-slot:error>
                      <div class="text-pink">
                        {{
                          fieldErrors("title").length > 0
                            ? fieldErrors("title")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                  <div class="editor-prototype workspace-setting-editor">
                    <editor
                      v-model="description"
                      :isAbPosition="true"
                      :className="['border-radius6', 'an-18', 'regular-text']"
                      :mentions="[]"
                      @submit="() => {}"
                      :autofocus="false"
                      :maxHeight="300"
                      placeholder="About: A brief description of what this workspace is about"
                      :showMenu="false"
                    />
                  </div>
                </div>
                <div class="add-invite-members-section">
                  <div class="row items-center justify-around q-my-md">
                    <div
                      :class="[
                        'add-inivite-member-btn',
                        showAddMembersPopup && 'active-menu',
                      ]"
                      @click="isMembersListExpanded = !isMembersListExpanded"
                      v-if="members"
                      style="flex:0 0 44%"
                    >
                      <q-icon :name="$icons.matAdd" size="26px" />
                      <span>Add members</span>
                      <q-btn
                        :icon="$icons.mdiHelp"
                        round
                        size="sm"
                        class="help-button-small"
                        @click.stop="videoPlayer"
                      >
                        <q-tooltip anchor="bottom start">
                          Learn about add members
                        </q-tooltip>
                      </q-btn>
                      <q-menu
                        :offset="[0, 7]"
                        content-class="create-ws-add-member-menu"
                        v-model="showAddMembersPopup"
                      >
                        <q-list>
                          <div
                            class="empty-list q-pa-md"
                            v-if="!this.members.length"
                          >
                            <span class="text-bold"
                              >There are no members in your team.</span
                            >
                            <p>
                              First invite people to your team, then you may add
                              them as a member.
                            </p>
                            <div
                              @click="showInvitePeoplePopup = true"
                              class="inivte-new-people"
                            >
                              Invite a new person
                            </div>
                          </div>
                          <div class="members-list q-py-sm" v-else>
                            <div class="list team-members-list">
                              <div class="search-member">
                                <q-input
                                  autofocus
                                  ref="search"
                                  v-model="search"
                                  outlined
                                  placeholder="Search Member"
                                  clearable
                                  dense
                                >
                                  <template v-slot:prepend>
                                    <q-icon :name="$icons.matSearch" />
                                  </template>
                                </q-input>
                              </div>
                              <div
                                class="item"
                                v-for="(member, i) in teamMembers"
                                :key="'member' + i"
                                @click="addMember(member)"
                              >
                                <q-avatar size="md" class="user_pic">
                                  <img v-if="member.pic" :src="member.pic" />
                                  <!-- <span else
                                    >{{ member.first | firstChar
                                    }}{{ member.last | firstChar }}</span
                                  > -->
                                  <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                                </q-avatar>
                                <div style="margin-left: 10px">
                                  {{ member.first + " " + member.last }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </q-list>
                      </q-menu>
                    </div>
                    <div
                      :class="[
                        'add-inivite-member-btn',
                        showInvitePeoplePopup && 'active-menu',
                      ]"
                      style="flex:0 0 52%"
                    >
                      <q-icon :name="$icons.mdiEmailOutline" size="26px" />
                      <span>Invite new people</span>
                      <q-btn
                        :icon="$icons.mdiHelp"
                        round
                        size="sm"
                        class="help-button-small"
                        @click.stop="videoPlayer"
                      >
                        <q-tooltip anchor="bottom start">
                          Learn about invite people
                        </q-tooltip>
                      </q-btn>
                      <q-menu
                        v-model="showInvitePeoplePopup"
                        content-class="create-ws-add-member-menu"
                        :offset="[0, 7]"
                        auto-close
                      >
                        <q-list>
                          <div class="invite-new-people q-pa-md">
                            <div class="text-bold q-mb-xs">
                              To invite a new person to this workspace:
                            </div>
                            <ul>
                              <li>
                                Create your workspace first and then you'll be
                                able to send invites
                              </li>
                              <li>
                                Here's a
                                <span
                                  @click="videoPlayer"
                                  class="cursor-pointer quick-tutorial"
                                  >quick tutorial</span
                                >
                                on how to do this
                              </li>
                            </ul>
                          </div>
                        </q-list>
                      </q-menu>
                    </div>
                  </div>
                  <div class="added-members" v-if="this.addedMembers.length">
                    <span class="title">Added Members:</span>
                    <div class="row items-center justify-between">
                      <div
                        class="item"
                        v-for="(member, i) in getAddedMembers"
                        :key="'addedMember' + i"
                      >
                        <q-avatar size="md" class="user_pic">
                          <img v-if="member.pic" :src="member.pic" />
                          <!-- <span else
                            >{{ member.first | firstChar
                            }}{{ member.last | firstChar }}</span
                          > -->
                          <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                        </q-avatar>
                        <div style="margin-left: 10px">
                          {{ member.first + " " + member.last }}
                        </div>
                        <div @click="removeMember(i)" class="close">
                          <q-icon :name="$icons.matClose" size="xs" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="workspace-setting q-mb-sm q-mt-lg q-pa-md">
                  <div class="wtitle">
                    Workspace settings
                  </div>
                  <div class="toggle">
                    <div class="row">
                      <q-toggle v-model="messageswitch" label="Show messages" />
                    </div>
                    <div class="row">
                      <q-toggle v-model="taskswitch" label="Show tasks" />
                    </div>
                    <div class="row">
                      <q-toggle
                        v-model="fileswitch"
                        label="Show files"
                        color="#15d89a"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            :src="typeswitch ? monsterImg.public : monsterImg.private"
            alt="Monster"
            class="monster"
          />
        </div>
      </form>
      <q-card-section style="padding-right: 30px">
        <div class="flex-x justify-end">
          <q-btn
            no-caps
            color="transparent"
            text-color="grey-4"
            size="md"
            dense
            class="q-px-md"
            @click="$emit('success')"
            label="Cancel"
          />
          <q-btn
            type="submit"
            text-color="white"
            class="q-px-md q-py-sm q-ml-sm primary-button"
            :loading="loader"
            :disabled="
              $v.$invalid ||
                loader ||
                availablity.loader ||
                availablity.titleTaken ||
                (!taskswitch && !messageswitch && !fileswitch)
            "
            :label="`${action} Workspace`"
            @click="submit"
          />
        </div>
      </q-card-section>
    </q-card>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </q-dialog>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import validationMixin from "@/mixins/validationMixin";
import { validWorkspaceTitle } from "@/utils/validators";
import { required, maxLength } from "vuelidate/lib/validators";
import UserScope from "@/mixins/UserScope";
import errorHandler from "@/utils/ErrorHandler";
import Editor from "@/components/Editor/Editor";
import {
  CreateWorkspaceMutation,
  UpdateWorkspaceMutation,
  Workspaces2Query,
  InvitationWorkspaceQuery,
} from "@/gql";
import mixpanel from "@/mixpanel";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import orderBy from "lodash/orderBy";
import Avatar from 'vue-avatar';

export default {
  name: "WorkspaceCreateDialog",
  props: ["workspace", "createType", "members"],
  mixins: [validationMixin, UserScope, ProxyModelMixin],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  validations: {
    title: {
      required,
      maxLength: maxLength(32),
      validWorkspaceTitle,
    },
    description: { maxLength: maxLength(3000) },
  },
  validationMessages: {
    title: {
      required: "message.validation.workspace.name.required",
      maxLength: "message.validation.workspace.name.max",
      validWorkspaceTitle: "message.validation.workspace.name.valid",
    },
    description: {
      maxLength: "message.validation.workspace.description.max",
    },
  },
  components: {
    Editor,
    VideoDialog,
    Avatar,
  },
  mounted() {
    this.refreshProps();
    this.$nextTick(() => {
      this.$refs.focusname.focus();
    });
  },
  data() {
    return {
      id: null,
      loader: false,
      search: null,
      title: null,
      typeswitch: true,
      description: null,
      isMembersListExpanded: false,
      availablity: {
        loader: false,
        titleTaken: false,
      },
      monsterImg: {
        public: "/static/images/workspace/wp-public-bg-img.svg",
        private: "/static/images/workspace/wp-private-monster-bg.svg",
      },
      errorMessage: {
        flag: false,
        text: null,
      },
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      addedMembers: [],
      addedMembersId: [],
      messageswitch: true,
      taskswitch: true,
      fileswitch: true,
      showInvitePeoplePopup: false,
      showAddMembersPopup: false,
    };
  },
  methods: {
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/578344075",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    addMember(member) {
      if (!this.addedMembers.includes(member)) {
        this.addedMembers.push(member);
        this.addedMembersId.push(member.id);
      }
    },
    removeMember(index) {
      this.addedMembers.splice(index, 1);
      this.addedMembersId.splice(index, 1);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    replaceSpace() {
      this.title = this.title.replace(/[\W_]/g, "-");
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.create();
      }
      // this.$emit('success')
    },
    async create() {
      this.loader = true;
      let variables = {
        title: this.title,
        description: this.description,
        type: this.type,
        members: this.addedMembersId,
        show_tasks: this.taskswitch,
        show_messages: this.messageswitch,
        show_files: this.fileswitch,
      };
      try {
        const response = await this.$api.mutate({
          mutation: CreateWorkspaceMutation,
          variables,
        });
        if (response.data.createWorkspace) {
          const query = this.$api.getQuery("Workspaces2Query");
          if (query.data && query.data.workspaces2) {
            query.data.workspaces2.push(response.data.createWorkspace);
          } else {
            query.data.workspaces2 = [response.data.createWorkspace];
          }
          this.loader = false;
          const newWorkspace = response.data.createWorkspace;
          this.$eventBus.$emit("updateWorkspace", newWorkspace.id);
          this.$router.push({
            name: "BoardView",
            params: {
              comapny: this.currentCompany.companyUri,
              workspace: newWorkspace.id,
            },
          });
          mixpanel.track("Workspace Create", { type: this.type });
          this.$emit("success");
          this.$api.query({
            query: InvitationWorkspaceQuery,
            cacheKey: "InvitationWorkspaceQuery",
          });
        } else {
          this.loader = false;
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: this.$t("message.WorkspaceTitleAlreadyTaken"),
            icon: this.$icons.matReportProblem,
          });
        }
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
    async update() {
      this.loader = true;
      let variables = {
        id: this.id,
        title: this.title,
        description: this.description,
        type: this.type,
        show_tasks: this.taskswitch,
        show_messages: this.messageswitch,
        show_files: this.fileswitch,
        members: this.addedMembersId,
      };
      try {
        const query = this.$api.getQuery(
          `WorkspaceMembersQuery:${this.workspace.id}`
        );
        this.addedMembersId.forEach((memberId) => {
          if (query.data) {
            const newMember = this.$api.getEntity("user", memberId);
            if (query.data.workspaceMembers) {
              query.data.workspaceMembers.push(newMember);
            } else {
              query.data.workspaceMembers = [newMember];
            }
          }
          const workspaceAvailableUsersQuery = this.$api.getQuery(
            `WorkspaceAvailableUsersQuery:${this.workspace.id}`
          );
          if (workspaceAvailableUsersQuery.data) {
            workspaceAvailableUsersQuery.data.workspaceAvailableUsers = workspaceAvailableUsersQuery.data.workspaceAvailableUsers.filter(
              (m) => m.id !== memberId
            );
          }
        });

        const updateWorkspaceData =  await this.$api.mutate({
          mutation: UpdateWorkspaceMutation,
          variables,
        });
        this.$api.query({
          query: Workspaces2Query,
          cacheKey: "Workspaces2Query",
        });
        
        this.$emit("success");
        
        this.loader = false;
        if(updateWorkspaceData.data)
        this.checkRouteAccess(updateWorkspaceData.data.updateWorkspace);
      } catch (error) {
        this.loader = false;
        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
    checkRouteAccess(workspace) {
      if (
        !workspace.show_messages &&
        this.$route.name == "MessagingView"
      ) {
        if (workspace.show_tasks) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        } else if (workspace.show_files) {
          this.$router.push({
            name: "MediaView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        }
      } else if (
        !workspace.show_tasks &&
        this.$route.name == "BoardView"
      ) {
        if (workspace.show_messages) {
          this.$router.push({
            name: "MessagingView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        } else if (workspace.show_files) {
          this.$router.push({
            name: "MediaView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        }
      } else if (
        !workspace.show_files &&
        this.$route.name == "MediaView"
      ) {
        if (workspace.show_tasks) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        } else if (workspace.show_messages) {
          this.$router.push({
            name: "MessagingView",
            params: {
              company: this.$route.params.company,
              workspace: workspace.id,
            },
          });
        }
      }
    },
    resetErrorMessage() {
      this.errorMessage = {
        flag: false,
        text: null,
      };
    },
    refreshProps() {
      if (this.workspace) {
        this.id = this.workspace.id;
        this.title = this.workspace.title;
        this.typeswitch = this.workspace.type === "public";
        this.description = this.workspace.description;
        this.taskswitch = this.workspace.show_tasks;
        this.messageswitch = this.workspace.show_messages;
        this.fileswitch = this.workspace.show_files;
      } else if (this.createType) {
        this.typeswitch = this.createType === "public";
        this.$v.$reset();
      }
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    canSwitch() {
      if (this.typeswitch) {
        return this.canCreatePrivateWorkspace;
      } else {
        return this.canCJSPublic;
      }
    },
    type() {
      return this.typeswitch ? "public" : "private";
    },
    action() {
      return this.workspace ? "Update" : "Create";
    },
    getAddedMembers() {
      return this.addedMembers;
    },
    teamMembers() {
      return this.search
        ? this.teamMembersFuse.filter((u) =>
            u.fullname.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.teamMembersFuse.hasOwnProperty("list")
        ? this.teamMembersFuse.list
        : this.teamMembersFuse;
    },
    teamMembersFuse() {
      if (this.members) {
        const members = [...this.members].filter(
          (member) => !this.addedMembersId.includes(member.id)
        );

        const orderedMembers = orderBy(
          members,
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return [this.user].filter(
        (member) => !this.addedMembersId.includes(member.id)
      );
    },
  },
  watch: {
    proxyModel(to) {
      if (!to) return;
      requestAnimationFrame(() => {
        this.$refs.focusname.focus();
        this.refreshProps();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vb-popover-static-secondary {
  padding: 10px !important;
}
.add-invite-members-section {
  .add-inivite-member-btn {
    height: 55px;
    border: solid 2px #bfcfe0;
    align-items: center;
    justify-content: space-around;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    span {
      font-size: 15px;
      font-weight: 500;
    }
    &:hover,
    &.active-menu {
      background-color: #007aff;
      color: white;
    }
  }
  .added-members {
    .item {
      flex: 0 0 45%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;
      color: #000000;
      .user_pic {
        border: solid 1px #e3e8ed;
        background-color: #e3e8ed;
        span {
          color: #42526e;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .title {
      margin-left: 15px;
      font-size: 15px !important;
      font-weight: bold;
    }
    .close {
      font-weight: bold;
      color: #000000;
      margin-left: auto;
    }
    .list {
      height: 100%;
      overflow: auto;
      min-height: 172px;
      max-height: 172px;
    }
  }
}
.disabled,
[disabled] {
  background-color: #b8c1cb !important;
}

.left-column {
  width: 370px;
}
.right-column {
  width: 475px;
  margin-left: 50px;
}
.card-body {
  flex: 0 1 900px;
  max-width: 100%;
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}
.workspace-setting {
  background-color: #f3f5f8;
  border-radius: 10px;
  .wtitle {
    font-size: 14px;
    font-weight: 600;
  }
  .q-toggle__inner--truthy {
    color: #15d89a;
    background-color: #15d89a;
  }
  .q-toggle__track {
    opacity: 1;
    background-color: #15d89a;
  }
}
</style>
