<template>
  <div>
    <!-- Auth User -->
    <div
      class="row items-center justify-between group-hover group-background-hover "
    >
      <div
        class="row items-center q-px-md q-py-sm full-width group-background-hover-item"
      >
        <q-avatar
          size="30px"
          color="blue-grey-11"
          text-color="grey-10"
          class="q-mr-sm"
          v-if="user"
        >
          <q-tooltip v-if="user">
            {{ user.first | capitalize }}
            {{ user.last | capitalize }}
          </q-tooltip>
          <img :src="user.pic" :alt="user.first" v-if="user.pic" />
          <!-- <span class="text-avatar-style" v-else>
            {{ user && user.first | firstChar
            }}{{ user && user.last | firstChar }}
          </span> -->
          <avatar v-else :size="30" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
        </q-avatar>
        <div class="text-subtitle1" v-if="user">
          {{ user.first | capitalize }}
          {{ user.last | capitalize }}
        </div>
      </div>
    </div>
    <!-- Workspace Members -->
    <template v-if="workspaceMembers">
      <div
        v-for="member in workspaceMembers"
        :key="member.id"
        class=" group-hover group-background-hover"
      >
        <div
          class="row q-px-md q-py-sm items-center group-background-hover-item justify-between"
        >
          <div class="row items-center">
            <q-avatar
              size="30px"
              color="blue-grey-11"
              text-color="grey-10"
              class="q-mr-sm"
              @click="showUserInfo(member)"
            >
              <q-tooltip>
                {{ member.first | capitalize }}
                {{ member.last | capitalize }}
              </q-tooltip>
              <img
                :src="member.pic"
                :alt="member.first"
                v-if="member.pic"
              />
              <!-- <span
                class="text-avatar-style"
                @click="showUserInfo(member)"
                v-else
              >
                {{ member.first | firstChar }}{{ member.last | firstChar }}
              </span> -->
              <avatar v-else :size="30" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
            </q-avatar>
            <div
              @click="showUserInfo(member)"
              class="text-subtitle1 cursor-pointer"
            >
              {{ member.first | capitalize }}
              {{ member.last | capitalize }}
            </div>
          </div>
          <div>
            <q-btn
              flat
              size="sm"
              round
              padding="0"
              color="grey-7"
              :icon="$icons.matClose"
              class="q-mr-md group-hover-item"
              v-if="canRemoveUserFromWorkspace || isWorkspaceOwner"
              @click="confirmBoxHandler(true, member.id)"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Avatar from 'vue-avatar';

export default {
  name: "WorkspaceMembers",
  props: [
    "user",
    "isWorkspaceOwner",
    "workspaceMembers",
    "showUserInfo",
    "confirmBoxHandler",
    "canRemoveUserFromWorkspace",
    "currentWorkspaceId",
  ],
  components:{
    Avatar,
  },
  api: {
    workspaceAvailableUsers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceAvailableUsersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  computed: {
    isVisible() {
      if (
        this.workspaceAvailableUsers &&
        this.workspaceAvailableUsers.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
