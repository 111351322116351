<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="$emit('closeDialog')"
    :value="value"
  >
    <q-card style="min-width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">Link Monday Account</span>
      </div>
      <div class="monday-desc">
        <ul>
          <li>
            From within the Monday.com application sidebar, click and open <b>Profile</b>. In the account mini menu, select <b>Admin</b>. On the admin page select <b>API</b>.
          </li>
          <li>
            Under API V2 Token, click the <b>generate</b> button, and copy the <b>Personal API Token</b>.
          </li>
          <li>
            Paste <b>Personal API Token</b> in the field below and click next.
          </li>
        </ul>
      </div>
      <q-input
        placeholder="Paste API Token"
        dense
        outlined
        autofocus
        v-model="apiToken"
        @keydown.enter.exact="
          getProjectList();
          $event.preventDefault();
        "
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Get Project List"
          class="create-folder-btn"
          @click="getProjectList"
          :loading="loading"
          :disable="this.apiToken ? false : true"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "MondayDialog",
  props: ["value"],
  data() {
    return {
      apiToken: null,
      loading: false,
    };
  },
  methods: {
    getProjectList() {
      this.loading = true;
      this.$emit("getProjectList", this.apiToken);
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
.monday-desc ul li {
  margin-bottom: 10px;
}
</style>
