import uniqBy from "lodash/uniqBy";
import {
  CopyMediaMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
  DetachMessageFolderMutation,
  CopyMediaFolderMutation,
} from "@/gql";

export default {
  api: {
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.actionScope.id}`;
      },
    },
  },
  data() {
    return {
      copyMoveDialog: {
        data: [],
        post: null,
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      deleteFolder: {
        id: null,
        post: null,
        flag: false,
        loader: false,
      },
    };
  },
  methods: {
    copyMoveFileFolderHandler(
      items,
      isFolder = false,
      isRoot = true,
      post = null
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.post = post;
    },
    copyFileFolderHandler(workspace, folder) {
      if (this.copyMoveDialog.isFolder) {
        this.copyFolders(workspace);
      } else {
        this.copyFiles(workspace, folder);
      }
    },
    async copyFolders(workspace) {
      try {
        this.copyMoveDialog.loading = true;
        // Array of folders
        const folderIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        if (workspace.id === this.currentWorkspaceId) {
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspaceId}`
          );
          if (query.data && query.data.mediaFolders.length) {
            query.data.mediaFolders.unshift(this.copyMoveDialog.data[0]);
          } else {
            query.data.mediaFolders = [this.copyMoveDialog.data[0]];
          }
        }
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFiles(workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when copy to folder
          if (workspace.id === this.currentWorkspaceId) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspaceId}`
            );
            if (query.data && query.data.mediaFolders.length && folder) {
              query.data.mediaFolders = query.data.mediaFolders.map((f) => {
                if (f.id === folder.id) {
                  f.media_count += fileIds.length;
                }
                return f;
              });
            }
            const mediaQuery = this.$api.getQuery(
              `MediaQuery:${this.currentWorkspaceId}`
            );
            if (mediaQuery.data && mediaQuery.data.newMedia.length) {
              mediaQuery.data.newMedia.unshift(this.copyMoveDialog.data[0]);
            } else {
              mediaQuery.data.newMedia = [this.copyMoveDialog.data[0]];
            }
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.post = null;
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteFolderHandler(folderId) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false, key) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            folder: [this.deleteFolder.id],
            [key]: this.actionObject.id,
          };
          await this.$api.mutate({
            mutation: DetachMessageFolderMutation,
            variables,
          });
          //Remove from Entity cache
          if (key === "post_id") {
            const postEntity = this.$api.getEntity(
              "post",
              this.actionObject.id
            );
            postEntity.mediafolder = postEntity.mediafolder.filter(
              (f) => f.id !== this.deleteFolder.id
            );
          } else if (key === "task_comment_id") {
            const taskCommentEntity = this.$api.getEntity(
              "taskComment",
              this.actionObject.id
            );
            taskCommentEntity.mediafolder = taskCommentEntity.mediafolder.filter(
              (f) => f.id !== this.deleteFolder.id
            );
          }
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.post = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(this.mediaFolders, "id");
      } else {
        return [];
      }
    },
  },
};
