<template>
  <div style="height: 100%">
    <q-card
      style="flex:0 1 1240px;max-width:100%;height:100%; padding: 30px 50px 10px 20px"
    >
      <div class="footer-menu-dialog-title">
        <p>Team info</p>
      </div>
      <div class="row user-profile-setting-form pb-0">
        <!-- Left Column -->
        <div class="flex-y flex-1 justify-start">
          <div class="mt-3">
            <div v-show="isEdit" class="relative-position" style="max-width: 200px;">
              <vue-cropper
                ref="cropper"
                drag-mode="move"
                :viewMode="3"
                :cropmove="cropImage"
                :aspectRatio="1"
                :background="false"
                :rotatable="true"
                :cropBoxResizable="true"
                :minCropBoxWidth="50"
                :minCropBoxHeight="50"
                :minContainerWidth="150"
                :minContainerHeight="150"
              ></vue-cropper>
              <q-btn
                :icon="$icons.matCheck"
                size="md"
                dense
                color="indigo-12"
                text-color="white"
                class="absolute pos-edit-img-btn"
                @click="setImage"
                round
              />
            </div>
            <div
              v-if="
                !isEdit &&
                  (companyLogo.selected ||
                    companyLogo.previous ||
                    companyLogo.cropImage)
              "
              class="profile-uploader relative-position"
            >
              <!-- Fab dialer -->
              <div class="absolute-top-right">
                <q-fab
                  color="indigo-12"
                  :class="[logo && 'q-pl-md']"
                  :icon="$icons.matMoreVert"
                  direction="left"
                  padding="sm"
                >
                  <q-fab-action
                    padding="sm"
                    color="indigo-12"
                    @click="fileTrigger"
                    :icon="$icons.matEdit"
                  />
                  <q-fab-action
                    v-show="logo"
                    color="pink"
                    @click="deletePhotoDialogHandler(true)"
                    :icon="$icons.matDeleteForever"
                  />
                </q-fab>
              </div>
              <!-- Fab dialer End -->
              <img :src="fetchLogo" class="circle-img" />
            </div>
            <div
              class="center-align logo-uploader"
              @click="fileTrigger"
              v-else-if="
                !isEdit &&
                  !companyLogo.selected &&
                  !companyLogo.previous &&
                  !companyLogo.previous
              "
            >
              <br />
              <q-icon size="lg" color="grey-7" :name="$icons.matBackup" />
              <br />
              <span>Upload</span>
              <br />
              <span>Logo Image</span>
            </div>
          </div>
          <p
            class="secondary--text error-message"
            v-if="companyLogo.errorMessage"
          >
            {{ companyLogo.errorMessage }}
          </p>
          <input
            type="file"
            ref="fileInput"
            @change="changeLogo"
            @click="clickFileInputHandler"
            v-show="false"
            accept="image/*"
          />
          <p class="desc" :class="this.imageValid ? 'desc-color' : 'text-pink'">
            Supporting: JPEG, PNG and GIF
          </p>
          <div class="input-card q-mt-lg">
            <div class="text-bold q-mb-sm">
              Team name
            </div>
            <q-input
              v-model.trim="company_name"
              @blur="$v.company_name.$touch()"
              @input="$v.company_name.$touch()"
              placeholder="Company Name"
              outlined
              bottom-slots
              :error="fieldErrors('company_name').length > 0"
              no-error-icon
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("company_name").length > 0
                      ? fieldErrors("company_name")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
            <div class="relative-position q-mt-lg">
              <div class="text-bold q-mb-sm">
                Invite link
              </div>
              <q-input
                v-model.trim="publicLink"
                ref="copytext"
                outlined
                readonly
              />
              <q-btn
                :color="isLinkCopy ? 'teal-14' : 'indigo-12'"
                :label="isLinkCopy ? 'Copied' : 'Copy'"
                size="md"
                no-caps
                class="absolute q-px-sm"
                style="top:33px;right:5px"
                @click="
                  $copyTextToClipboard(publicLink);
                  isLinkCopy = true;
                "
              />
            </div>
          </div>
          <div>
            <q-btn
              label="Save"
              class="footer-dialog-ok-btn q-mt-md"
              style="min-width:230px"
              :loading="loader.submit"
              @click.native="updateComapnyDetails()"
              :disabled="$v.$invalid"
            />
          </div>
        </div>
      </div>
    </q-card>
    <confirm-dialog
      v-model="deleteConfirm.flag"
      title="Delete Photo ?"
      question="Are you sure you want to delete this Photo?"
      cancleText="No thanks"
      successText="Delete photo"
      :cancleMethod="() => deletePhotoDialogHandler(false)"
      :successMethod="deletePhoto"
      :loading="false"
    />
  </div>
</template>
<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import validationMixin from "@/mixins/validationMixin";
import VueCropper from "vue-cropperjs";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import { uploadtoS3 } from "@/services/auth";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { CompanyQuery, UpdateCompanySettingsMutation } from "@/gql";

export default {
  name: "CompanySettingDialog",
  props: ["userId"],
  mixins: [validationMixin, ProxyModelMixin],
  validations: {
    company_name: {
      required,
      maxLength: maxLength(64),
      minLength: minLength(2),
    },
  },
  validationMessages: {
    company_name: {
      required: "message.validation.company.name.required",
      maxLength: "message.validation.company.name.max",
      minLength: "message.validation.company.name.min",
    },
  },
  mounted() {
    this.fetchCompany();
  },
  components: {
    VueCropper,
    ConfirmDialog,
  },
  data() {
    return {
      loader: {
        submit: false,
        imageUploader: false,
      },
      company_name: null,
      inviteLink: null,
      logo: null,
      uri: null,
      isFileUpload: false,
      companyLogo: {
        selected: null,
        previous: null,
        cropImage: null,
        errorMessage: null,
        thresholdSize: 25,
        type: "image/jpeg",
      },
      isEdit: false,
      fab: false,
      deleteConfirm: {
        flag: false,
      },
      isLinkCopy: false,
      imageValid: true,
    };
  },
  methods: {
    async fetchCompany() {
      const variables = {
        uri: this.companies[this.activeCompany].companyUri,
      };
      const response = await this.$api.query({
        query: CompanyQuery,
        variables,
      });
      const company = response.data.company;

      if (company) {
        this.company_name = company.name;
        this.inviteLink = company.public_link;
        this.uri = company.uri;
        this.logo = company.logo;
        this.companyLogo.previous = company.logo;
      }
    },
    deletePhotoDialogHandler(flag = true) {
      this.deleteConfirm.flag = flag;
    },
    deletePhoto() {
      this.updateComapnyDetails(true).then(() => {
        this.deletePhotoDialogHandler(false);
      });
    },
    fileTrigger() {
      this.$refs.fileInput.click();
      this.companyLogo.errorMessage = null;
    },
    cropImage() {
      this.companyLogo.cropImage = this.$refs.cropper
        .getCroppedCanvas({
          width: 200,
          height: 200,
          fillColor: "#FFF",
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL(this.companyLogo.type);
    },
    setImage() {
      this.isEdit = false;
    },
    clickFileInputHandler() {
      this.$refs["fileInput"].value = "";
    },
    changeLogo(event) {
      const files = event.target.files;
      if (files.length) {
        const size = files[0].size / (1024 * 1024);

        this.companyLogo.type = files[0].type;
        if (this.companyLogo.type.includes("image")) {
          this.imageValid = true;
          if (size < this.companyLogo.thresholdSize) {
            this.$refs.cropper.replace(URL.createObjectURL(files[0]));
            this.companyLogo.selected = files[0];
            this.isEdit = true;
          } else {
            this.companyLogo.errorMessage = this.$t(
              "message.validation.file.profileSize"
            );
            this.$refs["fileInput"].value = "";
          }
        } else {
          this.imageValid = false;
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async uploadLogo() {
      if (this.companyLogo.cropImage) {
        const file = await this.dataURLtoFile(
          this.companyLogo.cropImage,
          this.companies[this.activeCompany].companyId
        );

        const folderPath = `companies/${
          this.companies[this.activeCompany].companyId
        }/logo`;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("path", folderPath);
        const res = await uploadtoS3(formData);
        const response = await res;

        if (response) {
          this.logo = response.Key;
          this.isFileUpload = true;
        }
      } else if (!this.companyLogo.cropImage && this.companyLogo.selected) {
        const folderPath = `companies/${
          this.companies[this.activeCompany].companyId
        }/logo`;

        const formData = new FormData();
        formData.append("file", this.companyLogo.selected);
        formData.append("path", folderPath);
        const res = await uploadtoS3(formData);
        const response = await res;

        if (response) {
          this.logo = response.Key;
          this.isFileUpload = true;
        }
      }
    },
    async updateComapnyDetails(removeLogo = false) {
      this.loader.submit = true;

      await this.uploadLogo();

      const variables = {
        name: this.company_name,
        remove_logo: removeLogo,
      };

      if (this.isFileUpload) {
        variables.logo = this.logo;
        this.isFileUpload = false;
      }

      const response = await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables,
      });

      const company = response.data.updateCompanySettings;

      if (company) {
        this.loader.submit = false;
        this.companies[this.activeCompany].companyName = this.company_name;
        this.companies[this.activeCompany].logo = company.logo;
        this.$store.dispatch("resetCompanies", this.companies);

        this.$emit("success", {
          message: this.$t("message.companySettingUpdate"),
          flag: "modalDialog.companysetting",
        });
      } else {
        this.loader.submit = false;

        this.$emit("success", {
          flag: "modalDialog.companysetting",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    fetchLogo() {
      if (this.companyLogo.cropImage) {
        return this.companyLogo.cropImage;
      } else if (this.companyLogo.selected) {
        return URL.createObjectURL(this.companyLogo.selected);
      } else {
        return this.companyLogo.previous;
      }
    },
    publicLink() {
      const invitaionLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.uri}/invitation?type=public&secret=${this.inviteLink}`;
      return invitaionLink;
    },
  },
};
</script>

<style lang="stylus" scoped>
.logo-uploader {
  border: 3px solid #BFCFE0;
  border-radius: 15px;
  height: 150px;
  width: 150px;
}

.setting-height {
  height: 80%;
}

.profile-uploader img {
  width: 150px;
  height: 150px;
}

.pos-edit-img-btn {
  top: -12px;
  left: -12px;
}

.set-text {
  color: #8E9DAD !important;
}

.copy-btn {
  top: 6px;
  right: 6px;
  min-width: 80px;
}

.error-message {
  width: 140px;
  text-align: center;
  display: block;
  flex: 1;
}

.desc{
  font-size: 11px;
}
.desc-color{
  color: rgba(0, 0, 0, 0.54);
}
</style>
