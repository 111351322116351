<template>
  <div
    v-if="(activity.details || activity.object) && activtyTitle(activity)"
    class="row flex-no-wrap items-baseline q-mb-sm"
  >
    <div class="q-ml-md q-mr-xs">
      <q-avatar
        size="25px"
        color="blue-grey-11"
        text-color="grey-10"
        class="q-mr-sm q-mt-xs"
        @click="showUserInfo(activity.subject)"
      >
        <q-tooltip v-if="activity.subject">
          {{ activity.subject.first | capitalize }}
          {{ activity.subject.last | capitalize }}
        </q-tooltip>
        <img
          :src="activity.subject.pic"
          :alt="activity.subject.first"
          v-if="activity.subject.pic"
        />
        <!-- <span class="text-avatar-style" v-else>
          {{ activity.subject && activity.subject.first | firstChar
          }}{{ activity.subject && activity.subject.last | firstChar }}
        </span> -->
        <avatar v-else :size="25" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="activity.subject.first+' '+activity.subject.last"></avatar>
      </q-avatar>
    </div>
    <div>
      <div
        class="activity-title"
        ref="htmlDiv"
        v-html="activtyTitle(activity)"
      ></div>
      <div class="activity-time">
        {{ activity.created_at | dateConversion }}
      </div>
    </div>
  </div>
</template>

<script>
import parseJSON from "date-fns/parseJSON";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Avatar from 'vue-avatar';

export default {
  name: "ActivityLog",
  props: [
    "activity",
    "currentWorkspaceId",
    "openTaskDialogHandler",
    "fullview",
    "showUserInfo",
  ],
  components:{
    Avatar
  },
  mounted() {
    if (this.$refs.htmlDiv) {
      this.$refs.htmlDiv.addEventListener("click", this.docClickEventHandler),
        false;
    }
  },
  beforeDestroy() {
    if (this.$refs.htmlDiv) {
      this.$refs.htmlDiv.removeEventListener(
        "click",
        this.docClickEventHandler
      ),
        false;
    }
  },
  methods: {
    activtyTitle(activity) {
      const type = activity.type;
      let title = null;
      if (type === "message-created") {
        if (activity.object.forwardedpost || activity.object.forwardedPost) {
          title = `<span class="link-message">Shared </span>message`;
        } else {
          title = `Sent <span class="link-message">message</span>`;
        }
      }
      if (type === "message-updated") {
        title = `Edited <span class="link-message">message</span>`;
      }
      if (type === "message-deleted") {
        title = `Deleted message`;
      }
      if (type === "message-reaction-added") {
        title = `Reacted to <span  class="link-message">message</span>`;
      }
      if (type === "task-created") {
        title = `Created <span class='link-task'>${activity.object &&
          activity.object.title}</span>`;
      }
      if (type === "task-updated") {
        if (activity.details) {
          if (
            activity.details.sub_type === "task-completed" &&
            activity.object.completed
          ) {
            title = `Marked <span class="link-task">${activity.object &&
              activity.object.title}</span> as Completed`;
          }
          if (
            activity.details.sub_type === "task-date-changed" &&
            activity.object.end_date
          ) {
            title = `Changed due date of <span class="link-task">${activity.object &&
              activity.object.title}</span> to ${format(
              parseISO(activity.object.end_date),
              "MMMM dd yyyy"
            )}`;
          }
          if (activity.details.sub_type === "task-description-changed") {
            title = `Changed Description of <span class="link-task">${activity.object &&
              activity.object.title}</span>`;
          }
          if (activity.details.sub_type === "task-sequence-changed") {
            title = `Moved <span class="link-task">${activity.object &&
              activity.object.title}</span> Task`;
          }
          if (activity.details.sub_type === "task-title-changed") {
            title = `Changed Title of <span class="link-task">${activity.object &&
              activity.object.title}</span> Task`;
          }
        } else {
          title = `Updated <span class="link-task">${activity.object &&
            activity.object.title}</span>`;
        }
      }
      if (type === "task-deleted") {
        title = `Deleted task <span >${activity.details.title}</span>`;
      }
      if (type === "tasks-deleted") {
        const card = this.$api.getEntity("card", activity.details.card_id);
        if (card && activity.details) {
          if (activity.details.completed) {
            title = `Cleared Completed Tasks in <span >${card.title}</span> Column`;
          } else {
            title = `Cleared Active Tasks in <span >${card.title}</span> Column`;
          }
        }
      }
      if (type === "task-label-attached") {
        const task = this.$api.getEntity("task", activity.details.task_id);
        const label = this.$api.getEntity("label", activity.details.label_id);
        if (task) {
          title = `Changed label of <span class="link-task">${task.title}</span> to ${label.name}`;
        }
      }
      if (type === "task-label-detached") {
        const task = this.$api.getEntity("task", activity.details.task_id);
        const label = this.$api.getEntity("label", activity.details.label_id);
        if (task) {
          title = `Removed ${label.name} label from <span class="link-task">${task.title}</span> `;
        }
      }
      if (type === "task-member-attached") {
        const task = this.$api.getEntity("task", activity.details.task_id);
        const member = this.$api.getEntity("user", activity.details.user_id);
        if (task) {
          title = `Assigned <span class="link-task">${task.title}</span> to <span class="link-member">${member.first} ${member.last}</span>`;
        }
      }
      if (type === "task-subtask-created") {
        const task = this.$api.getEntity("task", activity.object.task_id);
        if (task) {
          title = `Added Subtask to <span class="link-task">${activity.object &&
            task.title}</span>`;
        }
      }
      if (type === "task-subtask-updated") {
        if (activity.details) {
          if (
            activity.details.sub_type === "subtask-completed" ||
            activity.object.completed
          ) {
            const task = this.$api.getEntity("task", activity.object.task_id);
            if (task) {
              title = `Marked Subtask as Complete on <span class="link-task">${activity.object &&
                task.title}</span> `;
            }
          }
        }
      }
      if (type === "task-subtask-cleared") {
        const task = this.$api.getEntity("task", activity.details.id);
        if (task) {
          if (activity.details.completed) {
            title = `Cleared all Completed Subtasks on <span class="link-task">${task.title}</span>`;
          } else {
            title = `Cleared all Active Subtasks on <span class="link-task">${task.title}</span>`;
          }
        }
      }
      if (type === "task-comment-created") {
        title = `Added Comment on <span class="link-task">${activity.object &&
          activity.object.task.title}</span>`;
      }
      if (type === "task-comment-updated") {
        title = `Edited Comment on <span class="link-task">${activity.object &&
          activity.object.task.title}</span>`;
      }
      if (type === "task-comment-deleted") {
        title = `Deleted Comment on <span class="link-task">${activity.object &&
          activity.object.task.title}</span>`;
      }
      if (type === "task-comment-reaction-added") {
        title = `Reacted to Comment on <span class="link-task">${activity.object &&
          activity.object.task.title}</span>`;
      }
      if (type === "card-created") {
        title = `Created ${activity.object && activity.object.title} Column`;
      }
      if (type === "card-updated") {
        if (activity.details) {
          if (activity.details.sub_type === "card-sequence-changed") {
            title = `Moved ${activity.object && activity.object.title} Column`;
          }
        }
      }
      
      if (type === "card-deleted") {
        title = `Deleted Column <span >${activity.details.title}</span>`;
      }
      if (type === "media-created") {
        if(activity.object && activity.object.is_document_section) {
          title = `Added Document ${activity.object && activity.object.filename}`;  
        } else {
          title = `Added ${activity.object && activity.object.filename}`;
        }
      }
      if (type === "media-updated") {
        if(activity.object && activity.object.is_document_section) {
          title = `Edited Document ${activity.object && activity.object.filename}`;  
        } else {
          title = `Edited ${activity.object && activity.object.filename}`;
        }
      }
      if (type === "media-deleted") {
        if(activity.object && activity.object.is_document_section) {
          title = `Deleted Document ${activity.object && activity.object.filename}`;  
        } else {
          title = `Deleted ${activity.details && activity.details.filename}`;
        }
      }
      if (type === "media-comment-created") {
        const media = this.$api.getEntity(
          "media",
          activity.object && activity.object.media_id
        );
        title = `Added Comment to <span class="link-media">${media.filename}</span>`;
      }

      if (type === "bookmark-created") {
        if(activity.object) {
          title = `Added Bookamrk  <a target="_blank" href="${activity.object.link}" >${activity.object.title}`;
        } else {
          title = "Added Bookamrk";
        }
      }
      
      if (type === "bookmark-deleted") {
        if(activity.object) {
          title = `Deleted Bookamrk  <a target="_blank" href="${activity.object.link}" >${activity.object.title}`;
        } else {
          title = "Deleted Bookamrk";
        }
      }
      
      if (type === "bookmark-folder-created") {
        title = `Added Bookamrk Folder: ${activity.object && activity.object.title}`;
      }
      
      if (type === "bookmark-folder-deleted") {
        title = `Deleted Bookamrk Folder: ${activity.object && activity.object.title}`;
      }

      return title;
    },
    redirectToMessagingView() {
      if (this.$route.name !== "MessagingView") {
        this.$router.push({
          name: "MessagingView",
          params: {
            company: this.$route.params.company,
            workspace: this.currentWorkspaceId,
          },
        });
      }
    },
    hasClass(elem, className) {
      return elem.className.split(" ").indexOf(className) > -1;
    },
    docClickEventHandler(event) {
      const element = event.target;
      if (this.hasClass(element, "link-message")) {
        event.preventDefault();
        this.redirectToMessagingView();
      }
      if (this.hasClass(element, "link-member")) {
        event.preventDefault();
        // if (member && has(member, "id")) {
        const member = this.$api.getEntity(
          "user",
          this.activity.details.user_id
        );
        if (member) {
          this.showUserInfo(member);
        }
        // }
      }
      if (this.hasClass(element, "link-task")) {
        event.preventDefault();
        if (
          this.activity.type === "task-comment-created" ||
          this.activity.type === "task-comment-reaction-added"
        ) {
          this.openTaskDialogHandler(this.activity.object.task);
        } else {
          this.openTaskDialogHandler(this.activity.object);
        }
      }
      if (this.hasClass(element, "link-media")) {
        const media = this.$api.getEntity(
          "media",
          this.activity.object.media_id
        );
        this.fullview(media);
      }
    },
  },
  filters: {
    dateConversion(date) {
      const formatedDate = format(parseJSON(date), "EEEE, LLL do @ hh:mma");
      return formatedDate;
    },
  },
};
</script>
