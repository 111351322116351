<template>
  <div id="activity" class="activity-log q-px-md">
    <div>
      <q-expansion-item
        v-for="(group, i) in activityGroup"
        :key="i"
        default-opened
        ref="expansionItems"
        exact-active-class="transparent"
        header-class="text-uppercase	text-subtitle1 bg-white text-bold text-grey-10 items-center justify-center q-px-none"
        header-style="position:sticky;top:0;z-index: 100;min-height:24px"
      >
        <template v-slot:header>
          <!-- <q-separator style="background:#DBE1E7;" class="q-mr-sm" /> -->
          <div>
            {{ i | dateConversion }}
          </div>
          <!-- <q-separator style="background:#DBE1E7;" class="q-ml-sm" /> -->
        </template>
        <template v-for="(activity, index) in group">
          <activity-log
            :group="group"
            :key="index"
            :index="index"
            :activity="activity"
            :currentWorkspaceId="currentWorkspaceId"
            :openTaskDialogHandler="openTaskDialogHandler"
            :fullview="fullview"
            :showUserInfo="showUserInfo"
          />
        </template>
      </q-expansion-item>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="imageList"
      :ishide="ishide"
      :labels="taskLabels"
      :moreOption="moreOption"
      :currentUser="user"
      :dialogMedia="dialogMedia"
      :isWorkspaceOwner="isWorkspaceOwner"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :resetDialog="() => resetDialog()"
      @sendComment="sendComment"
      @updateComment="updateComment"
      @updatepositions="updatepositions"
      @clickonhidebutton="ishide = !ishide"
      @clickonmoreoption="clickonmoreoption"
      @deleteMediaComment="deleteMediaComment"
      @deleteCommentPoint="deleteCommentPoint"
      @closeMediaModel="closeMediaModel"
      @setActiveMedia="setActiveMedia"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
    ></image-viewer-dialog>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :currentWorkspace="currentWorkspace"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    ></profile-dialog>
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import subDays from "date-fns/subDays";
import groupBy from "lodash/groupBy";

import format from "date-fns/format";
import parseJSON from "date-fns/parseJSON";

import ActivityLog from "@/views/WorkspaceView/InfoSidebar/About/ActivityLog";

import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import ProfileDialog from "@/components/ProfileDialog";

export default {
  name: "ActivityLogs",
  props: [
    "activityLog",
    "currentWorkspaceId",
    "currentWorkspace",
    "isWorkspaceOwner",
  ],
  components: {
    ActivityLog,
    ImageViewerDialog,
    ProfileDialog,
  },
  api: {
    board: {
      defaultValue: [],
      cacheKey() {
        return `BoardQuery:${this.currentWorkspace.boards[0].id}`;
      },
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      showModal: false,
      dialogMedia: null,
      gridMediaId: null,
      ishide: false,
      profileDialog: {
        show: false,
        user: null,
      },
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
    };
  },
  filters: {
    dateConversion(activtyGroupDate) {
      if (activtyGroupDate === format(new Date(), "iiii, MMM. do")) {
        return "Today";
      } else if (
        activtyGroupDate === format(subDays(new Date(), 1), "iiii, MMM. do")
      ) {
        return "Yesterday";
      }
      return activtyGroupDate;
    },
  },
  methods: {
    openTaskDialogHandler(taskData, isOpen = null) {
      let task = taskData;
      if(task.task_id) {
        task = this.$api.getEntity("task", task.task_id);
      }
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    fullview(media) {
      this.dialogMedia = media;
      this.showModal = true;
      this.gridMediaId = media.id;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
  },
  computed: {
    userProfileDialog() {
      return this.profileDialog.user;
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      } else {
        return [];
      }
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.imageList.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    activityGroup() {
      if (this.activityLog) {
        const activityList = this.activityLog.map((activity) => {
          return {
            ...activity,
          };
        });
        const uniqueList = orderBy(
          uniqWith(activityList, isEqual),
          ["id"],
          ["desc"]
        );
        return groupBy(uniqueList, (activity) => {
          return format(parseJSON(activity["created_at"]), "iiii, MMM. do");
        });
      } else {
        return null;
      }
    },
  },
};
</script>
