var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex-1 workspace-folder group-hover q-mx-sm',
    !_vm.isFolderOpen && 'workspace-folder-hover',
    !_vm.isFolderOpen && _vm.hasActiveWorkspace && 'folder-has-selected-workspace',
  ]},[_c('q-expansion-item',{staticStyle:{"margin-top":"6px"},attrs:{"value":_vm.isFolderOpen},on:{"input":_vm.toggleFolder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex-1 row items-center justify-between workspace-folder-actions",staticStyle:{"margin-left":"10px"}},[_c('div',{staticClass:"row items-center"},[_c('q-icon',{staticClass:"folder",class:[_vm.isFolderOpen && 'open-folder'],style:({ color: '#677484' }),attrs:{"size":"24px","name":_vm.isFolderOpen ? _vm.$icons.mdiFolderOpen : _vm.$icons.matFolder}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"folder-title",class:['folder-name q-ml-sm text-truncate'],style:({
              'max-width': '180px',
              display: 'flex',
              'align-items': 'center',
            })},[_vm._v(" "+_vm._s(_vm.folder.title)+" "),(_vm.hasWorkspaceNotification)?_c('q-icon',{staticStyle:{"margin-left":"3px"},attrs:{"color":"primary","name":_vm.$icons.matFiberManualRecord,"size":"12px"}}):_vm._e()],1),(_vm.isTruncated)?_c('q-tooltip',{attrs:{"offset":[0, -50]}},[_vm._v(" "+_vm._s(_vm.folder.title)+" ")]):_vm._e()],1),_c('q-menu',{attrs:{"context-menu":""}},[_c('q-list',{staticClass:"q-py-sm"},[_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticStyle:{"min-height":"24px"},attrs:{"clickable":""},on:{"click":function($event){return _vm.renameFolder(_vm.folder)}}},[_c('q-item-section',[_vm._v(" Rename folder ")])],1),_c('q-item',{staticStyle:{"min-height":"24px"},attrs:{"clickable":""}},[_c('q-item-section',[_vm._v(" Add to folder ")]),_c('q-item-section',{attrs:{"side":""}},[_c('q-icon',{attrs:{"name":_vm.$icons.matKeyboardArrowRight}})],1),(_vm.remainedWorkspaces)?_c('add-workspace-to-folder',{attrs:{"remainedWorkspaces":_vm.remainedWorkspaces,"folder":_vm.folder,"addWorkspaceToFolder":_vm.addWorkspaceToFolder,"removeWorkspaceFromFolder":_vm.removeWorkspaceFromFolder}}):_vm._e()],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticStyle:{"min-height":"24px"},attrs:{"clickable":""},on:{"click":function($event){return _vm.confirmDeleteFolder(_vm.folder.id, false)}}},[_c('q-item-section',[_vm._v(" Remove folder ")])],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"text-red",staticStyle:{"min-height":"24px"},attrs:{"clickable":""},on:{"click":function($event){return _vm.confirmDeleteFolder(_vm.folder.id, true)}}},[_c('q-item-section',[_vm._v(" Delete folder & worskapces ")])],1)],1)],1)],1)]},proxy:true}])},[_vm._l((_vm.preferredWorkspaces),function(child,i){return _c('q-item',{key:'folder-preferred' + child.id,class:[
        'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
        _vm.returnCurrentWorkspaceId === child.id &&
          'router-link-active active-workspace-item',
      ]},[_c('div',{class:{
          'q-mb-sm': _vm.returnCurrentWorkspaceId === child.id,
        }},[_c('workspace-navigation',{attrs:{"onlineMembers":_vm.onlineMembers,"workspace":child,"folders":_vm.folders,"index":i}})],1)])}),_vm._l((_vm.sortedWorkspaces),function(child,i){return _c('q-item',{key:'folder-public' + child.id,class:[
        'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
        _vm.returnCurrentWorkspaceId === child.id &&
          'router-link-active active-workspace-item',
      ]},[_c('div',{class:{
          'q-mb-sm': _vm.returnCurrentWorkspaceId === child.id,
        }},[_c('workspace-navigation',{attrs:{"workspace":child,"folders":_vm.folders,"index":i}})],1)])}),_vm._l((_vm.peopleWorkspaces),function(child,i){return _c('q-item',{key:i,class:[
        'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
        _vm.returnCurrentWorkspaceId === child.id &&
          'router-link-active active-workspace-item',
      ]},[_c('div',{class:{
          'q-mb-sm': _vm.returnCurrentWorkspaceId === child.id,
        }},[_c('workspace-navigation',{attrs:{"workspace":child,"onlineMembers":_vm.onlineMembers,"folders":_vm.folders,"index":i,"returnCurrentWorkspaceId":_vm.returnCurrentWorkspaceId}})],1)])}),_c('q-item',{staticClass:"q-mt-xs"},[_c('div',{staticClass:"add-folder-area q-mb-xs flex-1 text-center row justify-center items-center cursor-pointer",staticStyle:{"margin-left":"44px"}},[_vm._v(" Add to folder "),_c('q-tooltip',[_vm._v("Add workspaces & people to this folder")]),(_vm.remainedWorkspaces)?_c('add-workspace-to-folder',{attrs:{"remainedWorkspaces":_vm.remainedWorkspaces,"folder":_vm.folder,"addWorkspaceToFolder":_vm.addWorkspaceToFolder,"removeWorkspaceFromFolder":_vm.removeWorkspaceFromFolder}}):_vm._e()],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }