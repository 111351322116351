<template>
  <div v-if="editor">
    <document-editor-toolbar
      :editor="editor"
      @textformat="handleTextFormat"
      :closeDocEditor="updateDocStateOnClose"
      :publishDocument="publishDocumentHandler"
      :exportDocument="exportDocumentHandler"
      :isDisabled="isFocused"
      :isMobileView="isMobileView"
      :viewDocShortcuts="viewDocShortcuts"
      :playVideo="playVideo"
      :isFromNote="true"
      :isFromAbout="true"
    />
    <div class="about-editor-wrapper">
      <div class="q-px-xs">
        <div class="editor-width" style="max-width: 270px;">
          <EditorContent
            style="color: #42526e"
            class="overflow-hidden"
            :editor="editor"
          >
          </EditorContent>
        </div>
      </div>
    </div>

    <q-dialog
      no-backdrop-dismiss
      transition-hide="none"
      transition-show="none"
      no-refocus
      @hide="clsoeHyperlinkModal"
      v-model="hyperlinkModal"
    >
      <q-card style="min-width: 320px" class="create-folder">
        <div class="row title q-mb-md">
          <span class="q-mx-auto">Add URL</span>
        </div>
        <q-input
          placeholder="Type url"
          dense
          outlined
          autofocus
          v-model="url"
          @blur="$v.url.$touch()"
          :error="fieldErrors('url').length > 0"
          bottom-slots
          no-error-icon
          @keydown.enter.prevent="setLinkHandler"
        >
          <template v-slot:error>
            <div class="text-negative">
              {{ fieldErrors("url").length > 0 ? fieldErrors("url")[0] : "" }}
            </div>
          </template>
        </q-input>
        <div class="text-center q-mt-md">
          <q-btn
            color="transperant"
            dense
            flat
            no-caps
            size="14px"
            label="Cancel"
            style="width: 120px"
            class="q-mr-sm"
            @click="clsoeHyperlinkModal"
          />
          <q-btn
            color="primary"
            dense
            no-caps
            label="OK"
            class="set-link-btn"
            @click="setLinkHandler"
          />
        </div>
      </q-card>
    </q-dialog>
    <document-shortcuts-dialog
      v-model="isDocShortcutDialogOpen"
      @close="isDocShortcutDialogOpen = false"
    />
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

import { url } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Typography from "@tiptap/extension-typography";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
// import HardBreak from "@tiptap/extension-hard-break";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import Collaboration from "@tiptap/extension-collaboration";
import Details from "@/lib/tiptap-details/tiptap-extension-details";
import DetailsContent from "../../lib/tiptap-content/tiptap-extension-details-content";
import DetailsSummary from "../../lib/tiptap-summary/tiptap-extension-details-summary";
// import { customNewline } from "@/components/DocumentEditor/CustomExtensions";
import DocumentEditorToolbar from "@/components/DocumentEditor/DocumentEditorToolbar";
import DocumentShortcutsDialog from "@/components/DocumentEditor/DocumentShortcutsDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
// import { compile } from "@/components/Editor/MarkupUtils";

import {
  exportDocumentPDF,
  exportDocument,
  deleteDocumentPDF,
} from "@/services/auth";

export default {
  props: {
    doc: {
      type: Object,
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    updateDocStateOnClose: Function,
    title: String,
    docDetails: String,
    isMobileView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditorContent,
    DocumentEditorToolbar,
    DocumentShortcutsDialog,
    VideoDialog,
  },
  mixins: [validationMixin],
  validations: {
    url: { url },
  },
  validationMessages: {
    url: {
      url: "message.validation.url.url",
    },
  },
  data() {
    return {
      editor: null,
      isFocused: false,
      isInvalid: false,
      hyperlinkModal: false,
      url: null,
      isDocShortcutDialogOpen: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  created() {
    let vm = this;
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          HardBreak: {
            addKeyboardShortcuts() {
              return {
                Enter: () => this.editor.commands.setHardBreak(),
                // "Shift-Enter": () => this.editor.commands.setHardBreak(),
              };
            },
          },
          history: false,
        }),
        Placeholder.configure({
          placeholder: "Type or paste your content here",
        }),
        Link.extend({
          addKeyboardShortcuts() {
            return {
              "Mod-k": () => {
                const previousUrl = vm.editor.getAttributes("link").href;
                vm.url = previousUrl;
                vm.hyperlinkModal = true;
                // return true;
              },
            };
          },
        }).configure({
          openOnClick: false,
        }),
        // Link.configure({
        //   openOnClick: false,
        // }),
        TextStyle,
        Color,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Typography,
        Underline,
        Superscript,
        Subscript,
        Table.extend({
          addKeyboardShortcuts() {
            return {
              "Mod-Enter": () => {
                this.editor.chain().focus().addRowAfter().run();
                return true;
              },
            };
          },
        }).configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          alignments: ["left", "right", "center"],
        }),
        Collaboration.configure({
          document: this.doc,
        }),
        Details.configure({
          persist: true,
          HTMLAttributes: {
            class: "details",
          },
        }),
        DetailsContent,
        DetailsSummary,
      ],
      editorProps: {},
      editable: this.editable,
      content: this.docDetails,
      onTransaction({ editor, transaction }) {
        // The editor state has changed.
        if(transaction.updated > 0) {
          const json = editor.getHTML()
          // send the content to an API here
          vm.$emit("updateDescription", json);
        }
      },
    });
  },
  beforeDestroy() {
    if (!this.editor) return;
    this.editor.destroy();
  },
  methods: {
    getQueryParameterByName(name, url) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    },
    viewDocShortcuts() {
      this.isDocShortcutDialogOpen = true;
    },
    playVideo() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/766700215",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    focusContentEdiotr() {
      this.editor.commands.focus();
    },
    clsoeHyperlinkModal() {
      this.hyperlinkModal = false;
    },
    setLinkHandler() {
      // cancelled
      // this.editor.commands.scrollIntoView();
      if (this.url === null) {
        return;
      }
      if (this.url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        this.hyperlinkModal = false;
        return;
      }
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: this.url })
        .run();
      this.hyperlinkModal = false;
      // this.editor.commands.scrollIntoView();
    },
    publishDocumentHandler() {
      this.updateDocStateOnClose(true);
    },
    
    async exportDocumentHandler(type) {
      let response = null;
      if(type == "pdf") {
        response =  await exportDocumentPDF(
          {
            html: this.editor.getHTML(),
            title: this.title,
          },
          this.authToken
        );
      }else{
        response =  await exportDocument(
        {
          html: this.editor.getHTML(),
          title: this.title,
        },
        this.authToken
      );
      }
      
      if (response && response.status_code === 200) {
        window.location = response.data;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Document exported successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        setTimeout(() => {
          let fielName = this.getQueryParameterByName("fileName", response.data);
          deleteDocumentPDF(
            {
              file: fielName,
            },
            this.authToken
          );
        }, 5000);
      }
    },
    inputHandler(event) {
      if (this.isValid(event.target.value)) {
        this.isInvalid = false;
      } else {
        this.isInvalid = true;
      }
      this.$emit("updateTitle", event.target.value);
    },
    isValid(title) {
      if (isEmpty(title)) {
        this.isInvalid = true;
        this.$refs.docTitleTextarea.focus();
        return false;
      }
      this.isInvalid = false;
      return true;
    },
    handleTextFormat({ type, detail }) {
      switch (type) {
        case "undo":
          this.editor.chain().focus().undo().run();
          break;
        case "redo":
          this.editor.chain().focus().redo().run();
          break;
        case "paragraph":
          this.editor.chain().focus().setParagraph().run();
          break;
        case "heading":
          this.editor.chain().focus().toggleHeading({ level: detail }).run();
          break;
        case "bold":
          this.editor.chain().focus().toggleBold().run();
          break;
        case "italic":
          this.editor.chain().focus().toggleItalic().run();
          break;
        case "underline":
          this.editor.chain().focus().toggleUnderline().run();
          break;
        case "strike":
          this.editor.chain().focus().toggleStrike().run();
          break;
        case "subscript":
          this.editor.chain().focus().toggleSubscript().run();
          break;
        case "superscript":
          this.editor.chain().focus().toggleSuperscript().run();
          break;
        case "textalign":
          this.editor.chain().focus().setTextAlign(detail).run();
          break;
        case "color":
          this.editor.chain().focus().setColor(detail).run();
          break;
        case "bullet":
          if (detail === "ordered") {
            this.editor.chain().focus().toggleOrderedList().run();
          } else {
            this.editor.chain().focus().toggleBulletList().run();
          }
          break;
        case "task":
          this.editor.chain().focus().toggleTaskList().run();
          break;
        case "hyperlink": {
          const previousUrl = this.editor.getAttributes("link").href;
          this.url = previousUrl;
          this.hyperlinkModal = true;
          break;
        }
        case "quote":
          this.editor.chain().focus().toggleBlockquote().run();
          break;
        case "code":
          this.editor.chain().focus().toggleCode().run();
          break;
        case "codeblock":
          this.editor.chain().focus().toggleCodeBlock().run();
          break;
        case "hr":
          this.editor.chain().focus().setHorizontalRule().run();
          break;
        case "togglelist":
          if (this.editor.can().setDetails()) {
            this.editor.chain().focus().setDetails().run();
          } else {
            this.editor.chain().focus().unsetDetails().run();
          }
          break;
        case "table":
          if (this.editor.state.selection.$anchor.pos === 1) {
            /*
              Add empty node to have space above table if table is inserted at
              start of document editor.It is not correct way it is just work around 
              solution for time being.
            */
            this.editor.commands.insertContent("<p><div></div></p>");
          }
          this.editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run();
          break;
        case "addcolbefore":
          this.editor.chain().focus().addColumnBefore().run();
          break;
        case "addcolafter":
          this.editor.chain().focus().addColumnAfter().run();
          break;
        case "addrowbefore":
          this.editor.chain().focus().addRowBefore().run();
          break;
        case "addrowafter":
          this.editor.chain().focus().addRowAfter().run();
          break;
        case "deletetable":
          this.editor.chain().focus().deleteTable().run();
          break;
        case "deleterow":
          this.editor.chain().focus().deleteRow().run();
          break;
        case "deletecol":
          this.editor.chain().focus().deleteColumn().run();
          break;
        case "mergecell":
          this.editor.chain().focus().mergeCells().run();
          break;
        case "splitcell":
          this.editor.chain().focus().splitCell().run();
          break;
        default:
          console.log("no option");
      }
    },
    debounceTitleHandler: debounce(function (event) {
      this.inputHandler(event);
      this.isFocused = false;
    }, 300),
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    isToolbarDisabled() {
      return (
        document.getElementById("document-title") === document.activeElement
      );
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
  },
  watch: {
    doc() {
      // const isSame =
      //   JSON.stringify(this.editor.getJSON()) === JSON.stringify(value);
      // if (isSame) return;
      // this.editor.commands.setContent(value, false);
    },
    editable(value) {
      this.editor.setEditable(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.error-indicator {
  border: 1px solid #fc173d;
  background-color: #ffe5eb;
  border-radius: 6px;
}
.set-link-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
.document-editor-container {
  height: 400px;
  .document-editor {
    height: 400px;
    .editor-scroll-wrapper {
      height: 400px;
    }
  }
}
.about-editor-wrapper{
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 5px;
}
</style>