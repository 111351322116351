<template>
  <div
    class="group-hover relative-position q-mb-sm notification-wrapper q-py-sm"
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <div class="row flex-no-wrap q-pl-md">
      <div class="q-mr-md">
        <div
          class="message-notification-icon row justify-center"
          @click="routeTo('MessagingView', actionScope.id)"
        >
          <q-icon
            :name="$icons.matTextsms"
            color="white"
            class=" self-center"
            size="20px"
          />
        </div>
      </div>
      <div class="row" style="width:100%">
        <q-menu context-menu content-class="overflow-auto" auto-close>
          <q-list>
            <q-item clickable v-if="!notification.read" @click="updateNotification()">
              <q-item-section>
                Mark read
              </q-item-section>
              <!-- <q-item-section v-else>
                Mark unread
              </q-item-section> -->
            </q-item>
            <q-item
              clickable
              @click="routeTo('BoardView', actionScope.id)"
            >
              <q-item-section>
                Go to workspace
              </q-item-section>
            </q-item>
          </q-list>
      </q-menu>
        <div class="row justify-between" style="width:100%">
          <div class="cursor-pointer" @click="showUserInfo(actionSubject)">
            <span
              style="font-size:15px"
              class="text-subtitle2 text-blue-grey-10"
            >
              {{ actionTakenByFullName }}
            </span>
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md"
            style="right:0"
          >
            <q-btn
              flat
              :icon="$icons.matSentimentSatisfied"
              size="10px"
              class="thread-icon"
              rounded
              dense
            >
              <q-menu
                content-class="overflow-auto apply-width"
                anchor="bottom middle"
                self="top middle"
              >
                <div class="card react-emoji-card">
                  <img
                    v-for="(emoji, index) in reactions"
                    :key="index"
                    :src="'/static/react-icons/' + emoji.icon"
                    alt="ANGRY"
                    @click="
                      reactOnPost({ post: actionObject, reaction: emoji })
                    "
                    class="react-emoji-icon q-mr-sm cursor-pointer"
                  />
                </div>
              </q-menu>
            </q-btn>
            <!-- <q-btn
              v-if="notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark unread
              </q-tooltip>
            </q-btn>
            <q-btn
              v-else
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark read
              </q-tooltip>
            </q-btn> -->
          </div>
        </div>
        <div style="width:100%">
          <div
            v-if="notificationContent.length >= 165 && readMoreToggle === false"
            v-html="trucateContet(notificationContent)"
            class="q-mr-md notification-content post-content dont-break-out"
          ></div>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
            v-html="notificationContent"
            class="q-mr-md notification-content post-content dont-break-out"
          ></div>
          <span
            class="text-primary  cursor-pointer"
            @click="readMoreToggle = true"
            v-show="
              notificationContent.length >= 165 && readMoreToggle === false
            "
          >
            read more
          </span>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
            class="read-more-text"
          >
            <div
              v-if="
                (actionObject.media && actionObject.media.length) ||
                  (actionObject.mediafolder && actionObject.mediafolder.length)
              "
            >
              <post-media
                v-if="actionObject.media"
                :attachments="actionObject.media"
                :canRemoveMedia="
                  canRemoveMessageFromWorkspace ||
                    isWorkspaceOwner ||
                    (user && user.id === actionObject.user.id)
                "
                :forceDisableRemove="
                  actionObject.content === '' && actionObject.media.length <= 1
                "
                :removeMedia="removeMedia"
                :postId="actionObject.id"
                :labels="labels"
                :user="user"
                :workspace="actionScope.id"
                :isWorkspaceOwner="isWorkspaceOwner"
                :height="88"
                :editDoc="editDoc"
                @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
                @deleteCommentPoint="
                  (data) => $emit('deleteCommentPoint', data)
                "
                @sendComment="(data) => $emit('sendComment', data)"
                @updatepositions="(data) => $emit('updatepositions', data)"
                @updateMediaCommentPointLabel="
                  (data) => $emit('updateMediaCommentPointLabel', data)
                "
                @deleteMediaComment="
                  (data) => $emit('deleteMediaComment', data)
                "
                @updateComment="(data) => $emit('updateComment', data)"
              />
            </div>
          </div>
          <!-- Reactions List badges -->
          <div style="margin-top:10px" v-if="reactionData">
            <div class="row inline">
              <template v-for="(reactiondata, index) in reactionData">
                <div :key="index" class="q-ml-xs">
                  <div class="relative-position reaction-icon row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                      @click="
                        reactOnPost({
                          post: actionObject,
                          reaction: index,
                        })
                      "
                    />
                    <div class="text-black" style="font-size:12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height:30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center ">
                            <div class="q-mr-sm">
                              <q-avatar
                                color="blue-grey-11"
                                text-color="grey-10"
                                size="26px"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <!-- <span
                                  class="text-avatar-style"
                                  style="font-size:9px"
                                  v-else
                                >
                                  {{ item.first | firstChar
                                  }}{{ item.last | firstChar }}
                                </span> -->
                                <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="time" style="font-size:12px;">
          {{ dateTimeFormat() }}
          <span
            class="text-primary  cursor-pointer"
            style="font-size:16px"
            @click="readMoreToggle = false"
            v-if="readMoreToggle"
          >
            read less
          </span>
        </div>
      </div>
    </div>
    <div v-if="delete_media.flag">
      <confirm-dialog
        v-if="delete_media.flag"
        v-model="delete_media.flag"
        title="Delete File?"
        question="Are you sure you want to detach the media from the post?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
      />
    </div>
  </div>
</template>
<script>
import getUnixTime from "date-fns/getUnixTime";
import { mapGetters } from "vuex";
import NotificationNavMixin from "@/mixins/NotificationNavMixin";
import UserScope from "@/mixins/UserScope";
import { compile } from "@/components/Editor/MarkupUtils";
import PostMedia from "@/components/Miscellaneous/PostMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import {
  DetachPostMediaMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
  UpdateNotificationsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
export default {
  name: "NewPostReactionNotification",
  mixins: [NotificationNavMixin, UserScope],
  props: [
    "notification",
    "reactions",
    "showUserInfo",
    "isWorkspaceOwner",
    "labels",
    "editDoc",
  ],
  components: {
    PostMedia,
    ConfirmDialog,
  },
  data() {
    return {
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      readMoreToggle: false,
    };
  },
  mounted() {
    this.preloadImage();
  },
  methods: {
    redirectToMessages(actionObj) {
      this.$router.push(
        `/companies/${this.activeCompany}/workspaces/${actionObj.workspace_id}/messaging`
      );
    },
    preloadImage() {
      this.reactions.forEach((emoji) => {
        var img = new Image();
        img.src = "/static/react-icons/" + emoji.icon;
      });
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        const variables = {
          post_id: this.delete_media.post,
          media: [this.delete_media.id],
        };

        await this.$api.mutate({
          mutation: DetachPostMediaMutation,
          variables,
        });
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        const query = this.$api.getQuery("NotificationsQuery");
        const actionNotifications = query.data.notifications.filter(
          (n) => n.action.object_id === this.actionObject.id
        );
        actionNotifications.map((n) => {
          n.read = true;
          return n;
        });
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async reactOnPost(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }
      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      if (!this.notification.read) {
        const query = this.$api.getQuery(`NotificationsQuery`);
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope.id === Number(this.actionScope.id))
          .filter((n) => n.action.object_type === "App\\Post");
        if (notifications.length) {
          notifications.map((n) => (n.read = true));
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables: {
              notification_ids: notifications.map((n) => n.id),
              read: true,
            },
          });
        }
      }
      const newReactiondata = { ...post.reactiondata };
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          });
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        }
        this.notification.action.object.reactiondata = { ...newReactiondata };
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          },
        ];
        this.notification.action.object.reactiondata = { ...newReactiondata };
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
    reactionData() {
      return Array.isArray(this.actionObject.reactiondata)
        ? null
        : this.actionObject.reactiondata;
    },
    notificationContent() {
      return compile(this.actionObject.content);
    },
  },
};
</script>
