<template>
  <q-dialog :value="true" persistent>
    <q-card class="welcome-dialog">
      <div class="close" @click="closeDialog">
        <div class="icon">
          <q-icon
            :name="$icons.matClose"
            size="20px"
            style="cursor: pointer"
          ></q-icon>
        </div>
      </div>
      <div class="main-content">
        <div class="title">
          <p>Hey! Welcome!</p>
        </div>
        <div class="content">
          <p class="bold">
            You have access to all Heycollab features for 15 days.
          </p>
          <p>Watch this short video to get you started.</p>
        </div>
        <div class="video" style="border-radius: 7px" @click="videoPlayer">
          <q-icon
            :name="$icons.matPlayArrow"
            color="primary"
            size="80px"
          ></q-icon>
        </div>
      </div>
    </q-card>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </q-dialog>
</template>

<script>
import VideoDialog from "../VuePlayer/VideoDialog.vue";
export default {
  data() {
    return {
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  components: {
    VideoDialog,
  },
  methods: {
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/566888054",
      };

      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
      this.$mixpanelEvent("intro-video", {});
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    closeDialog() {
      this.$emit("closeDialog", null);
    },
  },
};
</script>
