var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.isShowImportStatusBanner.importCompleted
      ? 'import-completed'
      : 'import-pending',
    'check-import-status-banner',
  ]},[_c('div',{staticStyle:{"font-weight":"400"}},[(_vm.isShowImportStatusBanner.importCompleted)?_c('span',[_c('span',[_vm._v("Your import is now complete. ")])]):_c('span',[_vm._v("Your import is in progress! We'll email you as soon as it's done.")])]),_c('div',{staticClass:"row items-center justify-end q-mt-md"},[_c('q-btn',{staticClass:"status-check-btn bg-white q-mr-md",attrs:{"flat":"","dense":"","color":"primary","label":_vm.isShowImportStatusBanner.importCompleted
          ? 'Manage your imports'
          : 'Check status',"padding":"0 14px","no-caps":""},on:{"click":_vm.openImportMenuDialog}}),_c('q-btn',{attrs:{"flat":"","color":"white","label":"Dismiss","no-caps":""},on:{"click":function($event){return _vm.$emit('closeBanner')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }