<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
    style="max-width:450px;"
  >
    <q-card class="q-px-lg q-py-md bg-white task-duplication">
      <div class="close-btn-bg">
        <q-btn
          dense
          flat
          padding="0"
          size="sm"
          style="color:#a3a9b1;margin-left: 2px;margin-top:-2px"
          :icon="$icons.matClose"
          @click="closeDialog"
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto">Workspace e-mail</span>
      </div>
      <div class="content">
        <div class="relative-position field">
          <div class="text-bold q-mt-sm q-mb-sm">
            E-mail to message
          </div>
          <q-input
            outlined
            dense
            v-model="emailToMessage"
            readonly
          >
            <template v-slot:append>
              <div class="flex">
              <div 
              @click="() => (popover.message = !popover.message)" class="q-mr-sm">
                <q-btn
                  round
                  @mouseover="setHovering(true)"
                  @mouseout="setHovering(false)"
                  :icon="$icons.matInfo"
                  :style="hovering ? { 'color': '#007aff' } : { 'color': '#b1b7c5' }"
                  size="12px"
                />
              </div>
              <div>
              <q-btn
                :label="isMessageCopy ? 'Copied' : 'Copy'"
                size="sm"
                no-caps
                class="q-px-ml "
                style="background-color: #007aff; color:white"
                @click="
                  $copyTextToClipboard(emailToMessage, 'Message copied successfully');
                  isMessageCopy = true;
                "
              />
              </div>
              </div>
            </template>
          </q-input>
          <v-popover
              :autoHide="true"
              :handleResize="true"
              offset="30px"
              class="no-height"
              placement="top-start"
              :open="popover.message"
              popoverClass="vb-info-popover"
              top="100px"
            >
              <template slot="popover">
                <div class="close-btn-bg  text-right" @click="() => (popover.message = !popover.message)">
                  <q-btn
                    dense
                    flat
                    padding="0"
                    size="sm"
                    style="color:#a3a9b1;"
                    :icon="$icons.matClose"
                    class="q-mr-xs q-mt-xs"
                  />
                </div>
                <div class="vb-popover-information">
                  <div class="">
                    <p>You may e-mail a message directly to this workspace.</p>
                    <ul style="max-width:450px;padding-left: 15px;color: #9c98ab;">
                      <li>Text of e-mail will be the main message</li>
                      <li>E-mail attachments will be attached to message</li>
                      <li>People cc'ed in e-mail will be mentioned in message (only if they are members of this workspace)</li>
                    </ul>
                  </div>
                </div>
              </template>
            </v-popover>
        </div>
        <div class="relative-position field">
          <div class="text-bold q-mt-sm q-mb-sm">
            E-mail to task
          </div>
          <q-input
            ref="copytext"
            outlined
            dense
            v-model="emailToTask"
            readonly
          >
          <template v-slot:append>
              <div class="flex">
              <div 
              @click="() => (popover.email = !popover.email)" class="q-mr-sm">
                <q-btn
                  round
                  @mouseover="setHoveringEmail(true)"
                  @mouseout="setHoveringEmail(false)"
                  :icon="$icons.matInfo"
                  :style="emailHovering ? { 'color': '#007aff' } : { 'color': '#b1b7c5' }"
                  size="12px"
                />
              </div>
              <div>
              <q-btn
                :label="isTaskCopy ? 'Copied' : 'Copy'"
                size="sm"
                no-caps
                class="q-px-ml "
                style="background-color: #007aff; color:white;"
                @click="
                  $copyTextToClipboard(emailToTask, 'Email copied successfully');
                  isTaskCopy = true;
                "
              />
              </div>
              </div>
            </template>
          </q-input>
          <v-popover
              :autoHide="false"
              :handleResize="true"
              offset="30px"
              class="no-height"
              placement="bottom-start"
              :open="popover.email"
              popoverClass="vb-info-popover"
              top="100px"
            >
              <template slot="popover">
                <div class="close-btn-bg" >
                  <q-btn
                     dense
                    flat
                    padding="0"
                    size="sm"
                    style="color:#a3a9b1;margin-left: 2px;margin-top:-2px"
                    :icon="$icons.matClose"
                    @click="() => (popover.email = !popover.email)"
                  />
                </div>
                <div class="vb-popover-information">
                  <div class="">
                    <p>You may create a task by sending an e-mail.</p>
                    <ul style="max-width:450px;padding-left: 15px;color: #9c98ab;">
                      <li>E-mail subject will be the task name</li>
                      <li>E-mail text will be the task's info/about</li>
                      <li>E-mail attachments will be attached to the task</li>
                      <li> People cc'ed in e-mail will be assigned to the task (only if  
     they are members of this workspace)</li>
                    </ul>
                  </div>
                </div>
              </template>
            </v-popover>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>

import { VPopover } from "v-tooltip";

export default {
  components: {
    VPopover,
  },
  name: "WorkspaceEmail",
  props: ["workspaceName", "companyUrl", "value", "closeDialog"],
  data() {
    return {
      isMessageCopy: false,
      isTaskCopy: false,
      popover: {
        email: false,
        message: false,
      },
      hovering: false,
      emailHovering: false,
    };
  },
  computed: {
    emailToMessage() {
      return `${this.workspaceName}+message.${this.companyUrl}@mail.heycollab.com`;
    },
    emailToTask() {
      return `${this.workspaceName}+task.${this.companyUrl}@mail.heycollab.com`;
    },
  },
  methods: {
    setHovering(tf) {
      if (this.hovering != tf) {
        this.hovering = tf;
      }
    },
    setHoveringEmail(tf) {
      if (this.emailHovering != tf) {
        this.emailHovering = tf;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popover{
  .close-btn-bg {
    position: absolute;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
}
</style>