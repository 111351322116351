<template>
  <div class="q-pa-md">
    <div class="text-center">
      <img
        class="mt-3"
        src="https://s3-us-west-1.amazonaws.com/vabotu/default/sign_in_lock_icon.svg"
        height="145"
      />
      <h2 class="headline-text mt-3">Forgot Password?</h2>
      <h6 class="active--text an-15 medium-text">{{ companyUri }}</h6>
      <p class="headline-caption-text mt-3">
        Provide your e-mail address for the company name above to reset your
        password
      </p>
    </div>
    <div class="mt-4">
      <form v-on:submit.prevent="$v.$invalid ? null : submit()">
        <p class="secondary--text centered q-mb-sm" v-if="errorMessage.flag">
          {{ errorMessage.text }}
        </p>
        <q-input
          v-model="email"
          @blur="$v.email.$touch()"
          @focus="errorMessage.flag = false"
          label="Your company email address"
          type="text"
          bottom-slots
          color="primary"
          :error="fieldErrors('email').length > 0"
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-pink">
              {{
                fieldErrors("email").length > 0 ? fieldErrors("email")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-btn
          type="submit"
          no-caps
          color="primary"
          size="16px"
          class="full-width q-py-sm q-mt-sm"
          label="Continue"
          :loading="loader"
          :disabled="$v.$invalid || loader"
        />
      </form>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import errorHandler from "@/utils/ErrorHandler";
import { SendPasswordResetEmail } from "@/gql";

export default {
  name: "Step1Dialog",
  props: ["companyUri"],
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
  },
  data() {
    return {
      loader: false,
      email: null,
      forgotPasswordImage:
        "https://s3-us-west-1.amazonaws.com/vabotu/default/sign_in_lock_icon.svg",
      errorMessage: {
        flag: false,
        text: null,
      },
    };
  },
  methods: {
    async submit() {
      this.loader = true;
      const variables = {
        email: this.email,
        company_uri: this.companyUri,
      };
      this.$api
        .mutate({
          mutation: SendPasswordResetEmail,
          variables,
        })
        .then((res) => {
          if (res.data.sendPasswordResetEmail) {
            this.loader = false;
            this.$emit("next", { email: this.email });
          } else {
            if (res.errors[0].message === "UserNotFoundError") {
              this.loader = false;

              this.errorMessage = {
                flag: true,
                text: "Email not registered with this team",
              };
            }
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = {
            flag: true,
            text: errorHandler.getErrorMessage(
              error,
              this,
              "message.UserNotFoundError"
            ),
          };
        });
    },
    resetErrorMessage() {
      this.errorMessage = {
        flag: false,
        text: null,
      };
    },
  },
};
</script>
