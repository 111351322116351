<template>
  <div
    class="group-hover relative-position q-mb-sm notification-wrapper q-py-sm"
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <div class="row flex-no-wrap q-pl-md">
      <div class="q-mr-md">
        <div
          @click="edit(actionObject.task)"
          class="task-comment-notification-icon row justify-center"
        >
          <q-icon
            :name="$icons.mdiCommentCheck"
            color="white"
            class=" self-center"
            size="20px"
          />
        </div>
      </div>
      <div class="row" style="width:100%">
        <div class="row justify-between" style="width:100%">
          <div class="cursor-pointer" @click="showUserInfo(actionSubject)">
            <span
              style="font-size:15px"
              class="text-subtitle2 text-blue-grey-10"
            >
              {{ actionTakenByFullName }}
            </span>
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md"
            style="right:0"
          >
            <q-btn
              flat
              :icon="$icons.matSentimentSatisfied"
              size="10px"
              class="thread-icon"
              rounded
              dense
            >
              <q-menu
                content-class="overflow-auto apply-width"
                anchor="bottom middle"
                self="top middle"
              >
                <div class="card react-emoji-card">
                  <img
                    v-for="(emoji, index) in reactions"
                    :key="index"
                    :src="'/static/react-icons/' + emoji.icon"
                    alt="ANGRY"
                    @click="
                      reactOnPost({ post: actionObject, reaction: emoji })
                    "
                    class="react-emoji-icon q-mr-sm cursor-pointer"
                  />
                </div>
              </q-menu>
            </q-btn>
            <!-- <q-btn
              v-if="notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark unread
              </q-tooltip>
            </q-btn> -->
            <q-btn
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark read
              </q-tooltip>
            </q-btn>
            <!-- <q-btn
              v-if="notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark unread
              </q-tooltip>
            </q-btn> -->
            <q-btn
              v-if="!notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark read
              </q-tooltip>
            </q-btn>
            <!-- <q-btn
              class="thread-icon"
              flat
              round
              dense
              padding="0"
              size="12px"
              :icon="$icons.matMoreHoriz"
            >
              <q-menu auto-close>
                <q-list>
                  <q-item clickable @click="updateNotification()">
                    <q-item-section v-if="!notification.read">
                      Mark read
                    </q-item-section>
                    <q-item-section v-else>
                      Mark unread
                    </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="routeTo('MessagingView', actionScope.id)"
                  >
                    <q-item-section>
                      Go to workspace
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn> -->
          </div>
        </div>
        <div style="width:100%">
          <div
            v-html="actionObject.content"
            class="notification-content dont-break-out "
            style="margin-right:30px"
          ></div>
          <div>
            <post-media
              v-if="actionObject.media"
              :attachments="actionObject.media"
              :canRemoveMedia="
                canRemoveMessageFromWorkspace ||
                  isWorkspaceOwner ||
                  (user && user.id === actionObject.user.id)
              "
              :forceDisableRemove="
                actionObject.content === '' && actionObject.media.length <= 1
              "
              :removeMedia="removeMedia"
              :postId="actionObject.id"
              :labels="labels"
              :user="user"
              :workspace="actionScope.id"
              :isWorkspaceOwner="isWorkspaceOwner"
              :height="88"
              :editDoc="editDoc"
              @closeMediaModel="(data) => $emit('closeMediaModel', data)"
              @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
              @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
              @sendComment="(data) => $emit('sendComment', data)"
              @updatepositions="(data) => $emit('updatepositions', data)"
              @updateMediaCommentPointLabel="
                (data) => $emit('updateMediaCommentPointLabel', data)
              "
              @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
              @updateComment="(data) => $emit('updateComment', data)"
            />
          </div>

          <!-- Reactions List badges -->
          <div style="margin-top:10px" v-if="reactionData">
            <div class="row inline">
              <template v-for="(reactiondata, index) in reactionData">
                <div :key="index" class="q-ml-xs">
                  <div class="relative-position reaction-icon row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                      @click="
                        reactOnPost({
                          post: actionObject,
                          reaction: index,
                        })
                      "
                    />
                    <div class="text-black" style="font-size:12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height:30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center ">
                            <div class="q-mr-sm">
                              <q-avatar
                                color="blue-grey-11"
                                text-color="grey-10"
                                size="26px"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <!-- <span
                                  class="text-avatar-style"
                                  style="font-size:9px"
                                  v-else
                                >
                                  {{ item.first | firstChar
                                  }}{{ item.last | firstChar }}
                                </span> -->
                                <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="time" style="font-size:12px;">
          {{ dateTimeFormat() }}
        </div>
      </div>
    </div>
    <div v-if="delete_media.flag">
      <confirm-dialog
        v-if="delete_media.flag"
        v-model="delete_media.flag"
        title="Delete File?"
        question="Are you sure you want to detach the media from the post?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
      />
    </div>
  </div>
</template>
<script>
import getUnixTime from "date-fns/getUnixTime";
import { mapGetters } from "vuex";
import NotificationNavMixin from "@/mixins/NotificationNavMixin";
import UserScope from "@/mixins/UserScope";
import PostMedia from "@/components/Miscellaneous/PostMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
// import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
// import MediaMethodMixin from "@/mixins/MediaMethodMixin";
// import TaskDialog from "@/views/BoardView/TaskDialog";
import {
  DetachPostMediaMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
  UpdateNotificationsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
import Avatar from 'vue-avatar';

export default {
  name: "NewTaskCommentReactionNotification",
  mixins: [
    NotificationNavMixin,
    UserScope,
    // BoardsMethodMixin,
    // MediaMethodMixin,
  ],
  props: [
    "notification",
    "reactions",
    "showUserInfo",
    "isWorkspaceOwner",
    "labels",
    "mentions",
    "editDoc",
  ],
  components: {
    PostMedia,
    ConfirmDialog,
    // TaskDialog,
    Avatar,
  },
  data() {
    return {
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      taskDialog: false,
      task: null,
    };
  },
  mounted() {
    this.preloadImage();
  },
  methods: {
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    preloadImage() {
      this.reactions.forEach((emoji) => {
        var img = new Image();
        img.src = "/static/react-icons/" + emoji.icon;
      });
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        const variables = {
          post_id: this.delete_media.post,
          media: [this.delete_media.id],
        };

        await this.$api.mutate({
          mutation: DetachPostMediaMutation,
          variables,
        });
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        const query = this.$api.getQuery("NotificationsQuery");
        const actionNotifications = query.data.notifications.filter(
          (n) => n.action.object_id === this.actionObject.id
        );
        actionNotifications.map((n) => {
          n.read = true;
          return n;
        });
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async reactOnPost(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }
      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      if (!this.notification.read) {
        const query = this.$api.getQuery(`NotificationsQuery`);
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope.id === Number(this.actionScope.id))
          .filter((n) => n.action.object_type === "App\\Post");
        if (notifications.length) {
          notifications.map((n) => (n.read = true));
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables: {
              notification_ids: notifications.map((n) => n.id),
              read: true,
            },
          });
        }
      }
      const newReactiondata = { ...post.commentreactiondata };
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          });
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        }
        this.notification.action.object.commentreactiondata = {
          ...newReactiondata,
        };
        this.$api.updateEntity("taskcomment", post.id, {
          commentreactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          },
        ];
        this.notification.action.object.reactiondata = { ...newReactiondata };
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
    reactionData() {
      return Array.isArray(this.actionObject.commentreactiondata)
        ? null
        : this.actionObject.commentreactiondata;
    },
    currentBoardId() {
      if (this.actionScope) {
        return this.actionScope.boards[0].id;
      }
      return null;
    },
    board() {
      return this.$api.getEntity("board", this.currentBoardId);
    },
  },
};
</script>
