<template>
  <div class="flex-x scroll-x fill-height" style="padding: 30px 40px 10px 20px">
    <div class="full-width flex-y">
      <div class="flex-1 fill-height scroll-y">
        <q-linear-progress
          color="primary"
          indeterminate
          size="5px"
          class="ma-0"
          track-color="indigo-2"
          v-if="progress"
        />
        <table class="bordered vb-table" width="100%" v-else>
          <thead>
            <tr>
              <th>
                <div
                  class="users-th"
                  style="padding:9px 0 10px;-top-right-radius: 5px;background:#e0e4ec;"
                >
                  <q-checkbox
                    color="indigo-10"
                    v-model="selectAll"
                    @input="toggleAll"
                    size="sm"
                  />
                  <div style="margin-left: 20px">Users</div>
                </div>
              </th>
              <th width="20%">
                <div
                  class="users-th"
                  style="padding:9px 0 10px;background:#e0e4ec"
                >
                  <div style="margin-left: 20px">Email</div>
                </div>
              </th>
              <!-- <th width="13%">
                <div
                  class="users-th"
                  style="padding:9px 0 10px;background:#e0e4ec"
                >
                  <div style="margin-left: 20px">Reset</div>
                </div>
              </th> -->
              <th width="20%">
                <div
                  class="users-th"
                  style="padding:9px 0 10px;background:#e0e4ec"
                >
                  <div style="margin-left: 20px">Status</div>
                </div>
              </th>
              <th width="20%">
                <div
                  class="users-th"
                  style="padding:9px 0 10px;background:#e0e4ec"
                >
                  <div style="margin-left: 20px">Roles</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredMembers" :key="item.id" class="user-tr">
              <td v-if="item.roles[0]">
                <div class="items">
                  <q-checkbox
                    color="indigo-13"
                    v-model="item.selected"
                    @input="toggleSelect(item)"
                    size="sm"
                  />
                  <div style="margin-left: 15px">{{ item.first }} {{ item.last }}</div>
                </div>
              </td>
              <td>
                <div style="padding: 0 15px"><a :href="'mailto:'+item.email" style="color: #007aff; text-decoration: none">{{item.email}}</a></div>
              </td>
              <!-- <td v-if="item.roles[0]">
                <div style="margin-left: 15px">Reset Password</div>
              </td> -->
              <td v-if="item.roles[0]">
                <div style="margin-left: 15px">{{item.status | capitalize}}</div>
              </td>
              <td v-if="item.roles[0]">
                <div style="margin-left: 15px; margin-right: 5px">
                  <div
                    class="dropdown-set"
                  >
                    <div class="dropdwn-btn-content">
                      <div>{{ item.roles[0].name | capitalize }}</div>
                      <div>
                        <q-icon :name="$icons.matKeyboardArrowDown" size="20px" style="color: #007aff"></q-icon>
                      </div>
                    </div>
                    <q-menu content-class="overflow-auto" auto-close>
                      <q-list style="min-width:140px">
                        <q-item
                          v-for="role in roleList"
                          :key="role.id"
                          @click="changeRole(item, role)"
                          :clickable="
                            userRole &&
                              userRole.id <= item.roles[0].id &&
                              canChangeUserRole
                          "
                        >
                          <q-item-section
                            v-if="userRole && userRole.id <= role.id"
                          >
                            {{ role.name | capitalize }}
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="errorWarning" class="mt-4 pink--text">{{ errorWarning }}</div>
    </div>
    <div v-if="dialog.show">
      <confirm-dialog
        v-model="dialog.show"
        title="Delete Users ?"
        :question="$t('message.users.deleteConfirmation')"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="resetDialog"
        :successMethod="deleteUsersBulk"
        :loading="false"
      />
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      :recepient="userProfileDialog"
      v-if="profileDialog.show"
      :user="currentUser"
      :currentWorkspace="workspaceid"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    ></profile-dialog>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
// import InviteEmails from "@/components/InviteEmails";
import TableMultiSelection from "@/mixins/tableMultiSelect";
import UserScope from "@/mixins/UserScope";
import compact from "lodash/compact";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ProfileDialog from "@/components/ProfileDialog";
import {
  MembersQuery,
  RolesQuery,
  ChangeUserAccountStatusMutation,
  ChangeUserRoleMutation,
  DeleteUsersMutation,
  WorkspaceMembersQuery,
} from "@/gql";

export default {
  name: "ManageUsers",
  props: ["currentWorkspaceObj", "currentUser", "search"],
  mixins: [TableMultiSelection, UserScope],
  api: {
    members: {
      query: MembersQuery,
      cacheKey: "MembersQuery",
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    roles: {
      query: RolesQuery,
      cacheKey: "RolesQuery",
      defaultValue: null,
    },
  },
  beforeCreate() {
    this.$eventBus.$off("changeStatusActiveBulk");
    this.$eventBus.$off("changeStatusDeactiveBulk");
    this.$eventBus.$off("changeRoleBulk");
    this.$eventBus.$off("deleteUsersDialogHandler");
  },
  mounted() {
    this.$eventBus.$on("changeStatusActiveBulk", this.changeStatusActiveBulk);
    this.$eventBus.$on(
      "changeStatusDeactiveBulk",
      this.changeStatusDeactiveBulk
    );
    this.$eventBus.$on("changeRoleBulk", this.changeRoleBulk);
    this.$eventBus.$on(
      "deleteUsersDialogHandler",
      this.deleteUsersDialogHandler
    );
  },
  components: {
    // InviteEmails,
    ConfirmDialog,
    ProfileDialog,
  },
  data() {
    return {
      progress: false,
      inviteLoader: false,
      errorWarning: null,
      dialog: {
        show: false,
        title: null,
        type: null,
      },
      profileDialog: {
        show: false,
        user: null,
      },
      dropdown: [
        {
          title: "Activate Selected Users",
          color: "",
          action: this.changeStatusActiveBulk,
        },
        {
          title: "Deactivate Selected Users",
          color: "",
          action: this.changeStatusDeactiveBulk,
        },
      ],
      verifyCaptch: false,
      captchaToken: null,
      finalEmail: null,
      isValidForm: true,
    };
  },
  methods: {
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    closeLoader(data = null) {
      this.inviteLoader = false;
      if (data) {
        this.errorWarning = data;
      }
    },
    watchInviteEmails(obj) {
      this.finalEmail = obj.emails;
      this.isValidForm = obj.valid;
    },
    sendEmailsInvite() {
      this.inviteLoader = true;
      this.$refs.inviteMail.sendInvitation(this.captchaToken);
    },
    VerifyCaptcha(response) {
      if (response) {
        this.verifyCaptch = true;
        this.captchaToken = response;
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    async changeStatus(user) {
      const userStatus = user.status;
      const newStatus = userStatus === "active" ? "inactive" : "active";

      const variables = {
        user_ids: [user.id],
        status: newStatus,
      };

      await this.$api.mutate({
        mutation: ChangeUserAccountStatusMutation,
        variables,
      });
    },
    async changeRole(user, role) {
      const variables = {
        user_ids: [user.id],
        role_id: role.id,
      };
      await this.$api.mutate({
        mutation: ChangeUserRoleMutation,
        variables,
      });
      user.roles = [role];
    },
    async changeStatusActiveBulk() {
      this.progress = true;
      const variables = {
        user_ids: this.selected.map((o) => o.id),
        status: "active",
      };

      await this.$api.mutate({
        mutation: ChangeUserAccountStatusMutation,
        variables,
      });
      this.toggleAll();

      this.progress = false;
    },
    async changeStatusDeactiveBulk() {
      this.progress = true;
      const variables = {
        user_ids: this.selected.map((o) => o.id),
        status: "inactive",
      };

      await this.$api.mutate({
        mutation: ChangeUserAccountStatusMutation,
        variables,
      });
      this.toggleAll();
      this.progress = false;
    },
    async changeRoleBulk(role) {
      this.progress = true;
      const variables = {
        user_ids: this.selected.map((o) => o.id),
        role_id: role.id,
      };
      await this.$api.mutate({
        mutation: ChangeUserRoleMutation,
        variables,
      });
      this.toggleAll();
      this.progress = false;
    },
    async deleteUsersBulk() {
      this.progress = true;
      let selectedUser = this.selected.map((o) => o.id);
      const variables = {
        user_ids: selectedUser,
      };
      //console.log(variables)
      const res = await this.$api.mutate({
        mutation: DeleteUsersMutation,
        variables,
      });

      this.$api.query({
        query: MembersQuery,
        cacheKey: "MembersQuery",
      });

      if (res) {
        await this.$api.query({
          query: WorkspaceMembersQuery,
          variables: {
            workspace_id: this.currentWorkspaceObj.id,
          },
        });

        const query = this.$api.getQuery("Workspaces2Query");
        
        if (query.data) {
          query.data.workspaces2 = query.data.workspaces2.filter(
            (w) => w.type != 'people' && !selectedUser.includes(w.user_id)  
          );
        }


        this.toggleAll();
      }
      this.progress = false;
      this.resetDialog();
    },
    resetDialog() {
      this.dialog = {
        show: false,
        title: null,
        type: null,
      };
    },
    deleteUsersDialogHandler() {
      this.dialog = {
        show: true,
        title: "Delete selected users?",
        type: "delete",
      };
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    isReadyToSend() {
      if (this.finalEmail && compact(this.finalEmail).length > 0) {
        return true;
      }
      return false;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    workspaceid() {
      return this.currentWorkspaceObj.id;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    items() {
      if (this.members) {
        return this.members.map((element) => {
          return {
            ...element,
            selected: false,
          };
        });
      } else {
        return [];
      }
    },
    userRole() {
      if (this.user && this.user.roles && this.user.roles.length > 0) {
        return this.user.roles[0];
      }
      return null;
    },
    roleList() {
      if (this.roles) {
        return this.roles.filter((role) => {
          if (this.userRole) return this.userRole.id <= role.id;
          return false;
        });
      }
      return null;
    },
    filteredMembers() {
      if (this.search === null) {
        return this.items;
      }
      return this.search !== ""
        ? this.items.filter(
            (member) =>
              (member.first && member.first.toLowerCase().includes(this.search?.toLowerCase())) ||
              (member.last && member.last.toLowerCase().includes(this.search?.toLowerCase()))
          )
        : this.items;
    },
  },
  watch: {
    selected(to) {
      if (to.length) {
        this.$emit("userMemberSays", true);
      } else {
        this.$emit("userMemberSays", false);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
tr:hover {
  background: transparent !important;
}
.users-th {
  display: flex;
  align-items: center;
  height: 37px;
}
.user-tr:hover {
  background: #e0e4ec !important;
}
.items {
  display: flex;
  align-items: center;
}
.dropdown-set {
  width: 225px;
  height: 28px !important;
  background: #f9fafc;
  border: none;
  display: flex;
  align-itmes: center;
  padding: 0 5px;
  cursor: pointer;
}
.dropdwn-btn-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #000000;
  font-size: 14px;
  align-items: center;
}
.dropdown-set .q-btn__wrapper {
  padding: 0 10px;
}
</style>
