<template>
  <div>
    <div class="row items-center justift-between">
      <div
        id="trello"
        :class="[
          'row items-center justify-center q-px-md q-py-xs card history-card',
        ]"
        clickable
      >
        <q-icon v-if="history.medium === 'Trello'" :name="$icons.mdiTrello" style="color: #085cd5" size="25" />
        <q-icon v-if="history.medium === 'Slack'" :name="$icons.mdiSlack" style="color: #E01E5A" size="25" />
        <img v-if="history.medium === 'Asana'" class="image" src="/static/images/logo/asana-logo.svg" />
        <img v-if="history.medium === 'Clickup'" class="image" src="/static/images/logo/clickup.svg" />
        <img v-if="history.medium === 'Monday'" class="image" src="/static/images/logo/monday.svg" />
        <div class="justify-center items-center flex q-ml-xs card-text">
          {{ history.medium }}
        </div>
      </div>
      <div class="q-mx-xl flex-1">
        <div
          :class="[
            'import-status-text q-mb-sm',
            history.completed ? 'completed' : 'pending',
          ]"
        >
          {{ history.completed ? "Import completed" : "Import pending" }}
        </div>
        <q-linear-progress
          v-if="!history.completed"
          :value="importProgress"
          size="10px"
          color="primary"
          rounded
        />
        <div class="import-date-time-text q-mt-sm">
          {{ history.completed ? "Completed" : "Started" }} on
          {{ format(parseJSON(history.created_at), "MMMM dd yyyy hh:mm aaaa") }}
        </div>
      </div>
      <div
        v-if="history.completed"
        class="delete-import-textbtn"
        @click="deleteDialogHandler(true)"
      >
        Delete imported items
      </div>
    </div>
    <hr style="border: solid 1px #e2e4e6;" class="q-my-lg" />
    <confirm-dialog
      v-model="deleteConfirm.flag"
      title="Delete Imported Items?"
      question="Are you sure you want to delete imported items?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteDialogHandler(false)"
      :successMethod="deleteItem"
      :loading="deleteConfirm.loader"
    />
  </div>
</template>

<script>
import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { DeleteImportDataMutation } from "@/gql";
export default {
  name: "OtherPlatformImportDataHistory",
  props: ["history"],
  components: { ConfirmDialog },
  data() {
    return {
      parseJSON,
      format,
      deleteConfirm: {
        flag: false,
        loader: false,
      },
    };
  },
  methods: {
    deleteDialogHandler(flag = true) {
      this.deleteConfirm.flag = flag;
    },
    async deleteItem() {
      const importDataquery = this.$api.getQuery("ImportDataQuery");
      this.deleteConfirm.loader = true;
      try {
        const query = this.$api.getQuery("Workspaces2Query");
        if (query.data) {
          const workspaceIds = this.history.importDataHistory.map(
            (historyObj) => historyObj.workspace_id
          );
          if (workspaceIds.includes(this.currentWorkspaceId)) {
            this.$router.push({
              name: "BoardView",
              params: { workspace: 1, company: this.$route.params.company },
            });
          }
          this.history.importDataHistory.forEach((element) => {
            query.data.workspaces2 = query.data.workspaces2.filter(
              (w) => w.id !== element.workspace_id
            );
          });
        }
        await this.$api.mutate({
          mutation: DeleteImportDataMutation,
          variables: {
            id: this.history.id,
          },
        });
        if (!!importDataquery.data && importDataquery.data.importData) {
          importDataquery.data.importData = importDataquery.data.importData.filter(
            (data) => data.id !== this.history.id
          );
        }
        this.deleteConfirm.loader = false;
      } catch (error) {
        this.deleteConfirm.loader = false;
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong!Please try again.",
          icon: this.$icons.matReportProblem,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
  },
  computed: {
    startedDate() {
      if (this.history) {
        return format(
          parseJSON(this.history.created_at),
          "MMMM dd yyyy hh:mm aaa"
        );
      }
      return null;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    importProgress() {
      return this.history.stage / 100;
    },
  },
};
</script>
<style lang="scss" scoped>
.import-status-text {
  font-size: 16px;
  font-weight: 500;
  &.pending {
    color: #000;
  }
  &.completed {
    color: #15d89a;
  }
}
.import-date-time-text {
  font-size: 14px;
  font-weight: 500;
  color: #a7b0bb;
}
.delete-import-textbtn {
  font-size: 14px;
  font-weight: 500;
  color: #d92439;
  cursor: pointer;
}
</style>
