
<template>
  <div>
    <!-- Left Column -->
    <!-- <div class="col-6">
      <strong class="an-35 bold-text">Update Payment</strong>
      <div class="divider section-spacer"></div>
      <div class="medium-text an-20 mt-3">
        Adding a new card will update your payment details and remove the
        previous card added
      </div>
      <img
        src="/static/images/settings/setting-moster.png"
        alt="Monster"
        class="absolute-bottom-left q-ml-md q-mb-md"
      />
    </div> -->
    <!-- Right Column -->
    <div class="">
      <!-- Scrollable Section for tab components -->
      <form v-on:submit.prevent >
        <div>
          <!-- <span class="label-span">Name on Card*</span> -->
          <q-input
            v-model="nameoncard"
            @input="$v.nameoncard.$touch()"
            @blur="$v.nameoncard.$touch()"
            outlined
            placeholder="Name on Card"
            type="text"
            bottom-slots
            dense
            autofocus
            :error="fieldErrors('nameoncard').length > 0"
            no-error-icon
            bg-color="white"
          >
            <template v-slot:error>
              <div class="text-pink">
                {{
                  fieldErrors("nameoncard").length > 0
                    ? fieldErrors("nameoncard")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <stripe-element @error="stripeErrorHandler" ref="card" />
          <p v-show="cardError" class="mt-4 pink--text">{{ cardError }}</p>
          <p v-show="invalidCodeMessage" class="mt-4 pink--text">
            {{ invalidCodeMessage }}
          </p>
        </div>
      </form>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeCardDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Update payment"
          class="payment-btn"
          :loading="loader"
          :disabled="isDisabled"
          @click.native="createNewPayCard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import StripeElement from "@/components/StripeElement";
export default {
  name: "AddNewPayCard",
  mixins: [validationMixin],
  props: ["loader", "invalidCodeMessage"],
  components: {
    StripeElement,
  },
  validations: {
    nameoncard: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
  },
  validationMessages: {
    nameoncard: {
      required: "message.validation.cardinfo.nameoncard.required",
      maxLength: "message.validation.cardinfo.nameoncard.maxLength",
      minLength: "message.validation.cardinfo.nameoncard.minLength",
    },
  },
  data() {
    return {
      nameoncard: null,
      cardError: null,
    };
  },
  methods: {
    async createNewPayCard() {
      const result = await this.$refs.card.createToken();
      const variables = {
        id: result.token.id,
        card_id: result.token.card.id,
        nameoncard: this.nameoncard,
        last4: result.token.card.last4,
      };
      this.$emit("replaceCard", variables);
    },
    stripeErrorHandler(message) {
      this.cardError = message;
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>