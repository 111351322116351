<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white board-template">
      <div class="close-btn-bg">
        <q-btn
          dense
          flat
          padding="0"
          round
          size="sm"
          style="color:#000000;margin-left: 2px;margin-top:-2px"
          :icon="$icons.matClose"
          @click="closeDialog"
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto q-mb-md">Save board as template</span>
      </div>
      <q-input
        type="text"
        ref="focusname"
        v-model="title"
        @blur="$v.title.$touch()"
        :error="fieldErrors('title').length > 0"
        dense
        outlined
        bottom-slots
        no-error-icon
        placeholder="Template Name..."
      >
        <template v-slot:error>
          <div class="text-negative">
            {{ fieldErrors("title").length > 0 ? fieldErrors("title")[0] : "" }}
          </div>
        </template>
      </q-input>
      <q-btn
        dense
        no-caps
        padding="5px 5px"
        class="create-template-btn q-mt-sm primary-button"
        color="#15d89a"
        size="md"
        label="Save"
        @click="createTemplateHandler"
        :loading="isLoading"
        :disabled="$v.$invalid || isLoading"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import { CreateBoardTemplateMutation } from "@/gql";
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

export default {
  name: "CreateTemplateDialog",
  props: ["value", "closeDialog", "workspace"],
  mixins: [validationMixin],
  validations: {
    title: { required },
  },
  validationMessages: {
    title: {
      required: "message.validation.template.required",
    },
  },
  api: {},
  data() {
    return {
      title: null,
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.focusname.focus();
    });
  },
  methods: {
    replaceSpace() {
      this.title = this.title.replace(/[\W_]/g, "-");
    },
    async createTemplateHandler() {
      this.isLoading = true;
      const variables = {
        title: this.title,
        workspace_id: this.workspace.id,
      };

      await this.$api
        .mutate({
          mutation: CreateBoardTemplateMutation,
          variables,
        })
        .then((response) => {
          // const BoardTemplateQuery = this.$api.getQuery(`BoardTemplateQuery`);
          // BoardTemplateQuery.data.boardTemplate.push(
          //   response.data.createBoardTemplate
          // );
          // this.isLoading = false;
          if(response.data.createBoardTemplate) {
            this.$q.notify({
              classes: "success-notification",
              position: "top-right",
              message: "We are creating your board template. We'll notify you upon completion.",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ icon: this.$icons.matClose, color: "white" }],
            });
            this.closeDialog();
            this.$mixpanelEvent('create-template',{})
          }
        });
    },
  },
  computed: {},
};
</script>
