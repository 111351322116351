<template>
  <div
    :class="[
      isShowImportStatusBanner.importCompleted
        ? 'import-completed'
        : 'import-pending',
      'check-import-status-banner',
    ]"
  >
    <div style="font-weight: 400;">
      <span v-if="isShowImportStatusBanner.importCompleted">
        <span>Your import is now complete. </span>
        <!-- <span
          @click="openImportMenuDialog"
          class="cursor-pointer"
          style="text-decoration: underline;"
          >this page
        </span> -->
      </span>
      <span v-else
        >Your import is in progress! We'll email you as soon as it's done.</span
      >
    </div>
    <div class="row items-center justify-end q-mt-md">
      <q-btn
        flat
        dense
        color="primary"
        :label="
          isShowImportStatusBanner.importCompleted
            ? 'Manage your imports'
            : 'Check status'
        "
        class="status-check-btn bg-white q-mr-md"
        padding="0 14px"
        no-caps
        @click="openImportMenuDialog"
      />
      <q-btn
        flat
        color="white"
        label="Dismiss"
        no-caps
        @click="$emit('closeBanner')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["isShowImportStatusBanner", "openImportMenuDialog"],
};
</script>
