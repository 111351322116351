<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="$emit('closeDialog')"
    :value="value"
  >
    <q-card style="min-width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span v-if="renameFolder" class="q-mx-auto">Rename folder</span>
        <span v-else class="q-mx-auto">Create new folder</span>
      </div>
      <q-input
        placeholder="Type folder name"
        dense
        outlined
        autofocus
        v-model="folderName"
        @keydown.enter.exact="
          createFolderEvent();
          $event.preventDefault();
        "
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Rename folder"
          style="width:120px"
          v-if="renameFolder"
          @click="renameFolderHandler"
          :loading="loading"
          :disable="this.folderName ? false : true"
        />
        <q-btn
          v-else
          color="primary"
          dense
          no-caps
          label="Create folder"
          class="create-folder-btn"
          @click="createFolder"
          :loading="loading"
          :disable="this.folderName ? false : true"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import getUnixTime from "date-fns/getUnixTime";

import { CreateFolderMutation, UpdateFolderMutation } from "@/gql";

export default {
  name: "CreateFolderDialog",
  props: ["value", "user", "renameFolder"],
  data() {
    return {
      folderName: null,
      loading: false,
    };
  },
  mounted() {
    if (this.renameFolder) {
      this.folderName = this.renameFolder.title;
    }
  },
  methods: {
    createFolderEvent() {
      if (this.renameFolder) {
        this.renameFolderHandler();
      } else {
        this.createFolder();
      }
    },
    async createFolder() {
      this.loading = true;
      try {
        const foldersQuery = this.$api.getQuery("FoldersQuery");
        const OptID = getUnixTime(new Date());
        const optimisticResponseCreateFolder = {
          __typename: "folder",
          id: OptID,
          title: this.folderName,
          user_id: this.user.id,
          owner: this.user,
          workspaces: [],
        };
        if (foldersQuery.data.folder && foldersQuery.data.folder.length) {
          foldersQuery.data.folder.push(optimisticResponseCreateFolder);
        } else {
          foldersQuery.data.folder = [optimisticResponseCreateFolder];
        }
        const response = await this.$api.mutate({
          mutation: CreateFolderMutation,
          variables: {
            title: this.folderName,
          },
        });
        if (response.data.createFolder) {
          foldersQuery.data.folder = foldersQuery.data.folder.map((obj) =>
            obj === optimisticResponseCreateFolder
              ? response.data.createFolder
              : obj
          );
        }
        this.loading = false;
        this.$emit("closeDialog");
      } catch (error) {
        this.$emit("closeDialog");
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async renameFolderHandler() {
      this.loading = true;
      const folder = this.$api.getEntity("folder", this.renameFolder.id);
      folder.title = this.folderName;
      await this.$api.mutate({
        mutation: UpdateFolderMutation,
        variables: {
          title: this.folderName,
          id: this.renameFolder.id,
        },
      });
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
