<template>
  <q-card style="padding: 30px 50px 10px 20px" class="roles-and-permissions">
    <div class="scroll-y">
      <q-linear-progress
        indeterminate
        v-if="progress"
        class="my-0"
        size="sm"
        color="indigo-5"
      />
      <div class="footer-menu-dialog-title">
        <p>Roles & Permissions</p>
      </div>
      <table class="bordered role-permission-table" style="width: 100%">
        <thead>
          <tr style="display:flex;  width:240%">
            <th class="header" width="42%">
              <div>Functions</div>
            </th>
            <th v-for="(role, index) in roles" :key="index" width="10%" class="header">
              <div v-if="role.name === 'guest'">Guest</div>
              <div v-else>{{ role.name | capitalize }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          
          <tr v-for="(items, index) in coll" :key="index">
            <td class="item">
              <div class="q-py-md q-pl-md" v-html="items[0].permission" lines="1">
              </div>
            </td>
            <td v-for="(role, i) in roles" :key="i" class="item">
              <q-list class="text-center q-pl-sm">
                <q-item style="max-height:50px">
                  <template v-for="(item, j) in items">
                    <q-item-section :key="j" v-if="item.role_id === role.id">
                      <q-checkbox
                        :value="!!item.status"
                        :color="item.role_id === 1 ? 'grey-5' : 'indigo-5'"
                        size="md"
                        @input="
                          role.id !== 1 &&
                            togglePermission(
                              item.role_id,
                              item.permission_id,
                              $event
                            )
                        "
                        :disable="item.role_id === 1"
                        class="checkbox"
                      />
                    </q-item-section>
                  </template>
                </q-item>
              </q-list>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </q-card>
</template>
<script>
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

import { mapGetters } from "vuex";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import {
  RolesQuery,
  RolePermissionsQuery,
  ChangePermissionMutation,
} from "@/gql";

export default {
  name: "RolePermissionsDialog",
  mixins: [ProxyModelMixin],
  api: {
    rolePermissions: {
      query: RolePermissionsQuery,
      cacheKey: "RolePermissionsQuery",
      defaultValue: null,
    },
    roles: {
      query: RolesQuery,
      cacheKey: "RolesQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      progress: false,
    };
  },
  methods: {
    async togglePermission(roleId, permissionId, status) {
      this.progress = true;
      // const query = this.$api.getQuery("RolePermissionsQuery");
      // if (query.data) {
      //   query.data.rolePermissions.forEach((r) => {
      //     if (r.permission_id === permissionId && r.role_id === roleId) {
      //       r.status = status;
      //     }
      //   });
      // }
      const variables = {
        role_id: roleId,
        permission_id: permissionId,
        status: status,
      };

      await this.$api.mutate({
        mutation: ChangePermissionMutation,
        variables,
      });

      this.$api.query({
        query: RolePermissionsQuery,
        cacheKey: "RolePermissionsQuery",
      });

      this.progress = false;
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    coll() {
      return sortBy(
        groupBy(this.rolePermissions, "permission_id"),
        "permission_id"
      );
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
tr:hover {
  background: transparent !important;
}
.header {
  height: 37px;
  align-items: center;
}
.checkbox {
  display: flex;
  justify-content: center;
}
</style>
