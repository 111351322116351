<template>
  <div
    class="group-hover q-mb-sm relative-position notification-wrapper q-py-sm "
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <div class="row flex-no-wrap q-pl-md">
      <q-menu context-menu content-class="overflow-auto" auto-close>
          <q-list>
            <q-item clickable v-if="!notification.read" @click="updateNotification()">
              <q-item-section>
                Mark read
              </q-item-section>
              <!-- <q-item-section v-else>
                Mark unread
              </q-item-section> -->
            </q-item>
            <q-item
              @click="
                $copyTextToClipboard(copyTaskLink);
                isTaskCopy = true;
              "
              clickable
            >
              <q-item-section>Copy task link</q-item-section>
            </q-item>
            <q-item @click="edit(actionObject)" clickable>
              <q-item-section>
                Edit Task
              </q-item-section>
            </q-item>
            <q-item clickable @click="changeCompleteStatus">
              <q-item-section v-if="!actionObject.completed">
                Complete Task
              </q-item-section>
              <q-item-section v-else>
                Mark as Incomplete
              </q-item-section>
            </q-item>
            <q-item
              clickable
              @click="routeTo('BoardView', actionScope.id)"
            >
              <q-item-section>
                Go to workspace
              </q-item-section>
            </q-item>
            <q-item clickable @click="deleteNotification">
              <q-item-section class="text-red">
                Delete Notification
              </q-item-section>
            </q-item>
          </q-list>
      </q-menu>
      <div class="q-mr-md">
        <div
          @click="edit(actionObject)"
          class="task-notification-icon row justify-center"
        >
          <q-icon
            :name="$icons.fasCheck"
            color="white"
            class=" self-center"
            size="20px"
          />
          <q-tooltip> Task: {{ actionObject.title }} </q-tooltip>
        </div>
      </div>
      <div class="row" style="width:100%">
        <div class="row justify-between" style="width:100%">
          <div
            class="an-15 demi-bold-text task-title dont-break-out"
            style="font-weight: 500;"
            @click="edit(actionObject)"
          >
            {{ actionObject.title }}
            <q-tooltip> Assigned by {{ actionTakenByFullName }} </q-tooltip>
          </div>
          <div class="q-mr-md" v-if="actionObject.isbookmarked">
            <q-icon
              @click="removeTaskBookmark()"
              size="xs"
              class="cursor-pointer"
              color="pink"
              :name="$icons.matBookmark"
            >
              <q-tooltip>
                Remove from Saved items
              </q-tooltip>
            </q-icon>
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md q-px-xs"
            style="right:0"
          >
            <div
              v-if="actionObject.isbookmarked"
              @click="removeTaskBookmark()"
              class="cursor-pointer"
            >
              <q-icon size="xs" color="pink" :name="$icons.matBookmark">
                <q-tooltip>
                  Remove from Saved items
                </q-tooltip>
              </q-icon>
            </div>
            <div
              v-if="!actionObject.isbookmarked"
              @click="addTaskBookmark()"
              class="cursor-pointer"
            >
              <q-icon size="xs" :name="$icons.matBookmarkBorder">
                <q-tooltip>
                  Add to Saved items
                </q-tooltip>
              </q-icon>
            </div>
          </div>
        </div>
        <div class="time" style="font-size:12px;">
          {{ dateTimeFormat() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationNavMixin from "@/mixins/NotificationNavMixin";

import {
  UpdateTaskMutation,
  AddTaskBookmarkMutation,
  RemoveTaskBookmarkMutation,
  UpdateNotificationsMutation,
  DeleteNotificationsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
export default {
  name: "AssignTaskNotification",
  mixins: [NotificationNavMixin],
  props: [
    "notification",
    "reactions",
    "user",
    "mentions",
    "labels",
    "isWorkspaceOwner",
    "showUserInfo",
  ],
  api: {
    media: {
      defaultValue: null,
      cacheKey() {
        return `MediaQuery:${this.actionScope.id}`;
      },
    },
  },
  data() {
    return {
      isTaskCopy:false,
    };
  },
  methods: {
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async deleteNotification() {
      const variables = {
        notification_ids: [this.notification.id],
      };
      try {
        const query = this.$api.getQuery("NotificationsQuery");
        query.data.notifications = query.data.notifications.filter(
          (n) => n.id !== this.notification.id
        );
        await this.$api.mutate({
          mutation: DeleteNotificationsMutation,
          variables,
        });
        mixpanel.track("Notification Delete");
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeCompleteStatus() {
      const variables = {
        id: this.actionObject.id,
        completed: !this.actionObject.completed,
      };

      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        mixpanel.track("Task Update");
      } catch (error) {
        throw new Error(error);
      }
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    async addTaskBookmark() {
      try {
        const variables = {
          task_id: this.actionObject.id,
        };
        const task = this.$api.getEntity("task", this.actionObject.id);
        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Add");
        this.$mixpanelEvent("save-task", {});
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    async removeTaskBookmark() {
      try {
        const variables = {
          task_id: this.actionObject.id,
        };
        const task = this.$api.getEntity("task", this.actionObject.id);
        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
  },
  computed: {
    copyTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.currentCompany.companyUri}/workspaces/${this.actionScope.id}/board?task=${this.actionObject.id}&workspace=${this.actionScope.id}`;
      return taskLink;
    },
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
    currentBoardId() {
      if (this.actionScope) {
        return this.actionScope.boards[0].id;
      }
      return null;
    },
    board() {
      return this.$api.getEntity("board", this.currentBoardId);
    },
  },
};
</script>
