<template>
  <q-toolbar
    flat
    dense
    class="rounded-borders bg-white justify-between "
    style="min-height:40px;height:43px"
  >
    <div class="row items-center">
      <q-icon
        style="margin-left:10px;color:#674CE8"
        size="20px"
        class="q-mr-sm"
        :name="$icons.mdiSchool"
      />
      <q-toolbar-title
        shrink
        class="text-bold text-black q-px-none"
        style="font-size:15px"
      >
        Learn
      </q-toolbar-title>
    </div>
  </q-toolbar>
</template>
<script>
export default {
  name: "LearnViewToolbar",
};
</script>
