<template>
  <div @click.stop="$emit('closeNotificaiton')">
    <q-toolbar
      flat
      dense
      class="rounded-borders justify-between "
      style="min-height:40px;height:43px;background:#FCFCFD;"
    >
      <div class="row flex-no-wrap">
        <q-icon
          style="margin-left:10px"
          color="dark"
          size="20px"
          class="q-mr-sm"
          :name="typeIcon"
        />
        <q-toolbar-title
          shrink
          class="text-bold text-black q-px-none"
          style="font-size:15px"
        >
          {{ currentWorkspace && currentWorkspace.title }}
        </q-toolbar-title>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button q-ml-sm"
          @click="toggleBanner"
        >
          <q-tooltip>
            Learn about the messaging section
          </q-tooltip>
        </q-btn>
      </div>
      <div class="row items-center toolbar-search" v-if="isSearchVisible">
        <q-input
          dense
          v-model="messageSearch.searchText"
          outlined
          autofocus
          :placeholder="'Search ' + currentWorkspace.title"
          :clear-icon="$icons.matClose"
          class="q-mr-xs"
        >
          <template v-slot:prepend>
            <q-icon :name="$icons.matSearch" />
          </template>
          <template v-slot:append>
            <q-btn
              v-if="messageSearch.searchText"
              @click="clearPostSearchHandler"
              flat
              dense
              round
              class="close-btn"
            >
              <q-icon
                :name="$icons.mdiCloseThick"
                class="absolute"
                style="font-size:12px"
                color="white"
              />
              <q-tooltip>Clear</q-tooltip>
            </q-btn>
            <filter-message
              :user="user"
              :workspace="currentWorkspace"
            ></filter-message>
          </template>
        </q-input>
      </div>
      <div class="row items-center no-wrap q-mr-sm">
        <q-btn
          no-caps
          :icon="$icons.matVideoCall"
          class="q-mr-sm flex-no-wrap video-call-btn"
          dense
          padding="0"
          v-if="canPostMessageFromWorkspace"
          @click="showTooltip = false"
          label="Start call"
        >
          <q-tooltip v-if="showTooltip">Start a call</q-tooltip>
          <q-menu
          auto-close
          :offset="[45, 5]"
          content-class="document-help-menu"
          @before-hide="showTooltip = true"
        >
          <q-list>
            <q-item
              @click="openVideoDailog(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id, isPeople)"
              clickable
              :class="['items-center']"
              ><q-icon
                :name="$icons.matVideoCall"
                size="20px"
                class="q-mr-md"
              />
              Join call
            </q-item>
            <q-item
              @click="copyVideoCallLink(isPeople ? currentWorkspace.peopletitle : currentWorkspace.title, user.id)"
              clickable
              :class="['items-center']"
            >
              <q-icon size="20px" class="q-mr-md" :name="$icons.matLink" />
              Copy call link
            </q-item>
          </q-list>
        </q-menu>
        </q-btn>
        <q-btn
          flat
          round
          dense
          size="md"
          :color="isSearchVisible ? 'primary' : 'black'"
          @click.stop="isSearchVisible = !isSearchVisible"
          :icon="$icons.matSearch"
          class="toolbar-buttons"
        >
          <q-tooltip>
            Search
          </q-tooltip>
        </q-btn>
        <q-btn
          flat
          round
          dense
          size="md"
          :style="{ color: drawerRight === 'info' ? '#007AFF' : 'black' }"
          @click.native.stop="toggleDrawerRight('info')"
          :icon="$icons.matInfo"
          class="toolbar-buttons"
        >
          <q-tooltip>
            About
          </q-tooltip>
        </q-btn>
        <q-btn
          flat
          :icon="$icons.mdiDotsHorizontal"
          size="md"
          color="black"
          v-if="!isPeople"
          round
          dense
          class="toolbar-buttons"
        >
          <q-tooltip>
            More
          </q-tooltip>
          <q-menu content-class="q-py-sm" auto-close>
            <q-list>
              <q-item
                v-close-popup
                @click="openTimelogHendler"
                clickable
                style="min-height: 24px;"
              >
                <q-item-section side top>
                  <q-icon :name="$icons.matSchedule" style="color: #b8c1cb" />
                </q-item-section>
                <q-item-section>
                  View workspace timesheet
                </q-item-section>
              </q-item>
              <q-item
                @click="exportTimeLogCsv()"
                clickable
                style="min-height: 24px;"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Export time report
                </q-item-section>
              </q-item>
              <q-item
                @click="deleteTaskTimeLogs()"
                clickable
                style="min-height: 24px;"
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Clear time report
                </q-item-section>
              </q-item>
              <!-- <q-item
                style="min-height: 24px;"
                @click.stop="preferredWorkspace"
                clickable
              >
                <q-item-section>
                  {{ isPreferred ? "Remove from" : "Add to" }} favorite
                </q-item-section>
              </q-item>
              <q-item
                @click="pendingInvitationDialog = true"
                v-if="!isPeople"
                style="min-height: 24px;"
                clickable
              >
                <q-item-section>
                  Pending invites
                </q-item-section>
              </q-item> -->
              <q-separator class="q-my-sm" style="background:#E3E8ED;" />
              <q-item
                style="min-height:24px"
                @click="openNotificationSettingDialog"
                clickable
              >
                <q-item-section side top>
                  <q-icon
                    :name="$icons.matNotifications"
                    style="color: #b8c1cb"
                  />
                </q-item-section>
                <q-item-section>
                  Change notifications
                </q-item-section>
              </q-item>
              <q-item
                style="min-height:24px"
                v-if="currentWorkspace.notification_level !== 'none'"
                @click="changeWorkspaceSetting('none')"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Mute workspace
                </q-item-section>
              </q-item>
              <q-item
                style="min-height:24px"
                v-else
                @click="changeWorkspaceSetting('all')"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Unmute workspace
                </q-item-section>
              </q-item>
              <q-separator class="q-my-sm" style="background:#E3E8ED;" />
              <q-item
                @click="editView"
                v-if="canChangeWorkspaceSetting || isWorkspaceOwner"
                style="min-height: 24px;"
                clickable
              >
                <q-item-section side top>
                  <q-icon :name="$icons.matSettings" style="color: #b8c1cb" />
                </q-item-section>
                <q-item-section>Workspace settings</q-item-section>
              </q-item>
              <!-- <q-item
                @click="changeType"
                v-if="
                  !isGeneral && (canChangeWorkspaceSetting || isWorkspaceOwner)
                "
                style="min-height: 24px;"
                clickable
              >
                <q-item-section>
                  Change to {{ switchingType }} workspace
                </q-item-section>
              </q-item> -->
              <q-item
                @click="pendingInvitationDialog = true"
                v-if="!isPeople"
                style="min-height: 24px;"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>
                  Pending invites
                </q-item-section>
              </q-item>
              <q-item
                @click="checkExportConvoAccess"
                style="min-height: 24px;"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>Export conversation</q-item-section>
              </q-item>
              <q-item
                @click="dialogHandler(true, 'leaveWorkspaceDialog')"
                v-if="!isWorkspaceOwner && !isGeneral"
                style="min-height: 24px;"
                clickable
              >
                <q-item-section class="text-red">
                  Leave workspace
                </q-item-section>
              </q-item>
              <q-separator class="q-my-sm" style="background:#E3E8ED;" />
              <q-item
                @click="dialogHandler(true, 'deleteWorkspaceDialog')"
                v-if="!isGeneral && isWorkspaceOwner"
                style="min-height: 24px;"
                class="text-red"
                clickable
              >
                <q-item-section side top>
                  <q-icon />
                </q-item-section>
                <q-item-section>Delete workspace</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-btn
          flat
          :icon="$icons.mdiDotsHorizontal"
          size="sm"
          color="blue-grey-7"
          q-px-xs
          q-py-none
          round
          v-if="isPeople"
        >
          <q-tooltip>
            More
          </q-tooltip>
          <q-menu content-class="q-py-sm" auto-close>
            <q-list v-if="isPeople">
              <q-item
                style="min-height: 24px;"
                @click.stop="preferredWorkspace"
                clickable
              >
                <q-item-section>
                  {{ isPreferred ? "Remvove from" : "Add to" }} favorite
                </q-item-section>
              </q-item>
              <q-separator class="q-my-sm" style="background:#E3E8ED;" />
              <q-item
                style="min-height:24px"
                v-if="currentWorkspace.notification_level !== 'none'"
                @click="changeWorkspaceSetting('none')"
                clickable
              >
                <q-item-section>
                  Mute workspace
                </q-item-section>
              </q-item>
              <q-item
                style="min-height:24px"
                v-else
                @click="changeWorkspaceSetting('all')"
                clickable
              >
                <q-item-section>
                  Unmute workspace
                </q-item-section>
              </q-item>
              <q-item
                style="min-height:24px"
                @click="openNotificationSettingDialog"
                clickable
              >
                <q-item-section>
                  Change notifications
                </q-item-section>
              </q-item>
              <q-separator class="q-my-sm" style="background:#E3E8ED;" />
              <q-item
                style="min-height: 24px;"
                @click="checkExportConvoAccess"
                clickable
              >
                <q-item-section>Export conversation</q-item-section>
              </q-item>
              <q-item
                style="min-height: 24px;"
                class="text-red"
                @click="dialogHandler(true, 'deleteWorkspaceDialog')"
                clickable
              >
                <q-item-section>Delete conversation</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </q-toolbar>
    <workspace-create-dialog
      v-if="updateWorkspace.show"
      v-model="updateWorkspace.show"
      @success="updateWorkspace.show = false"
      :workspace="currentWorkspace"
    />
    <pending-invitations-dialog
      v-model="pendingInvitationDialog"
      v-if="pendingInvitationDialog"
      :workspace="currentWorkspace"
      :user="user"
    />
    <workspace-notification-settings
      v-if="notificationSettingDialog"
      :workspace="currentWorkspace"
      :notificationSettingDialog="notificationSettingDialog"
      :closeDialog="closeNotificationSettingDialog"
    />
    <div v-if="deleteWorkspaceDialog.flag">
      <confirm-dialog
        v-model="deleteWorkspaceDialog.flag"
        title="Delete Workspace?"
        :question="
          `Are you sure you want to delete the ${currentWorkspace.title} Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'deleteWorkspaceDialog')"
        :successMethod="deleteWorkspace"
        :loading="deleteWorkspaceLoader"
      />
    </div>
    <div v-if="leaveWorkspaceDialog.flag">
      <confirm-dialog
        v-model="leaveWorkspaceDialog.flag"
        title="Leave Workspace ?"
        :question="
          `Are you sure you want to leave the ${currentWorkspace.title} Workspace ?`
        "
        cancleText="Cancel"
        successText="Remove"
        :cancleMethod="() => dialogHandler(false, 'leaveWorkspaceDialog')"
        :successMethod="leaveWorkspace"
        :loading="leaveWorkspaceLoader"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import UserScope from "@/mixins/UserScope";
import BotMessenger from "@/mixins/BotMessenger";

import FilterMessage from "@/views/WorkspaceView/MessagingToolbar/FilterMessage";
import PendingInvitationsDialog from "@/components/Dialogs/PendingInvitationsDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import WorkspaceCreateDialog from "@/components/Dialogs/WorkspaceCreateDialog";
import WorkspaceNotificationSettings from "@/views/WorkspaceView/NavigationSidebar/WorkspaceNotificationSettings";

import { exportMessage, exportTimeLogs } from "@/services/auth";
import {
  PreferredWorkspaceMutation,
  UpdateWorkspaceMutation,
  LeaveWorkspaceMutation,
  DeleteWorkspaceMutation,
  UpdateNotificationLevelMutation,
  DeleteTaskTimeLogsMutation,
  UpdateUserOnboardingMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  name: "MessagingToolbar",
  props: [
    "toggleDrawerRight",
    "drawerRight",
    "messageSearch",
    "openVideoDailog",
    "copyVideoCallLink",
  ],
  mixins: [UserScope, BotMessenger],
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  components: {
    FilterMessage,
    PendingInvitationsDialog,
    ConfirmDialog,
    WorkspaceCreateDialog,
    WorkspaceNotificationSettings,
  },
  data() {
    return {
      showTooltip:true,
      pendingInvitationDialog: false,
      deleteWorkspaceLoader: false,
      leaveWorkspaceLoader: false,
      deleteWorkspaceDialog: {
        flag: false,
      },
      leaveWorkspaceDialog: {
        flag: false,
      },
      updateWorkspace: {
        show: false,
        type: "private",
      },
      notificationSettingDialog: false,
      isSearchVisible: false,
      timelogs: {
        flag: false,
      },
    };
  },
  methods: {
    openTimelogHendler() {
      this.$eventBus.$emit("openTimelogs", true, this.currentWorkspace.timelogs, false, null, true);
    },
    checkExportConvoAccess() {
      let eligible = this.$checkEligiblity("export_conversation");
      if (eligible) {
        this.exportMessageCsv();
      }
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
        user_id: 0,
        task_id: 0,
      };
      this.board.tasks = this.board.tasks.map((task) => {
        task.timelogs = [];
        return task;
      });
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });

      mixpanel.track("Task Time Logs Deleted");
    },
    async exportTimeLogCsv() {
      const res = await exportTimeLogs(
        this.currentWorkspaceId,
        this.currentCompany.accessToken
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    clearPostSearchHandler() {
      this.messageSearch.searchText = null;
      this.$eventBus.$emit("clearFilter");
    },
    async changeType() {
      const type =
        this.currentWorkspace.type === "public" ? "private" : "public";
      const variables = {
        id: this.currentWorkspace.id,
        type,
      };
      const workspaceEntity = this.$api.getEntity(
        "workspace",
        this.currentWorkspace.id
      );
      workspaceEntity.type = type;
      const res = await this.$api.mutate({
        mutation: UpdateWorkspaceMutation,
        variables,
      });

      if (res) {
        await this.changeWorkspaceType({
          workspaceId: this.currentWorkspace.id,
          type,
        });
      }
    },
    async changeWorkspaceSetting(notificationLevel) {
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2.map((w) => {
          if (w.id === this.currentWorkspaceId) {
            w.notification_level = notificationLevel;
          }
        });
      }
      try {
        await this.$api.mutate({
          mutation: UpdateNotificationLevelMutation,
          variables: {
            workspace_id: this.currentWorkspaceId,
            notification_level: notificationLevel,
          },
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong..",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    openNotificationSettingDialog() {
      this.notificationSettingDialog = !this.notificationSettingDialog;
    },
    closeNotificationSettingDialog() {
      this.notificationSettingDialog = false;
    },
    async preferredWorkspace() {
      const variables = {
        workspace_id: this.currentWorkspace.id,
      };
      try {
        const workspace = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspace.is_preferred = !workspace.is_preferred;
        await this.$api.mutate({
          mutation: PreferredWorkspaceMutation,
          variables,
        });
        mixpanel.track(
          `${
            workspace.is_preferred
              ? "Workspace Preferred Add"
              : "Workspace Preferred Remove"
          }`
        );
      } catch {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          icon: this.$icons.matReportProblem,
        });
      }
    },
    async leaveWorkspace() {
      this.leaveWorkspaceLoader = true;
      const workspaceId = this.currentWorkspace.id;
      const variables = {
        id: workspaceId,
      };
      await this.leavePublicWorkspaceAnnouncement({ workspaceId }); // mixin call
      await this.$api.mutate({
        mutation: LeaveWorkspaceMutation,
        variables,
      });
      this.leaveWorkspaceLoader = false;
      const query = this.$api.getQuery("Workspaces2Query");
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== workspaceId
        );
      }
      this.dialogHandler(false, "leaveWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
    async deleteWorkspace() {
      this.deleteWorkspaceLoader = true;
      const deletedWorkspaceId = this.currentWorkspaceId;

      const variables = {
        id: deletedWorkspaceId,
      };
      const query = this.$api.getQuery("Workspaces2Query");
      await this.$api.mutate({
        mutation: DeleteWorkspaceMutation,
        variables,
      });
      this.deleteWorkspaceLoader = false;
      if (query.data) {
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== deletedWorkspaceId
        );
      }
      this.dialogHandler(false, "deleteWorkspaceDialog");
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    },
    dialogHandler(flag = true, dialog = "deleteWorkspaceDialog") {
      this[dialog] = {
        flag,
      };
    },
    editView() {
      this.updateWorkspace.show = !this.updateWorkspace.show;
    },
    async exportMessageCsv() {
      const res = await exportMessage(
        this.currentWorkspaceId,
        this.currentCompany.accessToken
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    async toggleBanner() {
      this.user.onboarding.is_messaging = !this.user.onboarding.is_messaging;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_messaging",
          flag_value: this.user.onboarding.is_messaging,
        },
      });
      if (this.user.onboarding.is_messaging) {
        this.$mixpanelEvent("messaging-help", {});
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    type() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type;
      }
      return "public";
    },
    typeIcon() {
      let result;
      switch (this.type) {
        case "private":
          result = this.$icons.matLock;
          break;
        case "people":
          result = this.$icons.matPeople;
          break;
        default:
          result = this.$icons.fasBullhorn;
          break;
      }
      return result;
    },
    preferredWorkspaces() {
      return this.workspaces && this.workspaces.filter((w) => w.is_preferred);
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    isGeneral() {
      if (this.currentWorkspace) {
        return (
          this.currentWorkspace.type === "public" &&
          this.currentWorkspace.is_general
        );
      } else {
        return false;
      }
    },
    isPeople() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "people";
      } else {
        return false;
      }
    },
    isPreferred() {
      if (this.preferredWorkspaces && this.currentWorkspace) {
        const preferredIds = this.preferredWorkspaces.map((element) => {
          return element.id;
        });
        return preferredIds.includes(this.currentWorkspace.id);
      } else {
        return false;
      }
    },
    switchingType() {
      if (this.currentWorkspace) {
        return this.currentWorkspace.type === "public" ? "private" : "public";
      }
      return "public";
    },
  },
};
</script>
