<template>
  <q-card
    style="flex:0 1 1240px;max-width:100%;height:100%;max-height:800px;padding: 30px 50px 10px 20px"
  >
    <div class="footer-menu-dialog-title">
      <p>Notifications settings</p>
    </div>
    <div style="min-height:550px">
      <!-- Right Column -->
      <div class="col-8 column">
        <!-- Scrollable Section for tab components -->
        <div>
          <div>
            <div class="an-16 demi-bold-text q-my-md">
              Email Notifications
            </div>
            <div
              class="notification-toggle scroll-y rounded-borders bg-blue-grey-1 q-px-lg q-py-md row items-center flex-no-wrap"
            >
              <q-toggle
                :value="email_notification"
                @input="changeEmailSetting"
              />
              E-mail me when I am mentioned in a message and task comment or
              when added to a task
            </div>
          </div>
          <div class="column full-width">
            <div class="an-16 demi-bold-text q-my-md">App Notifications</div>
            <div>
              <form
                v-on:submit.prevent
                class="notification-toggle input-card q-px-lg q-py-md custom-switch rounded-borders bg-blue-grey-1"
              >
                <q-toggle
                  :label="
                    web_notification
                      ? labels.turn_notification_off
                      : labels.turn_notification_on
                  "
                  :value="web_notification"
                  @input="changeWebNotificationSetting"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-card>
</template>
<script>
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import { EditProfileMutation } from "@/gql";
export default {
  name: "NotificationSettingDialog",
  mixins: [ProxyModelMixin],
  props: ["companyId"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      email_notification: true,
      web_notification: true,
      labels: {
        turn_notification_off: "Notification On",
        turn_notification_on: "Notifications Off ",
      },
    };
  },
  mounted() {
    this.email_notification = this.user.email_notification;
    this.web_notification = this.user.web_notification;
  },
  methods: {
    async changeWebNotificationSetting(val) {
      let variables = {
        first: this.user.first,
        last: this.user.last,
        username: this.user.username,
        lang: this.user.lang,
        web_notification: val,
      };
      this.web_notification = val;
      await this.$api.mutate({
        mutation: EditProfileMutation,
        variables,
      });
    },
    async changeEmailSetting(val) {
      let variables = {
        first: this.user.first,
        last: this.user.last,
        username: this.user.username,
        lang: this.user.lang,
        email_notification: val,
      };
      this.email_notification = val;
      await this.$api.mutate({
        mutation: EditProfileMutation,
        variables,
      });
    },
  },
  watch: {
    user(val) {
      this.email_notification = val.email_notification;
      this.web_notification = !val.web_notification;
    },
  },
};
</script>
