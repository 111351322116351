var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-card',{staticClass:"bg-white",staticStyle:{"padding":"30px 50px 10px 20px"}},[_c('div',{staticClass:"footer-menu-dialog-title"},[_vm._v(" Import ")]),_c('div',{staticClass:"row col-grow justify-between change-password"},[_c('form',{staticClass:"field-width",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"step-title q-mb-md q-mt-sm",staticStyle:{"font-size":"16px"}},[_vm._v(" Select a platform below to get started ")]),_c('div',[_c('div',{staticClass:"row import-data"},[_c('div',{class:[
              _vm.platform === 'trello' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card',
            ],attrs:{"id":"trello","clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectPlatform('trello')}}},[_c('q-icon',{style:(_vm.platform === 'trello'
                  ? { color: '#085cd5' }
                  : { color: '#929398' }),attrs:{"name":_vm.$icons.mdiTrello,"size":"25"}}),_c('div',{staticClass:"justify-center items-center flex q-ml-xs card-text"},[_vm._v(" Trello ")])],1),_c('div',{class:[
              _vm.platform === 'asana' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
            ],attrs:{"id":"asana","clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectPlatform('asana')}}},[_c('img',{staticClass:"image",attrs:{"src":_vm.asana.logo}}),_c('div',{staticClass:"justify-center items-center flex q-ml-xs card-text"},[_vm._v(" Asana ")])]),_c('div',{class:[
              _vm.platform === 'slack' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
            ],attrs:{"id":"slack","clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectPlatform('slack')}}},[_c('q-icon',{style:(_vm.platform === 'slack'
                  ? { color: '#E01E5A' }
                  : { color: '#929398' }),attrs:{"name":_vm.$icons.mdiSlack,"size":"25"}}),_c('div',{staticClass:"justify-center items-center flex q-ml-xs card-text"},[_vm._v(" Slack ")])],1)]),_c('div',{staticClass:"row import-data q-mt-md"},[_c('div',{class:[
              _vm.platform === 'clickup' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card',
            ],attrs:{"id":"clickup","clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectPlatform('clickup')}}},[_c('img',{staticClass:"image",attrs:{"src":_vm.clickup.logo}}),_c('div',{staticClass:"justify-center items-center flex q-ml-xs card-text"},[_vm._v(" ClickUp ")])]),_c('div',{class:[
              _vm.platform === 'monday' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card q-ml-sm',
            ],attrs:{"id":"monday","clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectPlatform('monday')}}},[_c('img',{staticClass:"image",attrs:{"src":_vm.monday.logo}}),_c('div',{staticClass:"justify-center items-center flex q-ml-xs card-text"},[_vm._v(" Monday ")])])]),_c('div',{staticClass:"q-mt-lg"},[(_vm.platform)?_c('q-btn',{staticClass:"footer-dialog-ok-btn",attrs:{"type":"submit","no-caps":"","text-color":"white","padding":"0","label":'Import from ' +
                _vm.platform.charAt(0).toUpperCase() +
                _vm.platform.slice(1),"loading":this.cloneCard.loader},on:{"click":_vm.authoriseTrelloUser}}):_vm._e()],1)])]),(_vm.cloneCard.flag)?_c('create-trello-workspaceDialog',{attrs:{"card":_vm.cloneCard.card,"closeDialog":_vm.closeWorkspaceDialog,"company":_vm.company,"isFrom":"adminstration","platform":_vm.platform,"accessToken":_vm.accessToken},model:{value:(_vm.cloneCard.flag),callback:function ($$v) {_vm.$set(_vm.cloneCard, "flag", $$v)},expression:"cloneCard.flag"}}):_vm._e(),(_vm.monday.dialog)?_c('monday-dialog',{attrs:{"apiToken":_vm.monday.apiToken},on:{"closeDialog":_vm.closeMondayDialog,"getProjectList":_vm.getProjectList},model:{value:(_vm.monday.dialog),callback:function ($$v) {_vm.$set(_vm.monday, "dialog", $$v)},expression:"monday.dialog"}}):_vm._e()],1),_c('div',{staticClass:"q-mt-md"},_vm._l((_vm.importDataHistoryList),function(history){return _c('other-platform-import-data-history',{key:history.id,attrs:{"history":history}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }