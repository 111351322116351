<script>
const interval = 1000 * 60 * 5; // 5 min
const intervalInternet = 1000 * 60; // 1 min
import { pusher } from "@/pusher";
export default {
  name: "UpdateNotification",
  render() {
    return null;
  },
  data() {
    return {
      oldText: null,
      status: true,
    };
  },
  async created() {
    this.oldText = await this.getText();
    setInterval(() => this.checkForUpdates(), interval);
     setInterval(() => {
       if(pusher.connection.state === 'unavailable') {
         this.checkForInternet();
       }
    }, intervalInternet);
  },
  methods: {
    async getText() {
      const html = await fetch("/index.html");
      this.status = html.status === 200 ? true : false;
      const text = await html.text();
      return text;
    },
    async checkForUpdates() {
      const newText = await this.getText();
      if (this.status && newText !== this.oldText) {
        this.$q.notify({
          color: "primary",
          timeout: 0,
          position: "bottom-right",
          message: "There's an updated version of Heycollab available.",
          classes: "update-version-banner",
          actions: [
            {
              label: "Update Heycollab Now",
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    },
    checkForInternet() {
      this.$q.notify({
        color: "negative",
        timeout: 0,
        position: "top",
        message: "Real-time updates are disabled because you were disconnected from the internet.",
        classes: "internet-banner",
        actions: [
          {
            label: "Click here to refresh",
            color: '#e75179',
            handler: () => {
              window.location.reload();
            },
          },
        ],
      });
    },
  },
};
</script>
