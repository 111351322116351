<template>
  <div
    :class="[
      'flex-1 workspace-folder group-hover q-mx-sm',
      !isFolderOpen && 'workspace-folder-hover',
      !isFolderOpen && hasActiveWorkspace && 'folder-has-selected-workspace',
    ]"
  >
    <q-expansion-item
      @input="toggleFolder"
      :value="isFolderOpen"
      style="margin-top: 6px"
    >
      <template v-slot:header>
        <div
          class="flex-1 row items-center justify-between workspace-folder-actions"
          style="margin-left: 10px"
        >
          <div class="row items-center">
            <q-icon
              :class="[isFolderOpen && 'open-folder']"
              :style="{ color: '#677484' }"
              size="24px"
              :name="isFolderOpen ? $icons.mdiFolderOpen : $icons.matFolder"
              class="folder"
            />
            <span
              :class="['folder-name q-ml-sm text-truncate']"
              :style="{
                'max-width': '180px',
                display: 'flex',
                'align-items': 'center',
              }"
              v-tooltip
              class="folder-title"
            >
              {{ folder.title }}
              <q-icon
                v-if="hasWorkspaceNotification"
                color="primary"
                style="margin-left: 3px"
                :name="$icons.matFiberManualRecord"
                size="12px"
              />
            </span>
            <q-tooltip :offset="[0, -50]" v-if="isTruncated">
              {{ folder.title }}
            </q-tooltip>
          </div>
          <q-menu context-menu>
            <q-list class="q-py-sm">
              <q-item
                @click="renameFolder(folder)"
                clickable
                style="min-height: 24px"
                v-close-popup
              >
                <q-item-section> Rename folder </q-item-section>
              </q-item>
              <q-item clickable style="min-height: 24px">
                <q-item-section> Add to folder </q-item-section>
                <q-item-section side>
                  <q-icon :name="$icons.matKeyboardArrowRight" />
                </q-item-section>
                <add-workspace-to-folder
                  v-if="remainedWorkspaces"
                  :remainedWorkspaces="remainedWorkspaces"
                  :folder="folder"
                  :addWorkspaceToFolder="addWorkspaceToFolder"
                  :removeWorkspaceFromFolder="removeWorkspaceFromFolder"
                />
              </q-item>
              <q-item
                @click="confirmDeleteFolder(folder.id, false)"
                clickable
                v-close-popup
                style="min-height: 24px"
              >
                <q-item-section> Remove folder </q-item-section>
              </q-item>
              <q-item
                @click="confirmDeleteFolder(folder.id, true)"
                clickable
                v-close-popup
                class="text-red"
                style="min-height: 24px"
              >
                <q-item-section> Delete folder & worskapces </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </div>
      </template>
      <q-item
        v-for="(child, i) in preferredWorkspaces"
        :key="'folder-preferred' + child.id"
        :class="[
          'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
          returnCurrentWorkspaceId === child.id &&
            'router-link-active active-workspace-item',
        ]"
      >
        <div
          :class="{
            'q-mb-sm': returnCurrentWorkspaceId === child.id,
          }"
        >
          <workspace-navigation
            :onlineMembers="onlineMembers"
            :workspace="child"
            :folders="folders"
            :index="i"
          />
        </div>
      </q-item>
      <q-item
        v-for="(child, i) in sortedWorkspaces"
        :key="'folder-public' + child.id"
        :class="[
          'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
          returnCurrentWorkspaceId === child.id &&
            'router-link-active active-workspace-item',
        ]"
      >
        <div
          :class="{
            'q-mb-sm': returnCurrentWorkspaceId === child.id,
          }"
        >
          <workspace-navigation
            :workspace="child"
            :folders="folders"
            :index="i"
          />
        </div>
      </q-item>
      <q-item
        v-for="(child, i) in peopleWorkspaces"
        :key="i"
        :class="[
          'q-pa-none hover-list q-ml-md q-mr-sm q-mb-xs',
          returnCurrentWorkspaceId === child.id &&
            'router-link-active active-workspace-item',
        ]"
      >
        <div
          :class="{
            'q-mb-sm': returnCurrentWorkspaceId === child.id,
          }"
        >
          <workspace-navigation
            :workspace="child"
            :onlineMembers="onlineMembers"
            :folders="folders"
            :index="i"
            :returnCurrentWorkspaceId="returnCurrentWorkspaceId"
          />
        </div>
      </q-item>
      <q-item class="q-mt-xs">
        <div
          class="add-folder-area q-mb-xs flex-1 text-center row justify-center items-center cursor-pointer"
          style="margin-left: 44px"
        >
          Add to folder
          <q-tooltip>Add workspaces & people to this folder</q-tooltip>
          <add-workspace-to-folder
            v-if="remainedWorkspaces"
            :remainedWorkspaces="remainedWorkspaces"
            :folder="folder"
            :addWorkspaceToFolder="addWorkspaceToFolder"
            :removeWorkspaceFromFolder="removeWorkspaceFromFolder"
          />
        </div>
        <!-- <q-btn
          text-color="white"
          dense
          no-caps
          class="text-left add-workspace-btn rounded-borders q-mb-xs primary-button-actions nav-sidebar-button"
        >
          
          <q-icon
            size="20px"
            :name="$icons.mdiFolderPlus"
            class="add-folder-icon q-mr-sm"
          />
          Add to folder
          
        </q-btn> -->
      </q-item>
    </q-expansion-item>
  </div>
</template>

<script>
import WorkspaceNavigation from "@/views/WorkspaceView/NavigationSidebar/WorkspaceNavigation.vue";
import AddWorkspaceToFolder from "@/views/WorkspaceView/NavigationSidebar/WorkspaceFolder/AddWorkspaceToFolder";
import {
  AddWorkspaceToFolderMutation,
  RemoveWorkspaceFromFolderMutation,
} from "@/gql";
export default {
  name: "WorkspaceFolder",
  props: [
    "folder",
    "folders",
    "renameFolder",
    "confirmDeleteFolder",
    "workspaces",
    "allworkspaces",
    "onlineMembers",
  ],
  components: {
    WorkspaceNavigation,
    AddWorkspaceToFolder,
  },
  data() {
    return {
      isTruncated: false,
    };
  },
  methods: {
    toggleFolder() {
      this.$store.dispatch("setFolderState", {
        folderId: this.folder.id,
        flag: !this.isFolderOpen,
      });
    },
    async addWorkspaceToFolder(workspace) {
      const variables = {
        workspace_ids: [workspace.id],
        folder_id: this.folder.id,
      };
      const workspaceEntity = this.$api.getEntity("workspace", workspace.id);
      const folder = this.$api.getEntity("folder", this.folder.id);
      workspaceEntity.folder.push(folder);
      await this.$api.mutate({
        mutation: AddWorkspaceToFolderMutation,
        variables,
      });
    },
    async removeWorkspaceFromFolder(workspace) {
      const variables = {
        workspace_id: workspace.id,
        folder_id: this.folder.id,
      };
      const workspaceEntity = this.$api.getEntity("workspace", workspace.id);
      workspaceEntity.folder = workspaceEntity.folder.filter(
        (obj) => obj.id !== this.folder.id
      );
      await this.$api.mutate({
        mutation: RemoveWorkspaceFromFolderMutation,
        variables,
      });
    },
  },
  computed: {
    sortedWorkspaces() {
      const workspaces =
        this.workspaces &&
        this.workspaces.filter(
          (w) =>
            !w.is_preferred && (w.type === "public" || w.type === "private")
        );
      workspaces &&
        workspaces.sort((first, second) => {
          var nameA = first.title.toUpperCase();
          var nameB = second.title.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      return workspaces;
    },
    preferredWorkspaces() {
      return this.workspaces && this.workspaces.filter((w) => w.is_preferred);
    },
    peopleWorkspaces() {
      return (
        this.workspaces &&
        this.workspaces.filter((w) => !w.is_preferred && w.type === "people")
      );
    },
    returnCurrentWorkspaceId() {
      return Number(
        this.$route.params.workspace && this.$route.params.workspace
      );
    },
    remainedWorkspaces() {
      const filteredWorkspaces =
        this.allworkspaces &&
        this.allworkspaces.filter((w) => {
          if (w.folder[0] && w.folder[0].id !== this.folder.id) {
            return false;
          }
          return w;
        });
      filteredWorkspaces.sort((first, second) => {
        var nameA = first.title.toUpperCase();
        var nameB = second.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return filteredWorkspaces;
    },
    isFolderOpen() {
      if (this.$store.getters.isFolderOpen(this.folder.id)) {
        return this.$store.getters.isFolderOpen(this.folder.id).isOpen;
      }
      return true;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      }
      return null;
    },
    hasActiveWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.some((w) => w.id === this.currentWorkspaceId)
      );
    },
    hasWorkspaceNotification() {
      const workspaceIDs = this.workspaces && this.workspaces.map((w) => w.id);
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data && workspaceIDs) {
        const hasNotifications = query.data.notifications
          .filter((n) => !n.read)
          .some(
            (n) => n.action.scope && workspaceIDs.includes(n.action.scope.id)
          );
        return hasNotifications;
      }
      return false;
    },
  },
  directives: {
    tooltip: {
      bind(el, binding, vnode) {
        el.tooltipHandler = (evt) => {
          let targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            vnode.context.isTruncated = true;
          } else {
            vnode.context.isTruncated = false;
          }
        };
        el.addEventListener("mouseover", el.tooltipHandler);
      },
      unbind(el) {
        el.removeEventListener("mouseover", el.tooltipHandler);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-workspace-spacing {
  padding-left: 40px !important;
}
.folder-option {
  top: 3px;
  right: 18px;
  z-index: 10;
}
.folder-name {
  font-family: "Avenir Next";
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
}
.active-folder {
  color: #007aff !important;
}
</style>
