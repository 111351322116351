import get from "lodash/get";

export default {
  data() {
    return {
      loginModel: false,
      showForgotPasswordLink: false,
      forgotpasswordModal: false,
    };
  },
  methods: {
    handleLoginSuccess(company) {
      this.loginModel = false;
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.company.login"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.$router.push({
        name: "BoardView",
        params: {
          company: company.companyUri,
          workspace: company.redirectWorkspaceId,
        },
      });
      location.reload();
    },
    handleLoggedInCompany(company) {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.company.alreadyLogin"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.loginModel = false;
      if (company) {
        if (company.status === "active") {
          const activeCompany = get(this.companies, company.uri);
          this.$router.push({
            name: "BoardView",
            params: {
              company: company.uri,
              workspace: activeCompany.redirectWorkspaceId,
            },
          });
        } else {
          this.$router.push(`/companies/${company.uri}/payment`);
        }
      }
    },
    handleChangePassword() {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.passwordUpdated"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.loginModel = false;
    },
    forgetCompanyHandler() {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.company.forgot.name"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.loginModel = false;
    },
    showSingInHandler(flag = false) {
      if (flag) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.forgot.password"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } else {
        this.loginModel = true;
      }
      this.forgotpasswordModal = false;
    },
  },
};
