<template>
  <div
    class="group-hover q-mb-sm notification-wrapper relative-position q-py-sm"
    :class="[!notification.read && 'unread-notification-bg']"
  >
    <q-menu context-menu auto-close>
      <q-list>
        <q-item clickable v-if="!notification.read" @click="updateNotification()">
          <q-item-section>
            Mark read
          </q-item-section>
          <!-- <q-item-section v-else>
            Mark unread
          </q-item-section> -->
        </q-item>
        <q-item
          clickable
          @click="routeTo('MessagingView', actionScope.id)"
        >
          <q-item-section>
            Go to workspace
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <div class="row flex-no-wrap q-pl-md">
      <div class="q-mr-md">
        <div
          class="message-notification-icon row justify-center"
          @click="routeTo('MessagingView', actionScope.id)"
        >
          <q-icon
            :name="$icons.matTextsms"
            color="white"
            class=" self-center"
            size="20px"
          />
          <q-tooltip> Workspace: {{ workspace.title }} </q-tooltip>
        </div>
      </div>
      <div class="row" style="width:100%">
        <div class="row justify-between" style="width:100%">
          <div class="cursor-pointer" @click="showUserInfo(actionSubject)">
            <span
              style="font-size:15px"
              class="text-subtitle2 text-blue-grey-10"
            >
              {{ actionTakenByFullName }}
            </span>
          </div>
          <div
            class="an-13 regular-text q-mr-lg thread-icon"
            style="color: #15d89a;"
            v-if="actionObject.is_replied"
          >
            Replied
          </div>
          <div
            class="row absolute items-center group-hover-item thread-icons q-mr-md"
            style="right:0"
          >
            <q-btn
              flat
              :icon="$icons.matSentimentSatisfied"
              size="10px"
              class="thread-icon"
              style="margin-bottom:0px"
              rounded
              dense
            >
              <q-menu
                content-class="overflow-auto apply-width"
                auto-close
                anchor="bottom middle"
                self="top middle"
              >
                <div class="card react-emoji-card">
                  <img
                    v-for="(emoji, index) in reactions"
                    :key="index"
                    :src="'/static/react-icons/' + emoji.icon"
                    alt="ANGRY"
                    @click="
                      reactOnPost({ post: actionObject, reaction: emoji })
                    "
                    class="react-emoji-icon q-mr-sm cursor-pointer"
                  />
                </div>
              </q-menu>
            </q-btn>
            <!-- <q-btn
              v-if="notification.read"
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark unread
              </q-tooltip>
            </q-btn>
            <q-btn
              v-else
              @click="updateNotification()"
              flat
              :icon="$icons.matVisibility"
              size="8px"
              class="cursor-pointer thread-icon"
              dense
              rounded
            >
              <q-tooltip>
                Mark read
              </q-tooltip>
            </q-btn> -->
            <q-btn
              v-if="actionObject.isbookmarked"
              @click="removeMessageBookmark()"
              size="sm"
              class="cursor-pointer"
              color="pink"
              :icon="$icons.matBookmark"
              dense
              rounded
              flat
            >
              <q-tooltip>
                Remove from Saved items
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="!actionObject.isbookmarked"
              @click="addMessageBookmark()"
              size="sm"
              class="cursor-pointer thread-icon"
              :icon="$icons.matBookmarkBorder"
              dense
              rounded
              flat
            >
              <q-tooltip>
                Add to saved items
              </q-tooltip>
            </q-btn>
          </div>
        </div>
        <div style="width:100%">
          <div
            v-if="notificationContent.length >= 165 && readMoreToggle === false"
            v-html="trucateContet(notificationContent)"
            class="q-mr-md notification-content post-content dont-break-out"
          ></div>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
            v-html="notificationContent"
            class="q-mr-md notification-content post-content dont-break-out"
          ></div>
          <span
            class="text-primary  cursor-pointer"
            @click="readMoreToggle = true"
            v-show="
              notificationContent.length >= 165 && readMoreToggle === false
            "
          >
            read more
          </span>
          <div
            v-if="notificationContent.length < 165 || readMoreToggle === true"
            class="read-more-text"
          >
            <div
              v-if="
                (actionObject.media && actionObject.media.length) ||
                  (actionObject.mediafolder && actionObject.mediafolder.length)
              "
            >
              <post-media
                v-if="actionObject.media"
                :attachments="actionObject.media"
                :canRemoveMedia="
                  canRemoveMessageFromWorkspace ||
                    isWorkspaceOwner ||
                    (user && user.id === actionSubject.id)
                "
                :forceDisableRemove="
                  actionObject.content === '' && actionObject.media.length <= 1
                "
                :removeMedia="removeMedia"
                :postId="actionObject.id"
                :post="actionObject"
                :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                :openFolder="openFolder"
                :deleteFolderHandler="deleteFolderHandler"
                :labels="labels"
                :user="user"
                :workspace="actionScope.id"
                :isWorkspaceOwner="isWorkspaceOwner"
                :height="90"
                :editDoc="editDoc"
                @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
                @deleteCommentPoint="
                  (data) => $emit('deleteCommentPoint', data)
                "
                @sendComment="(data) => $emit('sendComment', data)"
                @updatepositions="(data) => $emit('updatepositions', data)"
                @updateMediaCommentPointLabel="
                  (data) => $emit('updateMediaCommentPointLabel', data)
                "
                @deleteMediaComment="
                  (data) => $emit('deleteMediaComment', data)
                "
                @updateComment="(data) => $emit('updateComment', data)"
              />
            </div>
            <!-- Forwarded Post -->
            <div
              v-if="actionObject.forwardedPost"
              style="min-width:150px;max-width: 300px;"
              class="forword-post-layout q-mt-md q-pa-md"
              :class="[
                typeof reactionData === 'object' && !isObejctEmpty
                  ? 'q-mb-md'
                  : '',
              ]"
            >
              <!-- Forwarded Post User section -->
              <div class="row">
                <div class="flex-1">
                  <div
                    class="row justify-between messaging-user-detail-chip items-center, full-width"
                  >
                    <div
                      @click="showUserInfo(actionObject.forwardedPost.owner)"
                      class="user-name"
                    >
                      {{ actionObject.forwardedPost.owner.first }}
                    </div>
                    <div class="time ">
                      {{
                        actionObject.forwardedPost.created_at | forwardPostTime
                      }}
                    </div>
                  </div>
                  <!-- Forwarded Post Content -->
                  <div
                    class="post-content dont-break-out"
                    v-if="actionObject.forwardedPost.content"
                    v-html="forwardPost"
                  ></div>
                  <post-media
                    v-if="
                      actionObject.forwardedPost.media &&
                        actionObject.forwardedPost.media.length
                    "
                    :attachments="actionObject.forwardedPost.media"
                    :canRemoveMedia="
                      canRemoveMessageFromWorkspace ||
                        isWorkspaceOwner ||
                        (user && user.id === actionSubject.id)
                    "
                    :forceDisableRemove="true"
                    :removeMedia="removeMedia"
                    :postId="actionObject.id"
                    :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                    :labels="labels"
                    :user="user"
                    :workspace="actionScope.id"
                    :isWorkspaceOwner="isWorkspaceOwner"
                    :height="88"
                    :editDoc="editDoc"
                    @clickonmoreoption="
                      (data) => $emit('clickonmoreoption', data)
                    "
                    @deleteCommentPoint="
                      (data) => $emit('deleteCommentPoint', data)
                    "
                    @sendComment="(data) => $emit('sendComment', data)"
                    @updatepositions="(data) => $emit('updatepositions', data)"
                    @updateMediaCommentPointLabel="
                      (data) => $emit('updateMediaCommentPointLabel', data)
                    "
                    @deleteMediaComment="
                      (data) => $emit('deleteMediaComment', data)
                    "
                    @updateComment="(data) => $emit('updateComment', data)"
                    @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                    :class="{
                      'mt-2':
                        actionObject.forwardedPost.content === null ||
                        actionObject.forwardedPost.content === '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Reactions List badges -->
          <div
            style="margin-top:10px"
            v-if="
              !Array.isArray(actionObject.reactiondata) &&
                actionObject.reactiondata &&
                !isNotificationVisible
            "
          >
            <div class="row inline">
              <template
                v-for="(reactiondata, index) in actionObject.reactiondata"
              >
                <div :key="index" class="q-ml-xs">
                  <div class="relative-position reaction-icon row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                      @click="
                        reactOnPost({
                          post: actionObject,
                          reaction: index,
                        })
                      "
                    />
                    <div class="text-black" style="font-size:12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height:30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center ">
                            <div class="q-mr-sm">
                              <q-avatar
                                color="blue-grey-11"
                                text-color="grey-10"
                                size="26px"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <!-- <span
                                  class="text-avatar-style"
                                  style="font-size:9px"
                                  v-else
                                >
                                  {{ item.first | firstChar
                                  }}{{ item.last | firstChar }}
                                </span> -->
                                <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="time" style="font-size:12px;">
          {{ dateTimeFormat() }}
          <span
            class="text-primary  cursor-pointer"
            style="font-size:16px"
            @click="readMoreToggle = false"
            v-if="readMoreToggle"
          >
            read less
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="notification.id === activeId && isNotificationVisible"
      class="q-my-sm q-mx-md"
    >
      <!-- <ReplyEditor
        :mentions="workspaceMembers"
        :taskLabels="labels"
        :currentUser="user"
        :isWorkspaceOwner="isWorkspaceOwner"
        :imageList="imageList"
        :notificationWorksape="actionScope.id"
        :currentCompany="currentCompany"
        :notification="notification"
        @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
        @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
        @updatepositions="(data) => $emit('updatepositions', data)"
        @updateMediaCommentPointLabel="
          (data) => $emit('updateMediaCommentPointLabel', data)
        "
        @updateComment="(data) => $emit('updateComment', data)"
        @sendComment="(data) => $emit('sendComment', data)"
        @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
        @closeMediaModel="(data) => $emit('closeMediaModel', data)"
        :updateReplyLabel="updateReplyLabel"
        :updateNotification="updateNotification"
        @closeEditor="closeReplyEditorHandler"
      ></ReplyEditor> -->
    </div>
    <div v-if="delete_media.flag">
      <confirm-dialog
        v-if="delete_media.flag"
        v-model="delete_media.flag"
        title="Delete File?"
        question="Are you sure you want to detach the media from the post?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
      />
    </div>
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="actionScope.id"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="actionScope.id"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="folderList"
      :authToken="currentCompany.accessToken"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false, 'post_id')"
      :successMethod="() => deleteFolderDialogHandler(true, 'post_id')"
      :loading="deleteFolder.loader"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import ReplyEditor from "@/components/Editor/ReplyEditor";
import PostMedia from "@/components/Miscellaneous/PostMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";

import NotificationNavMixin from "@/mixins/NotificationNavMixin";
import NotificationActionsMixin from "@/mixins/NotificationActionsMixin";
import UserScope from "@/mixins/UserScope";
import { compile } from "@/components/Editor/MarkupUtils";
// import { SET_REPLY_EDITOR_CONTENT } from "@/store/mutation-types";

import get from "lodash/get";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import getUnixTime from "date-fns/getUnixTime";
import Avatar from 'vue-avatar';

import {
  DetachPostMediaMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
  UpdateNotificationsMutation,
  AddMessageBookmarkMutation,
  RemoveMessageBookmarkMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
export default {
  name: "NewPostNotification",
  mixins: [NotificationNavMixin, NotificationActionsMixin, UserScope],
  props: [
    "notification",
    "reactions",
    "labels",
    "activeTab",
    "isWorkspaceOwner",
    "showUserInfo",
    "setActiveId",
    "activeId",
    "isNotificationVisible",
    "closeReplyEditorHandler",
    "editDoc",
  ],
  components: {
    // ReplyEditor,
    PostMedia,
    ConfirmDialog,
    PostCopyMoveFileFolder,
    FolderDetailsDialog,
    Avatar,
  },
  api: {
    media: {
      defaultValue: null,
      cacheKey() {
        return `MediaQuery:${this.actionScope.id}`;
      },
    },
  },
  data() {
    return {
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      readMoreToggle: false,
    };
  },
  mounted() {
    this.preloadImage();
  },
  methods: {
    // redirectToMessages(actionObj) {
    //   this.$router.push(
    //     `/companies/${this.activeCompany}/workspaces/${actionObj.workspace_id}/messaging`
    //   );
    // },
    async addMessageBookmark() {
      try {
        const variables = {
          post_id: this.actionObject.id,
        };
        const post = this.$api.getEntity("post", this.actionObject.id);
        post.isbookmarked = !this.actionObject.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddMessageBookmarkMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    async removeMessageBookmark() {
      try {
        const variables = {
          post_id: this.actionObject.id,
        };

        const post = this.$api.getEntity("post", this.actionObject.id);
        post.isbookmarked = !this.actionObject.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveMessageBookmarkMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.actionScope.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    preloadImage() {
      this.reactions.forEach((emoji) => {
        var img = new Image();
        img.src = "/static/react-icons/" + emoji.icon;
      });
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        const variables = {
          post_id: this.delete_media.post,
          media: [this.delete_media.id],
        };

        await this.$api.mutate({
          mutation: DetachPostMediaMutation,
          variables,
        });
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    async updateNotification() {
      if (this.notification.read) {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: false,
          },
        });
        mixpanel.track("Notification Unread");
      } else {
        this.notification.read = !this.notification.read;
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: [this.notification.id],
            read: true,
          },
        });
        mixpanel.track("Notification Read");
      }
    },
    async updateReplyLabel() {
      const { data } = await this.$api.getQuery("NotificationsQuery");
      if (data.notifications) {
        let notification = data.notifications.find(
          (obj) => obj.id === this.notification.id
        );
        if (notification) {
          notification.action.object.is_replied = true;
          let index = data.notifications.findIndex(
            (obj) => obj.id === this.notification.id
          );
          data.notifications.splice(index, 1, notification);
        }
      }
    },
    // sharePost(post) {
    //   this.$store.commit(SET_REPLY_EDITOR_CONTENT, {
    //     value: {
    //       html: post.content,
    //       id: post.id,
    //     },
    //     companyId: this.currentCompany.companyId,
    //     workspaceId: this.actionScope.id,
    //     notificationId: this.notification.id,
    //   });
    //   this.setActiveId(this.notification.id);
    // },
    async reactOnPost(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }
      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      if (!this.notification.read) {
        const query = this.$api.getQuery(`NotificationsQuery`);
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.object_id === Number(post.id))
          .filter((n) => n.action.object_type === "App\\Post");
        if (notifications.length) {
          notifications.map((n) => (n.read = true));
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables: {
              notification_ids: notifications.map((n) => n.id),
              read: true,
            },
          });
        }
      }
      const newReactiondata = { ...post.reactiondata };
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          });
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        }
        this.notification.action.object.reactiondata = { ...newReactiondata };
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          },
        ];
        this.notification.action.object.reactiondata = { ...newReactiondata };
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    isObejctEmpty() {
      return isEmpty(this.reactionData);
    },
    forwardPost() {
      return compile(this.actionObject.forwardedPost.content);
    },
    notificationContent() {
      return compile(this.actionObject.content);
    },
    actionObject() {
      return this.notification.action ? this.notification.action.object : null;
    },
    actionScope() {
      return this.notification.action ? this.notification.action.scope : null;
    },
    actionSubject() {
      return this.notification.action ? this.notification.action.subject : null;
    },
    workspaceMembers() {
      const workspace = this.$api.getEntity("workspace", this.actionScope.id);
      return workspace.users;
    },
    workspace() {
      const workspace = this.$api.getEntity("workspace", this.actionScope.id);
      return workspace;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    reactionData() {
      return Array.isArray(this.actionObject.reactiondata)
        ? null
        : this.actionObject.reactiondata;
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      }
      return null;
    },
  },
};
</script>
