<template>
  <div class="q-pa-md">
    <div class="text-center">
      <img class="mt-3" :src="forgotPasswordImage" height="145" />
      <h2 class="headline-text my-3">Enter 5-digit Code</h2>
      <p class="headline-caption-text">
        We've sent a 5-digit code to your email address. Input code below
      </p>
    </div>
    <div class="mt-4">
      <form v-on:submit.prevent="$v.$invalid ? null : verify()">
        <masked-input
          mask="111-11"
          class="masked-box"
          placeholder="000-00"
          @input="code = arguments[1]"
          :error-messages="fieldErrors('code')"
          @blur="$v.code.$touch()"
          type="tel"
        />
        <p v-if="invalidCodeMessage" class="pink--text">
          {{ invalidCodeMessage }}
        </p>
        <q-btn
          type="submit"
          color="primary"
          class="full-width q-py-sm q-mt-sm"
          label="RESET PASSWORD"
          :loading="loader"
          :disabled="$v.$invalid || loader || !isValidCode"
        />
      </form>
    </div>
  </div>
</template>
<script>
import MaskedInput from "vue-masked-input";
import validationMixin from "@/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import errorHandler from "@/utils/ErrorHandler";
import { CheckPasswordResetCodeMutation } from "@/gql";

export default {
  props: ["email", "companyUri"],
  mixins: [validationMixin],
  validations: {
    code: { required },
  },
  validationMessages: {
    code: { required: "message.validation.verificationCode.required" },
  },
  components: {
    MaskedInput,
  },
  data() {
    return {
      loader: false,
      invalidCodeMessage: null,
      code: null,
      forgotPasswordImage:
        "https://s3-us-west-1.amazonaws.com/vabotu/default/enter_code_dark.svg",
    };
  },
  methods: {
    async verify() {
      this.loader = true;
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
        company_uri: this.companyUri,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckPasswordResetCodeMutation,
          variables,
        });

        const isValidRestPasswordCode = response.data.checkPasswordResetCode;

        if (isValidRestPasswordCode) {
          this.$emit("next", {
            next: true,
            code: this.code,
          });
        } else {
          this.invalidCodeMessage = "Please enter valid code.";
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.InvalidVerificationCodeError"
        );
      }
    },
  },
  computed: {
    isValidCode() {
      if (this.code.match(/^[0-9]+$/)) return true;
      return false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.masked-box
  height: 80px
  font-size: 4.5em
  width: 100%
  border: 1.5px solid #bfcfe0
  border-radius: 6px
  padding: 8px 7px
  letter-spacing: 18px
  background: #fff;
</style>
