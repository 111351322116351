import { get, set } from "lodash";
export default {
  data() {
    return {
      settingModel: false,
      settingModelTitle: null,
      modalDialog: {
        rolepermission: false,
        companysetting: false,
        manageMembers: false,
        accountSetting: false,
        notificationSetting: false,
        userSetting: false,
        administration: false,
        closeAccount: false,
        timeLog: false
      },
      selectedTab: '',
      termsDialog: false,
      toolbarItems: [
        // {
        //   title: "Legal",
        //   action: this.agreementDialogHandler,
        //   args: [],
        //   roles: [1, 2, 3, 4],
        // },
        {
          title: "Roles & Permissions",
          action: this.manageDialogs,
          args: ["rolepermission"],
          roles: [1, 2],
        },
        // {
        //   title: "Change Password",
        //   action: this.manageDialogs,
        //   args: ["accountSetting"],
        //   roles: [1, 2, 3, 4],
        // },
        {
          title: "Manage Members",
          action: this.manageDialogs,
          args: ["manageMembers", "Manage Members"],
          roles: [1, 2],
        },
      ],
    };
  },
  methods: {
    manageDialogs(...arg) {
      if (arg[0] !== null) {
        this.modalDialog[arg[0]] = !this.modalDialog[arg[0]];
      }
    },
    closeModal(modalName) {
      const handler = (e) => {
        if (e.keyCode === 27) {
          set(this, modalName, false);
          window.removeEventListener("keydown", handler);
        }
      };
      if (get(this, modalName)) {
        window.addEventListener("keydown", handler);
      }
    },
    agreementDialogHandler(args = true) {
      this.termsDialog = args;
    },
  },
  watch: {
    settingModel(e) {
      this.closeModal("settingModel", e);
    },
    "modalDialog.rolepermission"(e) {
      this.closeModal("modalDialog.rolepermission", e);
    },
    "modalDialog.companysetting"(e) {
      this.closeModal("modalDialog.companysetting", e);
    },
    "modalDialog.manageMembers"(e) {
      this.closeModal("modalDialog.manageMembers", e);
    },
    "modalDialog.accountSetting"(e) {
      this.closeModal("modalDialog.accountSetting", e);
    },
    "modalDialog.notificationSetting"(e) {
      this.closeModal("modalDialog.notificationSetting", e);
    },
    "modalDialog.userSetting"(e) {
      this.closeModal("modalDialog.userSetting", e);
    },
  },
};
