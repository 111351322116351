<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card
      style="flex:0 1 1240px;max-width:100%;height:100%;max-height:800px;"
    >
      <div class="text-right q-mt-sm q-mr-sm">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <div class="row q-px-xl q-pt-xl" style="flex-wrap:nowrap">
        <!-- Left Column -->
        <div style="max-width:600px" class="col-6">
          <span class="an-bold-35 wp-title">Pending Invites</span>
          <div class="divider section-spacer"></div>
          <div class="medium-text an-20 q-my-lg">
            <span class="text-dark">
              The list of people to the right received invitations to join your
              company/workspace but never joined. You may resend the invitations
              or revoke the sent invites. Once a person joins the company and
              workspace, they will no longer show on this list.
            </span>
          </div>
          <img :src="monsterImg.private" alt="Monster" class="monster" />
        </div>
        <!-- Right Column -->
        <div class="col-6" style="max-width:600px">
          <div class="px-5">
            <form v-on:submit.prevent="searchInPendingInvites">
              <q-input
                v-model="search"
                outlined
                autofocus
                placeholder="Search through the list below"
                :clear-icon="$icons.matClose"
                ref="searchUserWorkspaceInvitation"
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="$icons.matClose"
                    @click="() => clearSearchHandler()"
                    class="cursor-pointer"
                  />
                </template>
              </q-input>
            </form>
            <q-linear-progress
              indeterminate
              size="4px"
              color="primary"
              v-if="mainloader"
            />
          </div>
          <!-- Scrollable section -->
          <div
            class="flex-1 scroll-y px-5 pt-4"
            v-infinite-scroll="fetchScrollHandler"
          >
            <q-list separator>
              <template v-for="(item, index) in workspacePendingInvites">
                <q-item
                  class="messaging-user-detail-chip"
                  :key="index"
                  clickable
                >
                  <q-item-section avatar>
                    <img
                      src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab-round.svg"
                      style="height:40px"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ item.email }}</q-item-label>
                    <q-item-label v-if="item === choosed" caption>
                      <q-linear-progress
                        indeterminate
                        size="5px"
                        color="primary"
                      />
                    </q-item-label>
                    <q-item-label
                      v-else-if="
                        choosed === null && item === revokeConfirmation
                      "
                      caption
                      class="text-pink"
                    >
                      <span class="caption text-red">
                        <strong>Revoke Invite</strong>:
                      </span>
                      <span
                        class="caption"
                        @click.stop="revokeInviteHandler(item)"
                        >Confirm</span
                      >|
                      <span
                        class="caption"
                        @click.stop="revokeConfirmation = null"
                        >Cancel</span
                      >
                    </q-item-label>
                    <q-item-label
                      v-else-if="canInviteMember || isWorkspaceOwner"
                      caption
                      class="text-pink"
                    >
                      <span
                        class="caption"
                        @click.stop="resendInviteHandler(item)"
                        >Resend Invite |
                      </span>
                      <span
                        class="caption"
                        @click.stop="revokeConfirmation = item"
                        >Revoke Invite</span
                      >
                    </q-item-label>
                  </q-item-section>
                  <q-item-section class="time" side>
                    {{ item.created_at }}
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
            <div class="full-width q-pr-sm text-center" v-if="isloading">
              <q-circular-progress
                indeterminate
                size="30px"
                color="primary"
                :thickness="0.2"
              />
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import last from "lodash/last";
// import errorHandler from '@/utils/ErrorHandler'
import { email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import UserScope from "@/mixins/UserScope";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import {
  WorkspacePendingInvitesQuery,
  ResendInvitationMutation,
  RevokeInvitationMutation,
} from "@/gql";

export default {
  name: "PendingInvitationsDialog",
  props: ["workspace", "user"],
  mixins: [validationMixin, UserScope, ProxyModelMixin],
  api: {
    workspacePendingInvites: {
      query: WorkspacePendingInvitesQuery,
      variables() {
        return {
          workspace_id: this.workspace.id,
        };
      },
      cacheKey() {
        return `WorkspacePendingInvitesQuery:${this.workspace.id}`;
      },
    },
  },
  validations: {
    search: {
      invitation: { email },
    },
  },
  mounted() {
    setTimeout(() => {
      this.mainloader = false;
    }, 2000);
  },
  data() {
    return {
      mainloader: true,
      monsterImg: {
        public: "/static/images/workspace/wp-public-bg-img.svg",
        private: "/static/images/workspace/wp-private-monster-bg.svg",
      },
      isloading: false,
      noData: false,
      search: null,
      choosed: null,
      revokeConfirmation: null,
      filter: {
        state: false,
      },
    };
  },
  methods: {
    successHandler(message) {
      this.$q.notify({
        classes: 'success-notification',
        position: "top",
        timeout: 2500,
        message,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    scollbar() {
      const ps = this.$refs.userpendinginvites;
      if (ps) ps.$el.scrollTop = 0;
    },
    resetLoader() {
      this.loader = {
        searchInvitation: false,
      };
    },
    resetSearch() {
      this.search = {
        invitation: "",
      };
    },
    async fetchScrollHandler() {
      if (this.isloading === false && this.noData === false) {
        this.isloading = true;
        const lastWorkspace = last(this.workspacePendingInvites);
        const variables = {
          cursor: lastWorkspace.id,
          limit: 10,
        };

        const currentWorkspacePendingInvites = this.$api.getQueryByName(
          "workspacePendingInvites"
        );
        const newWorkspacePendingInvites = await this.$api.query({
          query: WorkspacePendingInvitesQuery,
          variables,
        });

        currentWorkspacePendingInvites.data.workspacePendingInvites = [
          ...currentWorkspacePendingInvites.data.workspacePendingInvites,
          ...newWorkspacePendingInvites.data.workspacePendingInvites,
        ];

        this.isloading = false;
      }
    },
    searchInPendingInvites() {
      if (this.search) {
        this.filter.state = true;
        this.filterInvitations();
        this.scollbar();
      }
    },
    async filterInvitations() {
      const variables = {
        offset: 0,
        search: this.search,
        workspace_id: this.workspace.id,
      };

      const currentWorkspacePendingInvites = this.$api.getQueryByName(
        "workspacePendingInvites"
      );
      const newWorkspacePendingInvites = await this.$api.query({
        query: WorkspacePendingInvitesQuery,
        variables,
      });

      currentWorkspacePendingInvites.data.workspacePendingInvites = [
        ...newWorkspacePendingInvites.data.workspacePendingInvites,
      ];
    },
    clearSearchHandler() {
      this.search = null;
      if (this.filter.state === true) {
        this.scollbar();
        this.filterInvitations();
        this.filter.state = false;
      }
    },
    async resendInviteHandler(invitation) {
      this.choosed = invitation;
      const variables = {
        invitation_ids: [invitation.id],
      };

      const response = await this.$api.mutate({
        mutation: ResendInvitationMutation,
        variables,
      });
      if (response) {
        this.successHandler(this.$t("message.invitation.resent"));
      }

      this.choosed = null;
      return response;
    },
    async revokeInviteHandler(invitation) {
      this.choosed = invitation;
      const variables = {
        invitation_ids: [invitation.id],
      };
      const response = await this.$api.mutate({
        mutation: RevokeInvitationMutation,
        variables,
      });

      this.$api.query({
        query: WorkspacePendingInvitesQuery,
        variables: {
          workspace_id: this.workspace.id,
        },
        cacheKey() {
          return `WorkspacePendingInvitesQuery:${this.workspace.id}`;
        },
      });

      this.choosed = null;

      if (response) {
        this.successHandler(this.$t("message.invitation.revoke"));
      }
      return response;
    },
  },
  computed: {
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return null;
      }
    },
    workspaceType() {
      if (this.workspace) {
        return this.workspace.type;
      }
      return null;
    },
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    canInviteMember() {
      if (this.workspaceType) {
        if (this.workspaceType === "public")
          return this.canInviteMemberToPublicWorkspace;
        return this.canInviteMemberToPrivateWorkspace;
      }
      return false;
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;
      requestAnimationFrame(() => {
        this.$refs.searchUserWorkspaceInvitation.focus();
      });
      this.noData = false;
    },
    workspacePendingInvites() {
      if (this.workspacePendingInvites.length > 0) this.mainloader = false;
    },
  },
};
</script>
<style lang="stylus" scoped></style>
