<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    value="value"
  >
    <q-card style="width:500px;" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto text-center">Contact sales</span>
      </div>
      <div class="close-btn-bg">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          round
          size="10px"
          @click="closeSalesDialog"
          class="close-btn"
        />
      </div>
      <form v-on:submit.prevent="$v.$invalid ? null : console.log('ha')">
        <div style="display:flex; justify-content:space-between">
          <q-input
            v-model="first"
            placeholder="First name *"
            autofocus
            outlined
            dense
            class="app-text-normal"
            style="width:47%"
            @blur="$v.first.$touch()"
            @input="$v.first.$touch()"
            bottom-slots
            no-error-icon
            :error="fieldErrors('first').length > 0"
          >
            <template v-slot:error>
              <div>
                {{
                  fieldErrors("first").length > 0 ? fieldErrors("first")[0] : ""
                }}
              </div>
            </template>
          </q-input>
          <q-input
            v-model="last"
            placeholder="Last name *"
            outlined
            dense
            class="app-text-normal"
            style="width:47%"
            @blur="$v.last.$touch()"
            @input="$v.last.$touch()"
            bottom-slots
            no-error-icon
            :error="fieldErrors('last').length > 0"
          >
            <template v-slot:error>
              <div>
                {{
                  fieldErrors("last").length > 0 ? fieldErrors("last")[0] : ""
                }}
              </div>
            </template>
          </q-input>
        </div>
        <q-input
          v-model="email"
          placeholder="Work email *"
          outlined
          dense
          @blur="$v.email.$touch()"
          @input="$v.email.$touch()"
          :error="fieldErrors('email').length > 0"
          bottom-slots
          no-error-icon
          class="app-text-normal "
        >
          <template v-slot:error>
            <div>
              {{
                fieldErrors("email").length > 0 ? fieldErrors("email")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-input
          v-model="phone"
          placeholder="Phone"
          outlined
          dense
          class="app-text-normal "
          bottom-slots
          no-error-icon
          @blur="$v.phone.$touch()"
          @input="$v.phone.$touch()"
          :error="fieldErrors('phone').length > 0"
        >
          <template v-slot:error>
            <div>
              {{
                fieldErrors("phone").length > 0 ? fieldErrors("phone")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-select
          outlined
          color="blue-grey-12"
          option-label="text"
          bg-color="white"
          option-value="value"
          popup-content-class="overflow-auto"
          emit-value
          bottom-slots
          no-error-icon
          map-options
          @blur="$v.option.$touch()"
          v-model="option"
          :options="options"
          :label="
            option
              ? undefined
              : 'How many users are you exploring heycollab for? *'
          "
          dense
          class="app-text-normal"
          :error="fieldErrors('option').length > 0"
        >
          <template v-slot:error>
            <div>
              {{
                fieldErrors("option").length > 0 ? fieldErrors("option")[0] : ""
              }}
            </div>
          </template>
        </q-select>
        <q-btn
          dense
          label="Submit"
          color="primary"
          text-color="white"
          no-caps
          style="width:100%"
          class="app-text-normal"
          @click="submitForm"
          :disable="!this.$v.$invalid ? false : true"
        />
      </form>
    </q-card>
  </q-dialog>
</template>
<script>
import {
  required,
  alpha,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import { CreatePlanContactusMutation } from "@/gql";
export default {
  name: "ContactSalesDialog",
  props: ["value", "closeSalesDialog"],
  mixins: [validationMixin],
  validations: {
    first: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    last: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      numeric,
      maxLength: maxLength(15),
      minLength: minLength(7),
    },
    option: {
      required,
    },
  },
  validationMessages: {
    first: {
      required: "message.validation.userprofile.first.required",
      alpha: "message.validation.userprofile.first.firstname",
      maxLength: "message.validation.userprofile.first.max",
      minLength: "message.validation.userprofile.first.min",
    },
    last: {
      required: "message.validation.userprofile.last.required",
      alpha: "message.validation.userprofile.last.lastname",
      maxLength: "message.validation.userprofile.last.max",
      minLength: "message.validation.userprofile.last.min",
    },
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    phone: {
      required: "Please enter valid phone number",
      numeric: "message.validation.userprofile.phone.valid",
      maxLength: "message.validation.userprofile.phone.maxLength",
      minLength: "message.validation.userprofile.phone.minLength",
    },
    option: {
      required: "Please select any of the option.",
    },
  },
  data() {
    return {
      first: "",
      last: "",
      email: "",
      phone: "",
      option: "",
      options: ["149-250", "251-500", "500+"],
    };
  },
  methods: {
    async submitForm() {
      if (!this.$v.$invalid) {
        const variables = {
          first: this.first,
          last: this.last,
          email: this.email,
          phone: this.phone,
          user_limit: this.option,
        };

        await this.$api.mutate({
          mutation: CreatePlanContactusMutation,
          variables,
        });

        this.$q.notify({
          position: "top-right",
          message: "Your message was sent!",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          classes: "success-notification",
        });

        this.closeSalesDialog();
      }
    },
  },
};
</script>
<style scoped>
.margin {
  margin-top: 20px;
}
.input-arrange {
  display: flex;
  justify-content: space-between;
}
.close-btn {
  font-size: 10px;
  position: absolute;
  top: 10px;
  right: 13px;
}
.q-field__bottom {
  padding: 3px 2px 0 !important;
}
</style>

<style>
.q-field__bottom {
  padding: 3px 2px 0 !important;
}
</style>
