<template>
  <div class="flex-x scroll-x fill-height" style="padding: 30px 40px 10px 20px">
    <div class="flex-1">
      <q-linear-progress
        color="primary"
        indeterminate
        size="5px"
        v-if="progress"
      />
      <table class="bordered vb-table" width="100%">
        <thead>
          <tr>
            <th>
              <div
                class="users-th"
                style="padding:9px 0 10px;-top-right-radius: 5px;background:#e0e4ec;"
              >
                <q-checkbox
                  v-model="selectAll"
                  @input="toggleAll"
                  toggle-indeterminate
                  toggle-order="ft"
                  size="sm"
                />
                <div style="margin-left: 20px">Emails</div>
              </div>
            </th>
            <th width="30%">
              <div
                class="users-th"
                style="padding:9px 0 10px;background:#e0e4ec"
              >
                <div style="margin-left: 20px">Options</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="user-td">
            <td style="max-height:37px">
              <div class="items">
                <q-checkbox
                  color="primary"
                  size="sm"
                  v-model="item.selected"
                  @input="toggleSelect(item)"
                />
                <!-- <img
                  src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab-round.svg"
                  style="height:40px"
                /> -->
                <div style="margin-left: 15px">{{ item.email }}</div>
              </div>
            </td>
            <td>
              <div v-if="item.loader">
                <q-circular-progress
                  indeterminate
                  size="md"
                  :thickness="0.2"
                  color="primary"
                />
              </div>
              <div
                @click.stop="revokeInvite(item, index)"
                class="text-pink text-center"
                v-else
              >
                <span class="cursor-pointer">Revoke</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TableMultiSelection from "@/mixins/tableMultiSelect";
import {
  InvitationsQuery,
  ResendInvitationMutation,
  RevokeInvitationMutation,
} from "@/gql";

export default {
  name: "ManageInvitations",
  mixins: [TableMultiSelection],
  api: {
    invitations: {
      query: InvitationsQuery,
      cacheKey: "InvitationsQuery",
      defaultValue: null,
    },
  },
  beforeCreate() {
    this.$eventBus.$off("resendInviteBulk");
  },
  mounted() {
    this.$eventBus.$on("resendInviteBulk", this.resendInviteBulk);
  },
  data() {
    return {
      progress: false,
      dialog: {
        show: false,
        title: null,
        type: null,
        payload: null,
      },
      dropdown: [
        {
          title: "Resend Invitation to Selected Users",
          action: this.resendInviteBulk,
        },
      ],
    };
  },
  methods: {
    resetDialog() {
      this.dialog = {
        show: false,
        title: null,
        type: null,
        payload: null,
      };
    },
    async resendInviteHandler(invitationIds) {
      const variables = {
        invitation_ids: invitationIds,
      };

      const response = await this.$api.mutate({
        mutation: ResendInvitationMutation,
        variables,
      });

      return response ? response : null;
    },
    async revokeInvite(invitation, index) {
      invitation.loader = true;
      const revokeInvitation = await this.revokeInviteHandler([invitation.id]);
      invitation.loader = false;
      if (revokeInvitation) {
        this.items.splice(index, 1);
      }
      this.resetDialog();
    },
    async revokeInviteHandler(invitationIds) {
      const variables = {
        invitation_ids: invitationIds,
      };
      const response = await this.$api.mutate({
        mutation: RevokeInvitationMutation,
        variables,
      });

      this.$api.query({
        query: InvitationsQuery,
        cacheKey: "InvitationsQuery",
      });

      return response ? response : null;
    },
    async resendInviteBulk() {
      this.progress = true;
      await this.resendInviteHandler(this.selected.map((o) => o.id));
      this.toggleAll();
      this.progress = false;
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.invitation.sent"),
        icon: this.$icons.matAnnouncement,
      });
    },
  },
  computed: {
    items() {
      if (this.invitations) {
        return this.invitations.map((element) => {
          return {
            ...element,
            selected: false,
            loader: false,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    selected(to) {
      if (to.length) {
        this.$emit("userInvitationSays", true);
      } else {
        this.$emit("userInvitationSays", false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
tr:hover {
  background: transparent !important;
}
.users-th {
  display: flex;
  align-items: center;
  height: 37px;
}
.items {
  display: flex;
  align-items: center;
}
.user-td:hover {
  background: #e0e4ec !important;
}
</style>
