<template>
  <div class="column full-width notification-wrapper">
    <div class="row justify-between q-py-sm q-px-md group-hover">
      <div class="row align-center">
        <div class="an-20 bold-text">Notifications</div>
        <q-btn
          dense
          flat
          round
          padding="4px"
          color="grey-5"
          style="width: 25px; height: 25px"
        >
          <q-icon
            size="13px"
            style="margin-top: -2px"
            :name="$icons.fasSortDown"
          />
          <q-menu auto-close content-class="notifications-menu">
            <div class="header">
              <div>Notifications</div>
              <div class="close-btn cursor-pointer" @click="menu = false">
                <q-icon :name="$icons.matClose" class="close-icon" />
              </div>
            </div>
            <q-list>
              <q-item @click="handleFilter('date')" clickable>
                <q-item-section>
                  <q-item-label lines="1" class="row">
                    <span class="filter-custom-checkbox">
                      <div
                        v-if="filter.byMostRecent"
                        class="filter-selected-custom-checkbox"
                      ></div>
                    </span>
                    <span> Most Recent </span>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="handleFilter('unread')" clickable>
                <q-item-section>
                  <q-item-label lines="1" class="row">
                    <span class="filter-custom-checkbox">
                      <div
                        v-if="filter.byUnreadAtTop"
                        class="filter-selected-custom-checkbox"
                      ></div>
                    </span>
                    <span> Unread at the top </span>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="handleFilter('messages')" clickable>
                <q-item-section>
                  <q-item-label lines="1" class="row">
                    <span class="filter-custom-checkbox">
                      <div
                        v-if="filter.byMessages"
                        class="filter-selected-custom-checkbox"
                      ></div>
                    </span>
                    <span> Messages </span>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="handleFilter('tasks')" clickable>
                <q-item-section>
                  <q-item-label lines="1" class="row">
                    <span class="filter-custom-checkbox">
                      <div
                        v-if="filter.byTasks"
                        class="filter-selected-custom-checkbox"
                      ></div>
                    </span>
                    <span> Tasks </span>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-btn
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button-small"
          @click="
            toggleNotificationsWalkthrough(!user.onboarding.is_notifications)
          "
        />
      </div>
      <div
        class="absolute thread-icons group-hover-item"
        style="right: 5px; padding: 2px 7px 2px 7px"
      >
        <div class="row items-center">
          <q-btn
            :icon="$icons.matVisibility"
            flat
            dense
            padding="0"
            round
            size="10px"
            @click="clearNotification"
            class="thread-icon"
          >
            <q-tooltip>
              Mark all as read
            </q-tooltip>
          </q-btn>
          <q-btn
            flat
            size="10px"
            padding="0"
            round
            dense
            class="thread-icon"
            :icon="$icons.matDelete"
            @click="deleteNotificationDialogHandler"
          >
            <q-tooltip>
              Delete notifications
            </q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>
    <div
      class="walkthrough walkthrough-small"
      v-if="user.onboarding.is_notifications"
    >
      <div class="icon">
        <q-icon
          :name="$icons.matNotificationsActive"
          size="24px"
          style="color: #007aff"
        />
      </div>
      <div class="content">
        <p class="bold">Keep track of what’s new</p>
        <p>
          See all your notifications and respond directly from here across
          workspaces.
        </p>
        <div class="video" @click="videoPlayer">
          <q-icon
            class="play"
            color="primary"
            :name="$icons.mdiPlay"
            size="55px"
          />
        </div>
      </div>
      <div class="close" @click="toggleNotificationsWalkthrough(false)">
        <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
      </div>
    </div>
    <div class="overflow-auto" v-infinite-scroll="fetchScrollHandler">
      <div style="max-height: calc(100vh - 120px)">
        <template>
          <div v-if="filteredNotifications && filteredNotifications.length">
            <q-expansion-item
              v-for="(group, i) in allNotifications"
              :key="i"
              default-opened
              ref="expansionItems"
              exact-active-class="transparent"
              header-class="text-uppercase items-start justify-start q-mx-md q-mb-sm"
              header-style="display: block;background-color: #F6F6F8 !important;font-family: Avenir Next Demi;font-size: 14px; font-weight: 600;color: #42526E;"
            >
            <template v-slot:header>
              <div
                :class="[
                  i === todaytask ? 'today-task' : '',
                  i === yesterdayTask ? 'yesterday-task' : '',
                ]"
              >
                {{ i | dateConversion }}
              </div>
            </template>
            
            <div
              v-for="(noti, index) in group"
              :key="index"
            >
              <notification-component
                :notification="noti"
                :activeTab="activeTab"
                :user="user"
                :labels="taskLabels"
                :reactions="reactions"
                :mentions="workspaceMembers"
                :workspaces="workspaces"
                :activeId="activeId"
                :isNotificationVisible="isVisible"
                :currentCompany="currentCompany"
                :currentWorkspace="currentWorkspaceId"
                :isWorkspaceOwner="isWorkspaceOwner"
                :workspace="currentWorkspace"
                :showUserInfo="showUserInfo"
                :setActiveId="setActiveId"
                :closeReplyEditorHandler="closeReplyEditorHandler"
                :reactOnPost="reactOnPost"
              />
            </div>
            </q-expansion-item>
          </div>
          <div v-else class="empty-notification-placeholder">
            <div class="bell-icon">
              <q-icon
                size="22px"
                color="teal-14"
                :name="$icons.matNotifications"
              />
            </div>
            <div class="q-mt-md an-15 medium-text" style="color: #677484">
              <span class="an-15 regular-text">
                No new tasks & messages. You're all caught up!</span
              >
            </div>
          </div>
        </template>
        <div class="row justify-center q-my-md" v-if="loading">
          <q-circular-progress
            indeterminate
            size="30px"
            :thickness="0.2"
            color="primary"
            class="q-mb-sm q-ml-sm"
          />
        </div>
      </div>
    </div>
    <div v-if="deleteNotificationDialog.flag">
      <confirm-dialog
        v-model="deleteNotificationDialog.flag"
        title="Delete notifications ?"
        question="Are you sure you want to delete all notifications?"
        cancleText="Cancel"
        successText="Ok"
        :cancleMethod="resetdeleteNotificationDialog"
        :successMethod="deleteAllNotification"
        :loading="false"
      />
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :currentWorkspaceId="currentWorkspaceId"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import format from "date-fns/format";
import subDays from "date-fns/subDays";

import mixpanel from "@/mixpanel";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ProfileDialog from "@/components/ProfileDialog";
import NotificationComponent from "@/components/NotificationComponent";
import VideoDialog from "@/components/VuePlayer/VideoDialog";

import {
  UpdateNotificationsMutation,
  DeleteNotificationsMutation,
  NotificationsQuery,
  UpdateUserOnboardingMutation,
} from "@/gql";
export default {
  name: "NotificationsSidebar",
  props: ["toggleDrawerRight", "reactOnPost"],
  components: {
    ConfirmDialog,
    ProfileDialog,
    NotificationComponent,
    VideoDialog,
  },
  api: {
    notifications: {
      cacheKey: "NotificationsQuery",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  filters: {
    dateConversion(postGroupDate) {
      if (postGroupDate === format(new Date(), "iiii, MMM. do")) {
        return "Today";
      } else if (
        postGroupDate === format(subDays(new Date(), 1), "iiii, MMM. do")
      ) {
        return "Yesterday";
      }
      return postGroupDate;
    },
  },
  data() {
    return {
      activeTab: "tab-All",
      deleteNotificationDialog: {
        flag: false,
        id: null,
        loader: false,
      },
      tabs: ["All", "Tasks", "Messages"],
      filter: {
        byMostRecent: true,
        byUnreadAtTop: false,
        byMessages: false,
        byTasks: false,
      },
      activeId: null,
      isVisible: false,
      profileDialog: {
        show: false,
        user: null,
      },
      loading: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  methods: {
    async closeNotificationSidebar() {
      this.toggleDrawerRight('notifications');
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .map((n) => {
            n.read = true;
            return n.id;
          });
        if (notification_ids.length) {
          const variables = {
            notification_ids: notification_ids,
            read: true,
          };
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables,
          });
        }
      }
    },
    deleteNotificationDialogHandler() {
      this.deleteNotificationDialog = {
        flag: true,
        id: null,
      };
    },
    async deleteAllNotification() {
      const query = this.$api.getQuery("NotificationsQuery");
      query.data.notifications = [];
      await this.$api.mutate({
        mutation: DeleteNotificationsMutation,
      });
      this.resetdeleteNotificationDialog();
      mixpanel.track("Notification Delete All");
    },
    resetdeleteNotificationDialog() {
      this.deleteNotificationDialog = {
        flag: false,
        id: null,
        loader: false,
      };
    },

    async clearNotification() {
      const query = this.$api.getQuery("NotificationsQuery");
      query.data.notifications.forEach((n) => {
        n.read = true;
      });
      await this.$api.mutate({
        mutation: UpdateNotificationsMutation,
        variables: {
          read: true,
        },
      });
      mixpanel.track("Notification Read All");
    },
    changeScrollPos() {
      var element = document.getElementById("scroll");
      element.scrollTo(0, 0);
    },
    handleFilter(type) {
      if (type === "unread") {
        this.filter.byUnreadAtTop = true;
        this.filter.byMostRecent = false;
        this.filter.byMessages = false;
        this.filter.byTasks = false;
      }
      if (type === "date") {
        this.filter.byMostRecent = true;
        this.filter.byUnreadAtTop = false;
        this.filter.byMessages = false;
        this.filter.byTasks = false;
      }
      if (type === "messages") {
        this.filter.byMessages = true;
        this.filter.byUnreadAtTop = false;
        this.filter.byMostRecent = false;
        this.filter.byTasks = false;
      }
      if (type === "tasks") {
        this.filter.byTasks = true;
        this.filter.byUnreadAtTop = false;
        this.filter.byMostRecent = false;
        this.filter.byMessages = false;
      }
    },
    async fetchScrollHandler() {
      this.loading = true;
      if (this.notifications) {
        const lastNotification = last(this.notifications);

        if (lastNotification) {
          const currentNotifications = this.$api.getQuery("NotificationsQuery");
          const newNotifications = await this.$api.mutate({
            mutation: NotificationsQuery,
            variables: {
              cursor: lastNotification.id,
            },
          });
          if (newNotifications.data.notifications.length > 0) {
            currentNotifications.data.notifications = [
              ...currentNotifications.data.notifications,
              ...newNotifications.data.notifications,
            ];
          }
        }
      }
      this.loading = false;
    },
    setActiveId(activeID) {
      this.isVisible = true;
      this.activeId = activeID;
    },
    closeReplyEditorHandler() {
      this.isVisible = false;
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    async toggleNotificationsWalkthrough(flag) {
      this.user.onboarding.is_notifications = flag;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_notifications",
          flag_value: flag,
        },
      });
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/584323169",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    todaytask() {
      return format(new Date(), "iiii, MMM. do");
    },
    yesterdayTask() {
      return format(subDays(new Date(), 1), "iiii, MMM. do");
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    allNotifications() {
      if (this.filteredNotifications) {
        if (this.filter.byUnreadAtTop) {
          let sortedArr = sortBy(this.filteredNotifications, (n) => n.read);
          // return sortedArr;
          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }
        if (this.filter.byMessages) {
          let sortedArr = this.filteredNotifications.filter((n) => {
            if (n.action.object_type === "App\\Post") {
              return n;
            }
          });

          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }
        if (this.filter.byTasks) {
          let sortedArr = this.filteredNotifications.filter((n) => {
            if (
              n.action.object_type === "App\\Task" ||
              n.action.object_type === "App\\TaskComment"
            ) {
              return n;
            }
          });
          return groupBy(sortedArr, (post) => {
            return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
          });
        }

        return groupBy(this.filteredNotifications, (post) => {
          return format(Date.parse(post.action["created_at"]), "iiii, MMM. do");
        });
      } else {
        return [];
      }
    },
    filteredNotifications() {
      return (
        this.notifications && this.notifications.filter((n) => n.action.object)
      );
    },
    allNotificationsCount() {
      let filteredNotifications = [];
      if (this.filteredNotifications) {
        filteredNotifications = this.filteredNotifications.filter(
          (n) => !n.read
        );
      }
      return filteredNotifications.length;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
  },
};
</script>
