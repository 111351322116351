<template>
  <div>
    <div class="custome-tab activity-tab row">
      <div class="q-mt-md d-flex">
        <q-tabs
          :value="activeTab"
          @input="changeActiveTab"
          style="width:257px"
          class="text-dark"
          active-bg-color="dark"
          active-color="white"
          no-caps
        >
          <q-tab
            class="relative-position"
            name="about"
            style="width:25px;border-top-left-radius:14px;border-bottom-left-radius: 14px;"
            inline-label
            label="About"
          >
            <q-tooltip>
              About this workspace
            </q-tooltip>
          </q-tab>
          <q-tab
            class="relative-position"
            name="myTasks"
            style="width:50px;"
            inline-label
            label="Private tasks"
          >
            <q-tooltip>
              Tasks assigned to you
            </q-tooltip>
          </q-tab>
          <q-tab
            class="relative-position"
            name="files"
            style="width:25px;border-top-right-radius:14px;border-bottom-right-radius: 14px;"
            inline-label
            label="Files"
          >
            <q-tooltip>
              Files shared in this workspace
            </q-tooltip>
          </q-tab>
        </q-tabs>
        <div class="d-flex align-center">
          <q-btn
            :icon="$icons.mdiHelp"
            round
            size="sm"
            class="help-button-small"
            @click.stop="toggleAboutWalkthrough"
            style="left: 3px"
          >
            <q-tooltip anchor="bottom start">
              Learn about {{activeTab === 'myTasks' ? 'My Tasks' : activeTab === 'files' ? 'files section' : 'this section'}}
            </q-tooltip>
          </q-btn>
          <!-- <q-btn
            flat
            size="13px"
            round
            padding="0"
            :icon="$icons.matClose"
            class="absolute"
            style="color:black; right: 2px"
            @click="toggleDrawerRight('info')"
          /> -->
        </div>
      </div>
    </div>
    <q-tab-panels :value="activeTab">
      <q-tab-panel class="q-pa-none" name="about">
        <about />
      </q-tab-panel>
      <q-tab-panel class="q-pa-none" name="myTasks">
        <my-tasks />
      </q-tab-panel>
      <q-tab-panel class="q-pa-none" name="files">
        <files />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import About from "@/views/WorkspaceView/InfoSidebar/About";
import Files from "@/views/WorkspaceView/InfoSidebar/Files";
import MyTasks from "@/views/WorkspaceView/InfoSidebar/MyTasks";
import { UpdateUserOnboardingMutation } from '@/gql';
export default {
  name: "InfoSidebar",
  props: ["toggleDrawerRight", "workspaceId"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  components: {
    About,
    Files,
    MyTasks,
  },
  methods: {
    changeActiveTab(value) {
      this.$store.dispatch("setInfoSidebarActiveTab", {
        workspaceId: this.workspaceId,
        flag: value,
      });
    },
    async toggleAboutWalkthrough() {
      let flag_name;
      switch(this.activeTab) {
        case "about":
          flag_name = "is_about"
          this.$mixpanelEvent('about-help',{})
          break;
        case "myTasks":
          flag_name = "is_private_tasks"
          this.$mixpanelEvent('private-task-help',{})
          break;
        case "files":
          flag_name = "is_files"
          this.$mixpanelEvent('files-help',{})
          break;
      }
      this.user.onboarding[flag_name] = !this.user.onboarding[flag_name];
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: flag_name,
          flag_value: this.user.onboarding[flag_name]
        },
      });
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters.activeInfoTab(this.workspaceId)
        ? this.$store.getters.activeInfoTab(this.workspaceId)
            .infoSidebarActiveTab
        : "about";
    },
  },
};
</script>
