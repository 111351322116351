<template>
  <q-btn
    v-if="isVisible"
    padding="5px"
    dense
    class="member-section-btn"
    :icon="$icons.matAdd"
    label="Add members"
    no-caps
  >
    <q-menu
      content-class="q-px-md overflow-auto assign-member-menu"
      v-model="menu"
      class="scroll-y"
      v-infinite-scroll="fetchScrollHandler"
    >
      <q-linear-progress
        color="primary"
        indeterminate
        size="3px"
        v-if="isloading"
      />
      <div class="sticky-header">
        <div class="assign-to q-py-sm">
          Add members
        </div>
        <div class="search-member">
          <q-input
            v-model="search"
            outlined
            dense
            clearable
            placeholder="Search Member"
            @input="searchByTitle"
            autofocus
          >
            <template v-slot:prepend>
              <q-icon :name="$icons.matSearch" />
            </template>
          </q-input>
        </div>
      </div>
      <div
        :style="{ 'max-height': '320px' }"
        id="addto-workspace-member"
        ref="addtoWorkspacemember"
      >
        <q-list class="q-py-sm member-list">
          <template v-for="user in workspaceAvailableUsers">
            <q-item
              class="q-px-none q-py-xs member-item items-center"
              :key="user.id"
              @click.stop="addMember(user)"
              clickable
            >
              <q-item-section class="member-avatar" avatar>
                <q-avatar size="32px" v-if="user.pic">
                  <img :src="user.pic" :alt="user.first" />
                </q-avatar>
                <!-- <q-avatar
                  size="32px"
                  color="grey-4"
                  text-color="grey-10"
                  v-else
                >
                  {{ user.first | firstChar }}{{ user.last | firstChar }}
                </q-avatar> -->
                <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
              </q-item-section>
              <q-item-section class="member-name" v-tooltip>
                {{ user.first | capitalize }}
                {{ user.last | capitalize }}
                <q-tooltip v-if="isTruncated">
                  {{ user.first | capitalize }}
                  {{ user.last | capitalize }}
                </q-tooltip>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </div>
    </q-menu>
  </q-btn>
</template>
<script>
import {
  WorkspaceAvailableUsersQuery,
  AddMemberToWorkspaceMutation,
  Workspaces2Query,
} from "@/gql";
import mixpanel from "@/mixpanel";
import debounce from "lodash/debounce";

import Avatar from 'vue-avatar';

export default {
  name: "AddMemberToWorkspace",
  props: ["currentWorkspaceId"],
  api: {
    workspaceAvailableUsers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceAvailableUsersQuery:${this.currentWorkspaceId}`;
      },
      variables() {
        return {
          offset: 0,
          workspace_id: this.currentWorkspaceId,
        };
      },
    },
  },
  components:{
    Avatar
  },
  data() {
    return {
      menu: false,
      isloading: false,
      search: null,
      searchNoMatchFound: false,
      choosed: null,
      filter: {
        state: false,
      },
      isTruncated: false,
    };
  },
  methods: {
    async addMember(member) {
      this.choosed = member;
      const variables = {
        workspace_id: this.currentWorkspaceId,
        user_id: member.id,
      };
      try {
        const query = this.$api.getQuery(
          `WorkspaceMembersQuery:${this.currentWorkspaceId}`
        );
        if (query.data) {
          const newMember = this.$api.getEntity("user", member.id);
          if (query.data.workspaceMembers) {
            query.data.workspaceMembers.push(newMember);
          } else {
            query.data.workspaceMembers = [newMember];
          }
        }
        const response = await this.$api.mutate({
          mutation: AddMemberToWorkspaceMutation,
          variables,
        });
        mixpanel.track("Workspace Memeber Add");

        const res = await this.$api.query({
          query: WorkspaceAvailableUsersQuery,
          variables: {
            offset: 0,
            workspace_id: this.currentWorkspaceId,
          },
          cacheKey() {
            return `WorkspaceAvailableUsersQuery:${this.currentWorkspaceId}`;
          },
        });
        await this.$api.query({
          query: Workspaces2Query,
          cacheKey: "Workspaces2Query",
        });
        if (res.data.workspaceAvailableUsers) {
          if (this.workspaceAvailableUsers.length === 0) {
            this.menu = false;
          }
        }
        if (response.data.addMemberToWorkspace) {
          // await this.getWorkspaceAvailableUsers()
          this.$emit("added", member);
        }
        this.choosed = null;
      } catch (error) {
        this.choosed = null;
      }
    },
    async fetchScrollHandler() {
      const ps = this.$refs.addtoWorkspacemember;
      if (
        ps.scrollTop === ps.scrollHeight - ps.clientHeight &&
        ps.scrollTop > 0 &&
        this.isloading === false
      ) {
        this.isloading = true;
        const lengthOfData = this.workspaceAvailableUsers.length;
        const variables = {
          offset: lengthOfData,
          search: this.search,
        };

        const currentWorkspaceAvailableUsers = this.$api.getQueryByName(
          "workspaceAvailableUsers"
        );
        const newWorkspaceAvailableUsers = await this.$api.query({
          query: WorkspaceAvailableUsersQuery,
          variables,
        });

        currentWorkspaceAvailableUsers.data.workspaceAvailableUsers = [
          ...currentWorkspaceAvailableUsers.data.workspaceAvailableUsers,
          ...newWorkspaceAvailableUsers.data.workspaceAvailableUsers,
        ];

        this.isloading = false;
      }
    },
    searchByTitle() {
      this.searchNoMatchFound = false;
      if (this.search) {
        this.filter.state = true;
        let debounceFunction = debounce(() => {
          this.filterAvailableUsersForWorkspace();
        }, 500);
        debounceFunction();
        this.scollbar();
      }
    },
    async filterAvailableUsersForWorkspace() {
      const variables = {
        offset: 0,
        search: this.search,
        workspace_id: this.currentWorkspaceId,
      };

      const currentWorkspaceAvailableUsers = this.$api.getQueryByName(
        "workspaceAvailableUsers"
      );
      const newWorkspaceAvailableUsers = await this.$api.query({
        query: WorkspaceAvailableUsersQuery,
        variables,
      });

      if (
        newWorkspaceAvailableUsers.data.workspaceAvailableUsers.length === 0
      ) {
        this.searchNoMatchFound = true;
      } else {
        currentWorkspaceAvailableUsers.data.workspaceAvailableUsers = [
          ...newWorkspaceAvailableUsers.data.workspaceAvailableUsers,
        ];
      }
    },
    clearWorkspaceSearchHandler() {
      this.searchNoMatchFound = false;
      if (this.filter.state === true) {
        this.scollbar();
        this.search = null;
        this.filterAvailableUsersForWorkspace();
        this.filter.state = false;
      }
    },
    scollbar() {
      const ps = this.$refs.addtoWorkspacemember;
      if (ps) ps.scrollTop = 0;
    },
  },
  computed: {
    isVisible() {
      if (
        this.workspaceAvailableUsers &&
        this.workspaceAvailableUsers.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
