<template>
  <div class="field-width">
    <form v-on:submit.prevent="$v.$invalid ? null : submit()">
      <div class="forgot-password-title q-mb-lg app-text-normal">Set new password</div>
      <div class="spacing-right col-grow input-info-btn-popover q-mb-sm">
        <div class="input-label app-text-normal">Password</div>
        <q-input
          :badge="false"
          ref="input"
          type="password"
          v-model="password"
          v-bind:value="password"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          :error="fieldErrors('password').length > 0"
          outlined
          dense
          bottom-slots
          no-error-icon
          :hint="
            password && !fieldErrors('password').length
              ? 'Password must be 8 characters or longer.'
              : ''
          "
        >
          <template v-slot:error>
            <div class="text app-text-normal" style="color: #5E7191;" v-if="!password">
              Password must be 8 characters or longer.
            </div>
            <div class="text-negative" v-else>
              {{
                fieldErrors("password").length > 0
                  ? fieldErrors("password")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
      </div>
      <!-- <password
          v-model="password"
          :badge="false"
          hint
          @next="handlePasswordScoreEvent"
          required
        ></password> -->
      <div class="col-grow input-info-btn-popover ">
        <div class="input-label app-text-normal">Confirm Password</div>
        <q-input
          type="password"
          v-model="repeatPassword"
          @input="$v.repeatPassword.$touch()"
          @blur="$v.repeatPassword.$touch()"
          :error="fieldErrors('repeatPassword').length > 0"
          outlined
          dense
          bottom-slots
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("repeatPassword").length > 0
                  ? fieldErrors("repeatPassword")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
      </div>
      <!-- <q-input
          v-model="repeatPassword"
          @input="$v.repeatPassword.$touch()"
          @blur="$v.repeatPassword.$touch()"
          placeholder="Confirm New Password"
          type="password"
          bottom-slots
          outlined
          class="q-mt-sm"
          :error="fieldErrors('repeatPassword').length > 0"
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-pink">
              {{
                fieldErrors("repeatPassword").length > 0
                  ? fieldErrors("repeatPassword")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input> -->
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        padding="0"
        label="Set new password"
        class="signin-btn-class app-text-normal"
        @click="submit()"
        :loading="loader"
        :disabled="$v.$invalid"
      />
    </form>
  </div>
</template>
<script>
import validationMixin from "@/mixins/validationMixin";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { ResetPasswordMutation } from "@/gql";

export default {
  name: "Step4",
  props: ["email", "companyUri", "code"],
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  validationMessages: {
    password: {
      required: "message.validation.password.required",
      minLength: "message.validation.password.min",
    },
    repeatPassword: {
      sameAsPassword: "message.validation.password.confirm",
    },
  },
  components: {},
  data() {
    return {
      loader: false,
      password: null,
      repeatPassword: null,
      passwordScore: 0,
      invalidPassword: null,
      forgotPasswordImage:
        "https://s3-us-west-1.amazonaws.com/vabotu/default/dark_clock_no-circle.svg",
    };
  },
  methods: {
    handlePasswordScoreEvent(data) {
      this.passwordScore = data.score;
    },
    async submit() {
      this.loader = true;
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
        company_uri: this.companyUri,
        password: this.password,
      };

      try {
        await this.$api.mutate({
          mutation: ResetPasswordMutation,
          variables,
        });

        this.$emit("success", { next: true });
        this.loader = false;
      } catch (error) {
        this.loader = false;
        if (error.graphQLErrors[0].message === "PasswordInvalid") {
          this.invalidPassword = true;
        }
      }
    },
  },
};
</script>
