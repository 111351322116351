<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-pa-xl">
      <div>
        <q-btn
          dense
          round
          flat
          :icon="$icons.matClose"
          size="md"
          color="blue-grey-1"
          text-color="grey"
          class="absolute"
          style="margin: 8px 8px 0 0;top:0;right:0"
          @click.native="$emit('closeDialog')"
        />
      </div>
      <div class="text-center">
        <strong class="an-25 bold-text">Close Account</strong>
        <div class="an-14 medium-text closeAccText--text" style>
          All fields are required
        </div>
      </div>
      <div class="q-pt-lg">
        <div class="an-16 bold-text">Why are you closing your account ?</div>
        <div>
          <div class="row">
            <div class="col-grow">
              <q-checkbox
                v-model="selected"
                label="Missing feature"
                size="sm"
                val="Missing feature"
              />
            </div>
            <div class="col-grow">
              <q-checkbox
                v-model="selected"
                label="Just testing"
                size="sm"
                val="Just testing"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-grow" style="max-width:243px">
              <q-checkbox
                v-model="selected"
                label="Difficult to use"
                size="sm"
                val="Difficult to use"
              />
            </div>
            <div class="col-grow">
              <q-checkbox
                v-model="selected"
                size="sm"
                label="Other"
                val="Other"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="q-pt-lg">
        <div class="an-16 bold-text">Please provide a brief description</div>
        <div class="mt-3">
          <textarea
            v-model="description"
            class="cancel-desc an-16 medium-text"
            rows="6"
          ></textarea>
        </div>
      </div>
      <div class="text-center">
        <div class="text-red">
          Your account will be billed the remaining due. All your data will be
          deleted. This process cannot be undone.
        </div>
        <q-btn
          size="md"
          color="red-6"
          text-color="white"
          no-caps
          label="Close Account"
          :disabled="!loading && !checkValidation"
          :loading="loading"
          @click.native="cancelAccount"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "CloseAccount",
  props: ["isClosing", "value", "cancelAccountHandler"],
  data() {
    return {
      selected: [],
      description: "",
    };
  },
  computed: {
    loading() {
      return this.isClosing;
    },
    checkValidation() {
      return this.selected.length > 0 && this.description !== "";
    },
  },
  methods: {
    cancelAccount() {
      if (this.selected.length > 0 && this.description !== "") {
        this.cancelAccountHandler({
          reason_for_cancelation: this.selected,
          description: this.description,
        });
      }
    },
  },
};
</script>
