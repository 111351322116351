<template>
  <q-card style="padding: 30px 320px 10px 20px;" class="user-setting-dialog">
    <div class="footer-menu-dialog-title">
      <p>Edit Profile</p>
    </div>
    <div
      class="row user-profile-setting-form"
    >
      <!-- Right Column -->
      <div class="column col-12 q-pb-sm">
        <div>
          <div v-show="isEdit" class="relative-position" style="z-index:10">
            <vue-cropper
              ref="cropper"
              drag-mode="move"
              :viewMode="3"
              :rotatable="true"
              :cropBoxResizable="true"
              :cropmove="cropImage"
              :aspectRatio="1"
              :minCropBoxWidth="50"
              :containerStyle="{ maxWidth: '300px' }"
              :imgStyle="{ maxWidth: '300px' }"
              :minCropBoxHeight="50"
              :minContainerWidth="150"
              :minContainerHeight="150"
            ></vue-cropper>
            <q-btn
              :icon="$icons.matCheck"
              size="md"
              dense
              color="indigo-12"
              text-color="white"
              class="absolute pos-edit-img-btn"
              @click="setImage"
              round
            />
          </div>
          <div
            :class="[
              fetchUserProfile ? '' : 'primary',
              'profile-uploader',
              'relative-position',
            ]"
            v-if="
              !isEdit &&
                (profile.selected || profile.previous || profile.cropImage)
            "
          >
            <!-- Fab dialer -->
            <div class="absolute-top-right">
              <q-fab
                color="indigo-12"
                :class="[pic && 'q-pl-md']"
                :icon="$icons.matMoreVert"
                direction="left"
                padding="sm"
              >
                <q-fab-action
                  padding="sm"
                  color="indigo-12"
                  @click="fileTrigger"
                  :icon="$icons.matEdit"
                />
                <q-fab-action
                  v-show="pic"
                  color="pink"
                  @click="deletePhotoDialogHandler(true)"
                  :icon="$icons.matDeleteForever"
                />
              </q-fab>
            </div>
            <!-- Fab dialer End -->
            <img :src="fetchUserProfile" class="circle-img" />
          </div>
          <div
            :class="[
              fetchUserProfile ? '' : 'primary',
              'profile-uploader',
              'center-align',
            ]"
            v-else-if="!isEdit"
            @click="fileTrigger"
          >
            <div>
              <q-icon :name="$icons.matPhotoCamera" size="xl" color="white" />
              <span class="block text-subtitle1 text-white">Upload</span>
              <span class="block text-subtitle1 text-white"
                >Profile Photo</span
              >
            </div>
          </div>
        </div>
        <p class="secondary--text error-message" v-if="profile.errorMessage">
          {{ profile.errorMessage }}
        </p>
        <input
          type="file"
          ref="fileInput"
          @change="changeProfile"
          v-show="false"
          accept="image/*"
          @click="clickProfile"
        />
        <p class="desc" :class="this.imageValid?'desc-color':'text-pink'" >
          Supporting: JPEG, PNG and GIF
        </p>
      
        <div
          class="col-grow q-pt-lg"
          style="flex-basis:0%"
        >
          <p class="text-pink" v-if="isAvailabityError">
            {{ availabityErrorMessage }}
          </p>
          <div class="row">
            <div>
              <label>First Name</label>
              <q-input
                v-model="first"
                @input="$v.first.$touch()"
                @blur="$v.first.$touch()"
                outlined
                type="text"
                bottom-slots
                :error="fieldErrors('first').length > 0"
                no-error-icon
                bg-color="white"
                input-class="caps-input"
                style="margin-right: 18px;"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("first").length > 0
                        ? fieldErrors("first")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
            <div class="q-ml-sm flex-1">
              <label>Last Name</label>
              <q-input
                v-model="last"
                @input="$v.last.$touch()"
                @blur="$v.last.$touch()"
                outlined
                type="text"
                bottom-slots
                :error="fieldErrors('last').length > 0"
                no-error-icon
                input-class="caps-input"
                bg-color="white"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("last").length > 0
                        ? fieldErrors("last")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
          </div>
          <v-popover
            :autoHide="true"
            :handleResize="true"
            offset="35px"
            class="no-height"
            placement="bottom-start"
            :open="popover.username"
            popoverClass="vb-info-popover"
          >
            <template slot="popover">
              <div class="vb-popover-information">
                <q-icon
                  :name="$icons.matInfo"
                  color="white"
                  size="md"
                  class="absolute"
                />
                <div class="info-container">
                  <p>Usernames must be all lowercase, with no spaces.</p>
                  <p>
                    They can only contain
                    <span class="demi-bold-pink">
                      letters, numbers, periods
                      <br />hyphens and underscrores.
                    </span>
                  </p>
                </div>
              </div>
            </template>
          </v-popover>
          <div class="row">
            <div>
              <label>Username</label>
              <q-circular-progress
                indeterminate
                size="30px"
                :thickness="0.2"
                v-if="loader.usernameAvaibility"
                class="c-progress"
              />
              <q-input
                v-model="username"
                @input="handleUsernameInput"
                @blur="checkUsernameAvaibility"
                outlined
                placeholder="Username"
                type="text"
                bottom-slots
                :error="fieldErrors('username').length > 0"
                no-error-icon
                bg-color="white"
                class="text-field-with-append"
                style="margin-right: 18px;"
              >
                <!-- <template v-slot:append>
                  <div @click="() => (popover.username = !popover.username)">
                    <q-icon
                      :name="$icons.matInfo"
                      color="blue-grey-3"
                      size="sm"
                    />
                  </div>
                </template> -->
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("username").length > 0
                        ? fieldErrors("username")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
              
            </div>
            <div class="q-ml-sm flex-1">
              <label>Position</label>
              <q-input
                v-model="professional_title"
                @input="$v.professional_title.$touch()"
                @blur="$v.professional_title.$touch()"
                outlined
                type="text"
                bottom-slots
                :error="fieldErrors('professional_title').length > 0"
                no-error-icon
                bg-color="white"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("professional_title").length > 0
                        ? fieldErrors("professional_title")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
          </div>
          <div class="row">
            <div>
              <label>Email</label>
              <q-input
                v-model="email"
                disable
                outlined
                type="text"
                bg-color="white"
                style="margin-right: 18px"
              />
            </div>
            <div class="q-ml-sm flex-1">
              <label>Phone number</label>
              <q-input
                v-model="phone"
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                outlined
                type="text"
                bottom-slots
                :error="fieldErrors('phone').length > 0"
                no-error-icon
                bg-color="white"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("phone").length > 0
                        ? fieldErrors("phone")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
          </div>
          <div class="flex-x">
            <div>
              <label>Country</label>
              <q-input
                v-model="country"
                @input="$v.country.$touch()"
                @blur="$v.country.$touch()"
                outlined
                type="text"
                bottom-slots
                :error="fieldErrors('country').length > 0"
                no-error-icon
                style="margin-right: 18px"
                bg-color="white"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("country").length > 0
                        ? fieldErrors("country")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
            <div class="q-ml-sm flex-1">
              <label>City</label>
              <q-input
                v-model="city"
                @input="$v.city.$touch()"
                @blur="$v.city.$touch()"
                outlined
                type="text"
                class="flex-1"
                bottom-slots
                :error="fieldErrors('city').length > 0"
                no-error-icon
                input-class="caps-input"
                bg-color="white"
              >
                <template v-slot:error>
                  <div class="text-pink">
                    {{
                      fieldErrors("city").length > 0
                        ? fieldErrors("city")[0]
                        : ""
                    }}
                  </div>
                </template>
              </q-input>
            </div>
          </div>
          <!-- <div class="mt-4"></div> -->
          <label>Timezone:</label>
          <q-select
            outlined
            color="blue-grey-12"
            option-label="text"
            bg-color="white"
            option-value="value"
            popup-content-class="overflow-auto"
            emit-value
            bottom-slots
            map-options
            v-model="timezone"
            :options="timezoneList"
            @input="$v.language.$touch()"
            @blur="$v.language.$touch()"
            dense
          >
            <template v-slot:hint>
              <div style="white-space: break-spaces;">
                Your time zone which is used for summary and notifications
                correspondence for times in your activity feed & reminders.
              </div>
            </template>
          </q-select>
          <div class="text-xs-right">
          <q-btn
            :loading="loader.submit"
            @click.native="updateProfile(false)"
            :disabled="$v.$invalid"
            size="md"
            label="Save"
            class="footer-dialog-ok-btn"
            no-caps
          />
        </div>
        </div>
      </div>
    </div>
    <div v-if="deleteConfirm.flag">
      <confirm-dialog
        v-model="deleteConfirm.flag"
        title="Delete Photo ?"
        question="Are you sure you want to delete this Photo?"
        cancleText="No thanks"
        successText="Delete photo"
        :cancleMethod="() => deletePhotoDialogHandler(false)"
        :successMethod="deletePhoto"
        :loading="deletingPic"
      />
    </div>
  </q-card>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { VPopover } from "v-tooltip";
import validationMixin from "@/mixins/validationMixin";
import {
  uniqueUser,
  validNumber,
  cityCountry,
  validName,
} from "@/utils/validators";
import VueCropper from "vue-cropperjs";
import timezones from "@/utils/timezone";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { uploadtoS3 } from "@/services/auth";
import { CheckUsernameMutation, EditProfileMutation } from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  name: "UserSettingDialog",
  mixins: [validationMixin, ProxyModelMixin],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  validations: {
    first: {
      required,
      validName,
      minLength: minLength(2),
      maxLength: maxLength(60),
    },
    last: {
      required,
      validName,
      minLength: minLength(2),
      maxLength: maxLength(60),
    },
    username: {
      required,
      uniqueUser,
      minLength: minLength(4),
    },
    professional_title: {
      maxLength: maxLength(60),
    },
    phone: {
      validNumber,
      maxLength: maxLength(15),
      minLength: minLength(7),
    },
    skype: {
      maxLength: maxLength(32),
      minLength: minLength(2),
    },
    language: { required },
    country: {
      cityCountry,
      maxLength: maxLength(120),
      minLength: minLength(2),
    },
    city: {
      cityCountry,
      maxLength: maxLength(120),
      minLength: minLength(2),
    },
  },
  validationMessages: {
    first: {
      maxLength: "message.validation.userprofile.first.max",
      validName: "message.validation.userprofile.first.firstname",
      minLength: "message.validation.userprofile.first.min",
      required: "message.validation.userprofile.first.required",
    },
    last: {
      maxLength: "message.validation.userprofile.last.max",
      validName: "message.validation.userprofile.last.lastname",
      minLength: "message.validation.userprofile.last.min",
      required: "message.validation.userprofile.last.required",
    },
    username: {
      required: "message.validation.username.required",
      uniqueUser: "message.validation.username.uniqueUser",
      minLength: "message.validation.username.min",
    },
    professional_title: {
      maxLength: "message.validation.userprofile.professional_title.maxLength",
    },
    phone: {
      maxLength: "message.validation.userprofile.phone.maxLength",
      minLength: "message.validation.userprofile.phone.minLength",
      validNumber: "message.validation.userprofile.phone.valid",
    },
    skype: {
      maxLength: "message.validation.userprofile.skype.maxLength",
      minLength: "message.validation.userprofile.skype.minLength",
    },
    language: {
      required: "message.validation.lang.required",
    },
    country: {
      cityCountry: "message.validation.userprofile.country.valid",
      maxLength: "message.validation.userprofile.country.maxLength",
      minLength: "message.validation.userprofile.country.minLength",
    },
    city: {
      cityCountry: "message.validation.userprofile.city.valid",
      maxLength: "message.validation.userprofile.city.maxLength",
      minLength: "message.validation.userprofile.city.minLength",
    },
  },
  components: {
    VueCropper,
    VPopover,
    ConfirmDialog,
  },
  mounted() {
    this.fetchUser();
  },
  data() {
    return {
      first: null,
      last: null,
      username: null,
      professional_title: null,
      email: null,
      phone: null,
      city: null,
      country: null,
      skype: null,
      pic: null,
      language: null,
      timezone: null,
      id: null,
      error: null,
      languages: ["en"],
      loader: {
        usernameAvaibility: false,
        submit: null,
      },
      deletingPic: false,
      usernameAvaibilityFlag: false,
      usernameAvaibility: null,
      profile: {
        previous: null,
        selected: null,
        cropImage: null,
        errorMessage: null,
        thresholdSize: 25,
        type: "image/jpeg",
      },
      isEdit: false,
      profileUploaded: false,
      deleteConfirm: {
        flag: false,
      },
      popover: {
        username: false,
      },
      fab: false,
      imageValid:true
    };
  },
  methods: {
    async fetchUser() {
      if (this.user) {
        this.id = this.user.id;
        this.first = this.user.first;
        this.last = this.user.last;
        this.username = this.user.username;
        this.professional_title = this.user.designation;
        this.email = this.user.email;
        this.phone = this.user.phone;
        this.skype = this.user.skype;
        this.timezone = this.user.timezone;
        this.pic = this.user.pic;
        this.language = this.user.lang;
        this.city = this.user.city;
        this.country = this.user.country;
        this.profile.previous = this.user.pic;
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    fileTrigger() {
      this.$refs.fileInput.click();
      this.profile.errorMessage = null;
    },
    changeProfile(event) {
      const files = event.target.files;
      if (files.length) {
        const size = files[0].size / (1024 * 1024);
        this.profile.type = files[0].type;
        if(this.profile.type.includes('image')){
          this.imageValid = true
          if (size < this.profile.thresholdSize) {
          this.$refs.cropper.replace(URL.createObjectURL(files[0]));
          this.profile.selected = files[0];
          this.isEdit = true;
        } else {
          this.profile.errorMessage = this.$t(
            "message.validation.file.profileSize"
          );
          this.$refs["fileInput"].value = "";
        }
        }else{
          this.imageValid = false
        }
      }
    },
    clickProfile() {
      this.$refs["fileInput"].value = "";
    },
    deletePhotoDialogHandler(flag = true) {
      this.deleteConfirm.flag = flag;
      this.deletingPic = false;
    },
    deletePhoto() {
      this.deletingPic = true;
      this.updateProfile(true).then(() => {
        this.deletePhotoDialogHandler(false);
      });
    },
    cropImage() {
      this.profile.cropImage = this.$refs.cropper
        .getCroppedCanvas({
          width: 200,
          height: 200,
          fillColor: "#FFF",
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL(this.profile.type);
    },
    setImage() {
      this.isEdit = false;
    },
    handleUsernameInput() {
      this.$v.username.$touch();
      this.username = this.username.toLowerCase();
    },
    async checkUsernameAvaibility() {
      this.loader.usernameAvaibility = true;

      let variables = {
        company_uri: this.activeCompany,
        username: this.username,
        mod: true,
      };

      const response = await this.$api.mutate({
        mutation: CheckUsernameMutation,
        variables,
      });
      if (response) {
        const result = response.data.checkUsernameAvaibility;

        if (result === false) {
          this.usernameAvaibility = this.$t(
            "message.validation.username.alreadyExist"
          );
          this.usernameAvaibilityFlag = true;
        } else {
          this.usernameAvaibility = null;
          this.usernameAvaibilityFlag = false;
        }
        this.loader.usernameAvaibility = false;
      }
    },
    async uploadProfile() {
      if (this.profile.cropImage) {
        const file = await this.dataURLtoFile(
          this.profile.cropImage,
          this.username
        );
        const folderPath =
          "companies/" +
          this.companies[this.activeCompany].companyId +
          "/users/" +
          this.id;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("path", folderPath);
        const res = await uploadtoS3(formData);
        const response = await res;

        if (response) {
          this.pic = response.Key;
          this.profileUploaded = true;
        }
      } else if (!this.profile.cropImage && this.profile.selected) {
        const folderPath =
          "companies/" +
          this.companies[this.activeCompany].companyId +
          "/users/" +
          this.id;

        const formData = new FormData();
        formData.append("file", this.profile.selected);
        formData.append("path", folderPath);
        const res = await uploadtoS3(formData);
        const response = await res;

        if (response) {
          this.pic = response.Key;
          this.profileUploaded = true;
        }
      }
    },
    async updateProfile(removePic = false) {
      this.loader.submit = true;

      await this.checkUsernameAvaibility();

      if (!this.isAvailabityError) {
        await this.uploadProfile();

        let variables = {
          first: this.first,
          last: this.last,
          username: this.username,
          lang: this.language,
          designation: this.professional_title,
          phone: this.phone,
          skype: this.skype,
          city: this.city,
          country: this.country,
          timezone: this.timezone,
          remove_pic: removePic,
        };

        if (this.profileUploaded && !removePic) {
          variables.pic = this.pic;
          this.profileUploaded = false;
        }

        try {
          const user = await this.$api.mutate({
            mutation: EditProfileMutation,
            variables,
          });
          this.loader.submit = false;

          if (user) {
            mixpanel.track("Profile Update");
            mixpanel.people.set({
              $company: this.currentCompany.companyUri,
              $email: user.email,
              $first_name: user.first,
              $last_name: user.last,
              $created: user.created_at,
            });
            this.$store.dispatch("setLang", user.data.editProfile.lang);
            if (!removePic) {
              this.$emit("success", {
                message: this.$t("message.profileSettingUpdate"),
                flag: "modalDialog.userSetting",
              });
            } else {
              this.pic = null;
              this.profile.previous = null;
              this.profile.cropImage = null;
              this.profile.selected = null;
            }
          }
        } catch (error) {
          this.loader.submit = false;
          this.$emit("success", {
            flag: "modalDialog.userSetting",
            message: error.message,
          });
        }
      } else {
        this.loader.submit = false;
      }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("Profile changes saved successfully"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });

    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    timezoneList() {
      return timezones;
    },
    fetchUserProfile() {
      if (this.profile.cropImage) {
        return this.profile.cropImage;
      } else if (this.profile.selected) {
        return URL.createObjectURL(this.profile.selected);
      } else {
        return this.profile.previous;
      }
    },
    isAvailabityError() {
      if (this.usernameAvaibilityFlag && !this.loader.usernameAvaibility) {
        return true;
      } else {
        return false;
      }
    },
    availabityErrorMessage() {
      return this.usernameAvaibility;
    },
    currentCompany() {
      return this.companies[this.activeCompany].companyId;
    },
  },
};
</script>
<style lang="stylus" scoped>
.profile-uploader img {
  height: 140px;
  width: 140px;
}

.pos-edit-img-btn {
  top: -12px;
  left: -12px;
}

.error-message {
  width: 164px;
  text-align: center;
  display: block;
  flex: 1;
}
.desc{
  font-size: 11px;
}
.desc-color{
  color: rgba(0, 0, 0, 0.54);
}

.c-progress{
  color:#007aff;
  margin-left: 10px;
  font-size: 15px !important;
}
</style>
