
<template>
  <div class="">
    <!-- Left Column -->
    <!-- <div class="pr-5" style="max-width: 350px">
      <strong class="an-35 bold-text">Edit Biling Address</strong>
      <div class="divider section-spacer " style="margin-top:0"></div>
      <img
        src="/static/images/settings/setting-moster.png"
        alt="Monster"
        class="absolute-bottom q-ml-lg q-mb-md"
      />
    </div> -->
    <!-- Right Column -->
    <div class="">
      <!-- Scrollable Section for tab components -->
      <div class="">
        <form v-on:submit.prevent>
          <q-input
            v-model="addressForm.street_one"
            @input="$v.addressForm.street_one.$touch()"
            @blur="$v.addressForm.street_one.$touch()"
            outlined
            placeholder="Address line 1"
            type="text"
            bottom-slots
            :error="fieldErrors('addressForm.street_one').length > 0"
            no-error-icon
            bg-color="white"
            autofocus
          >
            <template v-slot:error>
              <div class="text-pink">
                {{
                  fieldErrors("addressForm.street_one").length > 0
                    ? fieldErrors("addressForm.street_one")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <q-input
            v-model="addressForm.street_two"
            outlined
            placeholder="Address line 2"
            class="q-mb-lg"
            type="text"
            bg-color="white"
          />
          <div class="row">
            <q-input
              v-model="addressForm.city"
              @input="$v.addressForm.city.$touch()"
              @blur="$v.addressForm.city.$touch()"
              outlined
              placeholder="City"
              type="text"
              class="q-mb-sm"
              bottom-slots
              :error="fieldErrors('addressForm.city').length > 0"
              no-error-icon
              style="margin-right: 18px;max-width: 47%;"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.city").length > 0
                      ? fieldErrors("addressForm.city")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
            <q-input
              v-model="addressForm.zipcode"
              @input="$v.addressForm.zipcode.$touch()"
              @blur="$v.addressForm.zipcode.$touch()"
              outlined
              placeholder="Zip/Postal"
              type="text"
              class="q-mb-sm q-ml-sm flex-1"
              bottom-slots
              :error="fieldErrors('addressForm.zipcode').length > 0"
              no-error-icon
              input-class="caps-input"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.zipcode").length > 0
                      ? fieldErrors("addressForm.zipcode")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
          </div>
          <div class="row">
            <q-input
              v-model="addressForm.state"
              @input="$v.addressForm.state.$touch()"
              @blur="$v.addressForm.state.$touch()"
              outlined
              placeholder="State/Province"
              type="text"
              class=""
              bottom-slots
              :error="fieldErrors('addressForm.state').length > 0"
              no-error-icon
              style="margin-right: 18px;max-width: 47%;"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.state").length > 0
                      ? fieldErrors("addressForm.state")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
            <q-input
              v-model="addressForm.country"
              @input="$v.addressForm.country.$touch()"
              @blur="$v.addressForm.country.$touch()"
              outlined
              placeholder="Country"
              type="text"
              class="q-ml-sm flex-1"
              bottom-slots
              :error="fieldErrors('addressForm.country').length > 0"
              no-error-icon
              input-class="caps-input"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.country").length > 0
                      ? fieldErrors("addressForm.country")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
          </div>
        </form>
      </div>
      <div class="text-center q-mt-sm">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeBillingDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Update billing"
          class="payment-btn"
          :loading="loader"
          @click.native="submit"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <!-- <q-btn
      flat
      :icon="$icons.matArrowBack"
      class="absolute-top-left q-ml-sm"
      round
      color="dark"
      size="sm"
      @click="$emit('back')"
    /> -->
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "EditBillingAddress",
  mixins: [validationMixin],
  props: ["paycard", "loader"],
  validations: {
    addressForm: {
      street_one: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      city: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      zipcode: {
        required,
        minLength: minLength(5),
      },
      state: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      country: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
    },
  },
  validationMessages: {
    addressForm: {
      street_one: {
        required: "message.validation.billingaddress.street_one.required",
        maxLength: "message.validation.billingaddress.street_one.maxLength",
        minLength: "message.validation.billingaddress.street_one.minLength",
      },
      city: {
        required: "message.validation.billingaddress.city.required",
        maxLength: "message.validation.billingaddress.city.maxLength",
        minLength: "message.validation.billingaddress.city.minLength",
      },
      zipcode: {
        required: "message.validation.billingaddress.zipcode.required",
        minLength: "message.validation.billingaddress.zipcode.minLength",
      },
      state: {
        required: "message.validation.billingaddress.state.required",
        maxLength: "message.validation.billingaddress.state.maxLength",
        minLength: "message.validation.billingaddress.state.minLength",
      },
      country: {
        required: "message.validation.billingaddress.country.required",
        maxLength: "message.validation.billingaddress.country.maxLength",
        minLength: "message.validation.billingaddress.country.minLength",
      },
    },
  },
  mounted() {
    const address = this.paycard.address;
    this.addressForm = { ...address };
  },
  data() {
    return {
      addressForm: {
        street_one: "",
        street_two: "",
        city: "",
        zipcode: "",
        state: "",
        country: "",
      },
    };
  },
  methods: {
    submit() {
      const variables = {
        street1: this.addressForm.street_one,
        street2: this.addressForm.street_two,
        city: this.addressForm.city,
        state: this.addressForm.state,
        country: this.addressForm.country,
        zipcode: this.addressForm.zipcode,
      };
      this.$emit("updateAddress", variables);
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

