<template>
  <div v-if="notification">
    <component
      :is="notificationComponent"
      :notification="notification"
      :user="user"
      :workspaces="workspaces"
      :labels="labels"
      :reactions="reactions"
      :mentions="mentions"
      :isWorkspaceOwner="isWorkspaceOwner"
      :showUserInfo="showUserInfo"
      :currentWorkspace="currentWorkspace"
      :activeTab="activeTab"
      :editDoc="editDoc"
      @clickonmoreoption="clickonmoreoption"
      @deleteCommentPoint="deleteCommentPoint"
      @updatepositions="updatepositions"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @updateComment="updateComment"
      @sendComment="sendComment"
      @deleteMediaComment="deleteMediaComment"
      :setActiveId="setActiveId"
      :activeId="activeId"
      :isNotificationVisible="isNotificationVisible"
      :reactOnPost="reactOnPost"
      :closeReplyEditorHandler="closeReplyEditorHandler"
      @clearNotification="$emit('clearNotification')"
    ></component>
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :closeDocEditor="closeDocEditor"
      :openDocEditor="openDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
      :hideDocumentSidebar="true"
    />
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import NotificationTemplates from "@/components/NotificationTemplates";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";

export default {
  name: "NotificationComponent",
  mixins: [MediaMethodMixin, DocumentEditorMixin],
  props: [
    "notification",
    "user",
    "currentCompany",
    "workspaces",
    "currentWorkspace",
    "isWorkspaceOwner",
    "activeTab",
    "mentions",
    "showUserInfo",
    "labels",
    "reactions",
    "setActiveId",
    "activeId",
    "isNotificationVisible",
    "closeReplyEditorHandler",
    "reactOnPost",
  ],
  components: {
    ...NotificationTemplates,
    DocumentEditorDialog,
  },
  computed: {
    notificationComponent() {
      const { action } = this.notification;
      // console.log("this.notification 123", this.notification, action);
      if (action.type === "message-created") {
        return "NewPostNotification";
      }
      if (action.type === "message-reaction-added") {
        return "NewPostReactionNotification";
      }
      if (action.type === "task-member-attached") {
        return "AssignTaskNotification";
      }
      if (action.type === "task-created") {
        return "AssignTaskNotification";
      }
      if (
        !isEmpty(action.object) &&
        action.type === "subtask-member-attached"
      ) {
        return "AssignSubtaskNotification";
      }
      if (
        action.type === "task-comment-created" ||
        action.type === "task-comment-reaction-added"
      ) {
        return "MentionedInTaskCommentNotification";
      }
      // if (action.type === "task-comment-reaction-added") {
      //   return "NewTaskCommentReactionNotification";
      // }
      return "";
    },
  },
};
</script>
